define('carlock-admin/models/c/vehicle/activation', ['exports', 'ember', 'carlock-admin/helpers/format-time'], function (exports, Ember, FormatTime) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		subscriptionDays: null,
		code: null,
		date: null,
		dateFormatted: Ember['default'].computed('date', FormatTime['default']),
		load: function load(data) {
			this.set('subscriptionDays', data.subscription_days);
			this.set('code', data.code);
			this.set('date', data.date);
			return this;
		}
	});

});