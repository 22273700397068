define('carlock-admin/components/dialogs/add-coupon', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/purchase/purchase-offer', 'carlock-admin/models/c/coupon/generate-coupon'], function (exports, Ember, Base, PurchaseOfferModel, GenerateCouponModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        coupon: null,
        couponTypeList: null,
        validations: {
            'coupon.offerUuid': {
                presence: true
            },
            'coupon.code': {
                presence: true
            },
            'coupon.type': {
                presence: true
            },
            'coupon.startDate': {
                presence: true
            },
            'coupon.value': {
                presence: true,
                numericality: {
                    onlyInteger: true,
                    greaterThan: 0
                }
            }
        },

        actions: {
            show: function show() {
                var type = Ember['default'].Object.extend({
                    id: null,
                    displayName: Ember['default'].computed('id', function () {
                        return 'coupon.type.' + this.get('id').toLowerCase();
                    })
                });

                type.FIXTURES = [type.create({ id: 'FIXED' }), type.create({ id: 'PERCENTAGE' })];
                this.set('couponTypeList', type.FIXTURES);

                this.logger.trace('component:add-coupon: Getting offers');
                var _this = this;

                var prepare = function prepare() {
                    return _this.carlock.getOffers();
                };

                var success = function success(response) {
                    var list = Ember['default'].A();

                    for (var i = 0; i < response.length; i++) {
                        list.pushObject(PurchaseOfferModel['default'].create().load(response[i]));
                    }

                    _this.set('offersList', list);
                };

                this.load(prepare, success);
                this.set('coupon', GenerateCouponModel['default'].create());
            },
            confirm: function confirm() {
                this.logger.trace('component:add-coupon: Adding coupon');
                var _this = this;

                var prepare = function prepare() {
                    var data = _this.get('coupon').toJson();
                    return _this.carlock.addCoupon(data);
                };

                var success = function success() {
                    _this.set('visible', false);
                };

                this.save(prepare, success);
            },
            selectOffer: function selectOffer(offer) {
                var coupon = this.get('coupon');
                coupon.set('offerUuid', offer.get('uuid'));
                this.set('coupon', coupon);
            },
            generateCode: function generateCode() {
                var c = '';
                for (var i = 0; i < 7; i++) {
                    c += String.fromCharCode(97 + Math.floor(Math.random() * 26));
                }

                var coupon = this.get('coupon');
                coupon.set('code', c.toUpperCase());
            }
        }
    });

});