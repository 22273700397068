define('carlock-admin/templates/c/purchases/sim', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": [
            "wrong-type"
          ]
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/c/purchases/sim.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["inline","forms/shipment-form",[],["ship","ship","orderId",["subexpr","@mut",[["get","orderId",["loc",[null,[1,42],[1,49]]]]],[],[]],"items",["subexpr","@mut",[["get","items",["loc",[null,[1,56],[1,61]]]]],[],[]],"title","purchase.sim.title"],["loc",[null,[1,0],[1,90]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});