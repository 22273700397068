define('carlock-admin/models/c/purchase/shipment-item', ['exports', 'ember', 'carlock-admin/models/c/purchase/shipment-platform'], function (exports, Ember, ShipmentPlatform) {

    'use strict';

    var ShipmentItem = Ember['default'].Object.extend({
        name: null,
        quantity: 0,
        id: Ember['default'].computed('platform', function () {
            var platform = this.get('platform.id');

            if (platform) {
                var amazonEu = ['DE', 'FR', 'IT', 'GB', 'ES'].some(function (c) {
                    return platform.endsWith(c);
                });

                if (amazonEu) {
                    platform = 'AMAZON_EU';
                }

                return this.get(platform + '_ID');
            }
        }),

        setPlatform: function setPlatform(platform) {
            this.set('platform', platform);
        }
    });

    var PhysicalItems = [ShipmentItem.create({
        AMAZON_EU_ID: 'CarlockOBD',
        name: 'Teltonika FMB010'
    }), ShipmentItem.create({
        AMAZON_EU_ID: 'Carlock Basic',
        name: 'Teltonika FMT100'
    }), ShipmentItem.create({
        AMAZON_US_ID: 'BG96',
        SHIPPICA_ID: '131313343433',
        name: 'Teltonika FMM001 BG96'
    }), ShipmentItem.create({
        AMAZON_US_ID: 'J0-5AAQ-MU1K',
        SHIPPICA_ID: 'J0-5AAQ-MU1K',
        name: 'Teltonika FMM001'
    }), ShipmentItem.create({
        AMAZON_US_ID: 'FMM00A',
        SHIPPICA_ID: '444400004444',
        name: 'Teltonika FMM00A'
    }), ShipmentItem.create({
        AMAZON_US_ID: 'GV500MA',
        SHIPPICA_ID: '500500500500',
        name: 'Quecklink GV500MA'
    }), ShipmentItem.create({
        SHIPPICA_ID: '444333222111',
        name: 'Quecklink GV500 V2'
    }), ShipmentItem.create({
        AMAZON_EU_ID: 'D6-73FQ-C1LO',
        AMAZON_US_ID: 'OBDExtSlim',
        SHIPPICA_ID: 'OBDExtSlim',
        name: 'OBD Slim extension'
    }), ShipmentItem.create({
        AMAZON_EU_ID: 'OBDPower',
        AMAZON_US_ID: 'OBDPower',
        SHIPPICA_ID: 'OBDPower',
        name: 'OBD Power Adapter'
    }), ShipmentItem.create({
        AMAZON_EU_ID: 'CarlockTag',
        AMAZON_US_ID: 'CarlockTag',
        SHIPPICA_ID: 'CarlockTag',
        name: 'CarLock Tag'
    }), ShipmentItem.create({
        AMAZON_EU_ID: 'FX-51JW-CQG2',
        AMAZON_US_ID: 'FX-51JW-CQG2',
        SHIPPICA_ID: 'FX-51JW-CQG2',
        name: 'OBD splitter cable'
    }), ShipmentItem.create({
        AMAZON_EU_ID: 'CLVibSensor',
        AMAZON_US_ID: 'CLVibSensor',
        SHIPPICA_ID: '691959961124',
        name: 'Vibration sensor'
    })];

    var SimItems = [ShipmentItem.create({
        AMAZON_EU_ID: '0L-VIF0-ISM1',
        AMAZON_US_ID: '0L-VIF0-ISM1',
        SHIPPICA_ID: '0L-VIF0-ISM1',
        name: 'Normal SIM'
    }), ShipmentItem.create({
        AMAZON_EU_ID: 'CLsim',
        AMAZON_US_ID: 'CLsim',
        SHIPPICA_ID: 'CLsim',
        name: 'Micro SIM'
    }), ShipmentItem.create({
        AMAZON_EU_ID: 'Tpsim',
        AMAZON_US_ID: 'Tpsim',
        SHIPPICA_ID: 'Tpsim',
        name: 'Truphone SIM'
    })];

    exports.PhysicalItems = PhysicalItems;
    exports.SimItems = SimItems;

});