define('carlock-admin/models/c/purchase/report/distributor-subscription-item', ['exports', 'ember', 'carlock-admin/helpers/format-time'], function (exports, Ember, FormatTime) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		purchaseUuuid: null,
		date: null,
		dateFormatted: Ember['default'].computed('date', FormatTime['default']),
		vehicleUuid: null,
		accountUuid: null,
		descriptionKey: null,
		descriptionKeyFormatted: Ember['default'].computed('descriptionKey', function () {
			return 'offer.' + this.get('descriptionKey');
		}),
		currency: null,
		price: null,
		distributorPrice: null,
		platform: null,
		platformFormatted: Ember['default'].computed('platform', function () {
			return 'purchase.platform.' + this.get('platform').toLowerCase();
		}),
		paidToProtectus: null,
		paidToProtectusFormatted: Ember['default'].computed('paidToProtectus', function () {
			return this.get('paidToProtectus') ? 'misc.yes' : 'misc.no';
		}),
		base: null,
		discount: null,
		tax: null,
		taxPercentage: null,
		transactionFee: null,
		transactionFeePercentage: null,
		distributorShareBeforeTransactionFee: null,
		distributorTransactionFee: null,
		distributorShareAfterTransactionFee: null,
		toProtectus: null,
		load: function load(data) {
			this.set('purchaseUuid', data.purchase_uuid);
			this.set('date', data.date);
			this.set('vehicleUuid', data.vehicle_uuid);
			this.set('accountUuid', data.account_uuid);
			this.set('descriptionKey', data.description_key);
			this.set('currency', data.currency);
			this.set('price', data.price);
			this.set('distributorPrice', data.distributor_price);
			this.set('platform', data.platform);
			this.set('paidToProtectus', data.paid_to_protectus);
			this.set('base', data.base);
			this.set('discount', data.discount);
			this.set('tax', data.tax);
			this.set('taxPercentage', data.tax_percentage);
			this.set('transactionFee', data.transaction_fee);
			this.set('transactionFeePercentage', data.transaction_fee_percentage);
			this.set('distributorShareBeforeTransactionFee', data.distributor_share_before_transaction_fee);
			this.set('distributorTransactionFee', data.distributor_transaction_fee);
			this.set('distributorShareAfterTransactionFee', data.distributor_share_after_transaction_fee);
			this.set('toProtectus', data.to_protectus);
			return this;
		}
	});

});