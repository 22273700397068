define('carlock-admin/models/c/vehicle/location', ['exports', 'ember', 'carlock-admin/helpers/format-time'], function (exports, Ember, FormatTime) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		timestamp: null,
		timestampFormatted: Ember['default'].computed('timestamp', 'profile.instance.timeZoneId', FormatTime['default']),
		latitude: null,
		longitude: null,
		coordinatesFormatted: Ember['default'].computed('latitude', 'longitude', function () {
			var lat = this.get('latitude');
			var lon = this.get('longitude');

			if (!isNaN(lat) && !isNaN(lon) && lat !== null && lon !== null) {
				return lat.toFixed(3) + ',' + lon.toFixed(3);
			}

			return 'X,Y';
		}),
		altitude: null,
		speed: null,
		heading: null,
		numberOfSatellites: null,
		address: null,
		load: function load(data) {
			this.set('timestamp', data.timestamp);
			this.set('latitude', data.latitude);
			this.set('longitude', data.longitude);
			this.set('altitude', data.altitude);
			this.set('speed', data.speed);
			this.set('heading', data.heading);
			this.set('numberOfSatellites', data.number_of_satellites);
			this.set('address', data.address);
			return this;
		}
	});

});