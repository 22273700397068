define('carlock-admin/routes/c/user', ['exports', 'ember', 'carlock-admin/routes/authenticated', 'carlock-admin/models/c/vehicle/user', 'carlock-admin/models/c/basic-device', 'ember-simple-auth/mixins/authenticated-route-mixin', 'carlock-admin/models/c/user/login-record'], function (exports, Ember, Base, UserModel, BasicDeviceModel, AuthenticatedRouteMixin, LoginRecordModel) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        model: function model(params) {
            return Ember['default'].RSVP.hash({
                user: this.carlock.getUser(params.uuid).then(null, this.onModelError),
                devices: this.carlock.getUserDevices(params.uuid).then(null, this.onModelError),
                logins: this.carlock.getUserLogins(params.uuid).then(null, this.onModelError)
            });
        },
        transformModel: function transformModel(model) {
            var devices = Ember['default'].A();

            for (var i = 0; i < model.devices.length; i++) {
                devices.pushObject(BasicDeviceModel['default'].create().load(model.devices[i]));
            }

            return {
                user: UserModel['default'].create().load(model.user),
                devices: devices,
                logins: model.logins.filter(function (l) {
                    return l.client_type === 'ANDROID' || l.client_type === 'IOS';
                }).map(function (l) {
                    return LoginRecordModel['default'].create().load(l);
                })
            };
        }
    });

});