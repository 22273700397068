define('carlock-admin/authenticators/carlock', ['exports', 'ember', 'ember-simple-auth/authenticators/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Base['default'].extend({
        loggerService: Ember['default'].inject.service('logger'),
        logger: function logger() {
            return this.get('loggerService');
        },
        carlockService: Ember['default'].inject.service('carlock'),
        carlock: function carlock() {
            return this.get('carlockService');
        },
        restore: function restore(properties) {
            this.logger().trace('Restoring session');
            this.logger().trace(properties);

            var propertiesObject = Ember['default'].Object.create(properties);
            return new Ember['default'].RSVP.Promise(function (resolve, reject) {
                if (!Ember['default'].isEmpty(propertiesObject.get('token'))) {
                    resolve(properties);
                } else {
                    reject();
                }
            });
        },
        authenticate: function authenticate(credentials) {
            this.logger().trace('Authenticating');

            var data = {
                'email': credentials.email,
                'password_hash': CryptoJS.SHA256(credentials.password).toString(CryptoJS.enc.Hex),
                'one_time_token': credentials.token
            };

            return this.carlock().login(data);
        },
        invalidate: function invalidate(session) {
            this.logger().trace('Logging out');
            return this.carlock().logout(session.token);
        }
    });

});