define('carlock-admin/components/lists/purchase-list', ['exports', 'ember', 'carlock-admin/mixins/base-list', 'carlock-admin/models/c/basic-purchase', 'carlock-admin/models/c/purchase/status', 'carlock-admin/models/c/purchase/platform'], function (exports, Ember, BaseList, BasicPurchaseModel, PurchaseStatusModel, PurchasePlatformModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(BaseList['default'], {
        skipLoading: Ember['default'].computed('filter', function () {
            return this.get('filter') === null;
        }),
        patternMatching: false,
        statusList: PurchaseStatusModel['default'].FIXTURES,
        statusInstanceObserver: Ember['default'].observer('statusInstance', function () {
            this.logger.trace('component:purchase-list: Status instance changed: ' + this.get('statusInstance.id'));
            this.set('status', this.get('statusInstance.id'));
        }),
        platformList: PurchasePlatformModel['default'].FIXTURES,
        platformInstanceObserver: Ember['default'].observer('platformInstance', function () {
            this.logger.trace('component:purchase-list: Platform instance changed: ' + this.get('platformInstance.id'));
            this.set('platform', this.get('platformInstance.id'));
        }),
        searchTags: ["email", "payment_id", "invoice", "credit_note", "vehicle_uuid", "vat_id"],
        init: function init() {
            this._super.apply(this, arguments);
            this.set('statusInstance', PurchaseStatusModel['default'].FIXTURES.findBy('id', this.get('status')));
            this.set('platformInstance', PurchasePlatformModel['default'].FIXTURES.findBy('id', this.get('platform')));
        },
        prepareLoadRequest: function prepareLoadRequest(filter, sort, descending, page, pageSize) {
            var distributorUuid = this.get('distributorUuid');
            var subscription = this.get('subscription');
            var dateFrom = this.get('dateFrom');
            var dateTo = this.get('dateTo');
            var status = this.get('status');
            var platform = this.get('platform');
            var patternMatching = this.get('patternMatching');
            return this.carlock.getPurchases(distributorUuid, subscription, dateFrom, dateTo, status, platform, filter, sort, descending, page, pageSize, patternMatching);
        },
        loadRequestSucceeded: function loadRequestSucceeded(response) {
            var list = this.get('model');

            for (var i = 0; i < response.purchases.length; i++) {
                list.pushObject(BasicPurchaseModel['default'].create().load(response.purchases[i]));
            }
        },
        actions: {
            togglePickDistributorDialog: function togglePickDistributorDialog() {
                this.toggleProperty('showPickDistributorDialog');
            },
            selectDistributor: function selectDistributor(distributor) {
                this.set('distributor', distributor);
                this.set('distributorUuid', distributor.get('uuid'));
            },
            clearFilter: function clearFilter() {
                this.set('distributor', null);
                this.set('distributorUuid', null);
                this.set('filter', null);
                this.set('dateFrom', null);
                this.set('dateTo', null);
                this.set('status', null);
                this.set('statusInstance', null);
                this.set('platform', null);
                this.set('platformInstance', null);
            },
            exportCsv: function exportCsv() {
                this.get('export').exportPurchases(this.get('model'), 'Purchases ' + new Date() + '.csv');
            },
            search: function search() {
                this.reloadData();
            }
        }
    });

});