define('carlock-admin/templates/c/purchases/reports/gb-vat-report', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "carlock-admin/templates/c/purchases/reports/gb-vat-report.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","tax-report");
          var el2 = dom.createTextNode("\n				");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h1");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n				");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","toolbar");
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","date-range");
          var el4 = dom.createTextNode("\n								");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n								");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createTextNode("-");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n								");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n						");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","generate form-button");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","clear");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" \n				");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
          morphs[1] = dom.createMorphAt(element2,1,1);
          morphs[2] = dom.createMorphAt(element2,5,5);
          morphs[3] = dom.createElementMorph(element3);
          morphs[4] = dom.createMorphAt(element3,0,0);
          return morphs;
        },
        statements: [
          ["inline","t",["report.us_vat_report"],[],["loc",[null,[3,8],[3,36]]]],
          ["inline","date-picker",[],["class","date-from","value",["subexpr","@mut",[["get","taxDateFrom",["loc",[null,[6,46],[6,57]]]]],[],[]],"timeZoneId",["subexpr","@mut",[["get","profile.instance.timeZoneId",["loc",[null,[6,69],[6,96]]]]],[],[]],"placeholder",["subexpr","t",["misc.date_from"],[],["loc",[null,[6,109],[6,129]]]]],["loc",[null,[6,8],[6,131]]]],
          ["inline","date-picker",[],["class","date-to","value",["subexpr","@mut",[["get","taxDateTo",["loc",[null,[8,44],[8,53]]]]],[],[]],"timeZoneId",["subexpr","@mut",[["get","profile.instance.timeZoneId",["loc",[null,[8,65],[8,92]]]]],[],[]],"placeholder",["subexpr","t",["misc.date_to"],[],["loc",[null,[9,22],[9,40]]]]],["loc",[null,[8,8],[9,42]]]],
          ["element","action",["generateTaxReport"],[],["loc",[null,[11,40],[11,70]]]],
          ["inline","t",["report.generate_button"],[],["loc",[null,[11,71],[11,101]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": [
            "wrong-type"
          ]
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/c/purchases/reports/gb-vat-report.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","access-control",["hasPurchaseRwSpecialAccess"],[],["loc",[null,[1,6],[1,51]]]]],[],0,null,["loc",[null,[1,0],[15,7]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});