define('carlock-admin/components/dialogs/set-vehicle-distributor', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        vehicleUuid: null,
        distributor: null,
        validations: {
            'distributor': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                this.set('distributor', null);
            },
            confirm: function confirm() {
                this.logger.trace('component:set-vehicle-distributor: Setting distributor');
                var _this = this;

                var prepare = function prepare() {
                    var distributor = _this.get('distributor');
                    var uuid = _this.get('vehicleUuid');
                    var data = {
                        'distributor_uuid': distributor.get('uuid')
                    };
                    return _this.carlock.setVehicleDistributor(uuid, data);
                };

                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});