define('carlock-admin/models/c/purchase/shipment-platform', ['exports', 'ember', 'carlock-admin/models/country', 'carlock-admin/models/c/purchase/distributor'], function (exports, Ember, CountryModel, Distributor) {

    'use strict';

    var platform = Ember['default'].Object.extend({
        id: null,
        name: null,
        countryCodes: null,
        distributor: null,
        init: function init() {
            var codes = this.get('countryCodes');
            var filter = codes ? function (c) {
                return codes.includes(c.isoCode);
            } : function (c) {
                return true;
            };
            this.set('countries', CountryModel['default'].FIXTURES.filter(filter));
        }
    });

    platform.FIXTURES = [platform.create({
        id: 'SHIPPICA',
        name: 'Shippica',
        countryCodes: ['US', 'CA'],
        distributor: Distributor['default'].CARLOCK_US
    }), platform.create({
        id: 'AMAZON_US',
        name: 'Amazon US',
        countryCodes: ['US', 'CA'],
        distributor: Distributor['default'].CARLOCK_US
    }), platform.create({
        id: 'AMAZON_CA',
        name: 'Amazon CA',
        countryCodes: ['CA'],
        distributor: Distributor['default'].CARLOCK_US
    }), platform.create({
        id: 'AMAZON_IT',
        name: 'Amazon IT',
        countryCodes: ['IT'],
        distributor: Distributor['default'].CARLOCK
    }), platform.create({
        id: 'AMAZON_DE',
        name: 'Amazon DE',
        countryCodes: ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'DE', 'GR', 'HU', 'IE', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SE'],
        distributor: Distributor['default'].CARLOCK
    }), platform.create({
        id: 'AMAZON_GB',
        name: 'Amazon GB',
        countryCodes: ['GB'],
        distributor: Distributor['default'].CARLOCK
    }), platform.create({
        id: 'AMAZON_ES',
        name: 'Amazon ES',
        countryCodes: ['ES'],
        distributor: Distributor['default'].CARLOCK
    }), platform.create({
        id: 'AMAZON_FR',
        name: 'Amazon FR',
        countryCodes: ['FR'],
        distributor: Distributor['default'].CARLOCK
    })];

    exports['default'] = platform;

});