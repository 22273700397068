define('carlock-admin/models/c/vehicle/vehicle', ['exports', 'ember', 'carlock-admin/models/c/vehicle/vehicle-subscription', 'carlock-admin/models/c/vehicle/vehicle-other-settings', 'carlock-admin/models/c/vehicle/distributor', 'carlock-admin/models/c/vehicle/activation', 'carlock-admin/models/c/vehicle/vehicle-notification-settings', 'carlock-admin/models/c/vehicle/location', 'carlock-admin/models/c/vehicle/lock', 'carlock-admin/models/c/vehicle/user', 'carlock-admin/models/c/vehicle/vehicle-state', 'carlock-admin/models/c/vehicle/vehicle-sms-notification-settings', 'carlock-admin/models/c/vehicle/vehicle-call-notification-settings', 'carlock-admin/models/c/vehicle/device'], function (exports, Ember, VehicleSubscriptionModel, VehicleOtherSettingsModel, DistributorModel, ActivationModel, VehicleNotificationSettingsModel, LocationModel, LockModel, UserModel, VehicleStateModel, VehicleSmsNotificationSettingsModel, VehicleCallNotificationSettingsModel, DeviceModel) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        id: null,
        uuid: null,
        distributor: null,
        user: null,
        activation: null,
        device: null,
        lock: null,
        state: null,
        notificationSettings: null,
        smsNotificationSettings: null,
        callNotificationSettings: null,
        otherSettings: null,
        location: null,
        subscription: null,
        showLock: Ember['default'].computed('device.type', function () {
            return this.get('device.type') != 'MOBILE_PHONE';
        }),
        markedAsReplacement: null,
        status: null,
        statusFormatted: Ember['default'].computed('status', function () {
            return 'vehicle.debug.status.' + this.get('status');
        }),
        vehicleData: Ember['default'].computed('id', 'device.imei', 'device.sim.msisdn', 'device.password', function () {
            return '\nID=' + this.get('id') + '\nIMEI=' + this.get('device.imei') + '\nMSISDN=' + this.get('device.sim.msisdn') + '\nPASS=' + this.get('device.password') + '\n';
        }),

        load: function load(data) {
            this.set('id', data.id);
            this.set('uuid', data.uuid);
            this.set('distributor', DistributorModel['default'].create().load(data.distributor));
            this.set('user', UserModel['default'].create().load(data.user));
            this.set('activation', ActivationModel['default'].create().load(data.activation));
            this.set('device', DeviceModel['default'].create().load(data.device));
            this.set('lock', LockModel['default'].create().load(data.lock));
            this.set('state', VehicleStateModel['default'].create().load(data.state));
            this.set('notificationSettings', VehicleNotificationSettingsModel['default'].create().load(data.notification_settings));
            this.set('smsNotificationSettings', VehicleSmsNotificationSettingsModel['default'].create().load(data.sms_notification_settings));
            this.set('callNotificationSettings', VehicleCallNotificationSettingsModel['default'].create().load(data.call_notification_settings));
            this.set('otherSettings', VehicleOtherSettingsModel['default'].create().load(data.other_settings));

            if (data.location) {
                this.set('location', LocationModel['default'].create().load(data.location));
            }

            if (data.subscription) {
                this.set('subscription', VehicleSubscriptionModel['default'].create().load(data.subscription));
            }

            return this;
        }
    });

});