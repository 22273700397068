define('carlock-admin/models/c/vehicle/cohort-item', ['exports', 'ember', 'carlock-admin/helpers/format-date'], function (exports, Ember, FormatDate) {

    'use strict';

    var extended = {
        vehicleUuid: null,
        userEmail: null,
        userName: null,
        promo: null,
        activationDate: null,
        activationDateFormatted: Ember['default'].computed('activationDate', 'profile.instance.timeZoneId', function () {
            return new moment.tz(this.get('activationDate'), 'UTC').format('MM/DD/YYYY');
        }),
        expirationDate: null,
        expirationDateFormatted: Ember['default'].computed('expirationDate', 'profile.instance.timeZoneId', FormatDate['default']),
        lastSeen: null,
        lastSeenFormatted: Ember['default'].computed('lastSeen', 'profile.instance.timeZoneId', FormatDate['default']),
        status: null,
        healthScore: null,
        health: null,
        subscribedAtActivationPlatform: null,
        subscribedAtActivationPlatformLocaleKey: Ember['default'].computed('subscribedAtActivationPlatform', function () {
            var platform = this.get('subscribedAtActivationPlatform');
            return platform === null ? 'misc.no' : 'purchase.platform.' + platform.toLowerCase();
        }),
        distributorName: null,
        rating: null,
        ratingFormatted: Ember['default'].computed('rating', function () {
            var rating = this.get('rating');
            var ratings = ['BAD', 'NEUTRAL', 'GOOD'];
            var ratingSymbols = [':(', ':|', ':)'];
            var formatted = '?';

            if (rating != null) {
                var index = ratings.indexOf(rating);

                if (index !== -1) {
                    var feedback = ratings[index].toLowerCase();
                    formatted = '<span class="rating-' + feedback + '">' + ratingSymbols[index] + '</span>';
                }
            }

            return formatted;
        }),
        tag: null,
        willItWorkDate: null,
        willItWorkDateFormatted: Ember['default'].computed('willItWorkDate', 'profile.instance.timeZoneId', FormatDate['default']),
        willItWorkUtmSource: null,
        willItWorkUtmMedium: null,
        willItWorkUtmCampaign: null,
        willItWorkGclid: null,
        willItWorkUtmSourceFormatted: Ember['default'].computed('willItWorkUtmSource', function () {
            return 'Source=' + this.get('willItWorkUtmSource') + '\n';
        }),
        willItWorkUtmMediumFormatted: Ember['default'].computed('willItWorkUtmMedium', function () {
            return 'Medium=' + this.get('willItWorkUtmMedium') + '\n';
        }),
        willItWorkUtmCampaignFormatted: Ember['default'].computed('willItWorkUtmCampaign', function () {
            return 'Campaign=' + this.get('willItWorkUtmCampaign') + '\n';
        }),
        willItWorkGclidFormatted: Ember['default'].computed('willItWorkGclid', function () {
            return 'gclid=' + this.get('willItWorkGclid') + '\n';
        }),

        wiwToActivationDaysFormatted: Ember['default'].computed('wiwToActivationDays', function () {
            return 'Will it work to activation days=' + this.get('wiwToActivationDays') + '\n';
        }),
        extraTrialCodeFormatted: Ember['default'].computed('extraTrialCode', function () {
            return this.get('extraTrialCode') ? 'PROMO=' + this.get('extraTrialCode') + '\n' : '';
        }),

        willItWorkReason: null,
        willItWorkManufacturer: null,
        willItWorkModel: null,
        carManufacturer: null,
        carModel: null,
        deviceType: null,
        deviceTypeFormatted: Ember['default'].computed('deviceType', function () {
            return 'vehicle.device_type_version.' + this.get('deviceType').toLowerCase();
        }),
        wasReferred: null,
        wiwToActivationDays: Ember['default'].computed('willItWorkDate', 'activationDate', function () {
            var wiwDate = this.get('willItWorkDate');
            return wiwDate ? moment(wiwDate).diff(moment(this.get('activationDate')), 'days') : null;
        }),
        wiwAfterActivation: Ember['default'].computed('wiwToActivationDays', function () {
            return this.get('wiwToActivationDays') > 0;
        }),
        vehicleCount: null,
        multiVehicle: Ember['default'].computed('vehicleCount', function () {
            return this.get('vehicleCount') > 1;
        }),
        country: null,
        firmware: null,
        firmwareRevision: null,
        extraTrialCode: null,
        successScore: null,
        trialWeek1Score: null,
        trialWeek2Score: null,
        trialWeek3Score: null,
        trialWeek4Score: null,
        tier: null,

        load: function load(data) {
            this.set('vehicleUuid', data.uuid);
            this.set('userEmail', data.email);
            this.set('userName', data.name);
            this.set('activationDate', data.activation_date);
            this.set('expirationDate', data.expiration_date);
            this.set('lastSeen', data.last_seen);
            this.set('status', data.status);
            this.set('subscribedAtActivationPlatform', data.subscribed_at_activation_platform);
            this.set('distributorName', data.distributor_name);
            this.set('rating', data.rating);
            this.set('tag', data.user_tag);
            this.set('willItWorkDate', data.will_it_work_date);
            this.set('willItWorkUtmSource', data.will_it_work_utm_source ? data.will_it_work_utm_source : "");
            this.set('willItWorkUtmMedium', data.will_it_work_utm_medium ? data.will_it_work_utm_medium : "");
            this.set('willItWorkUtmCampaign', data.will_it_work_utm_campaign ? data.will_it_work_utm_campaign : "");
            this.set('willItWorkGclid', data.will_it_work_gclid ? data.will_it_work_gclid : "");
            this.set('willItWorkReason', data.will_it_work_reason ? data.will_it_work_reason : "");
            this.set('willItWorkManufacturer', data.will_it_work_manufacturer);
            this.set('willItWorkModel', data.will_it_work_model);
            this.set('carManufacturer', data.car_manufacturer ? data.car_manufacturer.name : null);
            this.set('carModel', data.car_model);
            this.set('deviceType', data.device_type);
            this.set('promo', data.promo);
            this.set('wasReferred', data.was_referred);
            this.set('wiwToActivationDays', data.wiw_to_activation_days);
            this.set('vehicleCount', data.vehicle_count);
            this.set('country', data.country);
            this.set('firmware', data.firmware);
            this.set('firmwareRevision', data.firmware_revision);
            this.set('extraTrialCode', data.extra_trial_code);
            this.set('successScore', data.success_score);
            this.set('trialWeek1Score', data.trial_week1_score);
            this.set('trialWeek2Score', data.trial_week2_score);
            this.set('trialWeek3Score', data.trial_week3_score);
            this.set('trialWeek4Score', data.trial_week4_score);
            this.set('tier', data.tier);

            return this;
        }
    };

    exports['default'] = Ember['default'].Object.extend(extended);

});