define('carlock-admin/services/browser', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Service.extend({
        getNameAndVersion: function getNameAndVersion() {
            var agent = navigator.userAgent,
                tem;
            var matcher = agent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

            if (/trident/i.test(matcher[1])) {
                matcher = /\brv[ :]+(\d+)/g.exec(agent) || [];
                return 'IE ' + (matcher[1] || '');
            }

            if (matcher[1] === 'Chrome') {
                tem = agent.match(/\b(OPR|Edge)\/(\d+)/);

                if (tem != null) {
                    return tem.slice(1).join(' ').replace('OPR', 'Opera');
                }
            }

            matcher = matcher[2] ? [matcher[1], matcher[2]] : [navigator.appName, navigator.appVersion, '-?'];

            if ((tem = agent.match(/version\/(\d+)/i)) != null) {
                matcher.splice(1, 1, tem[1]);
            }

            return matcher.join(' ');
        }
    });

});