define('carlock-admin/models/c/coupon/coupon', ['exports', 'ember', 'carlock-admin/helpers/format-time', 'carlock-admin/models/c/purchase/purchase-offer'], function (exports, Ember, FormatTime, PurchaseOfferModel) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        code: null,
        codeFormatted: Ember['default'].computed('code', function () {
            var code = this.get('code');

            if (code) {
                return code.replace(/(.{5})(.{5})(.{5})/, "$1-$2-$3");
            }

            return null;
        }),
        offer: null,
        startDate: null,
        startDateFormatted: Ember['default'].computed('startDate', 'profile.instance.timeZoneId', FormatTime['default']),
        endDate: null,
        endDateFormatted: Ember['default'].computed('endDate', 'profile.instance.timeZoneId', FormatTime['default']),
        type: null,
        value: null,

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('code', data.code);
            this.set('offer', PurchaseOfferModel['default'].create().load(data.offer));
            this.set('startDate', data.start);
            this.set('endDate', data.end);
            this.set('value', data.value);
            this.set('type', data.type);
            return this;
        }
    });

});