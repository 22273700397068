define('carlock-admin/models/c/vehicle/sms-command', ['exports', 'ember', 'carlock-admin/helpers/format-date'], function (exports, Ember, FormatDate) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        timestamp: null,
        timestampFormatted: Ember['default'].computed('timestamp', 'profile.instance.timeZoneId', FormatDate['default']),
        command: null,
        status: null,

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('timestamp', data.date);
            this.set('command', data.command);
            this.set('status', data.status);

            return this;
        }
    });

});