define('carlock-admin/helpers/format-currency', ['exports'], function (exports) {

    'use strict';

    var formatDate = {
        get: function get(name) {
            if (name.endsWith('Formatted')) {
                var price = this.get(name.substring(0, name.length - 9));
                var currency = this.get('currency');
                price = (price / 100.0).toFixed(2).toLocaleString();

                switch (currency) {
                    case 'EUR':
                        return '€' + price;
                    case 'USD':
                        return '$' + price;
                    case 'GBP':
                        return '£' + price;
                    case 'AUD':
                        return 'A$' + price;
                    case "CAD":
                        return "C$" + price;
                }

                return price + ' ' + currency;
            } else {
                throw 'Must end with \"Formatted\"';
            }
        }
    };

    exports['default'] = formatDate;

});