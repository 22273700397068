define('carlock-admin/controllers/c/profile', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/country', 'carlock-admin/models/time-zone'], function (exports, Ember, Base, CountryModel, TimeZoneModel) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        countries: CountryModel['default'].FIXTURES,
        timeZones: TimeZoneModel['default'].FIXTURES,
        show2Fa: null,

        validations: {
            'model.email': {
                presence: true,
                email: true
            },
            'model.name': {
                presence: true
            },
            'model.country': {
                presence: true
            },
            'model.timeZone': {
                presence: true
            }
        },

        actions: {
            save: function save() {
                this.logger.trace('controller:profile: Saving profile');
                var _this = this;

                var prepare = function prepare() {
                    var isoCode = _this.get('model.country.isoCode');
                    var timeZoneId = _this.get('model.timeZone.id');
                    var data = {
                        'email': _this.get('model.email'),
                        'name': _this.get('model.name'),
                        'country_iso_code': isoCode,
                        'time_zone_id': timeZoneId
                    };
                    return _this.carlock.saveUser(data);
                };

                var success = function success() {
                    _this.set('applicationController.user', _this.get('model'));
                };

                this.save(prepare, success);
            },
            show2Fa: function show2Fa() {
                this.set('show2Fa', true);
            }
        }
    });

});