define('carlock-admin/models/c/vehicle/vehicle-sms-notification-settings', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		disableNotifications: null,
		disableNotificationsFormatted: Ember['default'].computed('disableNotifications', function () {
			return this.get('disableNotifications') ? 'misc.yes' : 'misc.no';
		}),
		deviceDisconnectedEnabled: null,
		deviceDisconnectedEnabledFormatted: Ember['default'].computed('deviceDisconnectedEnabled', function () {
			return this.get('deviceDisconnectedEnabled') ? 'misc.yes' : 'misc.no';
		}),
		movementDetectedEnabled: null,
		movementDetectedEnabledFormatted: Ember['default'].computed('movementDetectedEnabled', function () {
			return this.get('movementDetectedEnabled') ? 'misc.yes' : 'misc.no';
		}),
		vibrationDetectedEnabled: null,
		vibrationDetectedEnabledFormatted: Ember['default'].computed('vibrationDetectedEnabled', function () {
			return this.get('vibrationDetectedEnabled') ? 'misc.yes' : 'misc.no';
		}),
		engineStartedEnabled: null,
		engineStartedEnabledFormatted: Ember['default'].computed('engineStartedEnabled', function () {
			return this.get('engineStartedEnabled') ? 'misc.yes' : 'misc.no';
		}),
		externalAlarmEnabled: null,
		externalAlarmEnabledFormatted: Ember['default'].computed('externalAlarmEnabled', function () {
			return this.get('externalAlarmEnabled') ? 'misc.yes' : 'misc.no';
		}),
		doorOpenedEnabled: null,
		doorOpenedEnabledFormatted: Ember['default'].computed('doorOpenedEnabled', function () {
			return this.get('doorOpenedEnabled') ? 'misc.yes' : 'misc.no';
		}),
		load: function load(data) {
			this.set('disableNotifications', data.disable_notifications);
			this.set('deviceDisconnectedEnabled', data.device_disconnected_enabled);
			this.set('movementDetectedEnabled', data.movement_detected_enabled);
			this.set('vibrationDetectedEnabled', data.vibration_detected_enabled);
			this.set('engineStartedEnabled', data.engine_started_enabled);
			this.set('externalAlarmEnabled', data.external_alarm_enabled);
			this.set('doorOpenedEnabled', data.door_opened_enabled);
			return this;
		}
	});

});