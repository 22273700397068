define('carlock-admin/routes/application', ['exports', 'ember', 'carlock-admin/routes/base', 'ember-simple-auth/mixins/application-route-mixin', 'carlock-admin/models/language'], function (exports, Ember, Base, ApplicationRouteMixin, LanguageModel) {

    'use strict';

    exports['default'] = Base['default'].extend(ApplicationRouteMixin['default'], {
        setupController: function setupController(controller, model) {
            this.logger.trace('controller:application: Setting up controller');
            var locale = LanguageModel['default'].FIXTURES.findBy('isoCode', this.get('i18n.locale'));

            if (locale) {
                this.logger.trace('controller:application: Found locale: ' + locale.isoCode);
                controller.set('selectedLanguage', locale);
            } else {
                this.logger.trace('controller:application: Loading default locale');
                controller.set('selectedLanguage', LanguageModel['default'].FIXTURES[0]);
            }

            this._super(controller, model);
            this.profile.refresh();

            // Fade in
            Ember['default'].run.scheduleOnce('afterRender', this, function () {
                Ember['default'].$('#splash-screen').addClass('hidden');
                Ember['default'].$('#application-root').addClass('visible');
            });
        },
        actions: {
            yikes: function yikes(error) {
                // error.code, error.json - details
                this.logger.trace('route:application: Handling error: ' + error.code);
                this.controller.set('localizedError', 'error.' + error.code);

                if (error.json) {
                    this.controller.set('errorDetails', error.json.details);
                }

                Ember['default'].$("html, body").animate({ scrollTop: 0 }, 'slow');
                this.logger.trace('route:application: Handled error');
            }
        }
    });

});