define('carlock-admin/templates/components/dialogs/health-summary-popup', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": [
            "multiple-nodes"
          ]
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/components/dialogs/health-summary-popup.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h1");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" \n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h3");
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" | ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" | ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","strong");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (HH:mm:ss.S)");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","strong");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (HH:mm:ss.S)");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","strong");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (HH:mm:ss.S)");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","strong");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","strong");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [6]);
        var element2 = dom.childAt(fragment, [8]);
        var element3 = dom.childAt(fragment, [10]);
        var element4 = dom.childAt(fragment, [12]);
        var element5 = dom.childAt(fragment, [14]);
        var morphs = new Array(14);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
        morphs[1] = dom.createMorphAt(element0,1,1);
        morphs[2] = dom.createMorphAt(element0,3,3);
        morphs[3] = dom.createMorphAt(element0,5,5);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
        return morphs;
      },
      statements: [
        ["inline","t",["report.summary"],[],["loc",[null,[1,4],[1,26]]]],
        ["inline","t",["vehicle.health.min"],[],["loc",[null,[2,5],[2,31]]]],
        ["inline","t",["vehicle.health.max"],[],["loc",[null,[2,34],[2,60]]]],
        ["inline","t",["vehicle.health.avg"],[],["loc",[null,[2,63],[2,89]]]],
        ["inline","t",["vehicle.health.device_dead_time"],[],["loc",[null,[5,24],[5,63]]]],
        ["content","cohortItem.health.deviceDeadTime.summary",["loc",[null,[6,9],[6,53]]]],
        ["inline","t",["vehicle.health.device_to_server_delay"],[],["loc",[null,[9,24],[9,69]]]],
        ["content","cohortItem.health.deviceToServerDelay.summary",["loc",[null,[10,9],[10,58]]]],
        ["inline","t",["vehicle.health.push_notifications_delay"],[],["loc",[null,[13,24],[13,71]]]],
        ["content","cohortItem.health.pushNotificationsDelay.summary",["loc",[null,[14,9],[14,61]]]],
        ["inline","t",["vehicle.health.number_of_satellites"],[],["loc",[null,[17,24],[17,67]]]],
        ["content","cohortItem.health.numberOfSatellites.summary",["loc",[null,[18,9],[18,57]]]],
        ["inline","t",["vehicle.health.gsm_strength"],[],["loc",[null,[21,24],[21,59]]]],
        ["content","cohortItem.health.gsmStrength.summary",["loc",[null,[22,9],[22,50]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});