define('carlock-admin/controllers/c/vehicle/debug', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/config/environment'], function (exports, Ember, Base, Config) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        session: Ember['default'].inject.service('session'),
        token: Ember['default'].computed.alias('session.session.content.authenticated.token'),
        role: Ember['default'].computed.alias('session.session.content.authenticated.role'),
        permissions: Ember['default'].computed.alias('session.session.content.authenticated.permissions'),
        url: Config['default'].managerV2URL
    });

});