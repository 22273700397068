define('carlock-admin/models/c/purchase/subscription-plan', ['exports', 'ember', 'carlock-admin/models/c/purchase/offer'], function (exports, Ember, OfferModel) {

	'use strict';

	exports['default'] = OfferModel['default'].extend({
		billingInterval: null,
		billingFrequency: null,
		load: function load(data) {
			this._super(data);
			this.set('billingInterval', data.billing_interval);
			this.set('billingFrequency', data.billing_frequency);
			return this;
		}
	});

});