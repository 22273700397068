define('carlock-admin/models/c/purchase/paypal-accounting-data', ['exports', 'ember', 'carlock-admin/helpers/format-date'], function (exports, Ember, FormatDate) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		name: null,
		country: null,
		invoiceNumber: null,
		invoiceNumberObzorje: null,
		reference: null,
		date: null,
		dateFormatted: Ember['default'].computed('date', 'profile.instance.timeZoneId', FormatDate['default']),
		total: null,
		totalInEuro: null,
		taxTotal: null,
		taxTotalInEuro: null,
		invoiceAmoundPaid: null,
		invoiceAmountDue: null,
		invoiceAmountPaidInEuro: null,
		invoiceAmountDueInEuro: null,
		description: null,
		quantity: null,
		unitAmount: null,
		discount: null,
		lineAmount: null,
		accountCode: null,
		taxType: null,
		currency: null,
		euCompanyVatNumber: null,
		taxLabel: null,
		platform: null,
		isStripe: Ember['default'].computed('platform', function () {
			return this.get('platform') === 'STRIPE';
		}),

		load: function load(data) {
			this.set('name', data.contact_name);
			var country = '';
			if (data.country) {
				country = data.country.nice_name;

				if (data.country.iso_code === 'GB') {
					country = 'UK';
				} else if (data.country.iso_code === 'RU') {
					country = 'Russia';
				}
			}
			this.set('country', country);
			this.set('invoiceNumber', data.invoice_number);
			this.set('invoiceNumberObzorje', data.invoice_number_obzorje);
			this.set('reference', data.reference);
			this.set('date', data.date);
			this.set('total', data.total_in_cents || data.total_in_cents === 0 ? data.total_in_cents / 100 : '/');
			this.set('totalInEuro', data.total_in_euro_cents || data.total_in_euro_cents === 0 ? data.total_in_euro_cents / 100 : '/');
			this.set('taxTotal', data.tax_total_in_cents || data.tax_total_in_cents === 0 ? data.tax_total_in_cents / 100 : '/');
			this.set('taxTotalInEuro', data.tax_total_in_euro_cents || data.tax_total_in_euro_cents === 0 ? data.tax_total_in_euro_cents / 100 : '/');
			this.set('invoiceAmountPaid', data.invoice_amount_paid || data.invoice_amount_paid === 0 ? data.invoice_amount_paid / 100 : '/');
			this.set('invoiceAmountPaidInEuro', data.invoice_amount_paid_in_euro || data.invoice_amount_paid_in_euro === 0 ? data.invoice_amount_paid_in_euro / 100 : '/');
			this.set('invoiceAmountDue', data.invoice_amount_due || data.invoice_amount_due === 0 ? data.invoice_amount_due / 100 : '/');
			this.set('invoiceAmountDueInEuro', data.invoice_amount_due_in_euro || data.invoice_amount_due_in_euro === 0 ? data.invoice_amount_due_in_euro / 100 : '/');
			this.set('description', data.description);
			this.set('quantity', data.quantity ? data.quantity : '/');
			this.set('unitAmount', data.unit_amount || data.unit_amount === 0 ? data.unit_amount : '/');
			this.set('discount', data.discount || data.discount === 0 ? data.discount / 100 : '/');
			this.set('lineAmount', data.line_amount || data.line_amount === 0 ? data.line_amount : '/');
			this.set('accountCode', data.account_code ? data.account_code : '/');
			this.set('taxType', data.tax_type);
			this.set('currency', data.currency);
			this.set('euCompanyVatNumber', data.eu_company_vat_number);
			this.set('taxLabel', data.tax_label);
			this.set('platform', data.platform);

			return this;
		}
	});

});