define('carlock-admin/components/dialogs/two-factor-auth', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        qrCodeUrl: null,
        sharedSecret: null,
        oneWayToken: null,
        checked: null,
        showNew: null,

        actions: {
            confirm: function confirm() {
                var _this = this;
                var enabled = _this.get('checked') ? true : false;

                var prepare = function prepare() {
                    var body = {
                        token: _this.get('oneWayToken'),
                        enabled: enabled
                    };

                    return _this.carlock.set2Fa(body);
                };

                var success = function success() {
                    _this.logger.trace('component:two-factor-auth: Successfully set 2FA to ' + enabled);
                    _this.set('checked', enabled);
                    _this.set('visible', false);
                };

                this.save(prepare, success);
            },

            setup: function setup() {
                this.logger.trace('component:two-factor-auth: Creating 2FA');
                var _this = this;

                var prepare = function prepare() {
                    return _this.carlock.create2Fa();
                };

                var success = function success(data) {
                    _this.logger.trace('component:two-factor-auth: Successfully created 2FA');
                    _this.set('sharedSecret', data.shared_secret);
                    _this.set('qrCodeUrl', data.qr_code_url);
                    _this.set('showNew', true);
                    _this.set('checked', true);
                };

                this.save(prepare, success);
            }
        }
    });

});