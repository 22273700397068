define('carlock-admin/components/validated-input', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        type: 'input',
        showError: Ember['default'].computed('showErrors', 'wasFocused', function () {
            return this.get('showErrors') || this.get('wasFocused');
        }),
        errorClass: Ember['default'].computed('showError', 'errors', function () {
            var errors = this.get('errors');
            var showError = this.get('showError');
            return showError && !Ember['default'].isEmpty(errors) ? 'error' : '';
        }),
        actions: {
            enterPressed: function enterPressed() {
                this.sendAction('enterPressed');
            },
            focusOut: function focusOut() {
                this.set('wasFocused', true);
                this.sendAction('focusOut');
            }
        }
    });

});