define('carlock-admin/components/validated-checkbox', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        checked: false,
        click: function click() {
            this.set('checked', !this.get('checked'));
        }
    });

});