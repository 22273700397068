define('carlock-admin/models/c/login', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        email: null,
        password: null,
        token: null,
        rememberMe: true
    });

});