define('carlock-admin/services/logger', ['exports', 'ember', 'carlock-admin/config/environment'], function (exports, Ember, Config) {

    'use strict';

    exports['default'] = Ember['default'].Service.extend({
        error: function error(message) {
            try {
                if (console && console.error) {
                    if (message && message.responseJSON) {
                        console.error(message.responseJSON);
                    } else {
                        console.error(message);
                    }
                }
            } catch (error) {}
        },
        warn: function warn(message) {
            try {
                if (console && console.warn) {
                    console.warn(message);
                }
            } catch (error) {}
        },
        debug: function debug(message) {
            try {
                if ((Config['default'].staging || Config['default'].development) && console && console.debug) {
                    console.debug(message);
                }
            } catch (error) {}
        },
        trace: function trace(message) {
            try {
                if ((Config['default'].staging || Config['default'].development) && console && console.log) {
                    console.log(message);
                }
            } catch (error) {}
        }
    });

});