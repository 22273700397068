define('carlock-admin/initializers/i18n', ['exports'], function (exports) {

    'use strict';

    exports.initialize = initialize;

    function initialize(application) {
        application.inject('model', 'i18n', 'service:i18n');
        application.inject('controller', 'i18n', 'service:i18n');
        application.inject('route', 'i18n', 'service:i18n');
        application.inject('component', 'i18n', 'service:i18n');
    }

    exports['default'] = {
        name: 'i18n',
        after: 'ember-i18n',
        initialize: initialize
    };

});