define('carlock-admin/templates/c/vehicle/debug', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/c/vehicle/debug.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","main-content");
        dom.setAttribute(el1,"id","debug");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("iframe");
        dom.setAttribute(el2,"height","100%");
        dom.setAttribute(el2,"width","100%");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'allow');
        morphs[1] = dom.createAttrMorph(element0, 'src');
        return morphs;
      },
      statements: [
        ["attribute","allow",["concat",["clipboard-write self ",["get","url",["loc",[null,[2,42],[2,45]]]]]]],
        ["attribute","src",["concat",[["get","url",["loc",[null,[2,56],[2,59]]]],"/vehicles/",["get","model.uuid",["loc",[null,[2,73],[2,83]]]],"/?token=",["get","token",["loc",[null,[2,95],[2,100]]]],"&role=",["get","role",["loc",[null,[2,110],[2,114]]]],"&permissions=",["get","permissions",["loc",[null,[2,131],[2,142]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});