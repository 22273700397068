define('carlock-admin/models/c/purchase/credit-card', ['exports', 'ember', 'carlock-admin/helpers/format-date'], function (exports, Ember, FormatDate) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        id: null,
        expirationDate: null,
        expirationDateFormatted: Ember['default'].computed('expirationDate', function () {
            var expirationDate = this.get('expirationDate');
            var date = undefined;
            if (expirationDate) {
                date = moment(expirationDate).format('MM/YY');
            }

            return date;
        }),
        brand: null,
        ending: null,

        load: function load(data) {
            this.set('id', data.id);
            this.set('expirationDate', data.expiration_date);
            this.set('brand', data.brand);
            this.set('ending', data.ending);

            return this;
        }
    });

});