define('carlock-admin/routes/c/purchases', ['exports', 'ember', 'carlock-admin/routes/authenticated'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Base['default'].extend({
        actions: {
            viewPurchase: function viewPurchase(purchase) {
                this.logger.trace('route:purchases: Redirecting to purchase');
                this.transitionTo('c.purchase', {
                    queryParams: {
                        uuid: purchase.get('uuid')
                    }
                });
            }
        }
    });

});