define('carlock-admin/models/c/vehicle/device-type', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var model = Ember['default'].Object.extend({
        id: null,
        displayName: Ember['default'].computed('id', function () {
            return 'vehicle.device_type.' + this.get('id').toLowerCase();
        })
    });

    model.FIXTURES = [model.create({ id: 'VIRTUAL_OBD' }), model.create({ id: 'VIRTUAL' }), model.create({ id: 'TELTONIKA_FMB010' }), model.create({ id: 'TELTONIKA_FMT100' }), model.create({ id: 'TELTONIKA_FMC880' }), model.create({ id: 'TELTONIKA_FMC800' }), model.create({ id: 'TELTONIKA_FM3010' }), model.create({ id: 'TELTONIKA_FM3001' }), model.create({ id: 'TELTONIKA_FMM001' }), model.create({ id: 'TELTONIKA_FMM00A' }), model.create({ id: 'TELTONIKA_FMM80A' }), model.create({ id: 'TELTONIKA_FM_1000' }), model.create({ id: 'TELTONIKA_FM_1010' }), model.create({ id: 'TELTONIKA_FM_1200' }), model.create({ id: 'TELTONIKA_FM_1202' }), model.create({ id: 'TELTONIKA_FM_1122' }), model.create({ id: 'TELTONIKA_FMB122' }), model.create({ id: 'TELTONIKA_FM3622' }), model.create({ id: 'QUECLINK_GV_500' }), model.create({ id: 'QUECLINK_GV_65' }), model.create({ id: 'QUECLINK_GL_300W' }), model.create({ id: 'QUECLINK_GL_310MG' }), model.create({ id: 'QUECLINK_GL_320MG' }), model.create({ id: 'QUECLINK_GV_500MAP' }), model.create({ id: 'QUECLINK_GV_500MA' }), model.create({ id: 'QUECLINK_GB_130MG' }), model.create({ id: 'MOBILE_PHONE' })];

    exports['default'] = model;

});