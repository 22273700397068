define('carlock-admin/routes/c/purchases/index', ['exports', 'ember', 'carlock-admin/routes/authenticated'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Base['default'].extend({
        beforeModel: function beforeModel() {
            this.transitionTo('c.purchases.devices');
        }
    });

});