define('carlock-admin/components/dialogs/extend-vehicle-subscription', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        vehicleUuid: null,
        offer: null,
        validations: {
            'offer': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                this.set('offer', null);
            },
            confirm: function confirm() {
                this.logger.trace('component:extend-vehicle-subscription: Adding');
                var _this = this;

                var prepare = function prepare() {
                    var offer = _this.get('offer');
                    var uuid = _this.get('vehicleUuid');
                    var data = {
                        'offer_uuid': offer.get('uuid'),
                        'quantity': 1
                    };
                    return _this.carlock.extendVehicleSubscription(uuid, data);
                };

                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});