define('carlock-admin/validators/local/email', ['exports', 'ember', 'ember-validations/validators/base', 'ember-validations/messages'], function (exports, Ember, Base, Messages) {

    'use strict';

    var get = Ember['default'].get;
    var set = Ember['default'].set;

    exports['default'] = Base['default'].extend({
        init: function init() {
            this._super();

            if (this.options === true) {
                set(this, 'options', {});
            }

            if (this.options.message === undefined) {
                set(this, 'options.message', Messages['default'].render('email', this.options));
            }
        },
        call: function call() {
            var text = get(this.model, this.property);

            if (!text) {
                return;
            }

            var pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

            if (!pattern.test(text)) {
                this.errors.pushObject(this.options.message);
            }
        }
    });

});