define('carlock-admin/models/c/purchase/shipping-provider', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var provider = Ember['default'].Object.extend({
        code: null,
        name: null
    });

    provider.FIXTURES = [{ code: 'SHIPPICA', name: 'Shippica' }, { code: 'AMAZON', name: 'Amazon' }];

    exports['default'] = provider;

});