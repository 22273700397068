define('carlock-admin/controllers/c/purchases/shipment', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/vehicle/distributor'], function (exports, Ember, Base, DistributorModel) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        queryParams: ['uuid'],

        loadData: function loadData() {
            var _this = this;

            var prepare = function prepare() {
                return _this.carlock.getDistributors();
            };

            var success = function success(response) {
                var distributor = response.find(function (d) {
                    return d.uuid == _this.get('model.distributorUuid');
                });

                if (distributor) {
                    _this.set('model.distributor', DistributorModel['default'].create().load(distributor));
                }
            };

            this.load(prepare, success);
        }
    });

});