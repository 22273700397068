define('carlock-admin/models/c/vehicle/vehicle-subscription', ['exports', 'ember', 'carlock-admin/helpers/format-date', 'carlock-admin/models/c/vehicle/subscription-tier'], function (exports, Ember, FormatDate, SubscriptionTier) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        recurring: null,
        recurringFormatted: Ember['default'].computed('recurring', function () {
            return this.get('recurring') ? 'misc.yes' : 'misc.no';
        }),
        expirationDate: null,
        expirationDateFormatted: Ember['default'].computed('expirationDate', 'profile.instance.timeZoneId', FormatDate['default']),
        days: Ember['default'].computed('expirationDate', function () {
            var expiration = this.get('expirationDate');
            return expiration ? Math.ceil(moment.duration(moment(expiration).diff(moment())).asDays()) : 0;
        }),
        status: null,
        statusFormatted: Ember['default'].computed('status', function () {
            return 'vehicle.subscription_status.' + this.get('status').toLowerCase();
        }),
        statusClass: Ember['default'].computed('status', function () {
            return this.get('status').toLowerCase().replace(/_/g, '-');
        }),
        canPause: null,
        tier: null,
        showFundsReservation: null,

        load: function load(data) {
            this.set('recurring', data.recurring);
            this.set('expirationDate', data.expiration_date);
            this.set('status', data.status);
            this.set('canPause', data.can_pause);
            this.set('tier', SubscriptionTier['default'].FIXTURES.findBy('id', data.subscription_tier));
            this.set('showFundsReservation', data.show_funds_reservation_offer);

            return this;
        }
    });

});