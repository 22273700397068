define('carlock-admin/models/c/vehicle/vehicle-subscription-extension-reason', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var reason = Ember['default'].Object.extend({
        id: null
    });

    reason.FIXTURES = [{ id: "SUPPORT", text: "Support extension" }, { id: "RETURN_DEVICE", text: "5 months extension for returned device" }, { id: "PURCHASE", text: "Purchased subscription" }, { id: "PROMO_USER", text: "Promo user extension" }];

    exports['default'] = reason;

});