define('carlock-admin/models/c/purchase/status', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var status = Ember['default'].Object.extend({
        id: null,
        localizedKey: Ember['default'].computed('id', function () {
            return 'purchase.status.' + this.get('id').toLowerCase();
        })
    });

    status.FIXTURES = [status.create({ id: 'ACCEPTED' }), status.create({ id: 'PAID' }), status.create({ id: 'COMPLETED' }), status.create({ id: 'REFUNDED' }), status.create({ id: 'DISPUTED' })];

    exports['default'] = status;

});