define('carlock-admin/models/c/vehicle/cohorts', ['exports', 'ember', 'carlock-admin/models/c/vehicle/cohort-item'], function (exports, Ember, CohortItemModel) {

    'use strict';

    var getRatio = function getRatio(items, filter) {
        var all = items.filter(filter);
        var active = all.filter(function (i) {
            var status = i.get('status');return 'ACTIVE' === status || 'WILL_EXPIRE_SOON' === status;
        }).length;
        var activeRatio = active / all.length;
        return (100 * (isNaN(activeRatio) ? 0.00 : activeRatio)).toFixed(2);
    };

    exports['default'] = Ember['default'].Object.extend({
        totalUsers: null,
        activeUsers: null,
        trialUsers: null,
        frozenUsers: null,
        expiredUsers: null,
        promoUsers: null,
        customers: Ember['default'].computed('totalUsers', 'promoUsers', function () {
            var r = this.get('totalUsers') - this.get('promoUsers');
            return isNaN(r) ? 0 : r;
        }),
        securityTagCount: null,
        teenDrivingTagCount: null,
        fleetManagementTagCount: null,
        otherTagCount: null,
        activeSecurityTagCount: null,
        activeTeenDrivingTagCount: null,
        activeFleetManagementTagCount: null,
        activeOtherTagCount: null,
        leftCreditCardCount: null,
        ratio: Ember['default'].computed('activeUsers', 'customers', function () {
            var ratio = this.get('activeUsers') / this.get('customers');
            return (100 * (isNaN(ratio) ? 0.00 : ratio)).toFixed(2);
        }),
        leftCreditCardRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return i.get('subscribedAtActivationPlatform');
            });
        }),
        notLeftCreditCardRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return !i.get('subscribedAtActivationPlatform');
            });
        }),
        securityTagRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return i.get('tag') === 'SECURITY';
            });
        }),
        teenDrivingTagRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return i.get('tag') === 'TEEN_DRIVING';
            });
        }),
        fleetTagRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return i.get('tag') === 'FLEET_MANAGEMENT';
            });
        }),
        otherTagRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return i.get('tag') === 'OTHER';
            });
        }),
        goodRatingRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return i.get('rating') === 'GOOD';
            });
        }),
        badRatingRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return i.get('rating') === 'BAD';
            });
        }),
        wiwRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return i.get('willItWorkDate');
            });
        }),
        noWiwRatio: Ember['default'].computed('cohortItems', function () {
            return getRatio(this.get('cohortItems'), function (i) {
                return !i.get('willItWorkDate');
            });
        }),

        cohortItems: null,
        noLocationForLongTimeCount: null,
        noLocationForShortTimeCount: null,
        noLocationForLongTimeRatio: Ember['default'].computed('customers', 'noLocationForLongTimeCount', function () {
            var r = this.get('noLocationForLongTimeCount') / this.get('customers');
            return isNaN(r) ? '' : r.toFixed(2);
        }),
        noLocationTotalRatio: Ember['default'].computed('customers', 'noLocationForLongTimeCount', 'noLocationForShortTimeCount', function () {
            var stc = this.get('noLocationForShortTimeCount');
            var r = (this.get('noLocationForLongTimeCount') + stc) / this.get('customers');
            return isNaN(r) || stc <= 0 ? '' : ' / ' + r.toFixed(2);
        }),
        wiwCount: Ember['default'].computed('cohortItems', function () {
            return this.get('cohortItems').filter(function (i) {
                return i.get('willItWorkDate');
            }).length;
        }),
        utmCountMap: null,
        utmCountMapFormatted: Ember['default'].computed('utmCountMap', function () {
            var map = this.get('utmCountMap');
            var s = '';
            if (map) {
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = Object.keys(map)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var k = _step.value;

                        s += k + '=' + map[k] + '\n';
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator['return']) {
                            _iterator['return']();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
            }

            return s;
        }),
        wiwToActivationMedianDays: null,
        wiwToActivationAvgDays: null,
        wiwToActivationMedianDaysFormatted: Ember['default'].computed('wiwToActivationMedianDays', function () {
            return 'Will it work to activation days median: ' + this.get('wiwToActivationMedianDays') + '\n';
        }),
        wiwToActivationAvgDaysFormatted: Ember['default'].computed('wiwToActivationAvgDays', function () {
            return 'Will it work to activation days avg: ' + this.get('wiwToActivationAvgDays') + '\n';
        }),
        wiwZeroDaysActivation: null,
        wiwZeroDaysActivationFormatted: Ember['default'].computed('wiwZeroDaysActivation', function () {
            return '0 Will it work to activation days: ' + this.get('wiwZeroDaysActivation');
        }),
        wiwSevenDaysActivation: null,
        wiwSevenDaysActivationFormatted: Ember['default'].computed('wiwSevenDaysActivation', function () {
            return '1-7 Will it work to activation days: ' + this.get('wiwSevenDaysActivation');
        }),
        wiwFourteenDaysActivation: null,
        wiwFourteenDaysActivationFormatted: Ember['default'].computed('wiwFourteenDaysActivation', function () {
            return '8-14 Will it work to activation days: ' + this.get('wiwFourteenDaysActivation');
        }),

        wiwMoreDaysActivation: null,
        wiwMoreDaysActivationFormatted: Ember['default'].computed('wiwMoreDaysActivation', function () {
            return '14+ Will it work to activation days: ' + this.get('wiwMoreDaysActivation');
        }),

        load: function load(data) {
            this.set('totalUsers', data.total_count);
            this.set('activeUsers', data.active_count);
            this.set('trialUsers', data.trial_count);
            this.set('frozenUsers', data.frozen_count);
            this.set('expiredUsers', data.expired_count);
            this.set('promoUsers', data.promo_count);
            this.set('leftCreditCardCount', data.left_card_count);
            this.set('securityTagCount', data.security_tag_count);
            this.set('teenDrivingTagCount', data.teen_driving_tag_count);
            this.set('fleetManagementTagCount', data.fleet_management_tag_count);
            this.set('otherTagCount', data.other_tag_count);
            this.set('utmCountMap', data.utm_count);
            this.set('wiwToActivationAvgDays', data.wiw_to_activation_date_avg_days);
            this.set('wiwToActivationMedianDays', data.wiw_to_activation_date_median_days);
            this.set('wiwZeroDaysActivation', data.wiw_to_activation0_days_count);
            this.set('wiwSevenDaysActivation', data.wiw_to_activation7_days_count);
            this.set('wiwFourteenDaysActivation', data.wiw_to_activation14_days_count);
            this.set('wiwMoreDaysActivation', data.wiw_to_activation_more_days_count);

            var items = Ember['default'].A();
            this.set('cohortItems', items);

            var goodFeedback = 0;
            var badFeedback = 0;
            var noLocationForShortTimeCount = 0;
            var noLocationForLongTimeCount = 0;
            var activeSecurityCount = 0,
                activeTeenDrivingCount = 0,
                activeFleetManagementCount = 0,
                activeOtherCount = 0;

            for (var i = 0; i < data.cohort_items.length; i++) {
                var item = CohortItemModel['default'].create().load(data.cohort_items[i]);
                items.pushObject(item);
                var r = item.get('rating');
                var tag = item.get('tag');
                var _status = item.get('status');

                if (item.get('promo')) {
                    continue;
                }

                if (r) {
                    if (r === 'GOOD') {
                        goodFeedback++;
                    } else if (r === 'BAD') {
                        badFeedback++;
                    }
                }

                if ('ACTIVE' === _status || 'WILL_EXPIRE_SOON' === _status) {
                    if ('SECURITY' === tag) {
                        activeSecurityCount++;
                    } else if ('TEEN_DRIVING' === tag) {
                        activeTeenDrivingCount++;
                    } else if ('FLEET_MANAGEMENT' === tag) {
                        activeFleetManagementCount++;
                    } else if ('OTHER' === tag) {
                        activeOtherCount++;
                    }
                }

                if (item.get('lastSeen') === null) {
                    var activationDate = moment(item.get('activationDate')).utc();

                    // Less than 3 days since activation
                    if (activationDate.add(3, 'days').isAfter(moment().utc())) {
                        noLocationForShortTimeCount++;
                    } else {
                        noLocationForLongTimeCount++;
                    }
                }
            }

            this.set('goodFeedback', goodFeedback);
            this.set('badFeedback', badFeedback);
            this.set('noLocationForLongTimeCount', noLocationForLongTimeCount);
            this.set('noLocationForShortTimeCount', noLocationForShortTimeCount);
            this.set('activeSecurityTagCount', (activeSecurityCount / this.get('securityTagCount')).toFixed(2));
            this.set('activeTeenDrivingTagCount', (activeTeenDrivingCount / this.get('teenDrivingTagCount')).toFixed(2));
            this.set('activeFleetManagementTagCount', (activeFleetManagementCount / this.get('fleetManagementTagCount')).toFixed(2));
            this.set('activeOtherTagCount', (activeOtherCount / this.get('otherTagCount')).toFixed(2));

            return this;
        }
    });

});