define('carlock-admin/controllers/c/login', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        applicationController: Ember['default'].inject.controller('application'),
        validations: {
            'model.email': {
                presence: true
            },
            'model.password': {
                presence: true
            }
        },
        actions: {
            authenticate: function authenticate() {
                this.logger.trace('controller:login: Authenticating');

                var _this = this;
                var prepare = function prepare() {
                    var credentials = {
                        email: _this.get('model.email'),
                        password: _this.get('model.password'),
                        token: _this.get('model.token')
                    };

                    // Set whether to remember login if browser is closed
                    if (_this.get('model.rememberMe')) {
                        _this.set('session.store.cookieExpirationTime', 1209600); // 2 weeks
                    } else {
                            _this.set('session.store.cookieExpirationTime', null);
                        }

                    return _this.get('session').authenticate('authenticator:carlock', credentials);
                };

                var success = function success() {
                    _this.profile.refresh();
                };

                var failed = function failed(code) {
                    if (code == 'unauthorized') {
                        _this.logger.trace('controller:login: Invalid username or password');
                        _this.send('yikes', { code: 'invalid_username_or_password' });
                        return true; // Error handled
                    }
                };

                this.save(prepare, success, failed);
            }
        }
    });

});