define('carlock-admin/components/dialogs/send-push-notification', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/vehicle/push-notification'], function (exports, Ember, Base, PushNotification) {

    'use strict';

    var backends = [{ id: 'v1', name: 'v1' }, { id: 'v2', name: 'v2' }];

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        backend: backends[0],
        backends: backends,
        vehicleUuid: null,
        vehicleId: null,
        pushNotification: null,
        pushNotificationList: PushNotification['default'].FIXTURES,
        validations: {
            'pushNotification': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                this.set('pushNotification', null);
            },
            confirm: function confirm() {
                var _this = this;

                this.logger.trace('component:send-push-notification: Sending push notification');
                var prepare = function prepare() {
                    if (_this.get('backend').id === 'v2') {
                        var uuid = _this.get('vehicleUuid');
                        var data = {
                            'notification': _this.get('pushNotification').name
                        };
                        return _this.carlock.sendPushNotification(uuid, data);
                    } else {
                        var data = {
                            'vehicle_id': _this.get('vehicleId'),
                            'action': _this.get('pushNotification.id')
                        };

                        return _this.carlock.sendPushNotificationV1(data);
                    }
                };

                var success = function success() {
                    return _this.sendAction('success');
                };
                this.save(prepare, success);
            }
        }
    });

});