define('carlock-admin/controllers/c/coupons/coupon', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        queryParams: ['uuid'],
        actions: {
            refresh: function refresh() {
                this.get('target.router').refresh();
            }
        }
    });

});