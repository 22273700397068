define('carlock-admin/routes/c/vehicle/recurring-subscriptions', ['exports', 'ember', 'carlock-admin/routes/authenticated', 'carlock-admin/models/c/vehicle/recurring-subscription', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, Base, RecurringSubscriptionModel, AuthenticatedRouteMixin) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        model: function model(params) {
            return this.carlock.getRecurringSubscriptions(params.vehicle_uuid).then(null, this.onModelError);
        },
        transformModel: function transformModel(model) {
            var subscriptions = Ember['default'].A();
            model.forEach(function (sub) {
                return subscriptions.pushObject(RecurringSubscriptionModel['default'].create().load(sub));
            });
            var sortByDateDesc = function sortByDateDesc(rs1, rs2) {
                return rs2.get('date') - rs1.get('date');
            };
            subscriptions = subscriptions.sort(sortByDateDesc);

            return subscriptions;
        }
    });

});