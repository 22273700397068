define('carlock-admin/components/dialogs/confirm-user-debug-mode', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/config/environment'], function (exports, Ember, Base, Config) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        userUuid: null,
        vehicleUuid: null,
        actions: {
            confirm: function confirm() {
                this.logger.trace('component:confirm-user-debug-mode: Logging in to my.carlock');
                var _this = this;

                var prepare = function prepare() {
                    var uuid = _this.get('userUuid');
                    return _this.carlock.getUserDebugToken(uuid);
                };

                var success = function success(data) {
                    _this.set('visible', false);
                    var uuid = _this.get('vehicleUuid');
                    var url = Config['default'].myCarLockURL;

                    if (uuid) {
                        url += '/map/vehicle/' + uuid + '?auth=' + data.token;
                    } else {
                        url += '/map?auth=' + data.token;
                    }

                    window.open(url);
                    _this.logger.trace('component:confirm-user-debug-mode: Navigated to: ' + url);
                };

                this.save(prepare, success);
            }
        }
    });

});