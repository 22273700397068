define('carlock-admin/components/show-purchase', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        model: null,

        actions: {
            toggleConfirmPaymentDialog: function toggleConfirmPaymentDialog() {
                this.toggleProperty('showConfirmPaymentDialog');
            },
            toggleRegenerateInvoiceDialog: function toggleRegenerateInvoiceDialog() {
                this.toggleProperty('showRegenerateInvoiceDialog');
            },
            toggleRegenerateCreditNoteDialog: function toggleRegenerateCreditNoteDialog() {
                this.toggleProperty('showRegenerateCreditNoteDialog');
            },
            toggleConfirmRefundDialog: function toggleConfirmRefundDialog() {
                this.toggleProperty('showConfirmRefundDialog');
            },
            toggleChargeReservedFundsDialog: function toggleChargeReservedFundsDialog() {
                this.toggleProperty('showChargeReservedFundsDialog');
            },

            createXeroInvoice: function createXeroInvoice() {
                this.sendAction('createXeroInvoice');
            },
            createEracuniInvoice: function createEracuniInvoice() {
                this.sendAction('createEracuniInvoice');
            },
            cancelFundsReservation: function cancelFundsReservation() {
                this.sendAction('cancelFundsReservation');
            }
        }
    });

});