define('carlock-admin/controllers/c/purchases/billing-info', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/purchase/billing-info'], function (exports, Ember, Base, BillingInfo) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        searchTags: ["zip_code"],
        filter: null,
        items: Ember['default'].A(),
        noData: null,

        actions: {
            load: function load() {
                var _this = this;

                this.logger.trace('controller:billing-info: Searching for users by billing info');

                var prepare = function prepare() {
                    return _this.carlock.searchByBillingInfo(_this.get('filter'));
                };

                var success = function success(result) {
                    _this.set('noData', !result);
                    var items = _this.get('items');

                    if (result) {
                        items.clear();

                        result.forEach(function (user) {
                            user.billing_addresses.forEach(function (a) {
                                items.push(BillingInfo['default'].create().load(user.email, a));
                            });
                        });

                        _this.set('items', items);
                    }
                };

                this.load(prepare, success);
            },
            clearFilter: function clearFilter() {
                this.set('filter', null);
            },
            setTag: function setTag(tag) {
                var f = this.get('filter') || "";
                if (!f.includes(tag + ':')) {
                    this.set('filter', f + ' ' + tag + ':');
                }
            }
        }
    });

});