define('carlock-admin/templates/c/login', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 8
            },
            "end": {
              "line": 14,
              "column": 90
            }
          },
          "moduleName": "carlock-admin/templates/c/login.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","t",["login.forgot_password"],[],["loc",[null,[14,61],[14,90]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 8
            },
            "end": {
              "line": 21,
              "column": 8
            }
          },
          "moduleName": "carlock-admin/templates/c/login.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","form spinner");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dot");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dot");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dot");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/c/login.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","main-content");
        dom.setAttribute(el1,"id","login");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        \n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3,"class","form-button");
        dom.setAttribute(el3,"type","submit");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","clear");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(element1,1,1);
        morphs[3] = dom.createMorphAt(element1,3,3);
        morphs[4] = dom.createMorphAt(element1,5,5);
        morphs[5] = dom.createMorphAt(element1,7,7);
        morphs[6] = dom.createMorphAt(dom.childAt(element1, [9]),0,0);
        morphs[7] = dom.createMorphAt(element1,11,11);
        morphs[8] = dom.createMorphAt(element1,13,13);
        return morphs;
      },
      statements: [
        ["inline","t",["login.title"],[],["loc",[null,[2,8],[2,27]]]],
        ["element","action",["authenticate"],["on","submit"],["loc",[null,[4,10],[4,47]]]],
        ["inline","validated-input",[],["type","email","name","login.email","value",["subexpr","@mut",[["get","model.email",["loc",[null,[5,64],[5,75]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.model.email",["loc",[null,[6,19],[6,37]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[6,49],[6,59]]]]],[],[]],"enterPressed","authenticate"],["loc",[null,[5,8],[6,89]]]],
        ["inline","validated-input",[],["type","password","name","login.password","value",["subexpr","@mut",[["get","model.password",["loc",[null,[7,70],[7,84]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.model.password",["loc",[null,[8,19],[8,40]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[8,52],[8,62]]]]],[],[]],"enterPressed","authenticate"],["loc",[null,[7,8],[8,92]]]],
        ["inline","validated-input",[],["type","number","name","login.token","value",["subexpr","@mut",[["get","model.token",["loc",[null,[9,65],[9,76]]]]],[],[]],"mandatory",false,"errors",["subexpr","@mut",[["get","errors.model.token",["loc",[null,[10,19],[10,37]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[10,49],[10,59]]]]],[],[]],"enterPressed","authenticate"],["loc",[null,[9,8],[10,89]]]],
        ["inline","validated-checkbox",[],["name","login.remember_me","checked",["subexpr","@mut",[["get","model.rememberMe",["loc",[null,[11,62],[11,78]]]]],[],[]]],["loc",[null,[11,8],[11,80]]]],
        ["inline","t",["login.login"],[],["loc",[null,[13,50],[13,69]]]],
        ["block","link-to",["c.forgot-password"],["id","forgot-password"],0,null,["loc",[null,[14,8],[14,102]]]],
        ["block","if",[["get","saving",["loc",[null,[15,14],[15,20]]]]],[],1,null,["loc",[null,[15,8],[21,15]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});