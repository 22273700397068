define('carlock-admin/routes/c/purchases/reports/gb-vat-report', ['exports', 'carlock-admin/routes/authenticated', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Base, AuthenticatedRouteMixin) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        renderTemplate: function renderTemplate() {
            this.render('c.purchases.reports.gb-vat-report', {
                into: 'c.purchases.reports',
                outlet: 'reportsOutlet'
            });
        }
    });

});