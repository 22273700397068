define('carlock-admin/models/c/vehicle/health-summary', ['exports', 'ember', 'carlock-admin/models/c/vehicle/health', 'carlock-admin/helpers/format-time'], function (exports, Ember, HealthModel, FormatTime) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        vehicleUuid: null,
        count: null,
        list: null,
        summary: null,

        load: function load(data) {
            this.set('count', data.length);
            var list = Ember['default'].A();

            if (data.length > 0) {
                this.set('summary', HealthModel['default'].create().load(data[data.length - 1]));
            }

            for (var i = data.length - 2; i >= 0; i--) {
                list.pushObject(HealthModel['default'].create().load(data[i]));
            }

            this.set('list', list);

            return this;
        }
    });

});