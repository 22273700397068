define('carlock-admin/models/c/vehicle/trip', ['exports', 'ember', 'carlock-admin/helpers/format-time', 'carlock-admin/models/c/vehicle/location'], function (exports, Ember, FormatTime, LocationModel) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        startTime: null,
        startTimeFormatted: Ember['default'].computed('startTime', FormatTime['default']),
        startLocation: null,
        endTime: null,
        endTimeFormatted: Ember['default'].computed('endTime', FormatTime['default']),
        endLocation: null,
        distance: null,
        score: null,
        scoreFormatted: Ember['default'].computed('score', function () {
            var score = this.get('score');
            return score || score === 0 ? score.toFixed(2) : '/';
        }),
        tag: null,
        tagFormatted: Ember['default'].computed('tag', function () {
            return this.get('tag') ? this.get('tag').charAt(0) : '/';
        }),

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('startTime', data.start_time);
            this.set('startLocation', LocationModel['default'].create().load(data.start_location));
            this.set('endTime', data.end_time);
            this.set('endLocation', data.end_location ? LocationModel['default'].create().load(data.end_location) : null);
            this.set('distance', data.distance);
            this.set('score', data.score);
            this.set('tag', data.tag);

            return this;
        }
    });

});