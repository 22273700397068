define('carlock-admin/routes/c/purchase', ['exports', 'ember', 'carlock-admin/routes/authenticated', 'carlock-admin/models/c/purchase/purchase', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, Base, PurchaseModel, AuthenticatedRouteMixin) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        model: function model(params) {
            return this.carlock.getPurchase(params.uuid).then(null, this.onModelError);
        },
        transformModel: function transformModel(model) {
            return PurchaseModel['default'].create().load(model);
        }
    });

});