define('carlock-admin/router', ['exports', 'ember', 'carlock-admin/config/environment'], function (exports, Ember, Config) {

    'use strict';

    var Router = Ember['default'].Router.extend({
        location: Config['default'].locationType
    });

    Router.map(function () {
        this.route('c', function () {
            this.route('error');
            this.route('login');
            this.route('forgot-password');
            this.route('profile');
            this.route('set-password');

            this.route('devices', function () {
                this.route('devices');
                this.route('cohorts');
                this.route('promo');
                this.route('bulk');
            });
            this.route('user');
            this.route('vehicle', function () {
                this.route('index');
                this.route('health', { path: '/:vehicle_uuid/health' });
                this.route('debug', { path: '/:vehicle_uuid/debug' });
                this.route('recurring-subscriptions', { path: '/:vehicle_uuid/recurring-subscriptions' });
            });
            this.route('purchases', function () {
                this.route('devices');
                this.route('subscriptions');
                this.route('reports', function () {
                    this.route('distributor-report');
                    this.route('us-vat-report');
                    this.route('gb-vat-report');
                    this.route('fb-export');
                });
                this.route('paypal');
                this.route('stripe');
                this.route('promo');
                this.route('replacement');
                this.route('sim');
                this.route('business');
                this.route('billing-info');
                this.route('shipment');
            });
            this.route('purchase');
            this.route('gift-codes');
            this.route('gift-code');
            this.route('coupons', function () {
                this.route('index');
                this.route('coupon');
            });
        });
    });

    exports['default'] = Router;

});