define('carlock-admin/helpers/get-subscription-status', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    exports['default'] = Ember['default'].Helper.helper(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3);

        var expirationDate = _ref2[0];
        var activationDate = _ref2[1];
        var status = _ref2[2];

        if (!activationDate || !status) return false;
        if (!expirationDate) return status === 'active'; //Unlimited subscription

        var activationDatem = moment(activationDate).utc();
        var trialExpirationDate = moment(activationDatem).add(31, 'days'); //1 month free trial

        if (expirationDate > moment().utc().valueOf()) {
            if (expirationDate > trialExpirationDate.valueOf()) {
                return status === 'active'; //Paying subscription
            }

            return status === 'trial';
        } else {
            if (expirationDate > moment().utc().subtract(7, 'days')) {
                return status === 'expired';
            } else {
                return status === 'frozen';
            }
        }

        return false;
    });

});