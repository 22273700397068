define('carlock-admin/models/c/purchase/distributor', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var distributor = Ember['default'].Object.extend({
		uuid: null,
		name: null,
		notificationEmail: null,
		supportEmail: null,
		isCarLock: Ember['default'].computed('uuid', function () {
			return this.get('uuid') === distributor.CARLOCK;
		}),
		isCarLockUs: Ember['default'].computed('uuid', function () {
			return this.get('uuid') === distributor.CARLOCK_US;
		}),

		load: function load(data) {
			if (data) {
				this.set('uuid', data.uuid);
				this.set('name', data.name);
				this.set('notificationEmail', data.notification_email);
				this.set('supportEmail', data.support_email);
			}

			return this;
		}
	});

	distributor.CARLOCK_US = '52e52ad1-9919-4e3b-8f0d-69f91d0aace6';
	distributor.CARLOCK = '5c4d3d0a-a9f2-43fd-bd26-2684d442051c';

	exports['default'] = distributor;

});