define('carlock-admin/models/car-manufacturer', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var carManufacturer = Ember['default'].Object.extend({
        id: null,
        name: null
    });

    carManufacturer.FIXTURES = [{ id: 8, name: 'Alfa Romeo' }, { id: 19, name: 'Aston Martin' }, { id: 22, name: 'Audi' }, { id: 27, name: 'Dacia' }, { id: 32, name: 'BMW' }, { id: 37, name: 'Bentley' }, { id: 45, name: 'Buick' }, { id: 46, name: 'Cadillac' }, { id: 49, name: 'Chevrolet' }, { id: 51, name: 'Chrysler' }, { id: 52, name: 'Citroen' }, { id: 73, name: 'Dodge' }, { id: 90, name: 'Ferrari' }, { id: 92, name: 'Fiat' }, { id: 97, name: 'Ford' }, { id: 101, name: 'GMC' }, { id: 127, name: 'Holden' }, { id: 131, name: 'Honda' }, { id: 135, name: 'Hummer' }, { id: 138, name: 'Hyundai' }, { id: 147, name: 'Infiniti' }, { id: 156, name: 'Jaguar' }, { id: 157, name: 'Jeep' }, { id: 164, name: 'Kia' }, { id: 171, name: 'Lamborghini' }, { id: 173, name: 'Lancia' }, { id: 174, name: 'Land Rover' }, { id: 176, name: 'Lexus' }, { id: 183, name: 'Lotus' }, { id: 195, name: 'Maserati' }, { id: 198, name: 'Mazda' }, { id: 199, name: 'McLaren' }, { id: 201, name: 'Mercedes-Benz' }, { id: 206, name: 'Mini' }, { id: 207, name: 'Mitsubishi Motors' }, { id: 221, name: 'Nissan' }, { id: 225, name: 'Opel' }, { id: 236, name: 'Peugeot' }, { id: 240, name: 'Pontiac' }, { id: 241, name: 'Porsche' }, { id: 253, name: 'Renault' }, { id: 256, name: 'Rolls Royce' }, { id: 261, name: 'Saab' }, { id: 271, name: 'Škoda' }, { id: 327, name: 'Smart' }, { id: 278, name: 'Subaru' }, { id: 280, name: 'Suzuki' }, { id: 287, name: 'Tata Motors' }, { id: 291, name: 'Tesla' }, { id: 295, name: 'Toyota' }, { id: 308, name: 'Vauxhall' }, { id: 313, name: 'Volkswagen' }, { id: 314, name: 'Volvo' }, { id: 500, name: 'Other' }];

    exports['default'] = carManufacturer;

});