define('carlock-admin/initializers/session', ['exports'], function (exports) {

    'use strict';

    exports.initialize = initialize;

    function initialize(application) {
        application.inject('component', 'session', 'service:session');
        application.inject('controller', 'session', 'service:session');
        application.inject('route', 'session', 'service:session');
    }

    exports['default'] = {
        name: 'session',
        initialize: initialize
    };

});