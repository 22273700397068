define('carlock-admin/components/localized-currency', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        tagName: 'span',
        value: null, // in cents
        currency: null,
        hide: Ember['default'].computed('value', function () {
            var value = this.get('value');
            return value === undefined || value == null;
        }),
        display: Ember['default'].computed('value', 'currency', function () {
            var value = this.get('value');
            var currency = this.get('currency');
            value = (value / 100.0).toFixed(2).toLocaleString();

            switch (currency) {
                case 'EUR':
                    return '€' + value;
                case 'USD':
                    return '$' + value;
                case 'GBP':
                    return '£' + value;
                case 'AUD':
                    return 'A$' + value;
                case "CAD":
                    return "C$" + value;
            }

            return value + ' ' + currency;
        })
    });

});