define('carlock-admin/models/c/purchase/report/distributor-subscription-group', ['exports', 'ember', 'carlock-admin/models/c/purchase/report/distributor-subscription-item'], function (exports, Ember, DistributorSubscriptionItemModel) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		descriptionKey: null,
		descriptionKeyFormatted: Ember['default'].computed('descriptionKey', function () {
			return 'offer.' + this.get('descriptionKey');
		}),
		currency: null,
		price: null,
		distributorPrice: null,
		paidToProtectus: null,
		paidToProtectusFormatted: Ember['default'].computed('paidToProtectus', function () {
			return this.get('paidToProtectus') ? 'misc.yes' : 'misc.no';
		}),
		base: null,
		discount: null,
		tax: null,
		transactionFee: null,
		distributorShareBeforeTransactionFee: null,
		distributorTransactionFee: null,
		distributorShareAfterTransactionFee: null,
		toProtectus: null,
		items: null,
		count: null,
		load: function load(data) {
			this.set('descriptionKey', data.description_key);
			this.set('currency', data.currency);
			this.set('price', data.price);
			this.set('distributorPrice', data.distributor_price);
			this.set('paidToProtectus', data.paid_to_protectus);
			this.set('base', data.base);
			this.set('discount', data.discount);
			this.set('tax', data.tax);
			this.set('transactionFee', data.transaction_fee);
			this.set('distributorShareBeforeTransactionFee', data.distributor_share_before_transaction_fee);
			this.set('distributorTransactionFee', data.distributor_transaction_fee);
			this.set('distributorShareAfterTransactionFee', data.distributor_share_after_transaction_fee);
			this.set('toProtectus', data.to_protectus);

			var itemsList = Ember['default'].A();
			for (var i = 0; i < data.items.length; i++) {
				itemsList.pushObject(DistributorSubscriptionItemModel['default'].create().load(data.items[i]));
			}
			this.set('items', itemsList);
			this.set('count', itemsList.length);

			return this;
		}
	});

});