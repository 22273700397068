define('carlock-admin/controllers/c/set-password', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        queryParams: ['hash'],
        hash: null,
        validations: {
            'model.password': {
                presence: true,
                length: { minimum: 8 },
                'localized-confirmation': { message: 'doesnt_match_password' }
            },
            'model.passwordConfirmation': {
                presence: true
            }
        },
        actions: {
            setPassword: function setPassword() {
                var _this = this;

                this.logger.trace('controller:set-password: Setting password');

                var prepare = function prepare() {
                    var data = {
                        password_hash: CryptoJS.SHA256(_this.get('model.password')).toString(CryptoJS.enc.Hex)
                    };

                    var token = _this.hash;

                    if (!token) {
                        token = _this.get('sessionData.token');
                    }

                    return _this.carlock.setPassword(data, token);
                };

                var success = function success() {
                    _this.logger.trace('controller:set-password: Password set, redirecting to login');
                    _this.get('session').invalidate();
                };

                this.save(prepare, success);
            }
        }
    });

});