define('carlock-admin/helpers/format-month', ['exports'], function (exports) {

    'use strict';

    var formatMonth = {
        get: function get(name) {
            if (name.endsWith('Formatted')) {
                var value = this.get(name.substring(0, name.length - 9));

                if (value) {
                    return new moment(value).locale('en').format('MMMM YYYY');
                }

                return null;
            } else {
                throw 'Must end with \"Formatted\"';
            }
        }
    };

    exports['default'] = formatMonth;

});