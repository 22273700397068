define('carlock-admin/models/region', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var region = Ember['default'].Object.extend({
        isoCode: null,
        countries: null
    });

    var afl = ['dz', 'ao', 'sh', 'bj', 'bw', 'bf', 'bi', 'cm', 'cv', 'cf', 'td', 'km', 'cg', 'dj', 'eg', 'gq', 'er', 'et', 'ga', 'gm', 'gh', 'gw', 'gn', 'ci', 'ke', 'ls', 'lr', 'ly', 'mg', 'mw', 'ml', 'mr', 'mu', 'yt', 'ma', 'mz', 'na', 'ne', 'ng', 'st', 're', 'rw', 'st', 'sn', 'sc', 'sl', 'so', 'za', 'sh', 'sd', 'sz', 'tz', 'tg', 'tn', 'ug', 'cd', 'zm', 'tz', 'zw', 'ss', 'cd'];
    var af = region.create({ isoCode: 'af', countries: afl });
    var eul = ['al', 'ad', 'at', 'by', 'be', 'ba', 'bg', 'hr', 'cy', 'cz', 'dk', 'ee', 'fo', 'fi', 'fr', 'de', 'gi', 'gr', 'hu', 'is', 'ie', 'it', 'lv', 'li', 'lt', 'lu', 'mk', 'mt', 'md', 'mc', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sm', 'rs', 'sk', 'si', 'es', 'se', 'ch', 'ua', 'gb', 'va', 'rs', 'im', 'rs', 'me', 'sz', 'tz', 'tg', 'tn', 'ug', 'cd', 'zm', 'tz', 'zw', 'ss', 'cd'];
    var eu = region.create({ isoCode: 'eu', countries: eul });
    var mel = ['ba', 'cy', 'eg', 'ir', 'iq', 'il', 'jo', 'kw', 'lb', 'om', 'ps', 'qa', 'sa', 'sy', 'tr', 'ae', 'ye'];
    var me = region.create({ isoCode: 'me', countries: mel });

    region.AF = af;
    region.EU = eu;
    region.ME = me;
    region.FIXTURES = [af, eu, me];
    region.findByCountry = function (countryIsoCode) {
        var list = region.FIXTURES;

        for (var i = 0; i < list.length; i++) {
            var countries = list[i].get('countries');
            var index = countries.indexOf(countryIsoCode);

            if (index >= 0) {
                return list[i];
            }
        }

        return null;
    };

    exports['default'] = region;

});