define('carlock-admin/components/lists/distributor-list', ['exports', 'ember', 'carlock-admin/mixins/base-list', 'carlock-admin/models/c/vehicle/distributor'], function (exports, Ember, BaseList, DistributorModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(BaseList['default'], {
        prepareLoadRequest: function prepareLoadRequest() {
            return this.carlock.getDistributors();
        },
        loadRequestSucceeded: function loadRequestSucceeded(response) {
            var list = this.get('model');

            for (var i = 0; i < response.length; i++) {
                list.pushObject(DistributorModel['default'].create().load(response[i]));
            }
        }
    });

});