define('carlock-admin/mixins/base', ['exports', 'ember', 'ember-validations', 'npm:nprogress'], function (exports, Ember, EmberValidations, NProgress) {

    'use strict';

    exports['default'] = Ember['default'].Mixin.create(EmberValidations['default'], {
        sessionData: Ember['default'].computed.alias('session.session.content.authenticated'),
        access: Ember['default'].inject.service('access'),
        loading: false,
        saving: false,
        success: false,
        showErrors: false,
        frozen: false,
        error: null,
        localizedError: null,
        errorDetails: null,

        load: function load(prepare, succeed, fail) {
            var _this = this;

            // Check to prevent multi clicks
            _this.logger.trace('mixin:base: Initiating load request');

            if (this.get('loading')) {
                _this.logger.trace('mixin:base: Already loading');
                return;
            }

            // Reset state
            _this.set('error', null);
            _this.set('localizedError', null);
            _this.set('errorDetails', null);

            // Result of a prepare method is a request promise
            var request = prepare();

            if (request) {
                _this.set('loading', true);
                NProgress['default'].start();

                return request.then(function (response) {
                    _this.logger.trace('mixin:base: Request succeeded');

                    // Callback
                    if (succeed) {
                        succeed(response);
                    }
                    // Stop loader
                    NProgress['default'].done();
                    _this.set('loading', false);
                }, function (error) {
                    _this.handleError(error, fail);
                });
            }
        },
        save: function save(prepare, succeed, fail) {
            var _this = this;

            // Check to prevent multi clicks
            _this.logger.trace('mixin:base: Initiating save request');

            if (_this.get('saving')) {
                _this.logger.trace('mixin:base: Previous request is still saving');
                return;
            }

            // Reset state
            _this.set('success', false);
            _this.set('error', null);
            _this.set('localizedError', null);
            _this.set('errorDetails', null);

            // From now on we are allowed to display errors
            _this.set('showErrors', true);

            // Validate
            _this.validate().then(function () {
                // Result of a prepare method is a request promise
                _this.logger.trace('mixin:base: Preparing request');
                var request = prepare();

                if (request) {
                    _this.logger.trace('mixin:base: Executing request');

                    // Start loader
                    NProgress['default'].start();
                    _this.set('saving', true);

                    // Execute
                    request.then(function (response, location) {
                        _this.logger.trace('mixin:base: Request succeeded');

                        // Stop loader
                        NProgress['default'].done();
                        _this.set('saving', false);

                        // Show success
                        _this.set('success', true);

                        // Callback
                        if (succeed) {
                            succeed(response, location);
                        }
                    }, function (error) {
                        _this.logger.trace('mixin:base: Request failed');
                        _this.set('saving', false);
                        _this.handleError(error, fail);
                    });
                } else {
                    _this.logger.trace('mixin:base: Request empty');
                }
            }, function () {
                _this.logger.trace('mixin:base: Validation failed');
                //_this.logger.trace(JSON.stringify(_this.get('errors')));
                // Nothing needs to happen, since errors are displayed via binding
            });
        },
        handleError: function handleError(error, fail) {
            this.logger.trace('mixin:base: Handling error: ' + error.code);

            // Stop loader
            NProgress['default'].done();
            this.set('loading', false);
            this.set('error', error);

            // Callback
            if (fail) {
                var handled = fail(error.code, error.json);

                if (handled) {
                    this.logger.trace('mixin:base: Child handled error');
                    return;
                }
            }

            // Handle frozen vehicles
            if (error.code === 'subscription_frozen') {
                this.logger.trace('mixin:base: Subscription frozen');
                this.set('frozen', true);
                return;
            }

            this.set('localizedError', 'error.' + error.code);

            if (error.json) {
                this.set('errorDetails', error.json.details);
            }

            if (this.sendAction) {
                this.sendAction('yikes', error);
            } else {
                this.send('yikes', error);
            }

            // If response is unauthorized transition to login
            if (error.code === 'unauthorized') {
                this.logger.trace('mixin:base: Unauthorized, transitioning to login');
                this.session.invalidate();
            }

            this.logger.trace('mixin:base: Handled error');
        }
    });

});