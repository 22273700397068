define('carlock-admin/components/dialogs/confirm-charge-reserved-funds', ['exports', 'carlock-admin/mixins/base'], function (exports, Base) {

    'use strict';

    exports['default'] = Ember.Component.extend(Base['default'], {
        purchase: null,
        actions: {
            show: function show() {},
            confirm: function confirm() {
                var _this = this;

                var prepare = function prepare() {
                    return _this.carlock.chargeReservedFunds(_this.get('purchase.uuid'));
                };
                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});