define('carlock-admin/instance-initializers/global', ['exports'], function (exports) {

    'use strict';

    exports.initialize = initialize;

    function initialize(applicationInstance) {
        window.CarLock = applicationInstance;
    }

    exports['default'] = {
        name: 'global',
        initialize: initialize
    };

});