define('carlock-admin/validators/local/vat', ['exports', 'ember', 'ember-validations/validators/base', 'ember-validations/messages', 'carlock-admin/models/country'], function (exports, Ember, Base, Messages, CountryModel) {

    'use strict';

    var get = Ember['default'].get;
    var set = Ember['default'].set;

    exports['default'] = Base['default'].extend({
        init: function init() {
            this._super();
            this.countryCode = null;
            this.countryField = null;

            if (this.options === true) {
                this.options = {};
            }

            if (this.options.message === undefined) {
                this.options.message = Messages['default'].render('invalid_vat', this.options);
            }

            if (this.options.countryCode !== undefined) {
                this.countryCode = this.options.countryCode;
            }

            if (this.options.countryField !== undefined) {
                this.countryField = this.options.countryField;
                this.dependentValidationKeys.pushObject(this.countryField);
            }
        },
        call: function call() {
            var countryCode = null;

            if (this.countryField) {
                countryCode = get(this.model, this.countryField);
            } else if (this.countryCode) {
                countryCode = this.countryCode;
            }

            var value = get(this.model, this.property);

            if (!Ember['default'].isBlank(value)) {
                var vatCountryCode = value.substring(0, 2);

                if (vatCountryCode != countryCode) {
                    this.errors.pushObject(this.options.message);
                }
            }
        }
    });

});