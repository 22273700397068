define('carlock-admin/components/dialogs/edit-vehicle-sim', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/vehicle/sim', 'carlock-admin/models/c/vehicle/sim-provider', 'carlock-admin/models/c/vehicle/sim-service-profile', 'carlock-admin/models/c/vehicle/sim-status'], function (exports, Ember, Base, SimModel, SimProviderModel, SimServiceProfileModel, SimStatusModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        vehicleUuid: null,
        sim: null,
        adding: false,
        newSim: false,
        providerList: SimProviderModel['default'].FIXTURES,
        serviceProfileList: SimServiceProfileModel['default'].FIXTURES,
        statusList: SimStatusModel['default'].FIXTURES,
        validations: {
            'sim.provider': {
                presence: true
            },
            'sim.iccid': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                var sim = this.get('sim');

                if (!sim) {
                    this.set('sim', SimModel['default'].create());
                    this.set('adding', true);
                }
            },
            confirm: function confirm() {
                var _this = this;

                this.logger.trace('component:edit-vehicle-sim: Setting vehicle SIM');
                var newSim = this.get('newSim');

                var prepare = function prepare() {
                    var uuid = _this.get('vehicleUuid');

                    if (newSim) {
                        var data = {
                            'provider': _this.get('sim.provider.id'),
                            'iccid': _this.get('sim.iccid')
                        };

                        return _this.carlock.setNewVehicleSim(uuid, data);
                    } else {
                        var profile = _this.get('sim.serviceProfile');
                        var data = {
                            'provider': _this.get('sim.provider.id'),
                            'iccid': _this.get('sim.iccid'),
                            'imsi': _this.get('sim.imsi'),
                            'msisdn': _this.get('sim.msisdn'),
                            'service_profile': profile ? profile.get('id') : null,
                            'status': _this.get('sim.status.id')
                        };
                        return _this.carlock.setVehicleSim(uuid, data);
                    }
                };

                var success = function success() {
                    if (newSim) {
                        Ember['default'].run.once(function () {
                            _this.save(function () {
                                return _this.carlock.recycleOrActivateSim(_this.get('vehicleUuid'));
                            }, sendSuccess);
                        });
                    } else {
                        sendSuccess();
                    }
                };

                var sendSuccess = function sendSuccess() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            },
            close: function close() {
                this.sendAction('close');
            }
        }
    });

});