define('carlock-admin/components/dialogs/set-vehicle-user', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        vehicleUuid: null,
        user: null,
        validations: {
            'user': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                this.set('user', null);
            },
            confirm: function confirm() {
                this.logger.trace('component:set-vehicle-user: Setting user');
                var _this = this;

                var prepare = function prepare() {
                    var user = _this.get('user');
                    var uuid = _this.get('vehicleUuid');
                    var data = {
                        'user_uuid': user.get('uuid')
                    };
                    return _this.carlock.setVehicleUser(uuid, data);
                };

                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});