define('carlock-admin/instance-initializers/ip-info', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports.initialize = initialize;

    function initialize(applicationInstance) {
        var info = applicationInstance.lookup('service:ip-info');

        if (window.ipInfo) {
            info.infoObtained(window.ipInfo);
            window.ipInfo = null;
        }
    }

    exports['default'] = {
        name: 'ip-info',
        after: 'global',
        initialize: initialize
    };

});