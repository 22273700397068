define('carlock-admin/models/c/vehicle/sim', ['exports', 'ember', 'carlock-admin/models/c/vehicle/sim-provider', 'carlock-admin/models/c/vehicle/sim-service-profile', 'carlock-admin/models/c/vehicle/sim-status'], function (exports, Ember, SimProviderModel, SimServiceProfileModel, SimStatusModel) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
					provider: null,
					iccid: null,
					imsi: null,
					msisdn: null,
					serviceProfile: null,
					status: null,
					statusClass: Ember['default'].computed('status', function () {
									var status = this.get('status');
									return status ? status.get('id').toLowerCase() : null;
					}),
					load: function load(data) {
									this.set('provider', SimProviderModel['default'].FIXTURES.findBy('id', data.provider));
									this.set('iccid', data.iccid);
									this.set('imsi', data.imsi);
									this.set('msisdn', data.msisdn);
									this.set('serviceProfile', SimServiceProfileModel['default'].FIXTURES.findBy('id', data.service_profile));
									this.set('status', SimStatusModel['default'].FIXTURES.findBy('id', data.status));
									return this;
					}
	});

});