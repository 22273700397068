define('carlock-admin/models/c/vehicle/vehicle-state', ['exports', 'ember', 'carlock-admin/helpers/format-time'], function (exports, Ember, FormatTime) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    lastSeen: null,
    lastSeenFormatted: Ember['default'].computed('lastSeen', 'profile.instance.timeZoneId', FormatTime['default']),
    connectedTimestamp: null,
    connectedTimestampFormatted: Ember['default'].computed('connectedTimestamp', 'profile.instance.timeZoneId', FormatTime['default']),
    gsmStrength: null,
    sleepMode: null,
    sleepModeFormatted: Ember['default'].computed('sleepMode', function () {
      var sleep = this.get('sleepMode');
      return 'vehicle.sleep_mode.' + (sleep ? sleep.toLowerCase() : 'unknown');
    }),
    markedAsReplacement: null,
    deviceVoltage: null,
    deviceCurrent: null,
    enabledDeviceVoltage: null,
    enabledDeviceCurrent: null,
    lastDeviceCharge: null,
    lastDeviceChargeFormatted: Ember['default'].computed('lastDeviceCharge', 'profile.instance.timeZoneId', FormatTime['default']),

    load: function load(data) {
      this.set('lastSeen', data.last_seen);
      this.set('connectedTimestamp', data.connected_timestamp);
      this.set('gsmStrength', data.gsm_strength);
      this.set('sleepModel', data.sleep_mode);
      this.set('markedAsReplacement', data.marked_as_replacement);
      this.set('deviceVoltage', data.device_voltage);
      this.set('deviceCurrent', data.device_current);
      this.set('enabledDeviceVoltage', data.enabled_device_voltage);
      this.set('enabledDeviceCurrent', data.enabled_device_current);
      this.set('lastDeviceCharge', data.last_device_charge);

      return this;
    }
  });

});