define('carlock-admin/misc/obzorje-sifranti', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	var sifrant = Ember['default'].Object.extend({
		id: null,
		name: null,
		vatId: null
	});

	sifrant.FIXTURES = [{
		id: 0,
		name: "ADMINISTRATOR"
	}, {
		id: 1,
		name: "IGETIT.COM"
	}, {
		id: 2,
		name: "RS5, RAČUNALNIŠKI SISTEMI D.O.",
		vatId: "SI65361628"
	}, {
		id: 4,
		name: "KONČNI POTROŠNIK",
		vatId: "SI0000000"
	}, {
		id: 5,
		name: "STAL ELEKTRONIK TRGOVINA, SERV",
		vatId: "SI79312837"
	}, {
		id: 6,
		name: "PROXIMUS ENTERPRISE S.R.O.",
		vatId: "SK2023825826"
	}, {
		id: 7,
		name: "BLUE EYES",
		vatId: "FR66807381652"
	}, {
		id: 8,
		name: "SERGIO X PLAZA"
	}, {
		id: 9,
		name: "PROTECTUS TEHNOLOGIES INC"
	}, {
		id: 10,
		name: "NEW LOCK S.R.L.",
		vatId: "IT07871591215"
	}, {
		id: 11,
		name: "BIG BANG, TRGOVINA IN STORITVE",
		vatId: "SI18224326"
	}, {
		id: 12,
		name: "INTEC TIV, TISKANA VEZJA D.O.O",
		vatId: "SI82023883"
	}, {
		id: 13,
		name: "CENTER ODLIČNOSTI ZA BIOSENZOR",
		vatId: "SI83212353"
	}, {
		id: 14,
		name: "PLANINŠEC DERŽEK JOŽICA S.P., ",
		vatId: "SI83146172"
	}, {
		id: 15,
		name: "WEBICOM, TRGOVINA IN STORITVE,",
		vatId: "SI15318605"
	}, {
		id: 16,
		name: "GOOGLE IRELAND LIMITED"
	}, {
		id: 17,
		name: "TELTONIKA UAB",
		vatId: "LT244298917"
	}, {
		id: 18,
		name: "DHL EKSPRES (SLOVENIJA), HITRA",
		vatId: "SI18805477"
	}, {
		id: 19,
		name: "A1 DIGITAL INTERNATIONAL GMBH",
		vatId: "ATU66624566"
	}, {
		id: 20,
		name: "AITAO CHENG"
	}, {
		id: 21,
		name: "EGP EMBALAŽNO GRAFIČNO PODJETJ",
		vatId: "SI86534637"
	}, {
		id: 22,
		name: "ŠS STORITVENO PODJETJE D.O.O.",
		vatId: "SI93360894"
	}, {
		id: 23,
		name: "POŠTA SLOVENIJE D.O.O.",
		vatId: "SI25028022"
	}, {
		id: 24,
		name: "SHENZEN WELLCORP TEHNOLOGY LTD"
	}, {
		id: 25,
		name: "WINWIN ELECTRON LTD"
	}, {
		id: 26,
		name: "SHIPWIRE"
	}, {
		id: 27,
		name: "PRIMORSKI TEHNOLOŠKI PARK D.O.",
		vatId: "SI44124228"
	}, {
		id: 28,
		name: "GAMBIT TRADE D.O.O., SISTEMSKA",
		vatId: "SI13650203"
	}, {
		id: 29,
		name: "CES TRGOVINA Z ELEKTRONIKO D.O",
		vatId: "SI78912733"
	}, {
		id: 30,
		name: "ODVETNIKI MALOVRH&KASTIVNIK O.",
		vatId: "SI39973506"
	}, {
		id: 31,
		name: "SIONSI, RAZISKAVE IN RAZVOJ, D",
		vatId: "SI52538311"
	}, {
		id: 32,
		name: "INTUITIVA RAČUNALNIŠKE STORITV",
		vatId: "SI63642760"
	}, {
		id: 33,
		name: "LED LUKS, PROIZVODNJA SVETIL, ",
		vatId: "SI44941609"
	}, {
		id: 34,
		name: "AERO PRINT TISKANJE NALEPK D.O",
		vatId: "SI12636533"
	}, {
		id: 35,
		name: "MIMOVRSTE, SPLETNA TRGOVINA,D.",
		vatId: "SI81241038"
	}, {
		id: 36,
		name: "JAGROS TRGOVINA, PROIZVODNJA I",
		vatId: "SI79596592"
	}, {
		id: 37,
		name: "KONČNI POTROŠNIK TUJINA"
	}, {
		id: 38,
		name: "ARITI GRAND HOTEL CORFU",
		vatId: "GR094020594"
	}, {
		id: 39,
		name: "ETA S.A.",
		vatId: "EL094026224"
	}, {
		id: 40,
		name: "TSAOUSOGLOU S.A.",
		vatId: "EL094062573"
	}, {
		id: 41,
		name: "VIMEO LLC",
		vatId: "EU826014392"
	}, {
		id: 42,
		name: "EASYJET AIRLINE COMPANY LTD",
		vatId: "GB745360825"
	}, {
		id: 43,
		name: "SWISS INT"
	}, {
		id: 44,
		name: "MESTA OBČINA NOVA GORICA"
	}, {
		id: 45,
		name: "ALIEXPRESS"
	}, {
		id: 46,
		name: "FUJIAN XING"
	}, {
		id: 47,
		name: "HANATAJ KURIRSKE STORITVE, REF",
		vatId: "SI25131966"
	}, {
		id: 48,
		name: "SUBAN TRGOVINA IN STORITVE D.O",
		vatId: "SI26391201"
	}, {
		id: 49,
		name: "HABANERO DI ANDREA FEDERICO VE",
		vatId: "IT10396050014"
	}, {
		id: 50,
		name: "YLLI SERVICE SOCIETA' COOPERAT",
		vatId: "IT12288401008"
	}, {
		id: 51,
		name: "SKOKEC, KURIRSKA DEJAVNOST IN ",
		vatId: "SI28148614"
	}, {
		id: 52,
		name: "NOVA LJUBLJANSKA BANKA D.D., L",
		vatId: "SI91132550"
	}, {
		id: 53,
		name: "BANKA KOPER D.D.",
		vatId: "SI98026305"
	}, {
		id: 54,
		name: "HETZNER ONLINE GMBH",
		vatId: "DE812871812"
	}, {
		id: 55,
		name: "JOGER MIRAN S.P. ",
		vatId: "SI86767194"
	}, {
		id: 56,
		name: "HRIBAR JANEZ S.P. - SERVIS FOT",
		vatId: "SI88665399"
	}, {
		id: 57,
		name: "INTERNATIONAL PROCURMENT "
	}, {
		id: 58,
		name: "SPRL DJM WEB",
		vatId: "BE0887547822"
	}, {
		id: 59,
		name: "COLOTTI ANGELA",
		vatId: "IT03745240717"
	}, {
		id: 60,
		name: "GREGORC KRISTIJAN S.P.",
		vatId: "SI21735140"
	}, {
		id: 61,
		name: "CONNECTED WIRLD FOR INFORMA"
	}, {
		id: 62,
		name: "CPA CENTRO PRODUZIONE AUDIOVIS",
		vatId: "IT06200821004"
	}, {
		id: 63,
		name: "ALTERINI BUS S.R.L.",
		vatId: "IT02048210484"
	}, {
		id: 64,
		name: "TOPDRIVE CENTRO DE FORMAÇAO RO",
		vatId: "PT504699610"
	}, {
		id: 65,
		name: "VMT Union, s.r.o.",
		vatId: "CZ24801887"
	}, {
		id: 67,
		name: "OZIRIS RAČUNOVODSKE STORITVE D",
		vatId: "SI55972888"
	}, {
		id: 68,
		name: "ŠEGULA S.P."
	}, {
		id: 69,
		name: "VMT Pharma, s.r.o.",
		vatId: "SK2022131870"
	}, {
		id: 70,
		name: "TEMBO ENTERPRISES TRY LTD"
	}, {
		id: 71,
		name: "BSP GLOBAL CORP.LTD."
	}, {
		id: 72,
		name: "BULPOL CENTER LTD.",
		vatId: "BG121810019"
	}, {
		id: 73,
		name: "MARYLAND PUBLIC TELEVISION"
	}, {
		id: 74,
		name: "ENZO GRAFIKA REKLAMNI NAPISI I",
		vatId: "SI33542830"
	}, {
		id: 75,
		name: "METAKOCKA, NAPREDNE SPLETNE AP",
		vatId: "SI62315625"
	}, {
		id: 76,
		name: "BURGAS 2010",
		vatId: "BG201291322"
	}, {
		id: 77,
		name: "ZSOLT VARGA",
		vatId: "HU13137180"
	}, {
		id: 78,
		name: "EUCON, EVROPSKA SVETOVALNA MRE",
		vatId: "SI94061661"
	}, {
		id: 79,
		name: "AGENCIJA REPUBLIKE SLOVENIJE Z",
		vatId: "SI14717468"
	}, {
		id: 80,
		name: " CERAR JURE S.P.",
		vatId: "SI14952505"
	}, {
		id: 81,
		name: "V L A B O, s.r.o.",
		vatId: "CZ60935405"
	}, {
		id: 82,
		name: "POGAČAR ANTON S.P. - AVTOELEKT",
		vatId: "SI19976984"
	}, {
		id: 83,
		name: "BEAUTYPRO LTD",
		vatId: "GB998299035"
	}, {
		id: 84,
		name: "DIRECTED ELECTRONICS"
	}, {
		id: 85,
		name: "R O B O, PODJETJE ZA TRGOVINO ",
		vatId: "SI61142000"
	}, {
		id: 86,
		name: "IRP INŠTITUT ZA RAZISKOVANJE P",
		vatId: "SI18464696"
	}, {
		id: 87,
		name: "SIKOŠEK JOŽE - NOTAR",
		vatId: "SI45826226"
	}, {
		id: 88,
		name: "VERBILINGA PREVAJALSKE STORITV"
	}, {
		id: 89,
		name: "ŠTUDENTSKI SERVIS, POSREDOVANJ",
		vatId: "SI23389826"
	}, {
		id: 90,
		name: "JFDI GMBH",
		vatId: "ATU67137779"
	}, {
		id: 91,
		name: "FINESELL GMBH",
		vatId: "DE293960566"
	}, {
		id: 92,
		name: "PACO LOPEZ",
		vatId: "ESG83479972"
	}, {
		id: 93,
		name: "REDOK EDI DRUŽBA ZA PODJETNIŠK",
		vatId: "SI91301858"
	}, {
		id: 94,
		name: "INVENTUS LAW INC"
	}, {
		id: 95,
		name: "GO-DOTI TRGOVINA IN STORITVE D",
		vatId: "SI85260576"
	}, {
		id: 96,
		name: "WAKESYS SARL",
		vatId: "DE294396838"
	}, {
		id: 97,
		name: "ENFORCER LTD.",
		vatId: "BG200705941"
	}, {
		id: 98,
		name: "FINANČNI TRGI, MEDIJI IN POSLO",
		vatId: "SI60032464"
	}, {
		id: 99,
		name: "INNOVATION TICKS COMPANY"
	}, {
		id: 100,
		name: "ROBERT WILLIAM RACKSTRAW !! RO",
		vatId: "GB565901916"
	}, {
		id: 101,
		name: "BAČAR FRANCI S.P."
	}, {
		id: 102,
		name: "Eurosafe Security Solutions AB",
		vatId: "SE556891806301"
	}, {
		id: 103,
		name: "ABMOBIL POSLOVNE STORITVE D.O.",
		vatId: "SI84680296"
	}, {
		id: 104,
		name: "DARILNICA, IDEJE ZA DARILA, D.",
		vatId: "SI76194086"
	}, {
		id: 105,
		name: "MIKLIČ MITJA - SPECIALIST MEDI",
		vatId: "SI47982535"
	}, {
		id: 106,
		name: "KOŠAR BRATUŠA KSENIJA - NOTARK",
		vatId: "SI80101283"
	}, {
		id: 107,
		name: "MIPRO, RAZVOJ, PROIZVODNJA IN ",
		vatId: "SI86181122"
	}, {
		id: 108,
		name: "OSNOVNO ZDRAVSTVO GORENJSKE"
	}, {
		id: 109,
		name: "MEDICINA DELA MG D.O.O.",
		vatId: "SI74904574"
	}, {
		id: 110,
		name: "FORPLAN, SVETOVANJE, POSLOVNE ",
		vatId: "SI 67131824"
	}, {
		id: 111,
		name: "ZALAZNIK AVTOELEKTRIKA, AVTOAK",
		vatId: "SI 96065672"
	}, {
		id: 112,
		name: "PETROL, SLOVENSKA ENERGETSKA D",
		vatId: "SI80267432"
	}, {
		id: 113,
		name: "REX S.R.L. UNIPERSONALE",
		vatId: "IT06477451212"
	}, {
		id: 114,
		name: "Comm.V B-NYS",
		vatId: "BE0845590669"
	}, {
		id: 115,
		name: "FGL INFORMATICA DI MARCANTOGNI",
		vatId: "IT02423470414"
	}, {
		id: 116,
		name: "ROCKWARM INSULATION LTD",
		vatId: "GB418632255"
	}, {
		id: 117,
		name: "FRANCHI MARCO",
		vatId: "IT01448000081"
	}, {
		id: 118,
		name: "KERČMAR MARKO S.P."
	}, {
		id: 119,
		name: "SKB D.D."
	}, {
		id: 120,
		name: "GORENJSKA BANKA D.D."
	}, {
		id: 121,
		name: "DELAVSKA HRANILNICA D.D."
	}, {
		id: 122,
		name: "EURO GAMES GMBH",
		vatId: "DE201244402"
	}, {
		id: 123,
		name: "JASON JOHN WRIGHT !! JASON WRI",
		vatId: "GB249709860"
	}, {
		id: 124,
		name: "TOP NOTCH ALLOYS",
		vatId: "GB756641410"
	}, {
		id: 125,
		name: "KOBI KREATIVNO OBLIKOVANI INTE",
		vatId: "SI60386428"
	}, {
		id: 126,
		name: "AMAZING.COM"
	}, {
		id: 127,
		name: "ODVETNIŠKA PISARNA ULČAR & PAR",
		vatId: "SI28596897"
	}, {
		id: 128,
		name: "FOTOLITO DOLENC PODJETJE ZA GR",
		vatId: "SI81578610"
	}, {
		id: 129,
		name: "STEVE VINCENT MAHIRI UK"
	}, {
		id: 130,
		name: "DIONIS TRADE, POSREDNIŠTVO IN ",
		vatId: "SI61330132"
	}, {
		id: 131,
		name: "UNTON INC."
	}, {
		id: 132,
		name: "BOYNA HONKONG LTD"
	}, {
		id: 133,
		name: "C:WORX",
		vatId: "NL152375296B01"
	}, {
		id: 134,
		name: "FACEBOOK IRELAND LIMITED",
		vatId: "IE9692928F"
	}, {
		id: 135,
		name: "CONSUMER TEHNOLOGY ASSOCIATION"
	}, {
		id: 136,
		name: "BRITISH AIRWAYS"
	}, {
		id: 137,
		name: "ZORMAN MATIC S.P.",
		vatId: "SI92948472"
	}, {
		id: 138,
		name: "PIVNICA ADAM RAVBAR GOSTINSTVO",
		vatId: "SI25917854"
	}, {
		id: 139,
		name: "BEZIRKHAUPTMANNSCHAFT "
	}, {
		id: 140,
		name: "RYANAIR"
	}, {
		id: 141,
		name: "SLAK MATJAŽ S.P.",
		vatId: "SI45920192"
	}, {
		id: 142,
		name: "TIERRA S.P.A.",
		vatId: "IT09888530012"
	}, {
		id: 143,
		name: "SINOCASTEL "
	}, {
		id: 144,
		name: "OSOLNIK MARTIN S.P."
	}, {
		id: 145,
		name: "SOS SERVICE UKRAINE"
	}, {
		id: 146,
		name: "LORUPE SA",
		vatId: "LU24882166"
	}, {
		id: 147,
		name: "ROBIN DANIEL PEMBERTON",
		vatId: "IT02129630410"
	}, {
		id: 148,
		name: "B8TA ,INC. "
	}, {
		id: 149,
		name: "AMAZON.COM.DEDC LLC"
	}, {
		id: 150,
		name: "PROTECTUS, STORITVE, RAZVOJ IN",
		vatId: "SI55517463"
	}, {
		id: 151,
		name: "VIZIJA PRO, POSLOVNO SVETOVANJ",
		vatId: "SI24003069"
	}, {
		id: 152,
		name: "MERKUR TRGOVINA, D.D.",
		vatId: "SI76138437"
	}, {
		id: 153,
		name: "SPAR SLOVENIJA TRGOVSKO PODJET",
		vatId: "SI32156782"
	}, {
		id: 154,
		name: "OSOLNIK TADEJ S.P."
	}, {
		id: 155,
		name: "ALFANET TRGOVINA IN STORITVE D",
		vatId: "SI14323052"
	}, {
		id: 156,
		name: "ASSISTANCE CORIS D.O.O., ZAVAR",
		vatId: "SI67556639"
	}, {
		id: 157,
		name: "B8TA, NE UPORABLJAJ"
	}, {
		id: 158,
		name: "VIVA PREVOZI, PREVOZ POTNIKOV,",
		vatId: "SI12090425"
	}, {
		id: 159,
		name: "RAZLOŽNIK TINA S.P."
	}, {
		id: 160,
		name: "SAM PODJETJE ZA KOMERCIALNI IN",
		vatId: "SI96133708"
	}, {
		id: 161,
		name: "GRUPO HOTELES PLAYA S.A.A"
	}, {
		id: 162,
		name: "PAMETNI UPITI D. O. O.",
		vatId: "HR78730951820"
	}, {
		id: 163,
		name: " PONEBŠEK BOŠTJAN S.P.",
		vatId: "SI11032383"
	}, {
		id: 164,
		name: "POP CULTURE PR"
	}, {
		id: 165,
		name: "DODEVSKA MAJDA S.P."
	}, {
		id: 166,
		name: "AVTOBUSNA POSTAJA LJUBLJANA D.",
		vatId: "SI53393350"
	}, {
		id: 167,
		name: "CREATIV CAP.CONSULTING GMBH",
		vatId: "DE244683999"
	}, {
		id: 168,
		name: "SYSTEM SITE CCTV"
	}, {
		id: 169,
		name: "O.R.M.I. SUD S.R.L.",
		vatId: "IT02132640877"
	}, {
		id: 170,
		name: "PANTURIST D.D.",
		vatId: "HR33930043548"
	}, {
		id: 171,
		name: "CIRCUS CIRCUS"
	}, {
		id: 172,
		name: "UNICREDIT BANKA D.D."
	}, {
		id: 173,
		name: "MEDIDENTAL ApS",
		vatId: "DK31852161"
	}, {
		id: 174,
		name: "SOCIETA' COOPERATIVA EDITORIAL",
		vatId: "IT04074570716"
	}, {
		id: 175,
		name: "LAKESIDE CARS LTD",
		vatId: "GB883067794"
	}, {
		id: 176,
		name: "AIRTEXX",
		vatId: "DE201867800"
	}, {
		id: 177,
		name: "FLIXBUS DACH GMBH",
		vatId: "DE283764680"
	}, {
		id: 178,
		name: "CIRILLO GIUSEPPE",
		vatId: "IT07681911215"
	}, {
		id: 179,
		name: "MANOS MALAKOPOULOUS",
		vatId: "EL104211497"
	}, {
		id: 180,
		name: "PETER WERK",
		vatId: "DE243238520"
	}, {
		id: 181,
		name: "DISRUPTIVE BY DESIGN",
		vatId: "NL165298947B01"
	}, {
		id: 182,
		name: "CARSTEN KONIG",
		vatId: "DE298752006"
	}, {
		id: 183,
		name: "ERŽEN TOMAŽ - OSNOVNA KMETIJSK",
		vatId: "SI80647189"
	}, {
		id: 184,
		name: "KREATIVNO MESTO, DRUŽBA ZA STO",
		vatId: "SI50072536"
	}, {
		id: 185,
		name: "MCKENZIE SERVICES"
	}, {
		id: 186,
		name: "REMŠKAR SIMONA S.P.",
		vatId: "SI63379597"
	}, {
		id: 187,
		name: "TRIPSTA S.A.",
		vatId: "EL999098484"
	}, {
		id: 188,
		name: "ADRIA AIRWAYS, SLOVENSKI LETAL",
		vatId: "SI51049406"
	}, {
		id: 189,
		name: "SPECIALISTIČNA AMBULANTA MEDIC"
	}, {
		id: 190,
		name: "FIJONA FAMILY D.O.O."
	}, {
		id: 191,
		name: "RIMAC AUTOMOBILI D.O.O."
	}, {
		id: 192,
		name: "VUELING"
	}, {
		id: 193,
		name: "BV MEDIA, VIZUALNE KOMUNIKACIJ",
		vatId: "SI37236652"
	}, {
		id: 194,
		name: "SHOPIFY INC"
	}, {
		id: 195,
		name: "MLADINSKA KNJIGA TRGOVINA D.O.",
		vatId: "SI48494216"
	}, {
		id: 196,
		name: "CLEANED & SEALED",
		vatId: "GB176265682"
	}, {
		id: 197,
		name: "Pauker Siegfried Adolf",
		vatId: "ATU71395402"
	}, {
		id: 198,
		name: "HKC SERVICES LTD",
		vatId: "GB940902925"
	}, {
		id: 199,
		name: "FIRA BARCELONA"
	}, {
		id: 200,
		name: "CAMEA SK, s.r.o.",
		vatId: "SK2020008287"
	}, {
		id: 201,
		name: "SAS FIRST CLASS",
		vatId: "FR57800060865"
	}, {
		id: 202,
		name: "PRINTS GMBH",
		vatId: "DE136724163"
	}, {
		id: 203,
		name: "INTEGRA KW"
	}, {
		id: 204,
		name: "FLYPARKING VENEZIA"
	}, {
		id: 205,
		name: "TRANSIT HOTEL"
	}, {
		id: 206,
		name: "FASTSPRING"
	}, {
		id: 207,
		name: "STAYLE-7 "
	}, {
		id: 208,
		name: "TECHSOURCE"
	}, {
		id: 209,
		name: "EXPORTSTIFTELSEN ITM WORLDWIDE"
	}, {
		id: 210,
		name: "PIKAR D.O.O."
	}, {
		id: 211,
		name: "J&S AUTO REPAIR"
	}, {
		id: 212,
		name: "JAMES CONDON "
	}, {
		id: 213,
		name: "NKBM D.D."
	}, {
		id: 214,
		name: "AMAZON SERVICES EUROPE, SARL",
		vatId: "LU26888617"
	}, {
		id: 215,
		name: "HOTEL LORENSBERG AB"
	}, {
		id: 216,
		name: "ELEKTRONABAVA, SPECIALIZIRANA ",
		vatId: "SI75777037"
	}, {
		id: 217,
		name: "EDWARD HOTEL"
	}, {
		id: 218,
		name: "POSLOVNO SVETOVANJE MATEJ PERŠ"
	}, {
		id: 219,
		name: "GENERAL LOGISTICS SYSTEMS, LOG",
		vatId: "SI74531891"
	}, {
		id: 220,
		name: "DEBOFISK BVBA",
		vatId: "BE0454769058"
	}, {
		id: 221,
		name: "ILEARDA CAR SOUND ",
		vatId: "ESB25410374"
	}, {
		id: 222,
		name: "BIROTEHNA, SERVIS IN VZDRŽEVAN",
		vatId: "SI48671959"
	}, {
		id: 223,
		name: "ZAVOD REPUBLIKE SLOVENIJE ZA Z",
		vatId: "SI16669762"
	}, {
		id: 224,
		name: "DANIEL ENGELBRECHT",
		vatId: "DE230100533"
	}, {
		id: 225,
		name: "DAR TRGOVSKO-PROIZVODNO PODJET",
		vatId: "SI48263192"
	}, {
		id: 226,
		name: "ECO SRL",
		vatId: "IT03293810127"
	}, {
		id: 227,
		name: "IVAN HEGED",
		vatId: "HR96771899142"
	}, {
		id: 228,
		name: "STUDIO LEGALE CICALA RICCIONI",
		vatId: "IT07802681002"
	}, {
		id: 229,
		name: "REVOLUTION PORSCHE SPECIALISTS",
		vatId: "GB118265125"
	}, {
		id: 230,
		name: "MAILGUN TECHNOLOGIES"
	}, {
		id: 231,
		name: "UAB Monito",
		vatId: "LT100007697618"
	}, {
		id: 232,
		name: "SCHENZEN TAIDA CENT. TECH. LTD"
	}, {
		id: 233,
		name: "HOTELSKO GOSTINSKO PODJETJE HG",
		vatId: "SI16973488"
	}, {
		id: 234,
		name: "RUDNIDIS TRGOVINA, D.O.O.",
		vatId: "SI91433835"
	}, {
		id: 235,
		name: "TAIDA CENTURY TEHNOLOGY LTD"
	}, {
		id: 236,
		name: "INŠTITUT ZA VARSTVO PRI DELU I",
		vatId: "SI83226206"
	}, {
		id: 237,
		name: "ALCODE SOFT SRL",
		vatId: "RO32426790"
	}, {
		id: 238,
		name: "RIJAMAT, DEJAVNOST ZA NEGO TEL"
	}, {
		id: 239,
		name: "AMSYS S.R.L.",
		vatId: "IT08320281002"
	}, {
		id: 240,
		name: "MARIYAN PETKOV",
		vatId: "BG7401118649"
	}, {
		id: 241,
		name: "K&M STYLE LTD",
		vatId: "BG121109160"
	}, {
		id: 242,
		name: "CHRISFIX LLC"
	}, {
		id: 243,
		name: "JIM MAROTTA"
	}, {
		id: 244,
		name: "KONČNI POTROŠNIK GBP PAYPAL"
	}, {
		id: 245,
		name: "AMER-SIL MICROPORUS LTD"
	}, {
		id: 246,
		name: "SEMIČ SEBASTIJAN S.P.",
		vatId: "SI13038591"
	}, {
		id: 247,
		name: "FORPLAN D.O.O",
		vatId: "SI67131824"
	}, {
		id: 248,
		name: "ELEKTROCENTER, TRGOVINA IN STO",
		vatId: "SI30432715"
	}, {
		id: 249,
		name: "HELPTHECROWD B.V.",
		vatId: "NL855439506B01"
	}, {
		id: 250,
		name: "NEXMO INC"
	}, {
		id: 251,
		name: "MICROSOFT IRELAND OPERATIONS L",
		vatId: "IE8256796U"
	}, {
		id: 252,
		name: "WHEELED PLANET"
	}, {
		id: 253,
		name: "DTG, MUZEJI, IZOBRAŽEVANJE IN ",
		vatId: "SI50048368"
	}, {
		id: 254,
		name: "MURGELJ SILVESTER S.P.",
		vatId: "SI60223278"
	}, {
		id: 255,
		name: "KODAZ TRGOVINA IN STORITVE D.O",
		vatId: "SI52687821"
	}, {
		id: 256,
		name: "MAF-MAUČEC POSREDNIŠTVO IN STO",
		vatId: "SI57427119"
	}, {
		id: 257,
		name: "CAMBRIAN MARKETING LTD & TPC D",
		vatId: "GB148626348"
	}, {
		id: 258,
		name: "UX FIRST D.O.O.",
		vatId: "HR54271933129"
	}, {
		id: 259,
		name: "ZAMARO MAURO",
		vatId: "IT01632510309"
	}, {
		id: 260,
		name: "AUTOMOTIVE KEIM GMBH",
		vatId: "DE280783682"
	}, {
		id: 261,
		name: "DINODIRECT CHINA LIMITED"
	}, {
		id: 262,
		name: "SCHENZEN WEIGAV TECH. CO., LTD"
	}, {
		id: 263,
		name: "Teodorina Korseva Gladston",
		vatId: "BG204373290"
	}, {
		id: 264,
		name: "ŠPICA INTERNATIONAL D.O.O.",
		vatId: "SI13227955"
	}, {
		id: 265,
		name: "XYZ 3.0 SRL",
		vatId: "IT03365461205"
	}, {
		id: 266,
		name: "VROOMING TECH SRL",
		vatId: "IT03634271203"
	}, {
		id: 267,
		name: "GLOBAL AUTOMOTIVE s. r. o.",
		vatId: "SK2120220817"
	}, {
		id: 268,
		name: "ELEKTRONORM S.R.L.",
		vatId: "IT11021970154"
	}, {
		id: 269,
		name: "HUBTEL"
	}, {
		id: 270,
		name: "CENTRO DELLA SICUREZZA DI TIMP",
		vatId: "IT01185560073"
	}, {
		id: 271,
		name: "MARCO SCHOLZ",
		vatId: "DE204860835"
	}, {
		id: 272,
		name: "E.P.L. D.O.O., PODJETJE ZA INŽ",
		vatId: "SI10518711"
	}, {
		id: 273,
		name: "UMTR GROUP LIMITED",
		vatId: "GB289929226"
	}, {
		id: 274,
		name: "KING LIMOUSINE - SOCIETA' COOP",
		vatId: "IT11050541009"
	}, {
		id: 275,
		name: "VCS LUXURY RENTAL !!SAGL",
		vatId: "IT08830040963"
	}, {
		id: 276,
		name: "SKUBA HELLAS",
		vatId: "EL800572575"
	}, {
		id: 277,
		name: "VIZIJA RAČUNOVODSTVO, PODJETJE",
		vatId: "SI99403188"
	}, {
		id: 278,
		name: "SPRL IMAX COMMUNICATION",
		vatId: "BE0472794133"
	}, {
		id: 279,
		name: "XERO LTD NZ"
	}, {
		id: 280,
		name: "GHERGULESCU ADRIAN-EMANUEL - ",
		vatId: "RO30878535"
	}, {
		id: 281,
		name: "PICOLINK DI PASINI ANDREA",
		vatId: "IT03350820985"
	}, {
		id: 282,
		name: "Diexer Bau GmbH",
		vatId: "ATU49267706"
	}, {
		id: 283,
		name: "FATTORELLI ADRIANO S.R.L.",
		vatId: "IT03088890235"
	}, {
		id: 284,
		name: "MOTOR HOUSE HIRE (OLNEY) LTD",
		vatId: "GB335229956"
	}, {
		id: 285,
		name: "INNOVATE CORPORATION LIMITED",
		vatId: "GB163499673"
	}, {
		id: 286,
		name: "DRAŠČEK ANA S.P."
	}, {
		id: 287,
		name: "Plivo Inc."
	}, {
		id: 288,
		name: "USER VOICE INC"
	}, {
		id: 289,
		name: "SUPER FX DI SCORTICHINI FILIPP",
		vatId: "IT02653570982"
	}, {
		id: 290,
		name: "MAXIMO TEKSTIL TRGOVSKO PODJET",
		vatId: "SI43496083"
	}, {
		id: 291,
		name: "TERME MARIBOR, TURIZEM, ZDRAVS",
		vatId: "SI50534220"
	}, {
		id: 292,
		name: "NAUTA TRGOVINA IN STORITVE D.O",
		vatId: "SI20275749"
	}, {
		id: 293,
		name: "AVANGATE B.V.",
		vatId: "NL815605468B01"
	}, {
		id: 294,
		name: "KAYAKO LTD",
		vatId: "GB180865975"
	}, {
		id: 295,
		name: "MME TAGHRA, FATIMA",
		vatId: "BE0657866668"
	}, {
		id: 296,
		name: "S - GRAF DRUŽBA ZA GRAFIČNE ST",
		vatId: "SI18685803"
	}, {
		id: 297,
		name: "ELITE PMU, TRGOVINA IN STORITV",
		vatId: "SI32143788"
	}, {
		id: 298,
		name: "MICHAEL FITZPATRICK ARCHITECTS",
		vatId: "IE9809648R"
	}, {
		id: 299,
		name: "NICHOLAS JOHN THAKE !! N J THA",
		vatId: "GB213413905"
	}, {
		id: 300,
		name: "MIKEJI, INFORMACIJSKE TEHNOLOG",
		vatId: "SI77901070"
	}, {
		id: 301,
		name: "POLSKIE LINIE LOTNICZE 'LOT' S",
		vatId: "PL5220002334"
	}, {
		id: 302,
		name: "MURAT TERZIOGLU",
		vatId: "DE315418902"
	}, {
		id: 303,
		name: "MIŠ, ZALOŽNIŠTVO, TRGOVINA IN ",
		vatId: "SI96539372"
	}, {
		id: 304,
		name: "BRIXA MOTO SRL",
		vatId: "IT03058030986"
	}, {
		id: 305,
		name: "JUNSOE TRADE CO LTD"
	}, {
		id: 306,
		name: "DEVILISH DESSERTS SUSSEX ",
		vatId: "GB587743289"
	}, {
		id: 307,
		name: "RMCC SERVICES LTD",
		vatId: "GB144979860"
	}, {
		id: 308,
		name: "NORTHFIELD PARTNERSHIP LIMITED",
		vatId: "GB258691362"
	}, {
		id: 309,
		name: "S.A.T.E. DI GIUNCHI ALESSANDRO",
		vatId: "IT00570300392"
	}, {
		id: 310,
		name: "UMTR "
	}, {
		id: 311,
		name: "MAIO&STUMPF GBR",
		vatId: "DE264468125"
	}, {
		id: 312,
		name: "UNITED GAMESERVER GMBH",
		vatId: "DE228314357"
	}, {
		id: 313,
		name: "WAKESYS S.A R.L.",
		vatId: "LU26482247"
	}, {
		id: 314,
		name: "Genskabt Event & Dining",
		vatId: "DK35109811"
	}, {
		id: 315,
		name: "G J AIRCOOL LTD",
		vatId: "GB253168409"
	}, {
		id: 316,
		name: "IKEA ITALIA RETAIL S.R.L.",
		vatId: "IT02992760963"
	}, {
		id: 317,
		name: "MAGNET, TRGOVINA IN STORITVE D",
		vatId: "SI76565840"
	}, {
		id: 318,
		name: "PERDEC ALENKA S.P."
	}, {
		id: 319,
		name: "REDOLJUB, PRODAJA PISARNIŠKIH ",
		vatId: "SI44810610"
	}, {
		id: 320,
		name: "AUTOKLASSE LTD",
		vatId: "GB131840440"
	}, {
		id: 321,
		name: "CENTRAL IT SERVICES LTD",
		vatId: "GB849504109"
	}, {
		id: 322,
		name: "DON RADHIKA ASANGA PONNAMPERUM",
		vatId: "GB265808378"
	}, {
		id: 323,
		name: "SEVEN WONDERS LIMITED",
		vatId: "IE6368673L"
	}, {
		id: 324,
		name: "BRISTA TRGOVINA IN STORITVE D.",
		vatId: "SI27737926"
	}, {
		id: 325,
		name: "L'ADAM DISTRIBUTIONS",
		vatId: "NL856424614B01"
	}, {
		id: 326,
		name: "FGL INFORMATICA DI LUCA MARCAN",
		vatId: "IT02672590417"
	}, {
		id: 327,
		name: "MR IAN LYNCH",
		vatId: "IE7756029E"
	}, {
		id: 328,
		name: "MLACOM DRUŽBA ZA PROIZVODNJO",
		vatId: "SI23433116"
	}, {
		id: 329,
		name: "SEB DELANNEY",
		vatId: "CHE267570734"
	}, {
		id: 330,
		name: "AG Oldenburg ",
		vatId: "DE184293191"
	}, {
		id: 331,
		name: "DONE AND DUSTED LTD",
		vatId: "GB272771877"
	}, {
		id: 332,
		name: "GEORGIOS NIKOLAIDIS",
		vatId: "EL800610166"
	}, {
		id: 333,
		name: "ZEN ENGINEERING LTD",
		vatId: "GB165451604"
	}, {
		id: 334,
		name: "GEEKSTERLABS"
	}, {
		id: 335,
		name: "BIRO.SI TRGOVINA, ZASTOPANJE,",
		vatId: "SI98635301"
	}, {
		id: 336,
		name: "DENNIS PUBLISHING LIMITED",
		vatId: "GB494616317"
	}, {
		id: 337,
		name: "MARJETICA KOPER D.O.O.",
		vatId: "SI32375204"
	}, {
		id: 338,
		name: "PLATFORM IT LIMITED",
		vatId: "GB205775701"
	}, {
		id: 339,
		name: "Pro project EU s.r.o.",
		vatId: "SK2022493308"
	}, {
		id: 340,
		name: "MODRI PLANET, DRUŽBA ZA",
		vatId: "SI34334866"
	}, {
		id: 341,
		name: "PS INVEST PROIZVODNJA,",
		vatId: "SI63460912"
	}, {
		id: 342,
		name: "TSA PROJECT RESTAVRACIJE IN",
		vatId: "SI89943473"
	}, {
		id: 343,
		name: "MAPBOX INC"
	}, {
		id: 344,
		name: "ENIGMARIUM, ZABAVIŠČNI PARK,",
		vatId: "SI72449411"
	}, {
		id: 345,
		name: "Nabucco Consulting GMBH",
		vatId: "ATU71875447"
	}, {
		id: 346,
		name: "Baum&Garten GmbH ",
		vatId: "DE252452004"
	}, {
		id: 347,
		name: "Sukran Limousine Service UG ",
		vatId: "DE318578770"
	}, {
		id: 348,
		name: "SARL A.V.F.",
		vatId: "FR29793467895"
	}, {
		id: 349,
		name: "Saunders Havill Group Pty Ltd."
	}, {
		id: 350,
		name: "ABANKA D.D."
	}, {
		id: 351,
		name: " SARL AAA SOFT'EAU",
		vatId: "FR40388015497"
	}, {
		id: 352,
		name: "EVERYTHING IT LTD",
		vatId: "GB132218060"
	}, {
		id: 353,
		name: "SARL AQUITAINE BOISSONS SERVIC",
		vatId: "FR93489497917"
	}, {
		id: 354,
		name: "SASU TEAM EXPRESS",
		vatId: "FR75807634340"
	}, {
		id: 355,
		name: "SARL TOUT FER SERRURERIE",
		vatId: "FR82791654239"
	}, {
		id: 356,
		name: "TOUCHWOOD OF WETHERBY LTD",
		vatId: "GB758441212"
	}, {
		id: 357,
		name: "SIBLEY ELECTRICAL LIMITED",
		vatId: "GB368617613"
	}, {
		id: 358,
		name: "BVBA ELECOM TECHNICS",
		vatId: "BE0844897120"
	}, {
		id: 359,
		name: "SASU DALLES DE FRANCE",
		vatId: "FR89511037962"
	}, {
		id: 360,
		name: "EURL BOUILLON",
		vatId: "FR25832415269"
	}, {
		id: 361,
		name: "36&5 LIMITED",
		vatId: "GB199850443"
	}, {
		id: 362,
		name: " M BENZINA ADEL",
		vatId: "FR84489466680"
	}, {
		id: 363,
		name: "PROJECT: AWINING LTD",
		vatId: "GB268868924"
	}, {
		id: 364,
		name: "SARL ASSISTANCE REGIONALE PARI",
		vatId: "FR32498026848"
	}, {
		id: 365,
		name: "Lemaître, Rudy",
		vatId: "BE0862316043"
	}, {
		id: 366,
		name: "P.R. 2000 S.R.L.",
		vatId: "IT01994600599"
	}, {
		id: 367,
		name: "NESS PODJETJE ZA TRGOVINO IN",
		vatId: "SI41813073"
	}, {
		id: 368,
		name: "LINAS PUODZIUS"
	}, {
		id: 369,
		name: "JAMIE FYD"
	}, {
		id: 370,
		name: "TIK-TAK ŠKRLJ URARSTVO,",
		vatId: "SI54594618"
	}, {
		id: 371,
		name: "DIAGNOSTIC WORLD"
	}, {
		id: 372,
		name: "SARL AXENS SARL",
		vatId: "FR23394310080"
	}, {
		id: 373,
		name: "SECURITY S.R.L.",
		vatId: "IT02019790225"
	}, {
		id: 374,
		name: "PLAYNET SERVICE SRL",
		vatId: "IT05702030726"
	}, {
		id: 375,
		name: "SARL TRANS GHALIA",
		vatId: "FR54808429054"
	}, {
		id: 376,
		name: "Teppola Mika Petteri / Tmi Mik",
		vatId: "FI21256523"
	}, {
		id: 377,
		name: "REMES PODJETJE ZA PRODAJO IN",
		vatId: "SI54917921"
	}, {
		id: 378,
		name: "MOJPLANET, INFORMACIJSKE"
	}, {
		id: 379,
		name: "FAHRZEUG-CODIERUNG.CH"
	}, {
		id: 380,
		name: "Belhadj, Chahid",
		vatId: "BE0633741976"
	}, {
		id: 381,
		name: "STUDIO BENUCCI S.A.S. DEL RAG.",
		vatId: "IT03433220260"
	}, {
		id: 382,
		name: "SASU MAXIME WINLING",
		vatId: "FR67803780592"
	}, {
		id: 383,
		name: "EURL DOMOSERVICE",
		vatId: "FR71453238081"
	}, {
		id: 384,
		name: "M RIBEIRO RODRIGUES CARLOS",
		vatId: "FR25352007637"
	}, {
		id: 385,
		name: "CAPITAL CONTRACTS INC LTD",
		vatId: "GB181688668"
	}, {
		id: 386,
		name: "AD LUCEM, PODJETJE ZA",
		vatId: "SI13442783"
	}, {
		id: 387,
		name: "DIGITSTORE24 GMBH",
		vatId: "DE283017717"
	}, {
		id: 388,
		name: "OPTIWEB, SPLETNE REŠITVE,",
		vatId: "SI15129233"
	}, {
		id: 389,
		name: "PLAYGREEN, TURIZEM,",
		vatId: "SI45313784"
	}, {
		id: 390,
		name: "STJEPANOVIĆ MILAN  S.P.",
		vatId: "SI80361846"
	}, {
		id: 391,
		name: "KOLARIČ MIRAN S.P.",
		vatId: "SI99689308"
	}, {
		id: 392,
		name: "EURL AUTOCARS CHAIZE",
		vatId: "FR50389232430"
	}, {
		id: 393,
		name: "PAGEL JURGEN",
		vatId: "DE281934783"
	}, {
		id: 394,
		name: "IMMEDIATE MEDIA COMPANY LTD",
		vatId: "GB870006651"
	}, {
		id: 395,
		name: "NASE GOSTINSTVO D.O.O.",
		vatId: "SI86821385"
	}, {
		id: 396,
		name: "ŠPORTNO, KULTURNO,",
		vatId: "SI77846389"
	}, {
		id: 397,
		name: "SAS AGENCE STEENKISTE DEVINEAU",
		vatId: "FR23817831480"
	}, {
		id: 398,
		name: "SARL ALLIANCE BAT",
		vatId: "FR60844290635"
	}, {
		id: 399,
		name: "MIDNIGHT SUN TRANSLATIONS LIMI",
		vatId: "GB754698576"
	}, {
		id: 400,
		name: "HYPERGRID SRL",
		vatId: "IT01949320186"
	}, {
		id: 401,
		name: "BAYSIDE GRAPHICS LIMITED",
		vatId: "GB198128276"
	}, {
		id: 402,
		name: "SARL A2C ABSORTION CHAUFFAGE C",
		vatId: "FR89448881623"
	}, {
		id: 403,
		name: "SKOKEC, KURIRSKA DEJAVNOST IN",
		vatId: " SI28148614"
	}, {
		id: 404,
		name: "ERIS NETWORKS",
		vatId: "FR41807778212"
	}, {
		id: 405,
		name: "OKSIKOM, IZOBRAŽEVANJE,"
	}, {
		id: 406,
		name: "BIRO PLUS, INOVATIVNE",
		vatId: "SI13912526"
	}, {
		id: 407,
		name: "WINDELER COHEN ASSOCIATES LLP",
		vatId: "GB174010250"
	}, {
		id: 408,
		name: "JOSH STOVELL STUDIO LTD",
		vatId: "GB315204742"
	}, {
		id: 409,
		name: "SPRL HOCETEC",
		vatId: "BE0468806245"
	}, {
		id: 410,
		name: "EURO-MATIC DI SURACE MARCO",
		vatId: "IT06491640964"
	}, {
		id: 411,
		name: " SARL B.S.P. BA SECURITE PRIVE",
		vatId: "FR73447890310"
	}, {
		id: 412,
		name: "AROMA GLOBAL, PROIZVODNJA IN",
		vatId: "SI79375766"
	}, {
		id: 413,
		name: "KERN MIHA S.P., ORGANIZIRANJE",
		vatId: "SI34034099"
	}, {
		id: 414,
		name: "SARL CONSOREAL",
		vatId: "FR45490886553"
	}, {
		id: 415,
		name: "BERG INSIGHT AKTIEBOLAG",
		vatId: "SE556664014901"
	}, {
		id: 416,
		name: "EURL BFB EXOTIQUE",
		vatId: "FR00505030205"
	}, {
		id: 417,
		name: "CENTRAL IT SOLUTIONS LTD",
		vatId: "GB901815743"
	}, {
		id: 418,
		name: "VIGOLO SRL ",
		vatId: "IT04651590285"
	}, {
		id: 419,
		name: "CLIVE CHRISTIAN SCOTLAND LIMIT",
		vatId: "GB300340434"
	}, {
		id: 420,
		name: "GB-Electronic GmbH",
		vatId: "DE265309390"
	}, {
		id: 421,
		name: "EURL EURL STF",
		vatId: "FR82807955380"
	}, {
		id: 422,
		name: "Haute toiture hassaini",
		vatId: "BE0673803471"
	}, {
		id: 423,
		name: "Končni potrošnik Stripe"
	}, {
		id: 424,
		name: "EPLAS TRGOVINA IN",
		vatId: "SI21734836"
	}, {
		id: 425,
		name: "PRO MERIT INC."
	}, {
		id: 426,
		name: "ZAVOD MREŽA PODJETNIŠTVA,",
		vatId: "SI13448609"
	}, {
		id: 427,
		name: "KIRSTY MUNRO LTD",
		vatId: "GB204899583"
	}, {
		id: 428,
		name: "PALCOM SRLS UNIPERSONALE",
		vatId: "IT04686010754"
	}, {
		id: 429,
		name: " ABS INFORMATIQUE",
		vatId: "FR15792443053"
	}, {
		id: 430,
		name: "TECHNICAL AND EVENT SERVICES L",
		vatId: "GB778332008"
	}, {
		id: 431,
		name: "SAS GHEZA DISTRIBUTIONS",
		vatId: "FR62820310134"
	}, {
		id: 432,
		name: "NG LOGISTICS GRUUP LIMITED",
		vatId: "GB309462403"
	}, {
		id: 433,
		name: "COMPUTERUNIVERSE GMBH",
		vatId: "DE812826949"
	}, {
		id: 434,
		name: "SAS ONSHORE CELLARS",
		vatId: "FR73811931310"
	}, {
		id: 435,
		name: "PINPOINTUK LIMITED !! PINPOINT",
		vatId: "GB183264892"
	}, {
		id: 436,
		name: "Adel Zahirovič",
		vatId: "DE242234944"
	}, {
		id: 437,
		name: "LADISPOLI MODA S.R.L.",
		vatId: "IT11711261005"
	}, {
		id: 438,
		name: "Stefan Baudisch Vermietung e.K",
		vatId: "DE177566539"
	}, {
		id: 439,
		name: "SPELLINI, SARL",
		vatId: "LU18332372"
	}, {
		id: 440,
		name: "SARL LB3M",
		vatId: "FR89798717070"
	}, {
		id: 441,
		name: "MICROWAVE TECHNOLOGY S.R.L.",
		vatId: "IT03536180361"
	}, {
		id: 442,
		name: "CAR VISION SYSTEMS SRL",
		vatId: "RO16995955"
	}, {
		id: 443,
		name: "MR CONOR MOORE",
		vatId: "IE7100161R"
	}, {
		id: 444,
		name: "SAS GREIT",
		vatId: "FR00877638536"
	}, {
		id: 445,
		name: "Georgios Nikolaidis",
		vatId: "BG204498461"
	}, {
		id: 446,
		name: "SPRL SPRL VISEFESTYLOC & CLIMC",
		vatId: "BE0476917623"
	}, {
		id: 447,
		name: "GARAGE SAN MARCO",
		vatId: "IT02351190273"
	}, {
		id: 448,
		name: "LES-MMS TRGOVSKA DRUŽBA",
		vatId: "SI74735705"
	}, {
		id: 449,
		name: "COOPERATIVA SERVIZI DOMICILIAR",
		vatId: "IT03347040135"
	}, {
		id: 450,
		name: "SENETIC GMBH",
		vatId: "DE297177752"
	}, {
		id: 451,
		name: "JEZERŠEK GOSTINSTVO D.O.O.",
		vatId: "SI95669647"
	}, {
		id: 452,
		name: "Foucray, François",
		vatId: "BE0541581682"
	}, {
		id: 453,
		name: "MARULATEC LIMITED",
		vatId: "GB244635993"
	}, {
		id: 454,
		name: "SASU ECO SYSTEME DURABLE",
		vatId: "FR17798922589"
	}, {
		id: 455,
		name: "Nedelina Yaneva",
		vatId: "BG205499000"
	}, {
		id: 456,
		name: "MEDISYSTEMS S.R.L.",
		vatId: "IT02020600819"
	}, {
		id: 457,
		name: "SARL FJF ENERGIES",
		vatId: "FR46828926477"
	}, {
		id: 458,
		name: "ECO SASH AND CASE LTD !! ECO S",
		vatId: "GB315398101"
	}, {
		id: 459,
		name: "UNION BAU LUX S.A R.L.-S",
		vatId: "LU30143278"
	}, {
		id: 460,
		name: "ASIT AVTOMATIZACIJA SISTEMOV",
		vatId: "SI27058565"
	}, {
		id: 461,
		name: "ROOFING GURUS LIMITED !! ROOF ",
		vatId: "GB324185319"
	}, {
		id: 480,
		name: "",
		vatId: "FR51413868555"
	}, {
		id: 489,
		name: "",
		vatId: "IT02238250605"
	}, {
		id: 466,
		name: "",
		vatId: "GB889142678"
	}];

	var konto = Ember['default'].Object.extend({
		country: null,
		ddv: null,
		prihodek: null
	});

	konto.FIXTURES = [{
		country: "Netherlands",
		prihodek: 76201,
		ddv: 26101
	}, {
		country: "Germany",
		prihodek: 762010,
		ddv: 261010
	}, {
		country: "Ireland",
		prihodek: 762011,
		ddv: 261011
	}, {
		country: "UK",
		prihodek: 76202,
		ddv: 26102
	}, {
		country: "Romania",
		prihodek: 76213,
		ddv: 261013
	}, {
		country: "Bulgaria",
		prihodek: 76203,
		ddv: 26103
	}, {
		country: "Italy",
		prihodek: 76204,
		ddv: 26104
	}, {
		country: "Slovakia",
		prihodek: 76205,
		ddv: 26105
	}, {
		country: "Hungary",
		prihodek: 76206,
		ddv: 26106
	}, {
		country: "France",
		prihodek: 76207,
		ddv: 26107
	}, {
		country: "Lithuania",
		prihodek: 76208,
		ddv: 26108
	}, {
		country: "Greece",
		prihodek: 76209,
		ddv: 26109
	}, {
		country: "Spain",
		prihodek: 76210,
		ddv: 26110
	}, {
		country: "Czech Republic",
		prihodek: 76211,
		ddv: 26111
	}, {
		country: "Croatia",
		prihodek: 76212,
		ddv: 26112
	}, {
		country: "Belgium",
		prihodek: 76214,
		ddv: 26114
	}, {
		country: "Latvia",
		prihodek: 76215,
		ddv: 26115
	}, {
		country: "Denmark",
		prihodek: 76216,
		ddv: 26116
	}, {
		country: "Austria",
		prihodek: 76217,
		ddv: 26117
	}, {
		country: "Luxembourg",
		prihodek: 76219,
		ddv: 26119
	}, {
		country: "Portugal",
		prihodek: 76220,
		ddv: 26120
	}, {
		country: "Malta",
		prihodek: 76223,
		ddv: 26123
	}, {
		country: "Sweden",
		prihodek: 76221,
		ddv: 26121
	}, {
		country: "Estonia",
		prihodek: 76222,
		ddv: 26122
	}, {
		country: "Slovenia",
		prihodek: 7600,
		ddv: 26000
	}];

	exports['default'] = { sifrant: sifrant, konto: konto };

});