define('carlock-admin/models/c/purchase/purchase', ['exports', 'ember', 'carlock-admin/models/c/purchase/distributor', 'carlock-admin/models/c/purchase/purchase-item', 'carlock-admin/models/c/purchase/address', 'carlock-admin/models/country', 'carlock-admin/helpers/format-date', 'carlock-admin/helpers/format-time', 'carlock-admin/helpers/format-currency', 'carlock-admin/models/c/purchase/purchase-type'], function (exports, Ember, DistributorModel, PurchaseItemModel, AddressModel, CountryModel, FormatDate, FormatTime, FormatCurrency, PurchaseType) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        distributor: null,
        date: null,
        dateFormatted: Ember['default'].computed('date', 'profile.instance.timeZoneId', FormatTime['default']),
        status: null,
        accepted: Ember['default'].computed('status', function () {
            return this.get('status') === 'ACCEPTED';
        }),
        completed: Ember['default'].computed('status', function () {
            return this.get('status') === 'COMPLETED';
        }),
        canBeConfirmed: Ember['default'].computed('status', 'paymentPlatform', function () {
            var status = this.get('status');
            var platform = this.get('paymentPlatform');
            return status === 'ACCEPTED' && platform === 'SEPA';
        }),
        isSepa: Ember['default'].computed('paymentPlatform', function () {
            var platform = this.get('paymentPlatform');
            return platform === 'SEPA';
        }),
        statusFormatted: Ember['default'].computed('status', function () {
            return 'purchase.status.' + this.get('status').toLowerCase();
        }),
        items: null,
        email: null,
        phoneNumber: null,
        ip: null,
        ipCountryIsoCode: null,
        locale: null,
        comment: null,
        billingAddress: null,
        vatId: null,
        paymentPlatform: null,
        paymentPlatformFormatted: Ember['default'].computed('paymentPlatform', function () {
            return this.get('paymentPlatform') ? 'purchase.platform.' + this.get('paymentPlatform').toLowerCase() : 'purchase.platform.none';
        }),
        paymentDate: null,
        paymentDateFormatted: Ember['default'].computed('paymentDate', 'profile.instance.timeZoneId', FormatTime['default']),
        paymentId: null,
        paymentPlatformLink: Ember['default'].computed('paymentId', 'paymentPlatform', function () {
            var paymentId = this.get('paymentId');
            var platform = this.get('paymentPlatform');

            switch (platform) {
                case 'PAYPAL':
                    return 'https://www.paypal.com/activity/payment/' + paymentId;
                case 'STRIPE':
                    return 'https://dashboard.stripe.com/payments/' + paymentId;
                case 'PAYMIL':
                    return 'https://app.paymill.com/transactions/' + paymentId;
            }

            return null;
        }),
        paymentPlatformFee: null,
        paymentPlatformFeeFormatted: Ember['default'].computed('paymentPlatformFee', 'currency', FormatCurrency['default']),
        amountBeforeTax: null,
        amountBeforeTaxFormatted: Ember['default'].computed('amountBeforeTax', 'currency', FormatCurrency['default']),
        tax: null,
        taxFormatted: Ember['default'].computed('tax', 'currency', FormatCurrency['default']),
        amountAfterTax: null,
        amountAfterTaxFormatted: Ember['default'].computed('amountAfterTax', 'currency', FormatCurrency['default']),
        invoiceNumber: null,
        xeroInvoiceId: null,
        xeroInvoiceLink: Ember['default'].computed('xeroInvoiceId', function () {
            var id = this.get('xeroInvoiceId');
            return 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + id;
        }),
        distributorShareBeforePlatformFee: null,
        distributorShareBeforePlatformFeeFormatted: Ember['default'].computed('distributorShareBeforePlatformFee', 'currency', FormatCurrency['default']),
        distributorPlatformFeeShare: null,
        distributorPlatformFeeShareFormatted: Ember['default'].computed('distributorPlatformFeeShare', 'currency', FormatCurrency['default']),
        distributorShareAfterPlatformFee: null,
        distributorShareAfterPlatformFeeFormatted: Ember['default'].computed('distributorShareAfterPlatformFee', 'currency', FormatCurrency['default']),
        currency: null,
        shipmentAddress: null,
        shipmentDate: null,
        shipmentDateFormatted: Ember['default'].computed('shipmentDate', 'profile.instance.timeZoneId', FormatDate['default']),
        shipmentTrackingNumber: null,
        shipmentCompany: null,
        recurringSubscriptionUuid: null,
        containsPhysicalItems: Ember['default'].computed('items.[]', function () {
            var items = this.get('items');
            var containsPhysicalItems = false;

            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                var offer = item.get('offer');

                if (offer && offer.get('physical')) {
                    containsPhysicalItems = true;
                    break;
                }
            }

            return containsPhysicalItems;
        }),
        refundDate: null,
        refundDateFormatted: Ember['default'].computed('refundDate', 'profile.instance.timeZoneId', FormatTime['default']),
        creditNoteNumber: null,
        xeroCreditNoteId: null,
        xeroCreditNoteLink: Ember['default'].computed('xeroCreditNoteId', function () {
            var id = this.get('xeroCreditNoteId');
            return 'https://go.xero.com/AccountsReceivable/EditCreditNote.aspx?creditNoteID=' + id;
        }),
        refundId: null,
        canRefund: Ember['default'].computed('status', 'paymentPlatform', function () {
            var status = this.get('status');
            var platform = this.get('paymentPlatform');
            return (status === 'PAID' || status === 'COMPLETED') && (platform === 'STRIPE' || platform === 'PAYPAL' || platform === 'PAYMILL' || platform === 'SEPA');
        }),
        refunded: Ember['default'].computed('status', function () {
            var status = this.get('status');
            return status === 'REFUNDED';
        }),
        type: Ember['default'].computed('items[].offer.type', function () {
            var items = this.get('items');
            var type = undefined;
            if (items) {
                type = items[0].get('offer.type');
            }
            return PurchaseType['default'].FIXTURES.findBy('id', type ? type : 'SUBSCRIPTION');
        }),
        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('distributor', DistributorModel['default'].create().load(data.distributor));
            this.set('date', data.date);
            this.set('status', data.status);

            var itemsList = Ember['default'].A();
            for (var i = 0; i < data.items.length; i++) {
                var item = PurchaseItemModel['default'].create().load(data.items[i]);
                item.set('currency', data.currency);
                itemsList.pushObject(item);
            }

            this.set('items', itemsList);
            this.set('email', data.email);
            this.set('phoneNumber', data.phone_number);
            this.set('ip', data.ip);
            this.set('ipCountryIsoCode', data.ip_country_iso_code);
            this.set('ipCountry', CountryModel['default'].FIXTURES.findBy('isoCode', data.ip_country_iso_code));
            this.set('locale', data.locale);
            this.set('comment', data.comment);

            if (data.billing_address) {
                this.set('billingAddress', AddressModel['default'].create().load(data.billing_address));
            }

            this.set('vatId', data.vat_id);
            this.set('paymentPlatform', data.payment_platform);
            this.set('paymentDate', data.payment_date);
            this.set('paymentId', data.payment_id);
            this.set('paymentPlatformFee', data.payment_platform_fee);
            this.set('amountBeforeTax', data.amount_before_tax);
            this.set('tax', data.tax);
            this.set('amountAfterTax', data.amount_after_tax);
            this.set('invoiceNumber', data.invoice_number);
            this.set('xeroInvoiceId', data.xero_invoice_id);
            this.set('distributorShareBeforePlatformFee', data.distributor_share_before_platform_fee);
            this.set('distributorPlatformFeeShare', data.distributor_platform_fee_share);
            this.set('distributorShareAfterPlatformFee', data.distributor_share_after_platform_fee);
            this.set('currency', data.currency);

            if (data.shipment_address) {
                this.set('shipmentAddress', AddressModel['default'].create().load(data.shipment_address));
            }

            this.set('shipmentDate', data.shipment_date);
            this.set('shipmentTrackingNumber', data.shipment_tracking_number);
            this.set('shipmentCompany', data.shipment_company);
            this.set('recurringSubscriptionUuid', data.recurring_subscription_uuid);

            this.set('refundDate', data.refund_date);
            this.set('creditNoteNumber', data.credit_note_number);
            this.set('xeroCreditNoteId', data.xero_credit_note_id);
            this.set('refundId', data.refund_id);
            return this;
        }
    });

});