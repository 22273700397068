define('carlock-admin/routes/c/gift-code', ['exports', 'carlock-admin/routes/authenticated', 'carlock-admin/models/c/gift-code/gift-code', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Base, GiftCodeModel, AuthenticatedRouteMixin) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        model: function model(params) {
            return this.carlock.getGiftCode(params.uuid).then(null, this.onModelError);
        },
        transformModel: function transformModel(model) {
            return GiftCodeModel['default'].create().load(model);
        }
    });

});