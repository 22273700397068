define('carlock-admin/services/carlock', ['exports', 'ember', 'carlock-admin/config/environment'], function (exports, Ember, Config) {

    'use strict';

    exports['default'] = Ember['default'].Service.extend({
        loggerService: Ember['default'].inject.service('logger'),
        logger: function logger() {
            return this.get('loggerService');
        },
        session: Ember['default'].inject.service('session'),
        token: Ember['default'].computed.alias('session.session.content.authenticated.token'),
        i18nService: Ember['default'].inject.service('i18n'),
        i18n: function i18n() {
            return this.get('i18nService');
        },
        login: function login(data) {
            return this.admin('POST', '/user/login', data);
        },
        logout: function logout(token) {
            return this.admin('POST', '/user/logout', null, token ? token : this.get('token'), true);
        },
        setVehicleDistributor: function setVehicleDistributor(uuid, data) {
            return this.admin('PUT', '/vehicle/' + uuid + '/distributor', data, this.get('token'));
        },
        setVehicleUser: function setVehicleUser(uuid, data) {
            return this.admin('PUT', '/vehicle/' + uuid + '/user', data, this.get('token'));
        },
        setVehicleDevice: function setVehicleDevice(uuid, data) {
            return this.admin('PUT', '/vehicle/' + uuid + '/device', data, this.get('token'));
        },
        setVehicleSim: function setVehicleSim(uuid, data) {
            return this.admin('PUT', '/vehicle/' + uuid + '/sim', data, this.get('token'));
        },
        setNewVehicleSim: function setNewVehicleSim(uuid, data) {
            return this.admin('PUT', '/vehicle/' + uuid + '/sim_auto', data, this.get('token'));
        },
        removeVehicleSim: function removeVehicleSim(uuid) {
            return this.admin('DELETE', '/vehicle/' + uuid + '/sim', null, this.get('token'));
        },
        recycleOrActivateSim: function recycleOrActivateSim(vehicleUuid) {
            return this.admin('POST', '/vehicle/' + vehicleUuid + '/sim_recycling_or_activation', null, this.get('token'));
        },
        sendVehicleSmsCommand: function sendVehicleSmsCommand(uuid, data) {
            return this.admin('POST', '/vehicle/' + uuid + '/sms_command', data, this.get('token'));
        },
        getVehicleSubscriptionOffers: function getVehicleSubscriptionOffers(uuid) {
            return this.admin('GET', '/vehicle/' + uuid + '/subscription_offers', null, this.get('token'));
        },
        extendVehicleSubscription: function extendVehicleSubscription(uuid, data) {
            return this.admin('POST', '/vehicle/' + uuid + '/subscription_extension', data, this.get('token'));
        },
        extendVehicleSubscriptionDays: function extendVehicleSubscriptionDays(uuid, data) {
            return this.admin('POST', '/vehicle/' + uuid + '/subscription_days_extension', data, this.get('token'));
        },
        regeneratePurchaseInvoice: function regeneratePurchaseInvoice(uuid, data) {
            return this.admin('POST', '/purchase/' + uuid + '/invoice', data, this.get('token'));
        },
        regeneratePurchaseCreditNote: function regeneratePurchaseCreditNote(uuid, data) {
            return this.admin('POST', '/purchase/' + uuid + '/credit_note', data, this.get('token'));
        },
        refundPurchase: function refundPurchase(uuid, data) {
            return this.admin('POST', '/purchase/' + uuid + '/refund', data, this.get('token'));
        },
        resetVehicle: function resetVehicle(uuid, data) {
            return this.admin('POST', '/vehicle/' + uuid + '/reset', data, this.get('token'));
        },
        getDistributors: function getDistributors() {
            return this.admin('GET', '/distributor', null, this.get('token'));
        },
        getDistributorDevices: function getDistributorDevices(search, sort, descending, page, pageSize, patternMatching) {
            var url = '/distributor/devices';
            var filter = '';

            if (search) {
                filter += '&search=' + encodeURIComponent(search);
            }

            if (sort) {
                filter += '&sort_index=' + sort;
            }

            if (descending) {
                filter += '&descending=' + (descending ? 'true' : 'false');
            }

            if (page) {
                filter += '&page=' + page;
            }

            if (pageSize) {
                filter += '&page_size=' + pageSize;
            }

            if (patternMatching !== null) {
                filter += '&pattern_matching=' + patternMatching;
            }

            if (filter) {
                url += '?' + filter.substr(1);
            }

            return this.admin('GET', url, null, this.get('token'));
        },
        getPurchases: function getPurchases(distributorUuid, subscription, dateFrom, dateTo, status, platform, search, sort, descending, page, pageSize, patternMatching) {
            var url = '/purchase';
            var filter = '';

            if (distributorUuid) {
                filter += '&distributor_uuid=' + distributorUuid;
            }

            if (subscription != null) {
                filter += '&subscription=' + subscription;
            }

            if (dateFrom) {
                filter += '&date_from=' + dateFrom;
            }

            if (dateTo) {
                filter += '&date_to=' + dateTo;
            }

            if (status) {
                filter += '&status=' + status;
            }

            if (platform) {
                filter += '&platform=' + platform;
            }

            if (search) {
                filter += '&search=' + encodeURIComponent(search);
            }

            if (sort) {
                filter += '&sort_index=' + sort;
            }

            if (descending) {
                filter += '&descending=' + (descending ? 'true' : 'false');
            }

            if (page) {
                filter += '&page=' + page;
            }

            if (pageSize) {
                filter += '&page_size=' + pageSize;
            }

            if (patternMatching !== null) {
                filter += '&pattern_matching=' + patternMatching;
            }

            if (filter) {
                url += '?' + filter.substr(1);
            }

            return this.admin('GET', url, null, this.get('token'));
        },
        getPurchase: function getPurchase(uuid) {
            return this.admin('GET', '/purchase/' + uuid, null, this.get('token'));
        },
        confirmPurchasePayment: function confirmPurchasePayment(uuid, data) {
            return this.admin('POST', '/purchase/' + uuid + '/sepa_payment', data, this.get('token'));
        },
        getDevices: function getDevices(search, sort, descending, page, pageSize, patternMatching) {
            var url = '/device';
            var filter = '';

            if (search) {
                filter += '&search=' + encodeURIComponent(search);
            }

            if (sort) {
                filter += '&sort_index=' + sort;
            }

            if (descending) {
                filter += '&descending=' + (descending ? 'true' : 'false');
            }

            if (page) {
                filter += '&page=' + page;
            }

            if (pageSize) {
                filter += '&page_size=' + pageSize;
            }

            if (patternMatching !== null) {
                filter += '&pattern_matching=' + patternMatching;
            }

            if (filter) {
                url += '?' + filter.substr(1);
            }

            return this.admin('GET', url, null, this.get('token'));
        },
        getDistributorSubscriptionReport: function getDistributorSubscriptionReport(uuid, dateFrom, dateTo) {
            var url = '/distributor/' + uuid + '/subscription_report';
            var filter = '';

            if (dateFrom) {
                filter += '&date_from=' + dateFrom;
            }

            if (dateTo) {
                filter += '&date_to=' + dateTo;
            }

            if (filter) {
                url += '?' + filter.substr(1);
            }

            return this.admin('GET', url, null, this.get('token'));
        },
        getDistributorUsers: function getDistributorUsers(search, sort, descending, page, pageSize) {
            var url = '/distributor/users';
            var filter = '';

            if (search) {
                filter += '&search=' + encodeURIComponent(search);
            }

            if (sort) {
                filter += '&sort_index=' + sort;
            }

            if (descending) {
                filter += '&descending=' + (descending ? 'true' : 'false');
            }

            if (page) {
                filter += '&page=' + page;
            }

            if (pageSize) {
                filter += '&page_size=' + pageSize;
            }

            if (filter) {
                url += '?' + filter.substr(1);
            }

            return this.admin('GET', url, null, this.get('token'));
        },
        getUsers: function getUsers(search, sort, descending, page, pageSize) {
            var url = '/user';
            var filter = '';

            if (search) {
                filter += '&search=' + encodeURIComponent(search);
            }

            if (sort) {
                filter += '&sort_index=' + sort;
            }

            if (descending) {
                filter += '&descending=' + (descending ? 'true' : 'false');
            }

            if (page) {
                filter += '&page=' + page;
            }

            if (pageSize) {
                filter += '&page_size=' + pageSize;
            }

            if (filter) {
                url += '?' + filter.substr(1);
            }

            return this.admin('GET', url, null, this.get('token'));
        },
        getVehicle: function getVehicle(vehicleUuid) {
            return this.admin('GET', '/vehicle/' + vehicleUuid, null, this.get('token'));
        },
        addVehicle: function addVehicle(data) {
            return this.admin('POST', '/vehicle', data, this.get('token'));
        },
        getUser: function getUser(userUuid) {
            return this.admin('GET', '/user/' + userUuid, null, this.get('token'));
        },
        getUserDevices: function getUserDevices(userUuid) {
            return this.admin('GET', '/user/' + userUuid + '/devices', null, this.get('token'));
        },
        getUserDebugToken: function getUserDebugToken(userUuid) {
            return this.admin('GET', '/user/' + userUuid + '/debug_token', null, this.get('token'));
        },
        getGiftCodes: function getGiftCodes(search, sort, descending, page, pageSize) {
            var url = '/gift_code';
            var filter = '';

            if (search) {
                filter += '&search=' + encodeURIComponent(search);
            }

            if (sort) {
                filter += '&sort_index=' + sort;
            }

            if (descending) {
                filter += '&descending=' + (descending ? 'true' : 'false');
            }

            if (page) {
                filter += '&page=' + page;
            }

            if (pageSize) {
                filter += '&page_size=' + pageSize;
            }

            if (filter) {
                url += '?' + filter.substr(1);
            }

            return this.admin('GET', url, null, this.get('token'));
        },
        getGiftCode: function getGiftCode(giftCodeUuid) {
            return this.admin('GET', '/gift_code/' + giftCodeUuid, null, this.get('token'));
        },
        getGiftCodeOffers: function getGiftCodeOffers() {
            return this.admin('GET', '/gift_code/offers', null, this.get('token'));
        },
        generateGiftCodes: function generateGiftCodes(data) {
            return this.admin('POST', '/gift_code', data, this.get('token'));
        },
        getProfile: function getProfile() {
            return this.admin('GET', '/user/profile', null, this.get('token'));
        },
        saveUser: function saveUser(data) {
            return this.admin('PUT', '/user/profile', data, this.get('token'));
        },
        resetPassword: function resetPassword(data) {
            return this.core('POST', '/user/password_reset', data);
        },
        setPassword: function setPassword(data, token) {
            return this.core('PUT', '/user/password', data, token);
        },
        sendPushNotification: function sendPushNotification(uuid, data) {
            return this.admin('POST', '/vehicle/' + uuid + '/notification', data, this.get('token'));
        },
        sendPushNotificationV1: function sendPushNotificationV1(data) {
            return this.legacy('POST', '/development/send-notification', data, this.get('token'));
        },
        sendUserPushNotification: function sendUserPushNotification(uuid, data) {
            return this.admin('POST', '/user/' + uuid + '/notification', data, this.get('token'));
        },
        getVehicleHealth: function getVehicleHealth(uuid, from, to) {
            return this.admin('GET', '/vehicle/' + uuid + '/health/?date_from=' + from + '&date_to=' + to, null, this.get('token'));
        },
        getCohorts: function getCohorts(from, to, distributorUuid) {
            var distributor = distributorUuid ? '&distributor_uuid=' + distributorUuid : '';
            return this.admin('GET', '/metric/cohorts?date_from=' + from + '&date_to=' + to + distributor, null, this.get('token'));
        },
        getCoupons: function getCoupons(search, offerUuid, page, pageSize, sort, desc) {
            var query = '';
            if (search) {
                query += '&search=' + search;
            }
            if (offerUuid) {
                query += '&offer_uuid=' + offerUuid;
            }
            if (page) {
                query += '&page=' + page;
            }
            if (pageSize) {
                query += '&page_size=' + pageSize;
            }
            if (sort) {
                query += '&sort_index=' + sort;
            }
            if (desc) {
                query += '&descending=' + desc;
            }

            return this.admin('GET', '/coupon?' + query.substr(1), null, this.get('token'));
        },
        getCoupon: function getCoupon(uuid) {
            return this.admin('GET', '/coupon/' + uuid, null, this.get('token'));
        },
        getPaypalAccountingData: function getPaypalAccountingData(from, to) {
            return this.admin('GET', '/purchase/accounting_data_paypal?date_from=' + from + '&date_to=' + to, null, this.get('token'));
        },
        getStripeAccountingData: function getStripeAccountingData(from, to, distributor) {
            return this.admin('GET', '/purchase/accounting_data_stripe?distributor_uuid=' + distributor + '&date_from=' + from + '&date_to=' + to, null, this.get('token'), null, 'text/plain');
        },
        getVehicleDebug: function getVehicleDebug(vehicleUuid) {
            return this.admin('GET', '/vehicle/' + vehicleUuid + '/debug', null, this.get('token'));
        },
        getVehicleRecords: function getVehicleRecords(vehicleUuid, from, to) {
            return this.admin('GET', '/vehicle/' + vehicleUuid + '/records?date_from=' + from + '&date_to=' + to, null, this.get('token'));
        },
        getTrips: function getTrips(vehicleUuid, from, to, page, pageSize) {
            var queryParams = '';
            if (from) {
                queryParams += 'date_from=' + from + '&';
            }

            if (to) {
                queryParams += 'date_to=' + to + '&';
            }

            if (page || page === 0) {
                queryParams += 'page=' + page + '&';
            }

            if (pageSize) {
                queryParams += 'page_size=' + pageSize;
            }

            return this.admin('GET', '/vehicle/' + vehicleUuid + '/trips?' + queryParams, null, this.get('token'));
        },
        getNotifications: function getNotifications(vehicleUuid, from, to, page, pageSize, includeTrips) {
            var queryParams = 'include_trips=' + includeTrips + '&';

            if (from) {
                queryParams += 'date_from=' + from + '&';
            }

            if (to) {
                queryParams += 'date_to=' + to + '&';
            }

            if (page || page === 0) {
                queryParams += 'page=' + page + '&';
            }

            if (pageSize) {
                queryParams += 'page_size=' + pageSize;
            }

            return this.admin('GET', '/vehicle/' + vehicleUuid + '/notifications?' + queryParams, null, this.get('token'));
        },
        getOffers: function getOffers() {
            return this.admin('GET', '/coupon/offers', null, this.get('token'));
        },
        addCoupon: function addCoupon(data) {
            return this.admin('POST', '/coupon', data, this.get('token'));
        },
        refreshSim: function refreshSim(iccid) {
            return this.admin('GET', '/sim/' + iccid + '/refresh', null, this.get('token'));
        },
        create2Fa: function create2Fa() {
            return this.admin('POST', '/user/two_factor_auth', null, this.get('token'));
        },
        set2Fa: function set2Fa(data) {
            return this.admin('PUT', '/user/two_factor_auth', data, this.get('token'));
        },
        getPurchaseOffers: function getPurchaseOffers(distributorUuid, special, virtual) {
            var queryParams = 'distributor_uuid=' + distributorUuid + '&';

            if (special) {
                queryParams += 'include_special=' + special + '&';
            }

            if (virtual) {
                queryParams += 'include_virtual=' + virtual;
            }

            return this.store('GET', '/offer/list?' + queryParams, null, this.get('token'));
        },
        sendReplacement: function sendReplacement(data) {
            return this.store('POST', '/purchase/replacement', data, this.get('token'));
        },
        markVehicleAsReplacement: function markVehicleAsReplacement(vehicleUuid, mark) {
            return this.admin('PUT', '/vehicle/' + vehicleUuid + '/replacement?status=' + mark, null, this.get('token'));
        },
        markUserAsPromo: function markUserAsPromo(userUuid, mark) {
            return this.admin('PUT', '/user/' + userUuid + '/promo?promo=' + mark, null, this.get('token'));
        },
        sendSim: function sendSim(data) {
            return this.admin('POST', '/shipment/sim', data, this.get('token'));
        },
        sendPromo: function sendPromo(data) {
            return this.admin('POST', '/shipment/promo', data, this.get('token'));
        },
        sendReplacement: function sendReplacement(data) {
            return this.admin('POST', '/shipment/replacement', data, this.get('token'));
        },
        createPaypalPurchase: function createPaypalPurchase(data) {
            return this.admin('POST', '/paypal/create_purchase', data, this.get('token'));
        },
        createStripePurchase: function createStripePurchase(data) {
            return this.admin('POST', '/stripe/create_purchase', data, this.get('token'));
        },
        getTaxDataByUsStates: function getTaxDataByUsStates(dateFrom, dateTo) {
            return this.admin('GET', '/purchase/sum-by-us-states?date_from=' + dateFrom + (dateTo ? '&date_to=' + dateTo : ''), null, this.get('token'));
        },
        setCanPauseSubscription: function setCanPauseSubscription(vehicleUuid, enable) {
            return this.admin('PUT', '/vehicle/' + vehicleUuid + '/pause_subscription?enable=' + enable, null, this.get('token'));
        },
        createOrUpdateXeroInvoice: function createOrUpdateXeroInvoice(purchaseUuid) {
            return this.admin('POST', '/purchase/' + purchaseUuid + '/xero_invoice', null, this.get('token'));
        },
        createEracuniInvoice: function createEracuniInvoice(purchaseUuid) {
            return this.admin('POST', '/purchase/' + purchaseUuid + '/eracuni_invoice', null, this.get('token'));
        },
        getPromo: function getPromo() {
            return this.admin('GET', '/user/promo/details', null, this.get('token'));
        },
        getFbExport: function getFbExport() {
            return this.admin('GET', '/analytics/fb_export', null, this.get('token'), null, null);
        },
        getUserLogins: function getUserLogins(userUuid) {
            return this.admin('GET', '/user/' + userUuid + '/logins', null, this.get('token'));
        },
        getVehicleStatus: function getVehicleStatus(vehicleUuid) {
            return this.admin('GET', '/vehicle/' + vehicleUuid + '/status', null, this.get('token'));
        },
        setSubscriptionTier: function setSubscriptionTier(vehicleUuid, data) {
            return this.admin('PUT', '/vehicle/' + vehicleUuid + '/subscription_tier', data, this.get('token'));
        },
        getRecurringSubscriptions: function getRecurringSubscriptions(vehicleUuid) {
            return this.admin('GET', '/vehicle/' + vehicleUuid + '/recurring_subscriptions', null, this.get('token'));
        },
        getActiveSubscriptionsVatIds: function getActiveSubscriptionsVatIds(distributorUuid) {
            var distributorQuery = distributorUuid ? 'distributor_uuid=' + distributorUuid : '';
            return this.admin('GET', '/recurring-subscription/business?' + distributorQuery, null, this.get('token'));
        },
        sendGbVatReport: function sendGbVatReport(from, to) {
            var queryParams = "";
            if (from) {
                queryParams += 'from=' + from;
                queryParams += to ? '&to=' + to : '';
            }

            return this.admin('POST', '/purchase/generate_subscription_vat_report?' + queryParams, null, this.get('token'));
        },
        cancelFundsReservation: function cancelFundsReservation(uuid) {
            return this.admin('DELETE', '/purchase/' + uuid + '/funds_reservation', null, this.get('token'));
        },
        chargeReservedFunds: function chargeReservedFunds(uuid) {
            return this.admin('POST', '/purchase/' + uuid + '/charge_credit_card', null, this.get('token'));
        },
        setShowFundsReservationOffer: function setShowFundsReservationOffer(vehicleUuid, show) {
            return this.admin('PUT', '/vehicle/' + vehicleUuid + '/filter_reserve_fund_offers?show=' + show, null, this.get('token'));
        },
        searchByBillingInfo: function searchByBillingInfo(filter) {
            return this.admin('GET', '/user/by-billing-data?search=' + filter, null, this.get('token'));
        },
        addBackupNotifications: function addBackupNotifications(uuid, data) {
            return this.admin('PUT', '/account/' + uuid + '/backup_notifications', data, this.get('token'));
        },
        refreshDeviceData: function refreshDeviceData(imei) {
            return this.admin('GET', '/device/' + imei + '/info', null, this.get('token'));
        },
        setUserHelped: function setUserHelped(userUuid, helped) {
            return this.admin('PUT', '/user/' + userUuid + '/helped?helped=' + helped, null, this.get('token'));
        },
        searchDevicesInBulk: function searchDevicesInBulk(data, page, pageSize) {
            var url = '/device/search?';

            if (page) {
                url += 'page=' + page + '&';
            }

            if (pageSize) {
                url += 'page_size=' + pageSize;
            }

            return this.admin('POST', url, data, this.get('token'));
        },
        getShipment: function getShipment(uuid) {
            return this.admin('GET', '/shipment/' + uuid, null, this.get('token'));
        },

        core: function core(type, endpoint, data, token, neverFails, accept) {
            return this.request(type, Config['default'].apiURL + endpoint, data, token, neverFails, accept);
        },
        admin: function admin(type, endpoint, data, token, neverFails, accept) {
            return this.request(type, Config['default'].adminURL + endpoint, data, token, neverFails, accept);
        },
        store: function store(type, endpoint, data, token, neverFails, accept) {
            return this.request(type, Config['default'].storeURL + endpoint, data, token, neverFails, accept);
        },
        legacy: function legacy(type, endpoint, data, token) {
            return this.request(type, Config['default'].legacyApiURL + endpoint, data, token);
        },
        request: function request(type, endpoint, data, token, neverFails, accept) {
            var _this = this;
            _this.logger().trace('service:carlock: ' + type + ' request to ' + endpoint);

            if (data) {
                _this.logger().trace(data);
            }

            return new Ember['default'].RSVP.Promise(function (resolve, reject) {
                var acceptText = accept ? accept : 'application/json';
                var headers = {
                    'Accept': acceptText,
                    'X-Client-Type': Config['default'].APP.type,
                    'X-Client-Version': Config['default'].APP.version,
                    'X-Client-Locale': _this.i18n().get('locale')
                };

                if (token) {
                    headers['X-Token'] = token;
                }

                return Ember['default'].$.ajax({
                    url: endpoint,
                    method: type,
                    data: data ? JSON.stringify(data) : null,
                    dataType: 'json',
                    contentType: 'application/json; charset=UTF-8',
                    accepts: acceptText,
                    headers: headers
                }).then(function (data, status, xhr) {
                    _this.logger().trace('service:carlock: Request ' + status);
                    _this.logger().trace(data);

                    Ember['default'].run(function () {
                        resolve(data, xhr.getResponseHeader('Location'));
                    });
                }, function (xhr) {
                    Ember['default'].run(function () {
                        var error = 'server_not_available';
                        if (xhr.status === 200 || xhr.status === 201 || xhr.status === 202) {
                            // This happens when server returns nothing
                            var response = xhr.responseText ? xhr.responseText : {};
                            resolve(response, xhr.getResponseHeader('Location'));
                            return;
                        } else if (xhr.responseJSON) {
                            error = xhr.responseJSON.code.toLowerCase();
                        } else if (xhr.status === 400) {
                            error = 'invalid_request';
                        } else if (xhr.status === 401) {
                            error = 'unauthorized';
                        } else if (xhr.status === 404) {
                            error = 'resource_not_found';
                        } else if (xhr.error === 500) {
                            error = 'internal_server_error';
                        }

                        _this.logger().error('service:carlock: Request failed: ' + error);

                        if (xhr.responseJSON) {
                            _this.logger().error(xhr.responseJSON);
                        }

                        if (neverFails) {
                            resolve({}, xhr.getResponseHeader('Location'));
                        } else {
                            reject({ code: error, json: xhr.responseJSON });
                        }
                    });
                });
            });
        }
    });

});