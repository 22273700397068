define('carlock-admin/components/infinity-loader', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        guid: null,
        reachedInfinity: false,
        scrollable: null,
        isMobile: false,
        action: 'infinityLoad',
        infinityModelPushed: Ember['default'].observer('list.length', function () {
            Ember['default'].run.scheduleOnce('afterRender', this, this.checkIfInView);
        }),
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            // Setup scrollable
            this.set('guid', Ember['default'].guidFor(this));
            this.setupScrollable();

            // If window is re-sized, we must check if scrollable changed
            var _this = this;
            var logic = function logic() {
                var shouldBeMobile = Ember['default'].$(window).width() <= 780;
                var isMobile = _this.get('isMobile');

                if (shouldBeMobile && !isMobile || !shouldBeMobile && isMobile) {
                    _this.cleanScrollable();
                    _this.setupScrollable();
                }
            };

            this.bindEvent(Ember['default'].$(window), 'resize', logic);
        },
        setupScrollable: function setupScrollable() {
            // Get scrollable element
            var scrollable = Ember['default'].$(this.get('scrollable')).eq(0);
            var root = Ember['default'].$(window);
            var isMobile = false;

            if (root.width() <= 780) {
                scrollable = root;
                isMobile = true;
            }

            if (scrollable.length == 0) {
                scrollable = root;
            }

            this.set('isMobile', isMobile);
            this.set('_scrollable', scrollable);

            // Bind events to check if in view
            var _this = this;
            setTimeout(function () {
                _this.checkIfInView();
            }, 250);
            this.set('_timer', setInterval(function () {
                _this.checkIfInView();
            }, 1000));

            var logic = function logic() {
                Ember['default'].run.debounce(_this, _this.checkIfInView, 1);
            };

            this.bindEvent(scrollable, 'scroll', logic);
            this.bindEvent(scrollable, 'resize', logic);
        },
        bindEvent: function bindEvent(item, eventName, logic) {
            item.on(eventName + '.' + this.get('guid'), logic);
        },
        checkIfInView: function checkIfInView() {
            if (!this.$() || this.get('reachedInfinity')) {
                return;
            }

            var selfOffset = this.$().offset().top;
            var scrollable = this.get('_scrollable');
            var scrollableBottom = scrollable.height() + scrollable.scrollTop();
            var inView = selfOffset < scrollableBottom;

            if (inView) {
                this.sendAction('action');
            }
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this.cleanScrollable();
            clearInterval(this.get("_timer"));
            this.unbindEvent(Ember['default'].$(window), 'resize');
        },
        cleanScrollable: function cleanScrollable() {
            var scrollable = this.get('_scrollable');
            this.unbindEvent(scrollable, 'scroll');
            this.unbindEvent(scrollable, 'resize');
        },
        unbindEvent: function unbindEvent(item, eventName) {
            item.off(eventName + '.' + this.get('guid'));
        }
    });

});