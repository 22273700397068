define('carlock-admin/controllers/c/purchases/devices', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/helpers/computed-parser'], function (exports, Ember, Base, ComputedParser) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        queryParams: ['distributorUuid', 'dateFrom', 'dateTo', 'status', 'platform', 'filter', 'sort', 'descending'],
        distributorUuid: null,
        distributorUuidString: Ember['default'].computed('distributorUuid', ComputedParser['default']),
        dateFrom: null,
        dateFromInt: Ember['default'].computed('dateFrom', ComputedParser['default']),
        dateTo: null,
        dateToInt: Ember['default'].computed('dateTo', ComputedParser['default']),
        status: null,
        statusString: Ember['default'].computed('status', ComputedParser['default']),
        platform: null,
        platformString: Ember['default'].computed('platform', ComputedParser['default']),
        filter: null,
        filterString: Ember['default'].computed('filter', ComputedParser['default']),
        sort: '2',
        sortInt: Ember['default'].computed('sort', ComputedParser['default']),
        descending: 'true',
        descendingBool: Ember['default'].computed('descending', ComputedParser['default'])
    });

});