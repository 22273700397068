define('carlock-admin/models/c/vehicle/promo', ['exports', 'ember', 'carlock-admin/models/c/vehicle/promo-item'], function (exports, Ember, PromoItemModel) {

   'use strict';

   exports['default'] = Ember['default'].Object.extend({
      activeUsers: Ember['default'].computed('items', function () {
         var items = this.get('items');
         return items ? items.filter(function (i) {
            return i.get('active');
         }).length : 0;
      }),
      frozenUsers: Ember['default'].computed('items', function () {
         var items = this.get('items');
         return items ? items.filter(function (i) {
            return !i.get('active');
         }).length : 0;
      }),
      recurringUsers: Ember['default'].computed('items', function () {
         var items = this.get('items');
         return items ? items.filter(function (i) {
            return i.get('recurring');
         }).length : 0;
      }),
      nonRecurringUsers: Ember['default'].computed('items', 'recurringUsers', function () {
         var items = this.get('items');
         return items ? items.length - this.get('recurringUsers') : 0;
      }),
      activeRecurring: Ember['default'].computed('items', function () {
         var items = this.get('items');
         return items ? items.filter(function (i) {
            return i.get('active') && i.get('recurring');
         }).length : 0;
      }),
      frozenRecurring: Ember['default'].computed('items', function () {
         var items = this.get('items');
         return items ? items.filter(function (i) {
            return !i.get('active') && i.get('recurring');
         }).length : 0;
      }),

      load: function load(data) {
         var items = data.map(function (p) {
            return PromoItemModel['default'].create().load(p);
         });
         this.set('items', items);

         return this;
      }
   });

});