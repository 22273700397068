define('carlock-admin/components/dialogs/sms-command', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend(Base['default'], {
					vehicleUuid: null,
					imei: null,
					password: null,
					command: null,
					validations: {
									'command': {
													presence: true
									}
					},
					getPassword: function getPassword() {
									var password = this.get('password');
									return password ? 'clock ' + password : ' ';
					},

					actions: {
									show: function show() {
													this.set('command', null);
									},
									setReset: function setReset() {
													this.set('command', this.getPassword() + ' cpureset');
									},
									setParam: function setParam() {
													this.set('command', this.getPassword() + ' setparam {id} {value}');
									},
									setWebConnect: function setWebConnect() {
													this.set('command', this.getPassword() + ' web_connect');
									},
									setFlush: function setFlush() {
													this.set('command', this.getPassword() + ' flush ' + this.imei + ',m2m.tag.com,rs5,m2m572,gts.carlock.co,10004,0');
									},
									setSdformat: function setSdformat() {
													this.set('command', this.getPassword() + ' sdformat');
									},

									confirm: function confirm() {
													this.logger.trace('component:sms-command: Sending command');
													var _this = this;

													var prepare = function prepare() {
																	var uuid = _this.get('vehicleUuid');
																	var data = {
																					'command': _this.get('command')
																	};
																	return _this.carlock.sendVehicleSmsCommand(uuid, data);
													};

													var success = function success() {
																	_this.set('success', true);
													};

													this.save(prepare, success);
									}
					}
	});

});