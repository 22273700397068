define('carlock-admin/models/c/vehicle/promo-item', ['exports', 'ember', 'carlock-admin/helpers/format-date'], function (exports, Ember, FormatDate) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        email: null,
        activationDate: null,
        activationDateFormatted: Ember['default'].computed('activationDate', 'profile.instance.timeZoneId', FormatDate['default']),
        expirationDate: null,
        expirationDateFormatted: Ember['default'].computed('expirationDate', 'profile.instance.timeZoneId', FormatDate['default']),
        active: Ember['default'].computed('expirationDate', function () {
            return this.get('expirationDate') > Date.now();
        }),
        recurring: Ember['default'].computed('expirationDate', 'activationDate', function () {
            var e = moment(this.get('expirationDate')).utc();
            var a = moment(this.get('activationDate')).utc();
            return e.subtract(6, 'month').isAfter(a);
        }),

        load: function load(data) {
            this.set('email', data.user.email);
            this.set('activationDate', data.activation.date);
            this.set('expirationDate', data.subscription.expiration_date);

            return this;
        }
    });

});