define('carlock-admin/models/c/vehicle/sim-status', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var model = Ember['default'].Object.extend({
        id: null,
        displayName: Ember['default'].computed('id', function () {
            return 'vehicle.sim_status.' + this.get('id').toLowerCase();
        })
    });

    model.FIXTURES = [model.create({ id: 'INACTIVE' }), model.create({ id: 'ACTIVE' }), model.create({ id: 'SUSPENDED' }), model.create({ id: 'TERMINATED' }), model.create({ id: 'LOST' })];

    exports['default'] = model;

});