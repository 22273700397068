define('carlock-admin/models/c/vehicle/device-record', ['exports', 'ember', 'carlock-admin/helpers/format-time'], function (exports, Ember, FormatTime) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        timestamp: null, // Unit: epoch in seconds
        timestampFormatted: Ember['default'].computed('timestamp', FormatTime['default']),
        serverTimestamp: null, // Unit: epoch in seconds
        serverTimestampFormatted: Ember['default'].computed('serverTimestamp', FormatTime['default']),
        latitude: null,
        longitude: null,
        speed: null, // Unit: kph
        harshDrivingValue: null, // Unit: g/100
        satellites: null,
        hdop: null, // Hdop * 10
        moving: null,
        movingFormatted: Ember['default'].computed('moving', function () {
            return this.get('moving') ? '&#10004;' : '&#10005;';
        }),
        ignition: null,

        ignitionFormatted: Ember['default'].computed('ignition', function () {
            return this.get('ignition') ? '&#10004;' : '&#10005;';
        }),
        isHarshAcceleration: null,
        isHarshBraking: null,
        isHarshCornering: null,
        isAccurate: null,
        deviceToServerDelay: Ember['default'].computed('timestamp', 'serverTimestamp', function () {
            return this.get('serverTimestamp') - this.get('timestamp');
        }),
        gsmStrength: null,
        voltage: null,
        getType: Ember['default'].computed('isHarshAcceleration', 'isHarshBraking', 'isHarshCornering', function () {
            var prefix = 'vehicle.debug.records.';
            if (this.get('isHarshAcceleration')) {
                return prefix + 'harsh_acceleration';
            } else if (this.get('isHarshBraking')) {
                return prefix + 'harsh_braking';
            } else if (this.get('isHarshCornering')) {
                return prefix + 'harsh_cornering';
            }

            return null;
        }),
        getValue: Ember['default'].computed('getType', 'harshDrivingValue', function () {
            if (this.get('getType')) {
                return this.get('harshDrivingValue');
            }

            return null;
        }),

        load: function load(data) {
            this.set('timestamp', data.timestamp);
            this.set('serverTimestamp', data.server_timestamp);
            this.set('latitude', data.latitude);
            this.set('longitude', data.longitude);
            this.set('speed', data.speed);
            this.set('harshDrivingValue', data.harsh_driving_value);
            this.set('satellites', data.satellites);
            this.set('hdop', data.hdop);
            this.set('moving', data.moving);
            this.set('ignition', data.ignition);
            this.set('isHarshAcceleration', data.harsh_acceleration);
            this.set('isHarshBraking', data.harsh_braking);
            this.set('isHarshCornering', data.harsh_cornering);
            this.set('isAccurate', data.is_accurate);
            this.set('gsmStrength', data.gsm_strength);
            this.set('voltage', data.voltage);

            return this;
        }
    });

});