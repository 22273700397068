define('carlock-admin/validators/local/localized-confirmation', ['exports', 'ember', 'ember-validations/validators/base', 'ember-validations/messages'], function (exports, Ember, Base, Messages) {

    'use strict';

    var get = Ember['default'].get;
    var set = Ember['default'].set;

    exports['default'] = Base['default'].extend({
        init: function init() {
            this.originalProperty = this.property;
            this.property = this.property + 'Confirmation';
            this._super();
            this.dependentValidationKeys.pushObject(this.originalProperty);

            if (this.options === true) {
                set(this, 'options', { attribute: this.originalProperty });
                set(this, 'options', { message: Messages['default'].render('confirmation', this.options) });
            } else {
                set(this, 'options', { message: Messages['default'].render(this.options.message, this.options) });
            }
        },
        call: function call() {
            var original = get(this.model, this.originalProperty);
            var confirmation = get(this.model, this.property);

            if (!Ember['default'].isEmpty(original) || !Ember['default'].isEmpty(confirmation)) {
                if (original !== confirmation) {
                    this.errors.pushObject(this.options.message);
                }
            }
        }
    });

});