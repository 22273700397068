define('carlock-admin/models/c/coupon/generate-coupon', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        offerUuid: null,
        code: null,
        /*
        codeFormatted: Ember.computed('code', function() {
            var code = this.get('code');
             if (code) {
                return code.replace(/(.{5})(.{5})(.{5})/, "$1-$2-$3");
            }
             return null;
        }),
        */
        startDate: null,
        endDate: null,
        type: null,
        value: null,

        toJson: function toJson() {
            return {
                'code': this.get('code'),
                'offer_uuid': this.get('offerUuid'),
                'start': this.get('startDate'),
                'end': this.get('endDate'),
                'type': this.get('type.id'),
                'value': this.get('value')
            };
        }
    });

});