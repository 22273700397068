define('carlock-admin/controllers/c/gift-codes', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/helpers/computed-parser', 'carlock-admin/models/c/basic-gift-code'], function (exports, Ember, Base, ComputedParser, BasicGiftCodeModel) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        queryParams: ['filter', 'sort', 'descending'],
        sort: '3',
        sortInt: Ember['default'].computed('sort', ComputedParser['default']),
        descending: 'true',
        descendingBool: Ember['default'].computed('descending', ComputedParser['default']),
        actions: {
            viewGiftCode: function viewGiftCode(giftCode) {
                this.logger.trace('controller: Redirecting to vehicle');
                this.transitionToRoute('c.gift-code', {
                    queryParams: {
                        uuid: giftCode.get('uuid')
                    }
                });
            }
        }
    });

});