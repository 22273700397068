define('carlock-admin/controllers/c/purchases/promo', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/purchase/address', 'carlock-admin/models/c/purchase/purchase-offer', 'carlock-admin/models/country', 'carlock-admin/models/state', 'carlock-admin/models/c/purchase/shipping-provider', 'carlock-admin/models/c/purchase/shipment-item'], function (exports, Ember, Base, AddressModel, PurchaseOfferModel, CountryModel, StateModel, ShippingProvider, shipment_item) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        igName: null,
        igComputedLink: Ember['default'].computed('igName', function () {
            var igName = this.get('igName') ? this.get('igName').toLowerCase() : null;
            return 'https://www.carlock.co/amb/start?igH=' + CryptoJS.SHA256(igName + 'CL').toString(CryptoJS.enc.Hex);
        }),
        items: null,
        orderId: null,

        loadInterface: function loadInterface() {
            this.set('orderId', 'PROMO-' + moment().format('YYYY.MM.DD.HHmmSS'));
            this.set('items', shipment_item.PhysicalItems);
        },

        actions: {
            ship: function ship(shipment) {
                var _this = this;

                this.logger.trace('controller:promo purchase: Creating promo order');

                var prepare = function prepare() {
                    return _this.carlock.sendPromo(shipment);
                };

                var success = function success(response) {
                    _this.logger.trace(response);
                    _this.set('visible', false);
                };

                this.save(prepare, success);
            }
        }
    });

});