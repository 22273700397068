define('carlock-admin/initializers/export', ['exports'], function (exports) {

    'use strict';

    exports.initialize = initialize;

    function initialize(application) {
        application.inject('component', 'export', 'service:export');
        application.inject('controller', 'export', 'service:export');
        application.inject('route', 'export', 'service:export');
    }

    exports['default'] = {
        name: 'export',
        initialize: initialize
    };

});