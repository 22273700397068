define('carlock-admin/components/drop-down', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        tagName: 'a',
        attributeBindings: ['tabIndex:tabindex'],
        tabIndex: 0,
        classNameBindings: ['active'],
        classNames: 'drop-down',
        value: null,
        displayPlaceholder: Ember['default'].computed('value', function () {
            return this.get('value') == null;
        }),
        valueChanged: Ember['default'].observer('value', function () {
            this.logger.trace('component:drop-down: Value changed');
            this.set('selected', this.get('value'));
        }),
        selected: null,
        selectedChanged: Ember['default'].observer('selected', function () {
            this.logger.trace('component:drop-down: Selected changed');

            if (this.get('active') && !this.get('blockScroll')) {
                this.logger.trace('component:drop-down: Scrolling into view');
                this.scrollIntoView(this.get('selected'));
            }
        }),
        focused: false,
        active: false,
        keyDown: function keyDown(event) {
            this.logger.trace('component:drop-down: Key down: ' + event.which);

            if (!this.get('focused')) {
                this.logger.trace('component:drop-down: Key down blocked, not focused');
                return;
            }

            if (!this.get('active')) {
                this.set('active', true);
                this.logger.trace('component:drop-down: Toggled active');
            }

            var items = this.get('items');

            if (!items || items.length === 0) {
                this.logger.trace('component:drop-down: No items');
                return false;
            }

            // Escape key
            if (event.keyCode === 27) {
                this.logger.trace('component:drop-down: Escape detected, closing');
                this.set('active', false);
                return false;
            }

            // Down key
            var selected = this.get('selected');
            var index;

            if (event.keyCode === 40) {
                this.logger.trace('component:drop-down: Down detected');

                // Select first item
                if (selected == null) {
                    this.logger.trace('component:drop-down: Selecting first');
                    this.set('selected', items[0]);
                    return false;
                }

                // Select next item
                index = this.getIndex(selected);

                if (index >= 0 && index + 1 < items.length) {
                    this.set('selected', items[index + 1]);
                }

                return false;
            }

            // Up key
            if (event.keyCode === 38) {
                this.logger.trace('component:drop-down: Up detected');
                index = this.getIndex(selected);

                if (index - 1 >= 0) {
                    this.logger.trace('component:drop-down: Selecting previous');
                    this.set('selected', items[index - 1]);
                }

                return false;
            }

            // Enter key
            if (event.keyCode === 13) {
                this.logger.trace('component:drop-down: Enter detected');

                if (selected != null) {
                    this.logger.trace('component:drop-down: Setting selected as value');
                    this.set('value', selected);
                    this.set('active', false);
                }

                return false;
            }
        },
        keyPress: function keyPress(event) {
            this.logger.trace('component:drop-down: Key press: ' + event.which);

            if (!this.get('focused')) {
                this.logger.trace('component:drop-down: Key press blocked, not focused');
                return;
            }

            var selected = this.get('selected');
            var key = String.fromCharCode(event.which);

            // Find first and last item starting with specific letter
            var items = this.get('items');
            var first = -1;
            var last = -1;

            for (var i = 0; i < items.length; i++) {
                var text = this.getText(items[i]);
                var textFirst = text.substring(0, 1).toLowerCase();

                if (textFirst === key) {
                    if (first === -1) {
                        first = i;
                    }

                    last = i;
                }
            }

            // If we can't find it we are done
            if (first < 0) {
                this.logger.trace('component:drop-down: Cannot find first letter');
                return false;
            }

            var _this = this;
            _this.set('blockMouseOver', true);
            _this.logger.trace('component:drop-down: Blocking mouse over');
            setTimeout(function () {
                _this.set('blockMouseOver', false);
                _this.logger.trace('component:drop-down: Mouse over unblocked');
            }, 2000);

            // Check if we need to move to next item since we are repeating next key
            if (selected) {
                var selectedText = this.getText(selected);
                var selectedFirst = selectedText.substring(0, 1).toLowerCase();

                if (selectedFirst === key) {
                    var index = this.getIndex(selected);

                    if (index >= 0) {
                        if (index + 1 > last) {
                            this.set('selected', items[first]);
                        } else {
                            this.set('selected', items[index + 1]);
                        }
                    }
                } else {
                    this.set('selected', items[first]);
                }
            } else {
                this.set('selected', items[first]);
            }

            _this.logger.trace('component:drop-down: Key pressed');
            return false;
        },
        click: function click() {
            this.logger.trace('component:drop-down: Click');
            var items = this.get('items');

            if (!items || items.length === 0) {
                this.logger.trace('component:drop-down: No items');
                return;
            }

            this.set('active', !this.get('active'));

            if (this.get('selected') != null) {
                this.logger.trace('component:drop-down: Showing selected item');
                this.scrollIntoView(this.get('selected'));
            }

            this.logger.trace('component:drop-down: Clicked');
        },
        focusOut: function focusOut() {
            this.logger.trace('component:drop-down: Focus out');

            if (!this.get('blockFocusOut')) {
                this.set('active', false);
                this.sendAction('focus-out');
                this.logger.trace('component:drop-down: Focused out');
            } else {
                this.logger.trace('component:drop-down: Focus out blocked');
            }
        },
        focusIn: function focusIn() {
            this.logger.trace('component:drop-down: Focus in');
            this.set('focused', true);
            this.logger.trace('component:drop-down: Focused in');
        },
        getId: function getId(item) {
            if (!item) {
                return null;
            }

            var idField = this.get('idField');

            if (item.get) {
                return item.get(idField);
            }

            return item[idField];
        },
        getText: function getText(item) {
            if (!item) {
                return null;
            }

            var textField = this.get('textField');

            if (item.get) {
                return item.get(textField);
            }

            return item[textField];
        },
        getIndex: function getIndex(item) {
            if (!item) {
                return -1;
            }

            var items = this.get('items');
            var id = this.getId(item);

            for (var i = 0; i < items.length; i++) {
                if (this.getId(items[i]) === id) {
                    return i;
                }
            }

            return -1;
        },
        scrollIntoView: function scrollIntoView(item) {
            var panel = this.$().children('.items');
            var panelHeight = Ember['default'].$(panel).height();
            var panelScrollOffset = Ember['default'].$(panel).scrollTop();
            var id = this.getId(item);
            var itemId = '#' + this.get('identifier') + '-' + (id && id.replace ? id.replace(/\W/g, "") : id);
            var itemHeight = Ember['default'].$(itemId).height();
            var itemOffset = this.getIndex(item) * itemHeight;
            var toScroll = -1;

            if (itemOffset < panelScrollOffset) {
                toScroll = itemOffset;
            } else if (itemOffset + itemHeight > panelScrollOffset + panelHeight) {
                toScroll = panelScrollOffset + (itemOffset + itemHeight - (panelScrollOffset + panelHeight));
            }

            if (toScroll >= 0) {
                Ember['default'].$(panel).scrollTop(toScroll);
            }
        },
        actions: {
            itemClick: function itemClick(item) {
                this.logger.trace('component:drop-down: Item clicked: ' + item[this.get('idField')]);
                this.set('value', item);
            },
            itemMouseEnter: function itemMouseEnter(item) {
                this.logger.trace('component:drop-down: Item mouse over ' + item[this.get('idField')]);

                if (!this.get('blockMouseOver')) {
                    this.set('blockScroll', true);
                    this.set('selected', item);
                    this.set('blockScroll', false);
                    this.logger.trace('component:drop-down: Item moused over');
                } else {
                    this.logger.trace('component:drop-down: Item mouse over blocked');
                }
            }
        }
    });

});