define('carlock-admin/models/c/vehicle/push-notification', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var pushNotification = Ember['default'].Object.extend({
        name: null
    });

    pushNotification.FIXTURES = [{ id: 1, name: "VEHICLE_MOVED" }, { id: 2, name: "GPS_SIGNAL_LOST" }, { id: 3, name: "DEVICE_DISCONNECTED" }, { id: 4, name: "LOW_BATTERY" }, { id: 5, name: "ENGINE_STARTED" }, { id: 6, name: "MOTION_DETECTED" }, { id: 7, name: "DOOR_OPENED" }, { id: 8, name: "EXTERNAL_ALARM" }, { id: 9, name: "GSM_SIGNAL_LOST" }, { id: 10, name: "GSM_SIGNAL_OK" }, { id: 11, name: "AUTO_LOCKED" }, { id: 12, name: "AUTO_UNLOCKED" }, { id: 13, name: "DEVICE_CONNECTED" }, { id: 14, name: "AUTO_LOCK_OVERRIDDEN" }, { id: 15, name: "SUBSCRIPTION_EXPIRED" }, { id: 16, name: "SUBSCRIPTION_WILL_EXPIRE" }, { id: 17, name: "CRASH_DETECTED" }, { id: 18, name: "HIGH_BATTERY_DRAIN" }, { id: 19, name: "HARSH_BRAKING" }, { id: 20, name: "HARSH_ACCELERATION" }, { id: 21, name: "HARSH_CORNERING" }, { id: 22, name: "SMART_LOCATION_DETECTED" }, { id: 23, name: "TRIP_STARTED" }, { id: 24, name: "TRIP_ENDED" }, { id: 25, name: "ACTIVATION_LOGIN" }, { id: 31, name: "SPEEDING" }, { id: 34, name: "SOS_PRESSED" }, { id: 35, name: "FALL_DETECTED" }, { id: 36, name: "PAYMENT_PROBLEM" }, { id: 37, name: "EXTEND_BASIC_TRIAL" }, { id: 38, name: "GEOZONE_ENTER" }, { id: 39, name: "GEOZONE_EXIT" }];

    exports['default'] = pushNotification;

});