define('carlock-admin/controllers/c/forgot-password', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        validations: {
            'model.email': {
                presence: true
            }
        },
        actions: {
            sendInstructions: function sendInstructions() {
                this.logger.trace('controller:forgot-password: Sending instructions for forgot password');
                var _this = this;
                var prepare = function prepare() {
                    var data = {
                        email: _this.get('model.email')
                    };

                    return _this.carlock.resetPassword(data);
                };

                var failed = function failed(code) {
                    if (code == 'resource_not_found') {
                        _this.logger.trace('controller:forgot-password: Cannot find email');
                        _this.send('error', 'email_not_found');
                        return true; // Error handled
                    }
                };

                this.save(prepare, failed);
            }
        }
    });

});