define('carlock-admin/components/localized-distance', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        tagName: 'span',
        classNames: ['distance'],
        country: null,
        meters: null,
        unit: Ember['default'].computed('i18n.locale', 'country', function () {
            if (this.imperial()) {
                return 'misc.miles_short';
            }

            return 'misc.kilometers_short';
        }),
        value: Ember['default'].computed('meters', 'i18n.locale', 'country', function () {
            var distance = this.get('meters') / 1000;

            if (this.imperial()) {
                distance /= 1.60934;
            }

            return (Math.round(distance * 10) / 10).toLocaleString();
        }),
        imperial: function imperial() {
            // First check country
            var country = this.get('country');

            if (country) {
                return country.isoCode === 'GB' || country.isoCode === 'US';
            }

            // If we don't have country decide based on locale
            var locale = this.get('i18n.locale');

            if (locale) {
                return locale.indexOf('GB') >= 0 || locale.indexOf('US') >= 0;
            }

            // Default metric system
            return false;
        }
    });

});