define('carlock-admin/components/dialogs/send-user-push-notification', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/user/push-notification'], function (exports, Ember, Base, PushNotification) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        userUuid: null,
        pushNotification: null,
        pushNotificationList: PushNotification['default'].FIXTURES,
        validations: {
            'pushNotification': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                this.set('pushNotification', null);
            },
            confirm: function confirm() {
                this.logger.trace('component:send-push-notification: Sending push notification');
                var _this = this;

                var prepare = function prepare() {
                    var uuid = _this.get('userUuid');
                    var data = {
                        'notification': _this.get('pushNotification').name
                    };

                    return _this.carlock.sendUserPushNotification(uuid, data);
                };

                var success = function success() {
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});