define('carlock-admin/services/profile', ['exports', 'ember', 'carlock-admin/models/c/profile'], function (exports, Ember, ProfileModel) {

    'use strict';

    exports['default'] = Ember['default'].Service.extend({
        carlock: Ember['default'].inject.service('carlock'),
        session: Ember['default'].inject.service('session'),
        refresh: function refresh() {
            var session = this.get('session');
            var carlock = this.get('carlock');
            var _this = this;

            if (session.get('isAuthenticated')) {
                carlock.getProfile().then(function (response) {
                    _this.set('instance', ProfileModel['default'].create().load(response));
                });
            }
        }
    });

});