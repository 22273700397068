define('carlock-admin/controllers/c/coupons/index', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/helpers/computed-parser'], function (exports, Ember, Base, ComputedParser) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        queryParams: ['filter', 'sort', 'descending'],
        sort: '4',
        sortInt: Ember['default'].computed('sort', ComputedParser['default']),
        descending: 'true',
        descendingBool: Ember['default'].computed('descending', ComputedParser['default']),
        actions: {
            viewCoupon: function viewCoupon(coupon) {
                var uuid = coupon.get('uuid');
                this.logger.trace('controller: Redirecting to coupon = ' + uuid);
                this.transitionToRoute('c.coupons.coupon', {
                    queryParams: {
                        uuid: uuid
                    }
                });
            }
        }
    });

});