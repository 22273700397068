define('carlock-admin/models/c/vehicle/trip-notification', ['exports', 'ember', 'carlock-admin/helpers/format-time', 'carlock-admin/models/c/vehicle/location'], function (exports, Ember, FormatTime, LocationModel) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        timestamp: null,
        timestampFormatted: Ember['default'].computed('timestamp', FormatTime['default']),
        serverTimestamp: null,
        serverTimestampFormatted: Ember['default'].computed('serverTimestamp', FormatTime['default']),
        notification: null,
        location: null,

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('timestamp', data.timestamp);
            this.set('serverTimestamp', data.server_timestamp);
            this.set('notification', data.notification);
            this.set('location', LocationModel['default'].create().load(data.location));

            return this;
        }
    });

});