define('carlock-admin/components/dialogs/add-vehicle', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/vehicle/create-device', 'carlock-admin/models/c/vehicle/distributor', 'carlock-admin/models/c/vehicle/sim-provider', 'carlock-admin/models/c/vehicle/device-type'], function (exports, Ember, Base, CreateDeviceModel, DistributorModel, SimProviderModel, DeviceTypeModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        vehicle: null,
        validations: {
            'vehicle.distributor': {
                presence: true
            },
            'vehicle.deviceType': {
                presence: true
            },
            'vehicle.deviceSerialNumber': {
                presence: true
            },
            'vehicle.deviceImei': {
                presence: true
            },
            'vehicle.activationCode': {
                presence: true
            },
            'vehicle.activationDays': {
                presence: true,
                numericality: {
                    onlyInteger: true,
                    greaterThan: 0
                }
            },
            'vehicle.distributorSim': {
                presence: true
            }
        },
        providerList: SimProviderModel['default'].FIXTURES,
        deviceTypeList: DeviceTypeModel['default'].FIXTURES,
        distributorList: null,
        actions: {
            show: function show() {
                this.logger.trace('component:add-vehicle: Getting distributors');
                var _this = this;

                var prepare = function prepare() {
                    return _this.carlock.getDistributors();
                };

                var success = function success(response) {
                    var list = Ember['default'].A();

                    for (var i = 0; i < response.length; i++) {
                        list.pushObject(DistributorModel['default'].create().load(response[i]));
                    }

                    _this.set('distributorList', list);
                };

                this.load(prepare, success);
                this.set('vehicle', CreateDeviceModel['default'].create());
            },
            confirm: function confirm() {
                this.logger.trace('component:add-vehicle: Adding vehicle');
                var _this = this;
                var prepare = function prepare() {
                    var data = {
                        'distributor_uuid': _this.get('vehicle.distributor.uuid'),
                        'device_type': _this.get('vehicle.deviceType.id'),
                        'device_serial_number': _this.get('vehicle.deviceSerialNumber'),
                        'device_imei': _this.get('vehicle.deviceImei'),
                        'activation_code': _this.get('vehicle.activationCode'),
                        'activation_days': _this.get('vehicle.activationDays'),
                        'distributor_sim': _this.get('vehicle.distributorSim'),
                        'sim_provider': _this.get('simProvider') ? _this.get('vehicle.simProvider') : null,
                        'sim_iccid': _this.get('simIccid') ? _this.get('vehicle.simIccid') : null
                    };
                    return _this.carlock.addVehicle(data);
                };

                var success = function success() {
                    _this.set('success', true);
                    _this.set('vehicle', CreateDeviceModel['default'].create());
                };

                this.save(prepare, success);
            }
        }
    });

});