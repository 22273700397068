define('carlock-admin/helpers/computed-parser', ['exports'], function (exports) {

    'use strict';

    var parseComputed = {
        get: function get(name) {
            this.logger.trace('computed-parser: Getting ' + name);
            var value;

            if (name.endsWith('Int')) {
                var int = this.get(name.substring(0, name.length - 3));

                if (int === undefined || int == null) {
                    this.logger.trace('computed-parser: Returning null');
                    return null;
                }

                try {
                    value = parseInt(int);
                    this.logger.trace('computed-parser: Returning int ' + value);
                    return value;
                } catch (e) {}
            } else if (name.endsWith('Bool')) {
                var bool = this.get(name.substring(0, name.length - 4));

                if (bool === undefined || bool == null) {
                    this.logger.trace('computed-parser: Returning null');
                    return null;
                }

                value = String(bool).toLowerCase() === 'true';
                this.logger.trace('computed-parser: Returning bool ' + value);
                return value;
            } else if (name.endsWith('String')) {
                var string = this.get(name.substring(0, name.length - 6));

                if (string === undefined || string == null || string === 'null') {
                    this.logger.trace('computed-parser: Returning null');
                    return null;
                }

                this.logger.trace('computed-parser: Returning string ' + string);
                return string;
            }
        },
        set: function set(name, value) {
            this.logger.trace('computed-parser: Setting name=' + name + ' value=' + value);
            var field = null;

            if (name.endsWith('Int')) {
                field = name.substring(0, name.length - 3);
            } else if (name.endsWith('Bool')) {
                field = name.substring(0, name.length - 4);
            } else if (name.endsWith('String')) {
                field = name.substring(0, name.length - 6);
            }

            if (value === undefined) value = null;

            if (field) {
                this.logger.trace('computed-parser: Set name=' + field + ' value=' + value);
                this.set(field, value);
            }
        }
    };

    exports['default'] = parseComputed;

});