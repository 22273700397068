define('carlock-admin/routes/c/purchases/payouts', ['exports', 'carlock-admin/routes/authenticated', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Base, AuthenticatedRouteMixin) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        model: function model(params) {
            return params;
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('filter', model.filter);
            controller.set('sort', model.sort);
            controller.set('descending', model.descending);
        }
    });

});