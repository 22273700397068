define('carlock-admin/models/c/purchase/purchase-type', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var type = Ember['default'].Object.extend({
        id: null,
        displayName: Ember['default'].computed('id', function () {
            return 'purchase.type.' + this.get('id').toLowerCase();
        })

    });

    type.FIXTURES = [type.create({ id: 'PHYSICAL' }), type.create({ id: 'SHIPPING' }), type.create({ id: 'SUBSCRIPTION' }), type.create({ id: 'UPGRADE' }), type.create({ id: 'PAUSE' }), type.create({ id: 'FUNDS_RESERVATION' })];

    exports['default'] = type;

});