define('carlock-admin/routes/c/set-password', ['exports', 'ember', 'carlock-admin/routes/authenticated', 'carlock-admin/models/c/set-password'], function (exports, Ember, Base, SetPasswordModel) {

    'use strict';

    exports['default'] = Base['default'].extend({
        model: function model() {
            return SetPasswordModel['default'].create();
        },
        setupController: function setupController(controller, model) {
            this.logger.trace('controller:set-password: Setting up controller');

            // Setup base controller
            this._super(controller, model);

            if (!controller.get('session.isAuthenticated') && !controller.get('hash')) {
                controller.displayError('unauthorized');
            }
        }
    });

});