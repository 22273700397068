define('carlock-admin/helpers/access-control', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Helper.extend({
        access: Ember['default'].inject.service('access'),
        compute: function compute(checks) {
            var _this = this;

            return checks.every(function (check) {
                return _this.get('access')[check]();
            });
        }
    });

});