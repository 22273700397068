define('carlock-admin/controllers/c/purchases/paypal', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/purchase/paypal-accounting-data'], function (exports, Ember, Base, PaypalAccountingDataModel) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        dateFrom: null,
        dateTo: null,
        distributor: null,
        paymentIds: null,

        loadRequestSucceeded: function loadRequestSucceeded(response) {
            var list = [];

            for (var i = 0; i < response.length; i++) {
                list.pushObject(PaypalAccountingDataModel['default'].create().load(response[i]));
            }

            if (response.has_more) {
                response.total_count = 999999;
            } else {
                response.total_count = null;
            }
        },
        actions: {
            generateAll: function generateAll(export_type) {
                this.logger.trace('controller:paypal: Getting paypal accounting data');
                var _this = this;

                var prepare = function prepare() {
                    var dateFrom = _this.get('dateFrom');
                    var dateTo = _this.get('dateTo');

                    if (dateFrom) {
                        dateFrom = moment(dateFrom).utc().valueOf();
                    }

                    if (dateTo) {
                        dateTo = moment(dateTo).utc().valueOf();
                    }

                    return _this.carlock.getPaypalAccountingData(dateFrom, dateTo);
                    //Add to /public/
                    //return Ember.$.getJSON("test.json");
                };

                var success = function success(results) {
                    if (results) {
                        var items = [];

                        var _iteratorNormalCompletion = true;
                        var _didIteratorError = false;
                        var _iteratorError = undefined;

                        try {
                            for (var _iterator = results.data_list[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                                var result = _step.value;

                                var model = PaypalAccountingDataModel['default'].create().load(result);
                                if (model.get('invoiceNumber')) {
                                    items.push(model);
                                }
                            }
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion && _iterator['return']) {
                                    _iterator['return']();
                                }
                            } finally {
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }

                        _this['export'].exportPaypalAccountingData(items, export_type === 'csv');
                    }
                };

                this.load(prepare, success);
            },
            generatePurchases: function generatePurchases() {
                var _this2 = this;

                var distributorUuid = this.get('distributor.uuid');
                var paymentIds = this.get('paymentIds');
                var details = undefined;

                if (!distributorUuid) {
                    details = 'paypal_export.create_purchase.select_distributor';
                } else if (!paymentIds) {
                    details = 'paypal_export.create_purchase.enter_id';
                }

                if (details) {
                    var error = {
                        code: 'invalid_request',
                        json: { details: this.i18n.t(details) }
                    };

                    this.send('yikes', error);
                    return;
                }

                var data = {
                    distributor_uuid: distributorUuid,
                    payment_id_list: paymentIds.split('\n')
                };

                this.load(function () {
                    return _this2.carlock.createPaypalPurchase(data);
                }, function () {});
            },
            clearFilter: function clearFilter() {
                this.set('dateFrom', null);
                this.set('dateTo', null);
            },
            togglePickDistributorDialog: function togglePickDistributorDialog() {
                this.toggleProperty('showPickDistributorDialog');
            },
            selectDistributor: function selectDistributor(distributor) {
                this.set('distributor', distributor);
            }
        }
    });

});