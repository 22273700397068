define('carlock-admin/controllers/c/vehicle/index', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/vehicle/sim', 'carlock-admin/models/c/vehicle/subscription-tier'], function (exports, Ember, Base, SimModel, SubscriptionTier) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        hasSimAccess: Ember['default'].computed('model.device.distributorSim', function () {
            return this.get('access').hasVehicleRwAccess() && this.get('access').hasVehicleSimRwAccess() || this.get('model.device.distributorSim');
        }),
        queryParams: ['uuid'],
        autoSwitch: true,
        subscriptionTierList: SubscriptionTier['default'].FIXTURES,
        subscriptionTierObserver: Ember['default'].observer('model.subscription.tier', function () {}),
        markAsReplacement: function markAsReplacement() {
            this.logger.trace('controller: Marking vehicle as replacement');
            var markedAsReplacement = this.get('model.state.markedAsReplacement');
            var _this = this;

            var prepare = function prepare() {
                var vehicleUuid = _this.get('model.uuid');
                return _this.carlock.markVehicleAsReplacement(vehicleUuid, markedAsReplacement);
            };

            var success = function success(data) {};

            var error = function error() {
                _this.set('autoSwitch', true);
                _this.set('model.state.markedAsReplacement', !markedAsReplacement);
            };

            return _this.save(prepare, success, error);
        },
        markAsPromo: function markAsPromo() {
            this.logger.trace('controller: Marking user as promo');
            var markedAsPromo = this.get('model.user.promo');
            var _this = this;

            var prepare = function prepare() {
                var userUuid = _this.get('model.user.uuid');
                return _this.carlock.markUserAsPromo(userUuid, markedAsPromo);
            };

            var success = function success() {};

            var error = function error() {
                _this.set('model.user.promo', !markedAsPromo);
            };

            return _this.save(prepare, success, error);
        },
        togglePauseSubscription: function togglePauseSubscription() {
            var _this2 = this;

            this.logger.trace('controller: Toggling pause subscription');
            var canPause = this.get('model.subscription.canPause');

            var prepare = function prepare() {
                return _this2.carlock.setCanPauseSubscription(_this2.get('model.uuid'), canPause);
            };

            var error = function error() {
                return _this2.set('model.subscription.canPause', !canPause);
            };

            return this.save(prepare, function () {}, error);
        },

        actions: {
            showUser: function showUser() {
                this.logger.trace('controller: Redirecting to user');
                this.transitionToRoute('c.user', {
                    queryParams: {
                        uuid: this.get('model.user.uuid')
                    }
                });
            },
            toggleSetDistributorDialog: function toggleSetDistributorDialog() {
                this.toggleProperty('showSetDistributorDialog');
            },
            toggleSetUserDialog: function toggleSetUserDialog() {
                this.toggleProperty('showSetUserDialog');
            },
            toggleSetDeviceDialog: function toggleSetDeviceDialog() {
                this.toggleProperty('showSetDeviceDialog');
            },
            toggleSmsCommandDialog: function toggleSmsCommandDialog() {
                this.toggleProperty('showSmsCommandDialog');
            },
            toggleExtendSubscriptionDialog: function toggleExtendSubscriptionDialog() {
                this.toggleProperty('showExtendSubscriptionDialog');
            },
            toggleAddSubscriptionDaysDialog: function toggleAddSubscriptionDaysDialog() {
                this.toggleProperty('showAddSubscriptionDaysDialog');
            },
            toggleResetDialog: function toggleResetDialog() {
                this.toggleProperty('showResetDialog');
            },
            toggleSendPushNotificationDialog: function toggleSendPushNotificationDialog() {
                this.toggleProperty('showSendPushNotificationDialog');
            },
            toggleUserDebugModeDialog: function toggleUserDebugModeDialog() {
                this.toggleProperty('showUserDebugModeDialog');
            },
            toggleEditVehicleSimDialog: function toggleEditVehicleSimDialog() {
                this.toggleProperty('showEditVehicleSimDialog');
            },
            toggleConfirmRemoveVehicleSimDialog: function toggleConfirmRemoveVehicleSimDialog() {
                this.toggleProperty('showShowConfirmRemoveVehicleSimDialog');
            },
            refresh: function refresh() {
                this.set('autoSwitch', true);
                this.get('target.router').refresh();
            },
            refreshSim: function refreshSim() {
                var _this3 = this;

                if (this.get('model.device.sim')) {
                    var _ret = (function () {
                        var _this = _this3;
                        var iccid = _this3.model.device.sim.iccid;

                        if (iccid) {
                            var prepare = function prepare() {
                                return _this.carlock.refreshSim(iccid);
                            };

                            var success = function success(data) {
                                _this.set('model.device.sim', SimModel['default'].create().load(data));
                            };

                            return {
                                v: _this.load(prepare, success)
                            };
                        }
                    })();

                    if (typeof _ret === 'object') return _ret.v;
                }
            },
            promoChange: function promoChange() {
                this.markAsPromo();
            },
            replacementChange: function replacementChange() {
                this.markAsReplacement();
            },
            pauseSubscriptionChange: function pauseSubscriptionChange() {
                this.togglePauseSubscription();
            },
            updateSubscriptionTier: function updateSubscriptionTier() {
                var _this4 = this;

                var body = { tier: this.get('model.subscription.tier.id') };
                var vehicleUuid = this.get('model.uuid');
                var prepare = function prepare() {
                    return _this4.carlock.setSubscriptionTier(vehicleUuid, body);
                };
                var success = function success() {};
                this.save(prepare, success);
            },
            copyVehicleData: function copyVehicleData(text) {
                var _this5 = this;

                if (navigator.clipboard) {
                    navigator.clipboard.writeText(text).then(function () {
                        _this5.set('copyVehicleDataStatus', 'vehicle.debug.fota_export.copy_success');
                        Ember['default'].run.later(function () {
                            return _this5.set('copyFotaExportStatus', null);
                        }, 2000);
                    }, function (err) {
                        _this5.set('copyVehicleDataStatus', 'vehicle.debug.fota_export.copy_failed');
                        console.error('Async: Could not copy text: ', err);
                    });
                }
            },
            toggleShowFundsReservation: function toggleShowFundsReservation() {
                var _this6 = this;

                var prepare = function prepare() {
                    return _this6.carlock.setShowFundsReservationOffer(_this6.get('model.uuid'), _this6.get('model.subscription.showFundsReservation'));
                };
                var success = function success() {};
                this.save(prepare, success);
            },
            toggleBackupNotificationsDialog: function toggleBackupNotificationsDialog() {
                this.toggleProperty('showBackupNotificationsDialog');
            },
            refreshDeviceData: function refreshDeviceData() {
                var _this7 = this;

                var prepare = function prepare() {
                    return _this7.carlock.refreshDeviceData(_this7.get('model.device.imei'));
                };
                var success = function success(device) {
                    _this7.set('model.device', _this7.get('model.device').load(device));
                };
                this.load(prepare, success);
            }
        }
    });

});