define('carlock-admin/models/c/purchase/address', ['exports', 'ember', 'carlock-admin/models/country', 'carlock-admin/models/state'], function (exports, Ember, CountryModel, StateModel) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
					name: null,
					address: null,
					address2: null,
					zipCode: null,
					city: null,
					countryIsoCode: null,
					country: null,
					stateIsoCode: null,
					state: null,
					load: function load(data) {
									this.set('name', data.name);
									this.set('address', data.address);
									this.set('address2', data.address2);
									this.set('zipCode', data.zip_code);
									this.set('city', data.city);
									this.set('countryIsoCode', data.country_iso_code);
									this.set('country', CountryModel['default'].FIXTURES.findBy('isoCode', data.country_iso_code));
									this.set('stateIsoCode', data.state_iso_code);
									this.set('state', StateModel['default'].FIXTURES.findBy('isoCode', data.state_iso_code));
									return this;
					},
					toJson: function toJson() {
									return {
													"name": this.get('name'),
													"address": this.get('address'),
													"address2": this.get('address2'),
													"zip_code": this.get('zipCode'),
													"city": this.get('city'),
													"country_iso_code": this.get('countryIsoCode.isoCode'),
													"state_iso_code": this.get('stateIsoCode.isoCode')
									};
					}
	});

});