define('carlock-admin/components/localized-duration', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        tagName: 'span',
        classNames: ['duration'],
        seconds: null,
        hours: Ember['default'].computed('seconds', function () {
            var seconds = this.get('seconds');
            var minutes = Math.round(seconds / 60);
            return Math.floor(minutes / 60);
        }),
        minutes: Ember['default'].computed('seconds', function () {
            var seconds = this.get('seconds');
            var minutes = Math.round(seconds / 60);
            var hours = Math.floor(minutes / 60);
            return minutes - hours * 60;
        })
    });

});