define('carlock-admin/models/c/vehicle/recurring-subscription', ['exports', 'ember', 'carlock-admin/helpers/format-time', 'carlock-admin/models/c/purchase/subscription-plan', 'carlock-admin/models/c/purchase/address', 'carlock-admin/models/c/purchase/credit-card', 'carlock-admin/models/c/purchase/platform', 'carlock-admin/models/country'], function (exports, Ember, FormatTime, SubscriptionPlanModel, AddressModel, CreditCardModel, PaymentPlatformModel, CountryModel) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        distributorUuid: null,
        date: null,
        dateFormatted: Ember['default'].computed('date', 'profile.instance.timeZoneId', FormatTime['default']),
        plan: null,
        accountUuid: null,
        billingAddress: null,
        vatId: null,
        paymentPlatform: null,
        platformId: null,
        platformCustomerId: null,
        email: null,
        amountBeforeTax: null,
        tax: null,
        taxPercentage: null,
        taxCountry: null,
        amountAfterTax: null,
        startDate: null,
        startDateFormatted: Ember['default'].computed('startDate', 'profile.instance.timeZoneId', FormatTime['default']),
        cancellationDate: null,
        cancellationDateFormatted: Ember['default'].computed('cancellationDate', 'profile.instance.timeZoneId', FormatTime['default']),
        cancellationEntity: null,
        cancellationReason: null,
        failedDate: null,
        failedDateFormatted: Ember['default'].computed('failedDate', 'profile.instance.timeZoneId', FormatTime['default']),
        failedCount: null,
        creditCard: null,
        paypalEmail: null,
        suspendedDate: null,
        suspendedDateFormatted: Ember['default'].computed('suspendedDate', 'profile.instance.timeZoneId', FormatTime['default']),
        status: null,
        planSwitchDate: null,
        planSwitchDateFormatted: Ember['default'].computed('planSwitchDate', 'profile.instance.timeZoneId', FormatTime['default']),
        paymentPlatformLink: Ember['default'].computed('platformId', 'paymentPlatform', function () {
            var paymentId = this.get('platformId');
            switch (this.get('paymentPlatform.id')) {
                case 'PAYPAL':
                    return 'https://www.paypal.com/us/cgi-bin/webscr?cmd=_profile-recurring-payments&encrypted_profile_id=' + paymentId;
                case 'STRIPE':
                    return 'https://dashboard.stripe.com/subscriptions/' + paymentId;
            }

            return null;
        }),
        paymentPlatformCustomerLink: Ember['default'].computed('platformCustomerId', 'paymentPlatform', function () {
            var customerId = this.get('platformCustomerId');
            switch (this.get('paymentPlatform.id')) {
                case 'STRIPE':
                    return 'https://dashboard.stripe.com/customers/' + customerId;
            }

            return null;
        }),

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('distributorUuid', data.distributor_uuid);
            this.set('date', data.date);
            this.set('plan', SubscriptionPlanModel['default'].create().load(data.plan));
            this.set('accountUuid', data.account_uuid);
            this.set('billingAddress', AddressModel['default'].create().load(data.billing_address));
            this.set('vatId', data.vat_id);
            this.set('paymentPlatform', PaymentPlatformModel['default'].FIXTURES.findBy('id', data.payment_platform));
            this.set('platformId', data.platform_id);
            this.set('platformCustomerId', data.platform_customer_id);
            this.set('email', data.email);
            this.set('amountBeforeTax', data.amount_before_tax);
            this.set('tax', data.tax);
            this.set('taxPercentage', data.tax_percentage);
            this.set('taxCountry', CountryModel['default'].FIXTURES.findBy('isoCode', data.tax_country));
            this.set('amountAfterTax', data.amount_after_tax);
            this.set('startDate', data.start);
            this.set('cancellationDate', data.cancellation_date);
            this.set('cancellationEntity', data.cancellation_entity);
            this.set('cancellationReason', data.cancellation_reason);
            this.set('failedDate', data.failed_date);
            this.set('creditCard', data.credit_card ? CreditCardModel['default'].create().load(data.credit_card) : null);
            this.set('paypalEmail', data.paypal_email);
            this.set('suspendedDate', data.suspended_date);
            this.set('status', data.status);
            this.set('planSwitchDate', data.plan_switch_date);

            return this;
        }
    });

});