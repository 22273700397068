define('carlock-admin/models/c/vehicle/user', ['exports', 'ember', 'carlock-admin/models/country', 'carlock-admin/models/c/vehicle/account'], function (exports, Ember, CountryModel, AccountModel) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
				uuid: null,
				email: null,
				name: null,
				country: null,
				countryIsoCode: null,
				phoneNumber: null,
				trackTripHistory: null,
				trackTripHistoryFormatted: Ember['default'].computed('trackTripHistory', function () {
							return this.get('trackTripHistory') ? 'misc.yes' : 'misc.no';
				}),
				promo: null,
				worth: null,
				account: null,
				helped: null,

				load: function load(data) {
							this.set('uuid', data.uuid);
							this.set('email', data.email);
							this.set('name', data.name);
							this.set('country', CountryModel['default'].FIXTURES.findBy('isoCode', data.country_iso_code));
							this.set('countryIsoCode', data.country_iso_code);
							this.set('phoneNumber', data.phone_number);
							this.set('trackTripHistory', data.track_trip_history);
							this.set('promo', data.promo);
							this.set('worth', data.customer_worth ? data.customer_worth.toLowerCase() : null);
							this.set('helped', data.helped);

							if (data.account) {
										this.set('account', AccountModel['default'].create().load(data.account));
							}

							return this;
				}
	});

});