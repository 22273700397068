define('carlock-admin/models/c/vehicle/sim-service-profile', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var model = Ember['default'].Object.extend({
        id: null,
        displayName: Ember['default'].computed('id', function () {
            return 'vehicle.sim_service_profile.' + this.get('id').toLowerCase();
        })
    });

    model.FIXTURES = [model.create({ id: 'TEST' }), model.create({ id: 'EU' }), model.create({ id: 'EU_5MB' }), model.create({ id: 'EU_6MB' }), model.create({ id: 'USA' }), model.create({ id: 'USA_4MB' }), model.create({ id: 'USA_6MB' }), model.create({ id: 'WORLD' }), model.create({ id: 'UA_3MB' }), model.create({ id: 'AU_3MB' }), model.create({ id: 'AU_4MB' }), model.create({ id: 'AU_5MB' }), model.create({ id: 'RE_3MB' }), model.create({ id: 'SA_4MB' }), model.create({ id: 'SA_5MB' }), model.create({ id: 'SLEEP' }), model.create({ id: 'AR_4MB' }), model.create({ id: 'CA_4MB' }), model.create({ id: 'CA_5MB' })];

    exports['default'] = model;

});