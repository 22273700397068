define('carlock-admin/validators/local/credit-card-cvc', ['exports', 'ember', 'ember-validations/validators/base', 'ember-validations/messages'], function (exports, Ember, Base, Messages) {

    'use strict';

    var get = Ember['default'].get;
    var set = Ember['default'].set;

    exports['default'] = Base['default'].extend({
        init: function init() {
            this._super();

            if (this.options === true) {
                set(this, 'options', {});
            }

            if (this.options.message === undefined) {
                set(this, 'options.message', Messages['default'].render('invalid_credit_card_cvc', this.options));
            }
        },
        call: function call() {
            var value = get(this.model, this.property);
            var stripe = get(this.model, 'stripe');
            var paymill = get(this.model, 'paymill');

            if (get(this.model, this.options.stripe) && stripe) {
                if (!stripe.card.validateCVC(value)) {
                    this.errors.pushObject(this.options.message);
                }
            } else if (get(this.model, this.options.paymill) && paymill) {
                if (!paymill.validateCvc(value)) {
                    this.errors.pushObject(this.options.message);
                }
            }
        }
    });

});