define('carlock-admin/models/c/purchase/platform', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var platform = Ember['default'].Object.extend({
        id: null,
        localizedKey: Ember['default'].computed('id', function () {
            return 'purchase.platform.' + this.get('id').toLowerCase();
        })
    });

    platform.FIXTURES = [platform.create({ id: 'PAYPAL' }), platform.create({ id: 'STRIPE' }), platform.create({ id: 'PAYMILL' }), platform.create({ id: 'SEPA' }), platform.create({ id: 'CASH' }), platform.create({ id: 'AMAZON' }), platform.create({ id: 'PROMO' })];

    exports['default'] = platform;

});