define('carlock-admin/components/toggle-switch', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ['toggle-switch'],
        classNameBindings: ['checked'],
        checked: null,
        click: function click() {
            this.logger.trace('component:toggle-switch: Toggling value from: ' + this.get('checked'));
            this.set('checked', !this.get('checked'));
            this.sendAction();
            this.logger.trace('component:toggle-switch: Toggled value to: ' + this.get('checked'));
        }
    });

});