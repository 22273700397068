define('carlock-admin/components/date-picker', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        tagName: 'input',
        attributeBindings: ['readonly', 'disabled', 'placeholder', 'type', 'name', 'size', 'required'],
        type: 'text',
        onInsert: Ember['default'].on('didInsertElement', function () {
            this.setupPikaday();
        }),
        timeZoneId: null,
        setupPikaday: function setupPikaday() {
            var months = [];
            var days = [];
            var daysShort = [];

            for (var i = 0; i <= 11; i++) {
                months.push(this.i18n.t('misc.month_' + i));
            }

            for (var j = 0; j <= 6; j++) {
                days.push(this.i18n.t('misc.day_' + j));
                daysShort.push(this.i18n.t('misc.day_short_' + j));
            }

            var i18n = {
                previousMonth: this.i18n.t('misc.date_previous_month'),
                nextMonth: this.i18n.t('misc.date_next_month'),
                months: months,
                weekdays: days,
                weekdaysShort: daysShort
            };

            var options = {
                field: this.$()[0],
                onOpen: Ember['default'].run.bind(this, this.onPikadayOpen),
                onClose: Ember['default'].run.bind(this, this.onPikadayClose),
                onSelect: Ember['default'].run.bind(this, this.onPikadaySelect),
                onDraw: Ember['default'].run.bind(this, this.onPikadayRedraw),
                format: 'l',
                i18n: i18n,
                isRTL: this.i18n.get('language.rtl'),
                firstDay: this.i18n.get('language.firstDay')
            };

            var pikaday = new Pikaday(options);
            this.set('pikaday', pikaday);
            this.setPikadayDate();
        },
        onDestroy: Ember['default'].on('willDestroyElement', function () {
            this.teardownPikaday();
        }),
        teardownPikaday: function teardownPikaday() {
            this.get('pikaday').destroy();
        },
        setPikadayDate: function setPikadayDate() {
            var value = this.get('value');
            var pikaday = this.get('pikaday');

            if (!value) {
                pikaday.setDate(null, true);
                return;
            }

            var timeZoneId = this.get('timeZoneId');
            var timeZone = null;

            if (timeZoneId) timeZone = moment.tz.zone(timeZoneId);

            if (!timeZone) timeZoneId = 'UTC';

            pikaday.setDate(moment.tz(value, timeZoneId).toDate(), true);
        },
        onPikadayOpen: Ember['default'].K,
        onPikadayClose: function onPikadayClose() {
            if (this.get('pikaday').getDate() == null || Ember['default'].isEmpty(this.$().val())) {
                this.set('value', null);
            }
        },
        onPikadaySelect: function onPikadaySelect() {
            this.userSelectedDate();
        },
        onPikadayRedraw: Ember['default'].K,
        userSelectedDate: function userSelectedDate() {
            var date = this.get('pikaday').toString();

            if (!date || date === 'null') {
                this.set('value', null);
                return;
            }

            var timeZoneId = this.get('timeZoneId');
            var timeZone = null;

            if (timeZoneId) timeZone = moment.tz.zone(timeZoneId);

            if (!timeZone) timeZoneId = 'UTC';

            this.set('value', moment.tz(date, 'l', timeZoneId).valueOf());
        },
        valueObserver: Ember['default'].observer('value', 'timeZoneId', function () {
            this.setPikadayDate();
        }),
        autoHideOnDisabled: Ember['default'].observer('disabled', 'pikaday', function () {
            if (this.get('disabled') && this.get('pikaday')) {
                this.get('pikaday').hide();
            }
        }),
        localeChanged: Ember['default'].observer('i18n.locale', function () {
            this.teardownPikaday();
            this.setupPikaday();
        })
    });

});