define('carlock-admin/models/c/vehicle/vehicle-debug', ['exports', 'ember', 'carlock-admin/models/c/vehicle/distributor', 'carlock-admin/models/c/vehicle/activation', 'carlock-admin/models/c/vehicle/lock', 'carlock-admin/models/c/vehicle/user', 'carlock-admin/models/c/vehicle/vehicle-state', 'carlock-admin/models/c/vehicle/device', 'carlock-admin/models/c/vehicle/sms-command', 'carlock-admin/models/c/vehicle/vehicle-subscription'], function (exports, Ember, DistributorModel, ActivationModel, LockModel, UserModel, VehicleStateModel, DeviceModel, SmsCommandModel, VehicleSubscriptionModel) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        vehicleUuid: null,
        distributor: null,
        accountEmail: null,
        smsNotifications: null,
        callNotifications: null,
        user: null,
        device: null,
        activation: null,
        state: null,
        lock: null,
        logins: null,
        smsCommands: null,
        fotaExport: Ember['default'].computed('device', function () {
            try {
                //Check if Teltonika FMB then type is whole, if Teltonika FM_ then return just FMXY
                var type = this.get('device.type');

                if (type && type.indexOf('TELTONIKA') !== -1) {
                    var fm = type.indexOf('FM');

                    if (['FM_10', 'FM_11', 'FM_36', 'FM_12'].includes(type.slice(fm, fm + 5))) {
                        type = type.substring(fm, fm + 5).replace('_', '');
                    } else if (type.indexOf('FMT100') !== -1) {
                        type = 'FMB010';
                    } else {
                        type = type.substring(fm);
                    }
                } else {
                    type = null;
                }

                var device = this.get('device');
                var password = this.get('device.password') == null || this.get('device.password') == '' ? '' : this.get('device.password');
                var username = password == null || password == '' ? '' : 'clock';

                return device.serialNumber + ';;' + device.imei + ';' + type + ';;+' + device.sim.msisdn + ';m2m.tag.com;rs5;m2m572;' + username + ';' + password + ';;';
            } catch (e) {
                console.log(e);
                return '/';
            }
        }),
        load: function load(data) {
            this.set('vehicleUuid', data.vehicle_uuid);
            this.set('distributor', DistributorModel['default'].create().load(data.distributor));
            this.set('accountEmail', data.account_email);
            this.set('smsNotifications', data.sms_notifications);
            this.set('callNotifications', data.call_notifications);
            this.set('user', UserModel['default'].create().load(data.user));
            this.set('device', DeviceModel['default'].create().load(data.device));
            this.set('activation', ActivationModel['default'].create().load(data.activation));
            this.set('state', VehicleStateModel['default'].create().load(data.vehicle_state));
            this.set('lock', LockModel['default'].create().load(data.lock));
            this.set('subscription', data.subscription ? VehicleSubscriptionModel['default'].create().load(data.subscription) : null);

            var smsCommands = [];
            data.sms_commands.sort(function (sc1, sc2) {
                return sc2.date - sc1.date;
            });
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = data.sms_commands[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var cmd = _step.value;

                    //Only show this vehicle's SMS commands
                    smsCommands.push(SmsCommandModel['default'].create().load(cmd));

                    if (cmd.status === 'DEVICE_SWITCH') {
                        break;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator['return']) {
                        _iterator['return']();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            this.set('smsCommands', smsCommands);

            return this;
        }
    });

});