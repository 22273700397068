define('carlock-admin/controllers/c/purchase', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        queryParams: ['uuid'],

        actions: {
            refresh: function refresh() {
                this.get('target.router').refresh();
            },
            createXeroInvoice: function createXeroInvoice() {
                var _this = this;

                var prepare = function prepare() {
                    return _this.carlock.createOrUpdateXeroInvoice(_this.get('model.uuid'));
                };
                this.load(prepare, function () {});
            },
            createEracuniInvoice: function createEracuniInvoice() {
                var _this2 = this;

                var prepare = function prepare() {
                    return _this2.carlock.createEracuniInvoice(_this2.get('model.uuid'));
                };
                this.load(prepare, function () {});
            },
            cancelFundsReservation: function cancelFundsReservation() {
                var _this3 = this;

                var prepare = function prepare() {
                    return _this3.carlock.cancelFundsReservation(_this3.get('model.uuid'));
                };
                this.load(prepare, function () {});
            }
        }
    });

});