define('carlock-admin/routes/c/vehicle/health', ['exports', 'ember', 'carlock-admin/routes/authenticated', 'carlock-admin/models/c/vehicle/health-summary', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, Base, HealthSummaryModel, AuthenticatedRouteMixin) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        model: function model(params) {
            var to = moment().utc();
            var from = moment(to).utc().subtract(31, 'days');
            return this.carlock.getVehicleHealth(params.vehicle_uuid, from.valueOf(), to.valueOf()).then(null, this.onModelError);
        },
        transformModel: function transformModel(model) {
            return HealthSummaryModel['default'].create().load(model);
        }
    });

});