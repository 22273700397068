define('carlock-admin/services/ip-info', ['exports', 'ember', 'carlock-admin/models/ip-info'], function (exports, Ember, IpInfoModel) {

    'use strict';

    exports['default'] = Ember['default'].Service.extend({
        callbacks: Ember['default'].A(),
        infoObtained: function infoObtained(response) {
            var info = IpInfoModel['default'].create().load(response);
            var callbacks = this.get('callbacks');
            this.set('info', info);

            for (var i = 0; i < callbacks.length; i++) {
                callbacks[i](info);
            }
        }
    });

});