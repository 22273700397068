define('carlock-admin/components/dialogs/add-vehicle-subscription-days', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/vehicle/vehicle-subscription-extension-reason'], function (exports, Ember, Base, ExtensionReason) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        vehicleUuid: null,
        days: null,
        validations: {
            'days': {
                presence: true,
                numericality: true
            },
            'reason': {
                presence: true
            }
        },
        reason: null,
        reasonsList: ExtensionReason['default'].FIXTURES,

        actions: {
            show: function show() {
                this.set('days', null);
                this.set('reason', ExtensionReason['default'].FIXTURES.findBy('id', 'SUPPORT'));
            },
            confirm: function confirm() {
                var _this = this;

                this.logger.trace('component:add-vehicle-subscription-days: Adding');

                var prepare = function prepare() {
                    var uuid = _this.get('vehicleUuid');
                    var data = {
                        'subscription_days': _this.get('days'),
                        'reason': _this.get('reason.id')
                    };
                    return _this.carlock.extendVehicleSubscriptionDays(uuid, data);
                };

                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});