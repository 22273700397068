define('carlock-admin/routes/c/vehicle/index', ['exports', 'carlock-admin/routes/authenticated', 'carlock-admin/models/c/vehicle/vehicle', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Base, VehicleModel, AuthenticatedRouteMixin) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        model: function model(params) {
            return Ember.RSVP.hash({
                vehicle: this.carlock.getVehicle(params.uuid).then(null, this.onModelError),
                status: this.carlock.getVehicleStatus(params.uuid).then(null, this.onModelError)
            });
        },
        transformModel: function transformModel(model) {
            var vehicle = VehicleModel['default'].create().load(model.vehicle);
            vehicle.set('status', model.status.status);

            return vehicle;
        }
    });

});