define('carlock-admin/routes/c/devices/cohorts', ['exports', 'ember', 'carlock-admin/routes/authenticated', 'carlock-admin/models/date-range-option'], function (exports, Ember, Base, DateRangeOptionModel) {

    'use strict';

    exports['default'] = Base['default'].extend({
        setupController: function setupController(controller, model) {
            this.logger.trace('controller:purchases:cohorts: Setting up controller');
            this._super(controller, model);
            controller.set('selectedDate', moment().utc().valueOf());
        }
    });

});