define('carlock-admin/locales/en/translations', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        "main": {
            "profile": "Manage profile",
            "logout": "Sign out",
            "close": "close",
            "devices": "Devices",
            "purchases": "Purchases",
            "gift_codes": "Gift codes",
            "community_link": "Community",
            "privacy_link": "Privacy",
            "contact_link": "Contact",
            "coupons": "Coupons",
            "version": "Version"
        },
        "login": {
            "title": "Sign in to CarLock",
            "email": "E-mail address:",
            "password": "Password:",
            "token": "Two factor authentication token:",
            "login": "Sign in",
            "remember_me": "Remember me",
            "forgot_password": "Lost your password?"
        },
        "forgot_password": {
            "title": "Can't sign in? Forgot your password?",
            "text": "Enter your email address below and we'll send you password reset instructions:",
            "email": "Enter your email address",
            "send": "Send instructions",
            "sent": "Instructions sent"
        },
        "set_password": {
            "title": "Set password",
            "password": "Please enter your new password:",
            "password_confirmation": "Confirm password:",
            "set": "Set"
        },
        "profile": {
            "title": "Profile",
            "email": "E-mail address:",
            "email_confirmation": "Confirm e-mail address:",
            "name": "Name:",
            "country": "Country:",
            "time_zone": "Time zone:",

            "two_factor_auth": {
                "use_two_factor_auth": "Use two factor authentication (app)",
                "title": "Two factor authentication",
                "description": "Use 2FA to secure your account bla bla bla...",
                "setup": "Set up two factor authentication",
                "scan_qr_code": "Scan the QR code using your authenticator app",
                "enter_code_manually": "... or enter the code to authenticator app manually",
                "confirm_code": "Enter the code from your authenticator app to confirm:"
            }
        },
        "devices": {
            "title": "Device list",
            "search_placeholder": "Search by serial, IMEI or e-mail",
            "distributor": "Distributor",
            "device": "Device",
            "type": "Type",
            "serial": "Serial",
            "imei": "IMEI",
            "activation_date": "Activated",
            "email": "E-mail",
            "last_seen": "Last seen",
            "expiration_date": "Expires",
            "cohorts_menu": "Cohorts",
            "bulk_menu": "Bulk search",
            "promo_menu": "Promo",
            "export_file": "devices",
            "name": "Name",
            "bulk": {
                "emails": "Comma separated email addresses",
                "imeis": "Comma separated IMEI",
                "serials": "Comma separated device serial numbers",
                "phone_numbers": "Comma separated user phone numbers",
                "iccids": "Comma separated device SIM iccid",
                "paypal_emails": "Comma separated paypal emails"
            }
        },
        "purchases": {
            "one_time_purchases_menu": "One time purchases",
            "recurring_purchases_menu": "Recurring purchases",
            "reports_menu": "Reports",
            "paypal_menu": "PayPal",
            "stripe_menu": "Stripe",
            "promo_menu": "Promo",
            "replacement_menu": "Replacement",
            "sim_menu": "SIM",
            "business_menu": "Business",
            "billing_info_menu": "Billing info",
            "shipment_menu": "Shipments"
        },
        "distributors": {
            "title": "Distributor list",
            "name": "Name",
            "email": "E-mail",
            "support_email": "Support e-mail"
        },
        "users": {
            "title": "User list",
            "search_placeholder": "Search by name, e-mail or country",
            "email": "E-mail",
            "name": "Name",
            "country": "Country"
        },
        "offer": {
            "uuid": "UUID",
            "name": "Description",
            "subscription_days": "Days",
            "sms_notifications": "SMS",
            "call_notifications": "Calls",
            "price": "Price",
            "currency": "Currency",
            "start": "Valid from",
            "end": "Valid until",
            "device_type": "Device type",
            "country": "Country",
            "is_multi_use": "Multi use",
            "special": "Special",

            "device": "Device + 1 Month Subscription",
            "extension_cable": "50cm OBDII Extension Cable",
            "obd_adapter": "OBD Power Adapter",
            "shipping": "Shipping and Handling",
            "1_month_gift_code": "1-month CarLock subscription gift code",
            "1_year_gift_code": "1-year CarLock subscription gift code",
            "3_year_gift_code": "3-year CarLock subscription gift code",
            "3_year_gift_code_with_50_sms": "3-year CarLock subscription + 50 SMS notifications gift code",
            "3_month_gift_code": "3-month CarLock subscription gift code",
            "6_month_gift_code": "6-month CarLock subscription gift code",
            "1_year_subscription": "1-year CarLock Subscription",
            "2_year_subscription": "2-year CarLock Subscription",
            "3_year_subscription": "3-year CarLock Subscription",
            "100_sms_notifications": "100 SMS notifications",
            "100_call_notifications": "100 call notifications",
            "monthly_plan": "CarLock Monthly Plan",
            "1_month_subscription": "1-month CarLock Subscription",
            "beacon": "Beacon",
            "phone_holder": "Phone holder",
            "device_promo": "CL200",
            "portable": "Portable device",
            "sim": "Normal SIM",
            "micro_sim": "Micro SIM",
            "tpsim": "Truphone SIM",
            "portable_sim_kit": "Portable SIM kit",
            "splitter": "Splitter cable",
            "basic_upgrade_1_month_subscription": "Basic Upgrade",
            "annual_plan": "Annual plan",
            "pause_subscription_3m": "Pause subscription 3M",
            "standard_annual_plan": "Standard Annual plan",
            "basic_annual_plan": "Basic Annual plan",
            "standard_plus_monthly_plan": "Standard Plus Monthly Plan",
            "standard_plus_annual_plan": "Standard Plus Annual Plan",
            "funds_reservation": "Funds reservation"
        },
        "vehicle": {
            "title": "Vehicle",
            "add_title": "Add vehicle",
            "uuid": "UUID",
            "id": "ID",

            "distributor": "Distributor",
            "distributor_name": "Name",
            "distributor_email": "E-mail",
            "distributor_support_email": "Support e-mail",
            "distributor_set": "Set distributor",

            "user": "User",
            "user_email": "E-mail",
            "user_name": "Name",
            "user_phone_number": "Phone number",
            "user_country": "Country",
            "user_track_trip_history": "Track trip history",
            "user_promo": "Promo",
            "user_set": "Set user",
            "user_set_info": "This action assigns vehicle (device) and all associated data to another user.",
            "user_set_warning": "Please note that if this is <b>{{email}}</b> last vehicle, their account will be deleted and any " + "remaining SMS notifications will be transferred to the selected user.",
            "user_helped": "Needed help",
            "user_details": "Details",
            "user_my_carlock": "my.CarLock",
            "user_confirm_login": "Confirm login",
            "user_confirm_login_warning": "You are about to access user's personal account. This can only be used for " + "debug purposes.",
            "user_worth": "User worth",
            "user_success_score": "Scores",
            "account": {
                "sms": "SMS notifications",
                "calls": "Call notifications",
                "add_backup_notifications": "Add backup notifications"
            },

            "device": "Device",
            "device_serial": "Serial number",
            "device_imei": "IMEI",
            "device_type_title": "Device",
            "device_type": {
                "virtual_obd": "Virtual",
                "virtual": "Virtual Pro",
                "teltonika_fmb010": "Teltonika FMB010 (OBD 2G)",
                "teltonika_fm3010": "Teltonika FM3010 (OBD 3G)",
                "teltonika_fm3001": "Teltonika FM3001 (OBD 3G)",
                "teltonika_fmm001": "Teltonika FMM001 (OBD 4G)",
                "teltonika_fmm00a": "Teltonika FMM00A (OBD 4G)",
                "teltonika_fmm80a": "Teltonika FMM80A (OBD 4G)",
                "teltonika_tmt250": "Teltonika TMT250 (Portable 2G)",
                "teltonika_fm3622": "Teltonika FM3622 (Professional 2G)",
                "teltonika_fm_1000": "Teltonika FM1000 (OBD 2G)",
                "teltonika_fm_1010": "Teltonika FM1010 (OBD 2G)",
                "teltonika_fm_1200": "Teltonika FM1200 (Professional 2G)",
                "teltonika_fm_1202": "Teltonika FM1202 (Professional 2G)",
                "teltonika_fm_1122": "Teltonika FM1122 (Professional 2G)",
                "teltonika_fmb122": "Teltonika FMB122 (Professional 2G)",
                "teltonika_fmt100": "Teltonika FMT100 (Basic 2G)",
                "teltonika_fmc880": "Teltonika FMC880 (Basic 4G)",
                "teltonika_fmc800": "Teltonika FMC800 (OBD 4G)",
                "queclink_gv_500": "Queclink GV500 (OBD 2G)",
                "queclink_gv_65": "Queclink GV65 (Professional 2G)",
                "queclink_gl_300w": "Queclink GL300W (Portable 3G)",
                "queclink_gl_310mg": "Queclink GL310MG (Portable 4G)",
                "queclink_gl_320mg": "Queclink GL320MG (Portable 4G)",
                "queclink_gv_500map": "Queclink GV500MAP (OBD 4G)",
                "queclink_gv_500ma": "Queclink GV500MA (OBD 4G)",
                "queclink_gb_130mg": "Queclink GB130MG (Basic 4G)",
                "mobile_phone": "Mobile Phone"
            },
            "device_type_version_title": "Type",
            "device_type_version": {
                "obd": "OBD Dongle",
                "professional": "Professional",
                "mobile_phone": "Mobile Phone",
                "portable": "Portable",
                "basic": "Basic"
            },
            "device_set": "Set device",
            "device_set_warning": "Please note you can only select inactive devices.",
            "device_command_send": "Send SMS command",
            "device_command_title": "Send command to device via SMS",
            "device_command": "Command",
            "device_firmware_version": "Firmware version",
            "device_firmware_revision": "Firmware revision",
            "device_batch_date": "Batch date",
            "device_batch_code": "Batch code",
            "device_configuration": "Configuration",
            "device_password": "Password",
            "device_battery_voltage": "Device battery voltage",
            "device_battery_current": "Device battery current",
            "last_device_charge": "Last device battery charge",
            "refresh_device_data": "Refresh device data",

            "sim": "SIM card",
            "sim_missing": "SIM data is missing.",
            "sim_iccid": "ICCID",
            "sim_imsi": "IMSI",
            "sim_msisdn": "SIM phone number",
            "sim_service_profile_title": "Service profile",
            "sim_service_profile": {
                "test": "Test",
                "eu": "EU 3MB",
                "eu_5mb": "EU 5MB",
                "eu_6mb": "EU 6MB",
                "usa": "US 3MB",
                "usa_4mb": "US 4MB",
                "usa_5mb": "US 4MB",
                "usa_6mb": "US 6MB",
                "world": "World",
                "ua_3mb": "UA 3MB",
                "au_3mb": "AU 3MB",
                "au_4mb": "AU 4MB",
                "au_5mb": "AU 5MB",
                "re_3mb": "RE 3MB",
                "sa_4mb": "SA 4MB",
                "sa_5mb": "SA 5MB",
                "sleep": "Sleep",
                "ar_4mb": "AR 4MB",
                "ca_4mb": "CA 4MB",
                "ca_5mb": "CA 5MB"
            },
            "sim_status_title": "Status",
            "sim_status": {
                "inactive": "Inactive",
                "suspended": "Suspended",
                "active": "Active",
                "terminated": "Terminated",
                "lost": "Lost"
            },
            "sim_provider_title": "SIM provider",
            "sim_provider": {
                "telekom_austria": "Telekom Austria",
                "mobily": "Mobily"
            },
            "edit_sim_title": "Edit SIM",
            "add_sim_title": "Add SIM",
            "remove_sim_title": "Remove SIM",
            "refresh_sim_title": "Refresh SIM",
            "confirm_remove_sim": "Are you sure you want to remove SIM card from device?",
            "sim_owned_by_distributor": "Distributor SIM",
            "sim_owned_by_distributor_notes": "Determines whether distributor provides their own SIM cards",
            "activate_new_sim": "Activate new SIM card",

            "activation": "Activation",
            "activation_code": "Activation code",
            "activation_days": "Initial subscription (in days)",
            "activation_date": "Date",
            "activation_status": "Status",
            "activation_yes": "Activated",
            "activation_no": "Not activated",

            "subscription": "Subscription",
            "subscription_type": "Type",
            "subscription_recurring": "Recurring",
            "subscription_regular": "Regular",
            "subscription_expiration_date": "Expiration date",
            "subscription_never_expires": "Never expires",
            "subscription_status_title": "Status",
            "subscription_status": {
                "active": "Active",
                "will_expire_soon": "Is about to expire",
                "expired": "Expired",
                "frozen": "Frozen",
                "trial": "Trial",
                "terminated": "Terminated",
                "paused": "Paused"
            },
            "subscription_extend": "Purchase",
            "subscription_extend_title": "Extend subscription",
            "subscription_extend_warning": "WARNING: Use this action for substitution of cash, or any payments which were not " + "automatically processed. Selected subscription will be added to your monthly bill.",
            "subscription_days_input": "Number of days",
            "subscription_days_extend": "Extend",
            "subscription_extend_reason": "Reason for extension",
            "subscription_enable_pause": "Can purchase pause",
            "subscription_enable_funds_reservation": "Show reserve funds offers",
            "subscription_tier": {
                "title": "Subscription tier",
                "none": "None",
                "basic": "Basic",
                "pause": "Pause",
                "standard": "Standard",
                "standard_plus": "Standard Plus",
                "premium": "Premium"
            },

            "location": "Location",
            "location_missing": "Location not yet obtained.",
            "location_time": "Time",
            "location_coordinates": "Coordinates",
            "location_altitude": "Altitude",
            "location_speed": "Speed",
            "location_heading": "Heading",
            "location_satellites": "Number of satellites",

            "lock": "Monitoring status",
            "lock_locked": "Monitoring enabled",
            "lock_locked_since": "Time",
            "lock_in_follow_mode": "Follow mode active",
            "lock_source_title": "Who",
            "lock_source": {
                "user": "User",
                "schedule": "Scheduler",
                "engine_off_timer": "Engine off timer",
                "wifi": "WiFi",
                "beacon": "Beacon"
            },
            "lock_unlocked_under_scheduled_lock": "Stopped monitoring during scheduler",
            "lock_coordinates": "Coordinates",

            "state": "State",
            "state_connected_timestamp": "User connected device to OBD port",
            "state_last_seen": "Last seen",
            "state_gsm_strength": "GSM strength",

            "settings": "Settings",
            "settings_time_zone": "Time zone",
            "settings_smart": "Smart CarLock",
            "settings_manufacturer": "Manufacturer",
            "settings_model": "Model",
            "settings_speed_limit": "Speed limit",

            "settings_push": "Push notification settings",
            "settings_disable_notifications": "Disable notifications",
            "settings_silent_notifications": "Silent notifications",
            "settings_movement_detected_enabled": "Movement detected",
            "settings_device_disconnected_enabled": "Device disconnected",
            "settings_vibration_detected_enabled": "Vibration detected",
            "settings_engine_started_enabled": "Engine started",
            "settings_external_alarm_enabled": "External alarm",
            "settings_door_opened_enabled": "Door opened",
            "settings_gps_signal_lost_enabled": "GPS signal lost",
            "settings_gsm_signal_lost_enabled": "GSM signal lost",
            "settings_low_battery_enabled": "Low battery",
            "settings_scheduler_enabled": "Auto lock monitoring",
            "settings_harsh_driving_enabled": "Harsh driving",
            "settings_sms": "SMS notification settings",
            "settings_calls": "Call notification settings",

            "reset": "Reset",
            "send_push": "Send push notification",
            "confirm_reset_title": "Confirm reset vehicle",
            "confirm_reset_warning": "This action is not reversible! By confirming you will delete all data associated " + "with this vehicle including user (if this is the last vehicle owned by them)!",
            "confirm_reset_serial": "Device serial number:",
            "confirm_reset_activation_code": "Activation code:",
            "marked_as_replacement": "Replacement needed",
            "backend": "Push backend",

            "recurring_subscription": {
                "title": "Recurring subscription list",
                "uuid": "UUID",
                "date": "Date",
                "start_date": "Start",
                "cancellation_date": "Cancellation Date",
                "cancellation_entity": "Cancellation entity",
                "failed_date": "Failed date",
                "failed_count": "Failed count",
                "credit_card": "Credit card",
                "paypal_email": "PayPal email",
                "suspended_date": "Suspended date",
                "plan_switch_date": "Plan switch"
            },

            "health": {
                "title": "Vehicle health",
                "device_to_server_delay": "Device to server delay",
                "device_dead_time": "Device dead time",
                "push_notifications_delay": "Push notifications delay",
                "number_of_satellites": "Number of satellites",
                "gsm_strength": "GSM Strength",
                "count": "Number of samples",
                "min": "Minimum value",
                "max": "Maximum value",
                "avg": "Average value"
            },

            "debug": {
                "title": "Debug",
                "account_email": "Account email",
                "sms_notifications": "# SMS",
                "call_notifications": "# Calls",
                "status": {
                    "title": "Status",
                    "sim_not_active": "Sim card not ACTIVE",
                    "sim_sleep": "Sim in SLEEP profile",
                    "subscription_expired": "Subscription expired",
                    "device_not_responding": "Device didn't respond in the past two days",
                    "device_disconnected": "Device disconnected",
                    "no_signal": "No GSM signal",
                    "no_push_tokens": "No push tokens available",
                    "no_problems_found": "No problems found",
                    "never_connected": "Device never connected",
                    "user_deleted": "User deleted their vehicle"
                },
                "logins": {
                    "title": "Logins",
                    "timestamp": "Timestamp",
                    "client_type": "Client type",
                    "client_version": "Client version",
                    "client_locale": "Client locale",
                    "logout_timestamp": "Logout timestamp",
                    "client_os_version": "OS version",
                    "client_device_model": "Phone model",
                    "notification_target_id": "Target"
                },
                "records": {
                    "title": "Device records",
                    "timestamp": "Timestamp",
                    "harsh_acceleration": "Harsh acceleration",
                    "harsh_braking": "Harsh braking",
                    "harsh_cornering": "Harsh braking",
                    "voltage": "Voltage",
                    "server_timestamp": "Server timestamp",
                    "type": "Type",
                    "value": "Value",
                    "hdop": "HDOP",
                    "ignition": "Ignition",
                    "location": "Location",
                    "moving": "Moving",
                    "satellites": "# Sat",
                    "speed": "km/h",
                    "gsm_strength": "GSM"
                },
                "trips": {
                    "title": "Trips",
                    "uuid": "UUID",
                    "start_time": "Start time",
                    "start_location": "Start",
                    "end_time": "End time",
                    "end_location": "End",
                    "distance": "Distance",
                    "score": "Score",
                    "tag": "Tag"
                },
                "notifications": {
                    "title": "Notifications",
                    "uuid": "UUID",
                    "timestamp": "Timestamp",
                    "server_timestamp": "Server timestamp",
                    "location": "Location",
                    "notification": "Notification"
                },
                "fota_export": {
                    "title": "FOTA export",
                    "copy_success": "Copied to clipboard",
                    "copy_failed": "Failed to copy to clipboard"
                },
                "sms": {
                    "title": "Sent SMS commands",
                    "uuid": "Message UUID",
                    "timestamp": "Timestamp (UTC)",
                    "command": "Command",
                    "status": "Status"
                }
            },

            "sleep_mode": {
                "title": "Sleep mode",
                "deep_sleep": "Deep sleep",
                "sleep": "Sleep",
                "no_sleep": "Sleep disabled",
                "unknown": "Unknown"
            },
            "vehicle_data": "Vehicle data"
        },
        "purchase": {
            "title": "Purchase",

            "list": {
                "search_placeholder": "Find by customer email, name and invoice number",
                "distributor": "Distributor",
                "date": "Date",
                "status": "Status",
                "platform": "Platform",
                "email": "E-mail",
                "name": "Name",
                "quantity": "Quantity",
                "type": "Type",
                "item": "item"
            },

            "status_title": "Status",
            "status": {
                "accepted": "Accepted",
                "paid": "Paid",
                "completed": "Completed",
                "refunded": "Refunded",
                "cancelled": "Cancelled",
                "disputed": "Disputed",
                "shipped": "Shipped"
            },

            "platform_title": "Platform",
            "platform": {
                "paypal": "PayPal",
                "stripe": "Stripe",
                "paymill": "Paymill",
                "sepa": "SEPA",
                "cash": "Cash",
                "payfast": "PayFast",
                "amazon": "Amazon",
                "promo": "Promo",
                "none": "None"
            },

            "type": {
                "physical": "Physical",
                "shipping": "Shipping",
                "subscription": "Subscription",
                "gift_code": "Gift code",
                "upgrade": "Upgrade",
                "pause": "Pause",
                "funds_reservation": "Funds reservation"
            },

            "basic": "Basic info",
            "date": "Date",
            "uuid": "UUID",
            "invoice_number": "Invoice number",
            "xero_invoice_id": "Xero invoice ID",
            "distributor": "Distributor",

            "customer": "Customer details",
            "email": "E-mail",
            "phone_number": "Phone number",
            "ip": "IP",
            "ip_country": "IP country",
            "locale": "Locale",
            "comment": "Comment",

            "shipping_address": "Shipping address",
            "billing_address": "Billing address",
            "billing_all_address": "Billing & shipping address",
            "same_addresses": "Shipping address is the same as billing address",
            "address": {
                "name": "Name",
                "1": "Address",
                "2": "Address 2",
                "city": "City",
                "zip_code": "Zip code",
                "country": "Country",
                "state": "State",
                "vat_id": "VAT ID"
            },

            "payment": "Payment",
            "payment_date": "Date",
            "payment_id": "ID (on payment platform)",
            "payment_platform_fee": "Platform fee",
            "amount_before_tax": "Amount before tax",
            "tax": "Tax",
            "amount_after_tax": "Amount after tax",
            "platform_id": "Platform ID",
            "platform_customer_id": "Customer ID",

            "distributor_shares": "Distributor shares",
            "distributor_share_before_platform_fee": "Share before platform fee",
            "distributor_platform_fee_share": "Platform fee share",
            "distributor_share_after_platform_fee": "Share after platform fee",

            "shipment": "Shipment info",
            "shipment_date": "Date",
            "shipment_company": "Company",
            "tracking_number": "Tracking number",

            "item": {
                "name": "Name",
                "coupon": "Coupon",
                "quantity": "Q",
                "price": "Price",
                "tax": "Tax",
                "tax_percentage": "Tax (%)",
                "distributor_share": "Distributor share",
                "account": "Account",
                "vehicle": "Vehicle"
            },

            "confirm_payment": "Confirm payment",
            "confirm_payment_warning": "Confirming payment will mark payment as <b>PAID</b>, notify customer via e-mail " + "and automatically process all subscription related items (e.g. extend subscription, add SMS notifications...). " + "In case all items were processed automatically it will also mark it as <b>COMPLETED</b>.",
            "confirm_payment_date": "Date:",

            "generate_invoice": {
                "title": "Regenerate Invoice",
                "recalculate_amounts": "Recalculate Amounts",
                "recalculate_amounts_notes": "Before generating invoice it recalculates tax. This is useful when tax rate changes.",
                "eracuni_invoice": "Regenerate e-racuni invoice"
            },

            "refund": {
                "title": "Refund",
                "date": "Date",
                "date_info": "Date: (will be today if empty)",
                "credit_note_number": "Credit note number",
                "id": "ID (on payment platform)",
                "xero_id": "Xero credit note ID ",
                "regenerate_credit_note": "Regenerate Credit Note"
            },

            "promo": {
                "title": "Create promo purchase",
                "ig_name": "IG name",
                "promo_link": "Promo personal link"
            },

            "replacement": {
                "title": "Create replacement order",
                "return_timestamp": "Return date",
                "reference": "Reference"
            },

            "sim": {
                "title": "SIM fulfillment order",
                "platform": "Platform"
            },

            "provider": "Provider",
            "priority": "Priority",
            "funds_reservation": {
                "title": "Funds reservation",
                "cancel": "Cancel reservation",
                "charge": "Charge reserved funds",
                "confirm": "Are you sure you want to make a charge for {{price}}?"
            }
        },
        "shipment": {
            "title": "Shipment",
            "order_id": "Order ID",
            "priority": "Priority"
        },
        "gift_code": {
            "uuid": "UUID",
            "title": "Gift code",
            "search_placeholder": "Search by code",
            "code": "Code",
            "offer": "Item",
            "date_created": "Date created",
            "date_claimed": "Claim date",
            "export_file": "Gift codes",
            "offer_description_key": "Description",
            "offer_subscription_days": "Subscription days",
            "offer_sms_notifications": "SMS notifications",
            "offer_call_notifications": "Call notifications",
            "offer_start": "Valid from",
            "offer_end": "Expires on",
            "offer_is_multi_use": "Is multi use",
            "generate_button": "Generate",
            "generate_title": "Generate gift codes",
            "generate_count": "Number of codes to generate"
        },
        "coupon": {
            "search_placeholder": "Search by code",
            "add_coupon": "Add coupon",
            "title": "Coupon",
            "value": "Value",
            "offer_uuid": "Offer UUID",
            "code": "Code",
            "start": "Start",
            "end": "End",
            "type": {
                "type": "Type",
                "fixed": "Fixed",
                "percentage": "Percentage"
            }
        },
        "report": {
            "subscription_report": {
                "title": "Subscription report",
                "purchase_uuid": "Purchase UUID",
                "date": "Date",
                "account_uuid": "Account UUID",
                "vehicle_uuid": "Vehicle UUID",
                "name": "Name",
                "currency": "Currency",
                "price": "Price",
                "distributor_price": "Distributor price",
                "platform": "Platform",
                "paid_to_protectus": "Paid to Protectus",
                "paid_to_protectus_title": "Determines whether customer paid directly to Protectus",
                "count": "#",
                "base": "Base price",
                "base_title": "Price with discounts and without tax",
                "discount": "Discount",
                "tax": "Tax",
                "tax_percentage": "Tax (%)",
                "transaction_fee": "Transaction fee",
                "distributor_share_before_transaction_fee": "Distributor share before transaction fee",
                "distributor_transaction_fee_percentage": "Distributor transaction fee share (%)",
                "distributor_transaction_fee_share": "Distributor transaction fee share",
                "distributor_share_after_transaction_fee": "Distributor share after transaction fee",
                "to_protectus": "To pay to Protectus"
            },
            "summary": "Summary",
            "generate_button": "Generate",
            "us_vat_report": "US VAT Report",
            "fb_export": "Export active US customers in FB CSV format",
            "gb_vat_report": "GB VAT Report",
            "distributor_report_menu": "Distributor Report"
        },
        "cohort": {
            "total": "Total",
            "title": "Cohort report",
            "ratio": "Active / All ratio",
            "left_credit_card": "Left CC",
            "health": "Health",
            "no_pushes": "Push metrics are currently unavailable",
            "rating": "Rating",
            "security": "S",
            "teen_driving": "TD",
            "fleet_management": "FM",
            "other": "O",
            "will_it_work": "WIW",
            "will_it_work_utm": "UTM",
            "customers": "Customers"
        },
        "paypal_export": {
            "title": "Paypal export",
            "contact_name": "Contact name",
            "country": "Country",
            "invoice_number": "Invoice number",
            "invoice_number_obzorje": "Invoice number (Obzorje)",
            "reference": "Reference",
            "date": "Date",
            "total": "Total",
            "total_in_euro": "Total in EUR",
            "tax_total": "Tax total",
            "tax_total_in_euro": "Tax total in EUR",
            "invoice_amount_paid": "Amount paid",
            "invoice_amount_due": "Amount due",
            "invoice_amount_paid_in_euro": "Amount paid in EUR",
            "invoice_amount_due_in_euro": "Amount due in EUR",
            "quantity": "Quantity",
            "discount": "Discount",
            "currency": "Currency",
            "description": "Description",
            "eu_company_vat_number": "VAT ID (EU only)",
            "tax_label": "Tax label",

            "create_purchase": {
                "title": "Create missing purchases",
                "ids_placeholder": "XXXXXXXXXX\nYYYYYYYYY",
                "description": "Select distributor and enter ID for each payment to create purchase from in own line.",
                "select_distributor": "Learn to read. Please select distributor!",
                "enter_id": "Learn to read. Please enter payment ID's"
            }
        },

        "stripe_export": {
            "title": "Export Stripe transactions"
        },

        "teltonika_sms_command": {
            "reset": "<b>cpureset</b>: Resets device",
            "set_param": "<b>setparam {id} {value}</b>: Sets specific parameter of device",
            "web_connect": "<b>web_connect</b>: Force connection to FOTA WEB",
            "flush": "<b>flush</b>: Force flushing records",
            "sdformat": "<b>sdformat</b>: Format SD card"
        },
        "misc": {
            "close_button_label": "Close",
            "save_button_label": "Save",
            "continue_button_label": "Next &#8594;",
            "status_saved": "Saved",
            "status_sent": "Sent",
            "date": "Date",
            "date_current_month": "Current month",
            "date_previous_month": "Previous month",
            "date_custom_range": "Date range",
            "date_from": "Date from",
            "date_to": "Date to",
            "month_0": "January",
            "month_1": "February",
            "month_2": "March",
            "month_3": "April",
            "month_4": "May",
            "month_5": "June",
            "month_6": "July",
            "month_7": "August",
            "month_8": "September",
            "month_9": "October",
            "month_10": "November",
            "month_11": "December",
            "day_0": "Sunday",
            "day_1": "Monday",
            "day_2": "Tuesday",
            "day_3": "Wednesday",
            "day_4": "Thursday",
            "day_5": "Friday",
            "day_6": "Saturday",
            "day_short_0": "Sun",
            "day_short_1": "Mon",
            "day_short_2": "Tue",
            "day_short_3": "Wed",
            "day_short_4": "Thu",
            "day_short_5": "Fri",
            "day_short_6": "Sat",
            "not_found": "No data",
            "sort_ascending": "A..Z",
            "sort_descending": "Z..A",
            "sort_ascending_title": "Sort ascending",
            "sort_descending_title": "Sort descending",
            "hours_short": "h",
            "minutes_short": "min",
            "kilometers_short": "km",
            "miles_short": "mi",
            "meters_short": "m",
            "switch_on": "on",
            "kilometers_per_hour_short": "km/h",
            "switch_off": "off",
            "yes": "Yes",
            "no": "No",
            "mind": "No, I changed my mind",
            "confirm": "Confirm",
            "confirm_text": "Are you sure you want to do this?",
            "add": "Add",
            "never": "Never",
            "clear_filter": "Clear filter",
            "export_csv": "Export to CSV",
            "export_excel": "Export to XLSX",
            "load_more": "Load more",
            "generate": "Generate",
            "pattern_matching": "Search by pattern",
            "search": "Search",
            "change": "Change"
        },
        "error": {
            "generic_error": "Error executing request",
            "server_not_available": "Server is currently unavailable, please try again later",
            "invalid_request": "Invalid request",
            "unauthorized": "Request cannot be authenticated",
            "resource_not_found": "Resource not found",
            "email_not_found": "E-mail address not found",
            "invalid_username_or_password": "Invalid username or password",
            "internal_server_error": "Internal server error",
            "vehicle_already_activated": "Device already activated",
            "invalid_gift_code": "Invalid gift code",
            "gift_code_not_yet_available": "Gift code is not yet available",
            "gift_code_expired": "Gift code has expired",
            "gift_code_already_claimed": "Gift code already claimed",
            "invalid_activation_data": "Invalid activation code or device serial number",
            "unlimited_subscription": "Subscription is unlimited",
            "missing_subscription": "Entity cannot be found or does not have subscription",
            "payment_processing_error": "Payment processing error"
        },
        "validation": {
            "blank": "can't be empty",
            "email": "is not a valid e-mail address",
            "phone_number": "is not a valid international phone number",
            "tooShort": "is too short (minimum is {{count}} characters)",
            "notANumber": "is not a number",
            "greaterThan": "must be greater than {{count}}",
            "lessThan": "must be less than {{count}}",
            "doesnt_match_email": "doesn't match email",
            "doesnt_match_password": "doesn't match password",
            "missing_selection": "missing selection"
        }
    };

});