define('carlock-admin/components/custom-scrollable', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        classNames: ['scrollable-container'],
        mobileThreshold: 780,
        loadMoreOffset: 500,
        guid: null,
        loadMore: null,
        didInsertElement: function didInsertElement() {
            this.set('guid', Ember['default'].guidFor(this));

            // Initialize scrollable
            var w = Ember['default'].$(window).width();
            var threshold = this.get('mobileThreshold');

            if (w > threshold) {
                this.initializeScrollable();
            }

            // Initialize window resize handler
            var _this = this;
            var onResize = function onResize() {
                var threshold = _this.get('mobileThreshold');
                var scrollable = _this.get('scrollable');
                var w = Ember['default'].$(window).width();

                if (w <= threshold && scrollable) {
                    _this.destroyScrollable();
                } else if (w > threshold && !scrollable) {
                    _this.initializeScrollable();
                }

                Ember['default'].run.debounce(_this, _this.checkLoadMore, 100);
            };
            this.bindEvent(Ember['default'].$(window), 'resize', onResize);

            // Initialize window scroll handler (applicable only to mobile)
            var onScroll = function onScroll() {
                Ember['default'].run.debounce(_this, _this.checkLoadMore, 10);
            };
            this.bindEvent(Ember['default'].$(window), 'scroll', onScroll);

            // First check
            this.checkLoadMore();
        },
        willDestroyElement: function willDestroyElement() {
            this.destroyScrollable();
            this.unbindEvent(Ember['default'].$(window), 'resize');
            this.unbindEvent(Ember['default'].$(window), 'scroll');
        },

        refreshTimer: null,
        lastWidth: null,
        lastHeight: null,
        initializeScrollable: function initializeScrollable() {
            this.logger.trace('component:custom-scrollable: Initializing');

            // Initialize library
            var _this = this;
            var element = this.$()[0];
            var scrollable = new IScroll(element, {
                scrollX: true,
                scrollY: true,
                mouseWheel: true,
                scrollbars: 'custom',
                probeType: 2
            });
            this.set('scrollable', scrollable);

            // Initialize scroll handler
            var onScroll = function onScroll() {
                Ember['default'].run.debounce(_this, _this.checkLoadMore, 10);
            };
            scrollable.on('scroll', onScroll);

            // Initialize refresh timer
            var timer = setInterval(function () {
                var lastWidth = _this.get('lastWidth');
                var lastHeight = _this.get('lastHeight');
                var element = _this.$(':first-child');
                var width = element.width();
                var height = element.height();

                if (!lastWidth || lastWidth != width || !lastHeight || lastHeight != height) {
                    _this.set('lastWidth', width);
                    _this.set('lastHeight', height);
                    var scrollable = _this.get('scrollable');

                    if (scrollable) {
                        _this.logger.trace('component:custom-scrollable: Refreshing library');
                        scrollable.refresh();
                    }
                }
            }, 250);
            this.set('refreshTimer', timer);

            this.logger.trace('component:custom-scrollable: Initialized');
        },
        checkLoadMore: function checkLoadMore() {
            this.logger.trace('component:custom-scrollable: Checking if we need to load more');
            var scrollable = this.get('scrollable');
            var offset = this.get('loadMoreOffset');
            var root = Ember['default'].$(window);
            var height = this.$(':first-child').height();
            var bottom = null;
            var selfOffset = null;

            if (scrollable) {
                bottom = -scrollable.y + this.$().height();
                selfOffset = 0;
            } else {
                bottom = root.scrollTop() + root.height();
                selfOffset = this.$().offset().top;
            }

            if (bottom + offset >= selfOffset + height) {
                this.logger.trace('component:custom-scrollable: Need to load more');
                this.sendAction('loadMore');
            }
        },
        destroyScrollable: function destroyScrollable() {
            this.logger.trace('component:custom-scrollable: Destroying');
            var scrollable = this.get('scrollable');

            if (scrollable) {
                this.set('scrollable', null);
                scrollable.destroy();
            }

            clearInterval(this.get('refreshTimer'));
            this.logger.trace('component:custom-scrollable: Destroyed');
        },
        bindEvent: function bindEvent(item, eventName, logic) {
            item.on(eventName + '.' + this.get('guid'), logic);
        },
        unbindEvent: function unbindEvent(item, eventName) {
            item.off(eventName + '.' + this.get('guid'));
        }
    });

});