define('carlock-admin/models/c/user/push-notification', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var pushNotification = Ember['default'].Object.extend({
        name: null
    });

    pushNotification.FIXTURES = [{ name: 'CARLOCK_TIP_1' }, { name: 'CARLOCK_TIP_2' }, { name: 'CARLOCK_TIP_3' }, { name: 'CARLOCK_TIP_4' }, { name: 'CARLOCK_TIP_5' }, { name: 'CARLOCK_TIP_6' }, { name: 'CARLOCK_TIP_7' }];

    exports['default'] = pushNotification;

});