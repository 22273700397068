define('carlock-admin/models/date-range-option', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var dateRangeOption = Ember['default'].Object.extend({
        id: null,
        name: null
    });

    dateRangeOption.FIXTURES = [{ id: 1, name: 'misc.date_current_month' }, { id: 2, name: 'misc.date_previous_month' }, { id: 3, name: 'misc.date_custom_range' }];

    exports['default'] = dateRangeOption;

});