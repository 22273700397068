define('carlock-admin/models/c/purchase/shipment', ['exports', 'ember', 'carlock-admin/models/c/purchase/shipped-item', 'carlock-admin/models/c/purchase/address', 'carlock-admin/helpers/format-date', 'carlock-admin/helpers/format-time'], function (exports, Ember, ShipmentItemModel, AddressModel, FormatDate, FormatTime) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        distributorUuid: null,
        distributor: null,
        date: null,
        dateFormatted: Ember['default'].computed('date', 'profile.instance.timeZoneId', FormatTime['default']),
        status: null,
        statusFormatted: Ember['default'].computed('status', function () {
            return 'purchase.status.' + this.get('status').toLowerCase();
        }),
        items: null,
        email: null,
        phoneNumber: null,
        address: null,
        shipmentPlatform: null,
        orderId: null,
        priority: null,

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('distributorUuid', data.distributor_uuid);
            this.set('date', data.date);
            this.set('status', data.status);

            var itemsList = Ember['default'].A();
            for (var i = 0; i < data.items.length; i++) {
                var item = ShipmentItemModel['default'].create().load(data.items[i]);
                itemsList.pushObject(item);
            }

            this.set('items', itemsList);
            this.set('email', data.email);
            this.set('phoneNumber', data.phone_number);
            this.set('address', AddressModel['default'].create().load(data.address));
            this.set('shipmentPlatform', data.shipment_platform);
            this.set('orderId', data.order_id);
            this.set('priority', data.priority);

            return this;
        }
    });

});