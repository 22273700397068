define('carlock-admin/helpers/generate-dom-id', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

    exports['default'] = Ember['default'].Helper.helper(function (_ref) {
        var _ref2 = _toArray(_ref);

        var id = _ref2[0];

        var prefixes = _ref2.slice(1);

        var prefix = prefixes.join('');
        return '' + prefix + id;
    });

});