define('carlock-admin/templates/components/dialogs/confirm-vehicle-reset', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": [
              "multiple-nodes"
            ]
          },
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "carlock-admin/templates/components/dialogs/confirm-vehicle-reset.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","warning");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          morphs[2] = dom.createMorphAt(element0,3,3);
          return morphs;
        },
        statements: [
          ["inline","t",["vehicle.confirm_reset_warning"],[],["loc",[null,[3,23],[3,60]]]],
          ["inline","validated-input",[],["name","vehicle.confirm_reset_serial","value",["subexpr","@mut",[["get","deviceSerialNumber",["loc",[null,[5,68],[5,86]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.deviceSerialNumber",["loc",[null,[6,19],[6,44]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[6,56],[6,66]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[5,8],[6,91]]]],
          ["inline","validated-input",[],["name","vehicle.confirm_reset_activation_code","value",["subexpr","@mut",[["get","activationCode",["loc",[null,[7,77],[7,91]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.activationCode",["loc",[null,[8,19],[8,40]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[8,52],[8,62]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[7,8],[8,87]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": [
            "wrong-type"
          ]
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/components/dialogs/confirm-vehicle-reset.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","dialogs/modal-dialog",[],["visible",["subexpr","@mut",[["get","visible",["loc",[null,[1,32],[1,39]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[1,51],[1,61]]]]],[],[]],"localizedError",["subexpr","@mut",[["get","localizedError",["loc",[null,[1,77],[1,91]]]]],[],[]],"errorDetails",["subexpr","@mut",[["get","errorDetails",["loc",[null,[1,105],[1,117]]]]],[],[]],"saving",["subexpr","@mut",[["get","saving",["loc",[null,[2,11],[2,17]]]]],[],[]],"title","vehicle.confirm_reset_title","confirm","confirm","show","show"],0,null,["loc",[null,[1,0],[10,25]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});