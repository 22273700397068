define('carlock-admin/initializers/carlock', ['exports'], function (exports) {

    'use strict';

    exports.initialize = initialize;

    function initialize(application) {
        application.inject('component', 'carlock', 'service:carlock');
        application.inject('controller', 'carlock', 'service:carlock');
        application.inject('route', 'carlock', 'service:carlock');
    }

    exports['default'] = {
        name: 'carlock',
        initialize: initialize
    };

});