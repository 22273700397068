define('carlock-admin/templates/c/user', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 2
            },
            "end": {
              "line": 20,
              "column": 2
            }
          },
          "moduleName": "carlock-admin/templates/c/user.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","line");
          var el2 = dom.createTextNode("\n			");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","left");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n			");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","right");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","t",["vehicle.user_phone_number"],[],["loc",[null,[17,21],[17,54]]]],
          ["content","model.user.phoneNumber",["loc",[null,[18,22],[18,48]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.1",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 4
              },
              "end": {
                "line": 41,
                "column": 4
              }
            },
            "moduleName": "carlock-admin/templates/c/user.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("					");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"class","form-button red");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element5);
            morphs[1] = dom.createMorphAt(element5,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["toggleSendPushNotificationDialog"],[],["loc",[null,[40,37],[40,82]]]],
            ["inline","t",["vehicle.send_push"],[],["loc",[null,[40,83],[40,108]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 2
            },
            "end": {
              "line": 43,
              "column": 2
            }
          },
          "moduleName": "carlock-admin/templates/c/user.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("			");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","line");
          var el2 = dom.createTextNode("\n				");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","left");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n				");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","right switch");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n			");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n			");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","line buttons");
          var el2 = dom.createTextNode("\n				");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","form-button");
          var el3 = dom.createTextNode("\n					");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n				");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("		    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(fragment, [3]);
          var element8 = dom.childAt(element7, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
          morphs[2] = dom.createElementMorph(element8);
          morphs[3] = dom.createMorphAt(element8,1,1);
          morphs[4] = dom.createMorphAt(element7,3,3);
          return morphs;
        },
        statements: [
          ["inline","t",["vehicle.user_helped"],[],["loc",[null,[31,22],[31,49]]]],
          ["inline","toggle-switch",[],["id","user.helped","checked",["subexpr","@mut",[["get","model.user.helped",["loc",[null,[32,71],[32,88]]]]],[],[]],"action","toggleHelped"],["loc",[null,[32,30],[32,112]]]],
          ["element","action",["toggleUserDebugModeDialog"],[],["loc",[null,[36,32],[36,70]]]],
          ["inline","t",["vehicle.user_my_carlock"],[],["loc",[null,[37,5],[37,36]]]],
          ["block","if",[["subexpr","access-control",["hasUserRwAccess"],[],["loc",[null,[39,10],[39,44]]]]],[],0,null,["loc",[null,[39,4],[41,11]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 63,
              "column": 5
            },
            "end": {
              "line": 74,
              "column": 5
            }
          },
          "moduleName": "carlock-admin/templates/c/user.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("					");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("div");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("div");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("div");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("div");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("div");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("div");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("div");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n						");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","logout-button");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n					");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [15, 0]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 0]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3, 0]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [5, 0]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [7, 0]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [9, 0]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element3, [11, 0]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element3, [13, 0]),0,0);
          morphs[7] = dom.createElementMorph(element4);
          return morphs;
        },
        statements: [
          ["content","login.timestampFormatted",["loc",[null,[65,15],[65,43]]]],
          ["content","login.clientType",["loc",[null,[66,15],[66,35]]]],
          ["content","login.clientVersion",["loc",[null,[67,15],[67,38]]]],
          ["content","login.clientLocale",["loc",[null,[68,15],[68,37]]]],
          ["content","login.clientOsVersion",["loc",[null,[69,15],[69,40]]]],
          ["content","login.clientDeviceModel",["loc",[null,[70,15],[70,42]]]],
          ["content","login.notificationsTargetId",["loc",[null,[71,15],[71,46]]]],
          ["element","action",["clearLogin",["get","login.uuid",["loc",[null,[72,59],[72,69]]]]],[],["loc",[null,[72,37],[72,71]]]]
        ],
        locals: ["login"],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 83,
              "column": 8
            },
            "end": {
              "line": 85,
              "column": 8
            }
          },
          "moduleName": "carlock-admin/templates/c/user.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","not-found");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","t",["misc.not_found"],[],["loc",[null,[84,35],[84,57]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.1",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 20
              },
              "end": {
                "line": 91,
                "column": 20
              }
            },
            "moduleName": "carlock-admin/templates/c/user.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1,"class","distributor-column");
            var el2 = dom.createElement("div");
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0, 0]),0,0);
            return morphs;
          },
          statements: [
            ["inline","t",["devices.distributor"],[],["loc",[null,[90,66],[90,93]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 104,
                  "column": 24
                },
                "end": {
                  "line": 106,
                  "column": 24
                }
              },
              "moduleName": "carlock-admin/templates/c/user.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              dom.setAttribute(el1,"class","distributor-column");
              var el2 = dom.createElement("div");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]),0,0);
              return morphs;
            },
            statements: [
              ["content","device.distributorName",["loc",[null,[105,64],[105,90]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 111,
                  "column": 32
                },
                "end": {
                  "line": 113,
                  "column": 32
                }
              },
              "moduleName": "carlock-admin/templates/c/user.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","device.device.serialNumber",["loc",[null,[112,36],[112,66]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.1",
            "loc": {
              "source": null,
              "start": {
                "line": 102,
                "column": 16
              },
              "end": {
                "line": 121,
                "column": 16
              }
            },
            "moduleName": "carlock-admin/templates/c/user.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","device-column");
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","type-column");
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","serial-column");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","imei-column");
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","activated-column");
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","last-seen-column");
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"class","expires-column");
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(9);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0,1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [3, 0]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [5, 0]),0,0);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [7, 1]),1,1);
            morphs[5] = dom.createMorphAt(dom.childAt(element0, [9, 0]),0,0);
            morphs[6] = dom.createMorphAt(dom.childAt(element0, [11, 0]),0,0);
            morphs[7] = dom.createMorphAt(dom.childAt(element0, [13, 0]),0,0);
            morphs[8] = dom.createMorphAt(dom.childAt(element0, [15, 0]),0,0);
            return morphs;
          },
          statements: [
            ["element","action",["showDevice",["get","device",["loc",[null,[103,46],[103,52]]]]],[],["loc",[null,[103,24],[103,54]]]],
            ["block","if",[["subexpr","access-control",["hasUserRoAccess"],[],["loc",[null,[104,30],[104,64]]]]],[],0,null,["loc",[null,[104,24],[106,31]]]],
            ["inline","t",[["get","device.device.typeFormatted",["loc",[null,[107,59],[107,86]]]]],[],["loc",[null,[107,55],[107,88]]]],
            ["inline","t",[["get","device.device.typeVersionFormatted",["loc",[null,[108,57],[108,91]]]]],[],["loc",[null,[108,53],[108,93]]]],
            ["block","unless",[["get","device.device.serialNumberHidden",["loc",[null,[111,42],[111,74]]]]],[],1,null,["loc",[null,[111,32],[113,43]]]],
            ["content","device.device.imei",["loc",[null,[116,53],[116,75]]]],
            ["content","device.activationDateFormatted",["loc",[null,[117,58],[117,92]]]],
            ["content","device.lastSeenFormatted",["loc",[null,[118,58],[118,86]]]],
            ["content","device.expirationDateFormatted",["loc",[null,[119,56],[119,90]]]]
          ],
          locals: ["device"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 8
            },
            "end": {
              "line": 124,
              "column": 8
            }
          },
          "moduleName": "carlock-admin/templates/c/user.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1,"class","selectable");
          dom.setAttribute(el1,"cellpadding","0");
          dom.setAttribute(el1,"cellspacing","0");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","device-column");
          var el5 = dom.createElement("div");
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","type-column");
          var el5 = dom.createElement("div");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","serial-column");
          var el5 = dom.createElement("div");
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","imei-column");
          var el5 = dom.createElement("div");
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","activated-column");
          var el5 = dom.createElement("div");
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","last-seen-column");
          var el5 = dom.createElement("div");
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","expires-column");
          var el5 = dom.createElement("div");
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1, 1]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(element2,1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 0, 0]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [5, 0]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [7, 0, 0]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element2, [9, 0, 0]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element2, [11, 0, 0]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element2, [13, 0, 0]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element2, [15, 0, 0]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","access-control",["hasUserRoAccess"],[],["loc",[null,[89,26],[89,60]]]]],[],0,null,["loc",[null,[89,20],[91,27]]]],
          ["inline","t",["devices.device"],[],["loc",[null,[92,57],[92,79]]]],
          ["inline","t",["devices.type"],[],["loc",[null,[93,49],[93,69]]]],
          ["inline","t",["devices.serial"],[],["loc",[null,[94,57],[94,79]]]],
          ["inline","t",["devices.imei"],[],["loc",[null,[95,55],[95,75]]]],
          ["inline","t",["devices.activation_date"],[],["loc",[null,[96,60],[96,91]]]],
          ["inline","t",["devices.last_seen"],[],["loc",[null,[97,60],[97,85]]]],
          ["inline","t",["devices.expiration_date"],[],["loc",[null,[98,58],[98,89]]]],
          ["block","each",[["get","model.devices",["loc",[null,[102,24],[102,37]]]]],[],1,null,["loc",[null,[102,16],[121,25]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 128,
              "column": 4
            },
            "end": {
              "line": 136,
              "column": 4
            }
          },
          "moduleName": "carlock-admin/templates/c/user.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","loader");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","spinner");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dot");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dot");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","dot");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 137,
              "column": 4
            },
            "end": {
              "line": 143,
              "column": 4
            }
          },
          "moduleName": "carlock-admin/templates/c/user.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","form spinner");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dot");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dot");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dot");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 151,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/c/user.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","main-content");
        dom.setAttribute(el1,"id","user");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","tile-wrapper");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tile-container");
        var el4 = dom.createTextNode("\n	    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tile-content");
        var el5 = dom.createTextNode("\n		");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","line");
        var el6 = dom.createTextNode("\n		    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","left");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n		    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","right");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n		");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n		");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","line");
        var el6 = dom.createTextNode("\n		    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","left");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n		    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","right");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n		");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("		");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","line");
        var el6 = dom.createTextNode("\n		    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","left");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n		    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","right");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n		");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n		");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","line");
        var el6 = dom.createTextNode("\n		    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","left");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n		    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","right");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n		");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("	    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n	");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tile-container");
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tile-content debug-tile");
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n			");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("table");
        dom.setAttribute(el5,"class","selectable scrollable-table");
        dom.setAttribute(el5,"cellpadding","0");
        dom.setAttribute(el5,"cellspacing","0");
        var el6 = dom.createTextNode("\n				");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("thead");
        var el7 = dom.createTextNode("\n					");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("tr");
        dom.setAttribute(el7,"class","table-header");
        var el8 = dom.createTextNode("\n						");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        var el9 = dom.createElement("div");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n						");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        var el9 = dom.createElement("div");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n						");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        var el9 = dom.createElement("div");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n						");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        var el9 = dom.createElement("div");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n						");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        var el9 = dom.createElement("div");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n						");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        var el9 = dom.createElement("div");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n						");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        var el9 = dom.createElement("div");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n						");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("td");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n					");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n				");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n				");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tbody");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("				");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n			");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n		");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n	");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table device-list");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var element11 = dom.childAt(element10, [3]);
        var element12 = dom.childAt(element11, [1, 1]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [3]);
        var element15 = dom.childAt(element12, [7]);
        var element16 = dom.childAt(element12, [9]);
        var element17 = dom.childAt(element11, [3, 1]);
        var element18 = dom.childAt(element17, [3]);
        var element19 = dom.childAt(element18, [1, 1]);
        var morphs = new Array(25);
        morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
        morphs[1] = dom.createMorphAt(dom.childAt(element13, [1]),0,0);
        morphs[2] = dom.createMorphAt(dom.childAt(element13, [3]),0,0);
        morphs[3] = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element14, [3]),0,0);
        morphs[5] = dom.createMorphAt(element12,5,5);
        morphs[6] = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element15, [3]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
        morphs[10] = dom.createMorphAt(element12,11,11);
        morphs[11] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element19, [1, 0]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element19, [3, 0]),0,0);
        morphs[14] = dom.createMorphAt(dom.childAt(element19, [5, 0]),0,0);
        morphs[15] = dom.createMorphAt(dom.childAt(element19, [7, 0]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element19, [9, 0]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element19, [11, 0]),0,0);
        morphs[18] = dom.createMorphAt(dom.childAt(element19, [13, 0]),0,0);
        morphs[19] = dom.createMorphAt(dom.childAt(element18, [3]),1,1);
        morphs[20] = dom.createMorphAt(dom.childAt(element10, [5]),1,1);
        morphs[21] = dom.createMorphAt(element10,7,7);
        morphs[22] = dom.createMorphAt(element10,8,8);
        morphs[23] = dom.createMorphAt(element10,10,10);
        morphs[24] = dom.createMorphAt(element10,12,12);
        return morphs;
      },
      statements: [
        ["inline","t",["vehicle.user"],[],["loc",[null,[2,8],[2,28]]]],
        ["inline","t",["vehicle.user_email"],[],["loc",[null,[8,24],[8,50]]]],
        ["content","model.user.email",["loc",[null,[9,25],[9,45]]]],
        ["inline","t",["vehicle.user_name"],[],["loc",[null,[12,24],[12,49]]]],
        ["content","model.user.name",["loc",[null,[13,25],[13,44]]]],
        ["block","if",[["get","model.user.phoneNumber",["loc",[null,[15,8],[15,30]]]]],[],0,null,["loc",[null,[15,2],[20,9]]]],
        ["inline","t",["vehicle.user_country"],[],["loc",[null,[22,24],[22,52]]]],
        ["content","model.user.country.niceName",["loc",[null,[23,25],[23,56]]]],
        ["inline","t",["vehicle.user_track_trip_history"],[],["loc",[null,[26,24],[26,63]]]],
        ["inline","t",[["get","model.user.trackTripHistoryFormatted",["loc",[null,[27,29],[27,65]]]]],[],["loc",[null,[27,25],[27,67]]]],
        ["block","if",[["subexpr","access-control",["hasUserRwAccess"],[],["loc",[null,[29,8],[29,42]]]]],[],1,null,["loc",[null,[29,2],[43,9]]]],
        ["inline","t",["vehicle.debug.logins.title"],[],["loc",[null,[48,7],[48,41]]]],
        ["inline","t",["vehicle.debug.logins.timestamp"],[],["loc",[null,[52,15],[52,53]]]],
        ["inline","t",["vehicle.debug.logins.client_type"],[],["loc",[null,[53,15],[53,55]]]],
        ["inline","t",["vehicle.debug.logins.client_version"],[],["loc",[null,[54,15],[54,58]]]],
        ["inline","t",["vehicle.debug.logins.client_locale"],[],["loc",[null,[55,15],[55,57]]]],
        ["inline","t",["vehicle.debug.logins.client_os_version"],[],["loc",[null,[56,15],[56,61]]]],
        ["inline","t",["vehicle.debug.logins.client_device_model"],[],["loc",[null,[57,15],[57,63]]]],
        ["inline","t",["vehicle.debug.logins.notification_target_id"],[],["loc",[null,[58,15],[58,66]]]],
        ["block","each",[["get","model.logins",["loc",[null,[63,13],[63,25]]]]],[],2,null,["loc",[null,[63,5],[74,14]]]],
        ["block","unless",[["get","model.devices.length",["loc",[null,[83,18],[83,38]]]]],[],3,4,["loc",[null,[83,8],[124,19]]]],
        ["block","if",[["get","loading",["loc",[null,[128,10],[128,17]]]]],[],5,null,["loc",[null,[128,4],[136,11]]]],
        ["block","if",[["get","saving",["loc",[null,[137,10],[137,16]]]]],[],6,null,["loc",[null,[137,4],[143,11]]]],
        ["inline","dialogs/confirm-user-debug-mode",[],["id","confirm-user-debug-mode","visible",["subexpr","@mut",[["get","showUserDebugModeDialog",["loc",[null,[145,75],[145,98]]]]],[],[]],"userUuid",["subexpr","@mut",[["get","model.user.uuid",["loc",[null,[146,17],[146,32]]]]],[],[]]],["loc",[null,[145,4],[146,34]]]],
        ["inline","dialogs/send-user-push-notification",[],["id","send-user-push-notification-dialog","visible",["subexpr","@mut",[["get","showSendPushNotificationDialog",["loc",[null,[149,16],[149,46]]]]],[],[]],"userUuid",["subexpr","@mut",[["get","model.user.uuid",["loc",[null,[149,56],[149,71]]]]],[],[]],"success","refresh"],["loc",[null,[148,4],[149,91]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }()));

});