define('carlock-admin/controllers/c/purchases/stripe', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        dateFrom: null,
        dateTo: null,
        distributor: null,

        actions: {
            generateAll: function generateAll(export_type) {
                var _this2 = this;

                this.logger.trace('controller:stripe: Getting stripe accounting data');
                var _this = this;

                var prepare = function prepare() {
                    var dateFrom = _this.get('dateFrom');
                    var dateTo = _this.get('dateTo');

                    if (dateFrom) {
                        dateFrom = moment(dateFrom).utc().valueOf();
                    }

                    if (dateTo) {
                        dateTo = moment(dateTo).utc().valueOf();
                    }

                    var distributorUuid = _this2.get('distributor.uuid');
                    return _this.carlock.getStripeAccountingData(dateFrom, dateTo, distributorUuid);
                };

                var success = function success(results) {
                    if (results) {
                        _this['export'].exportStripeAccountingData(results, export_type === 'csv');
                    }
                };

                this.load(prepare, success);
            },
            generatePurchases: function generatePurchases() {
                var _this3 = this;

                var distributorUuid = this.get('distributor.uuid');
                var paymentIds = this.get('paymentIds');
                var details = undefined;

                if (!distributorUuid) {
                    details = 'paypal_export.create_purchase.select_distributor';
                } else if (!paymentIds) {
                    details = 'paypal_export.create_purchase.enter_id';
                }

                if (details) {
                    var error = {
                        code: 'invalid_request',
                        json: { details: this.i18n.t(details) }
                    };

                    this.send('yikes', error);
                    return;
                }

                var data = {
                    distributor_uuid: distributorUuid,
                    payment_id_list: paymentIds.split('\n')
                };

                this.load(function () {
                    return _this3.carlock.createStripePurchase(data);
                }, function () {});
            },
            clearFilter: function clearFilter() {
                this.set('dateFrom', null);
                this.set('dateTo', null);
                this.set('distributor', null);
            },
            togglePickDistributorDialog: function togglePickDistributorDialog() {
                this.toggleProperty('showPickDistributorDialog');
            },
            selectDistributor: function selectDistributor(distributor) {
                this.set('distributor', distributor);
            }
        }
    });

});