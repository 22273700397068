define('carlock-admin/components/dialogs/confirm-remove-vehicle-sim', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        vehicleUuid: null,
        actions: {
            confirm: function confirm() {
                this.logger.trace('component:confirm-remove-vehicle-sim: Removing vehicle SIM');
                var _this = this;

                var prepare = function prepare() {
                    var uuid = _this.get('vehicleUuid');
                    return _this.carlock.removeVehicleSim(uuid);
                };

                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});