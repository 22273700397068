define('carlock-admin/models/c/vehicle/health-statistic', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        count: null,
        min: null,
        max: null,
        sum: null,
        avg: Ember['default'].computed('count', 'sum', function () {
            if (!this.get('count') || !this.get('sum')) {
                return null;
            }

            return Math.round(this.get('sum') / this.get('count'));
        }),
        summary: Ember['default'].computed('min', 'max', 'avg', function () {
            var min = this.get('min');
            var max = this.get('max');
            var avg = this.get('avg');

            var padWithZeroes = function padWithZeroes(number, precision) {
                return ('0' + number).substr(-precision);
            };

            var formatTime = function formatTime(time) {
                var ms = (time % 1000 + '').substr(0, 1);
                var s = Math.round(Math.floor(time / 1000 % 60).toPrecision(2));
                var m = Math.round(Math.floor(time / (1000 * 60)));
                m = padWithZeroes(m, Math.max(2, (m + '').length));
                s = padWithZeroes(s, 2);

                return m + ':' + s + '.' + ms;
            };

            if (min == null || max == null || avg == null) {
                return '';
            } else {
                if (this.get('format')) {
                    return formatTime(min) + ' | ' + formatTime(max) + ' | ' + formatTime(avg);
                } else if (this.get('type') === 'gsmStrength') {
                    min = (min / 16).toFixed(1);
                    max = (max / 16).toFixed(1);
                    avg = (avg / 16).toFixed(1);
                }

                return min + ' | ' + max + ' | ' + avg;
            }
        }),
        format: null,
        type: null,

        load: function load(data, format, type) {
            this.set('count', data.count);
            this.set('min', data.min);
            this.set('max', data.max);
            this.set('sum', data.sum);
            this.set('format', format);
            this.set('type', type);

            return this;
        }
    });

});