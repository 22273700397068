define('carlock-admin/instance-initializers/i18n', ['exports', 'ember', 'ember-validations/messages', 'ember-validations/validators/local/confirmation', 'carlock-admin/models/language', 'carlock-admin/models/region'], function (exports, Ember, ValidationMessages, ConfirmationValidator, LanguageModel, RegionModel) {

    'use strict';

    exports.initialize = initialize;

    function initialize(applicationInstance) {
        var i18n = applicationInstance.lookup('service:i18n');
        var ipInfo = applicationInstance.lookup('service:ip-info');

        // Get browser locale
        var browserLocale = null;

        if (typeof navigator !== 'undefined') {
            browserLocale = navigator.language || navigator.userLanguage;
        }

        // Get url locale
        var regex = new RegExp('[\\?&]locale=([^&#]*)');
        var results = regex.exec(location.href);
        var urlLocale = null;

        if (results != null) {
            urlLocale = decodeURIComponent(results[1].replace(/\+/g, ' '));
        }

        // Get cookie locale
        var cookieLocale = Cookies.get('language');

        // Combine locales
        var locale = 'en-US';

        if (urlLocale) {
            locale = urlLocale;
        } else if (cookieLocale) {
            locale = cookieLocale;
        } else if (browserLocale) {
            locale = browserLocale;
        }

        // Set moment locale
        moment.locale(locale);

        // Find available languages
        i18n.set('languages', LanguageModel['default'].EU_FIXTURES);
        i18n.updateAvailableLanguages = function (countryIsoCode) {
            var region = RegionModel['default'].findByCountry(countryIsoCode);
            var languages = LanguageModel['default'].EU_FIXTURES;

            if (region) {
                var isoCode = region.get('isoCode');

                if (isoCode === RegionModel['default'].AF.get('isoCode') || isoCode === RegionModel['default'].ME.get('isoCode')) {
                    languages = LanguageModel['default'].AR_FIXTURES;
                }
            }

            i18n.set('languages', languages);
        };

        var updateCountriesFromInfo = function updateCountriesFromInfo(info) {
            var countryIsoCode = info.get('countryIsoCode');

            if (countryIsoCode) {
                countryIsoCode = countryIsoCode.toLowerCase();
                i18n.updateAvailableLanguages(countryIsoCode);
            }
        };

        var info = ipInfo.get('info');

        if (info) {
            updateCountriesFromInfo(info);
        } else {
            ipInfo.get('callbacks').pushObject(updateCountriesFromInfo);
        }

        // Add function to detect units
        i18n.imperial = function (profile) {
            // First check country
            if (profile) {
                var country = profile.get('instance.country.isoCode');
                return country === 'GB' || country === 'US';
            }

            // If we don't have country decide based on locale
            var locale = this.get('locale');

            if (locale) {
                return locale.indexOf('GB') >= 0 || locale.indexOf('US') >= 0;
            }

            // Default metric system
            return false;
        };

        // Update
        i18n.selectLanguage = function (language) {
            var isoCode = language.get('isoCode');
            i18n.set('locale', isoCode);
            i18n.set('language', language);

            // Update uservoice settings
            initializeUserVoice(isoCode);

            // Remember choice
            Cookies.set('language', isoCode, { expires: 365 });
        };

        // Check if we have language definitions for specified locale
        var languageCode = locale.substring(0, Math.min(2, locale.length)).toLowerCase();
        var language = LanguageModel['default'].FIXTURES.findBy('isoCode', languageCode);

        if (!language) {
            languageCode = 'en';
            language = LanguageModel['default'].FIXTURES.findBy('isoCode', languageCode);
        }

        i18n.set('locale', languageCode);
        i18n.set('language', language);

        // Configure ember-validations
        ValidationMessages['default'].render = function (attribute, context) {
            return {
                text: 'validation.' + attribute,
                count: context.count
            };
        };
    }

    exports['default'] = {
        name: 'i18n',
        after: 'ip-info',
        initialize: initialize
    };

});