define('carlock-admin/templates/c/purchases/reports/distributor-report', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.1",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 7,
                "column": 4
              }
            },
            "moduleName": "carlock-admin/templates/c/purchases/reports/distributor-report.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("						");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element13, 'class');
            morphs[1] = dom.createElementMorph(element13);
            morphs[2] = dom.createMorphAt(element13,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["distributor-filter ",["subexpr","if",[["get","distributor",["loc",[null,[4,42],[4,53]]]],"has-value"],[],["loc",[null,[4,37],[4,67]]]]]]],
            ["element","action",["togglePickDistributorDialog"],[],["loc",[null,[4,69],[4,109]]]],
            ["inline","input",[],["type","input","value",["subexpr","@mut",[["get","distributor.name",["loc",[null,[5,35],[5,51]]]]],[],[]],"placeholder",["subexpr","t",["purchase.list.distributor"],[],["loc",[null,[5,64],[5,95]]]],"readonly",true],["loc",[null,[5,8],[5,111]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.1",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 26,
                "column": 4
              }
            },
            "moduleName": "carlock-admin/templates/c/purchases/reports/distributor-report.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("						");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","form spinner");
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","dot");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","dot");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","dot");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 12
                },
                "end": {
                  "line": 77,
                  "column": 12
                }
              },
              "moduleName": "carlock-admin/templates/c/purchases/reports/distributor-report.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("														");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n																		");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","export-csv");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n																		");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","export-xls");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n																");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n														");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [27]);
              var element2 = dom.childAt(element1, [1]);
              var element3 = dom.childAt(element1, [3]);
              var morphs = new Array(17);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 0]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 0]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 0]),0,0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 0]),0,0);
              morphs[4] = dom.createMorphAt(dom.childAt(element0, [9, 0]),0,0);
              morphs[5] = dom.createMorphAt(dom.childAt(element0, [11, 0]),0,0);
              morphs[6] = dom.createMorphAt(dom.childAt(element0, [13, 0]),0,0);
              morphs[7] = dom.createMorphAt(dom.childAt(element0, [15, 0]),0,0);
              morphs[8] = dom.createMorphAt(dom.childAt(element0, [17, 0]),0,0);
              morphs[9] = dom.createMorphAt(dom.childAt(element0, [19, 0]),0,0);
              morphs[10] = dom.createMorphAt(dom.childAt(element0, [21, 0]),0,0);
              morphs[11] = dom.createMorphAt(dom.childAt(element0, [23, 0]),0,0);
              morphs[12] = dom.createMorphAt(dom.childAt(element0, [25, 0]),0,0);
              morphs[13] = dom.createAttrMorph(element2, 'title');
              morphs[14] = dom.createElementMorph(element2);
              morphs[15] = dom.createAttrMorph(element3, 'title');
              morphs[16] = dom.createElementMorph(element3);
              return morphs;
            },
            statements: [
              ["inline","t",[["get","group.descriptionKeyFormatted",["loc",[null,[59,29],[59,58]]]]],[],["loc",[null,[59,25],[59,60]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.price",["loc",[null,[60,52],[60,63]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[60,73],[60,87]]]]],[],[]]],["loc",[null,[60,25],[60,89]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.distributorPrice",["loc",[null,[61,52],[61,74]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[61,84],[61,98]]]]],[],[]]],["loc",[null,[61,25],[61,100]]]],
              ["inline","t",[["get","group.paidToProtectusFormatted",["loc",[null,[62,29],[62,59]]]]],[],["loc",[null,[62,25],[62,61]]]],
              ["content","group.count",["loc",[null,[63,25],[63,40]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.base",["loc",[null,[64,52],[64,62]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[64,72],[64,86]]]]],[],[]]],["loc",[null,[64,25],[64,88]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.discount",["loc",[null,[65,52],[65,66]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[65,76],[65,90]]]]],[],[]]],["loc",[null,[65,25],[65,92]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.tax",["loc",[null,[66,52],[66,61]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[66,71],[66,85]]]]],[],[]]],["loc",[null,[66,25],[66,87]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.transactionFee",["loc",[null,[67,52],[67,72]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[67,82],[67,96]]]]],[],[]]],["loc",[null,[67,25],[67,98]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.distributorShareBeforeTransactionFee",["loc",[null,[68,52],[68,94]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[68,104],[68,118]]]]],[],[]]],["loc",[null,[68,25],[68,120]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.distributorTransactionFee",["loc",[null,[69,52],[69,83]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[69,93],[69,107]]]]],[],[]]],["loc",[null,[69,25],[69,109]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.distributorShareAfterTransactionFee",["loc",[null,[70,52],[70,93]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[70,103],[70,117]]]]],[],[]]],["loc",[null,[70,25],[70,119]]]],
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","group.toProtectus",["loc",[null,[71,52],[71,69]]]]],[],[]],"currency",["subexpr","@mut",[["get","group.currency",["loc",[null,[71,79],[71,93]]]]],[],[]]],["loc",[null,[71,25],[71,95]]]],
              ["attribute","title",["concat",[["subexpr","t",["misc.export_csv"],[],["loc",[null,[73,49],[73,72]]]]]]],
              ["element","action",["exportGroupCsv",["get","group",["loc",[null,[73,100],[73,105]]]]],[],["loc",[null,[73,74],[73,107]]]],
              ["attribute","title",["concat",[["subexpr","t",["misc.export_xls"],[],["loc",[null,[74,49],[74,72]]]]]]],
              ["element","action",["exportGroupXls",["get","group",["loc",[null,[74,100],[74,105]]]]],[],["loc",[null,[74,74],[74,107]]]]
            ],
            locals: ["group"],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 96,
                  "column": 12
                },
                "end": {
                  "line": 110,
                  "column": 12
                }
              },
              "moduleName": "carlock-admin/templates/c/purchases/reports/distributor-report.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("														");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"colspan","4");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","total");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n																");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n														");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 19, 0]),0,0);
              return morphs;
            },
            statements: [
              ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","totalPayToProtectus",["loc",[null,[107,66],[107,85]]]]],[],[]],"currency",["subexpr","@mut",[["get","model.currency",["loc",[null,[107,95],[107,109]]]]],[],[]]],["loc",[null,[107,39],[107,111]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.1",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 4
              },
              "end": {
                "line": 114,
                "column": 4
              }
            },
            "moduleName": "carlock-admin/templates/c/purchases/reports/distributor-report.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("						");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table");
            var el2 = dom.createTextNode("\n								");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("table");
            dom.setAttribute(el2,"cellpadding","0");
            dom.setAttribute(el2,"cellspacing","0");
            var el3 = dom.createTextNode("\n										");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("thead");
            var el4 = dom.createTextNode("\n												");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createTextNode("\n																");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n														");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createTextNode("\n																");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n														");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n												");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n										");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n										");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tbody");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("										");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n										");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tfoot");
            var el4 = dom.createTextNode("\n												");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            dom.setAttribute(el5,"colspan","4");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createElement("div");
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n														");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("td");
            var el6 = dom.createTextNode("\n																");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","export-csv");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n																");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","export-xls");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n														");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n												");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("										");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n								");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n						");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var element5 = dom.childAt(element4, [1, 1]);
            var element6 = dom.childAt(element5, [7]);
            var element7 = dom.childAt(element5, [11]);
            var element8 = dom.childAt(element4, [5]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element9, [21]);
            var element11 = dom.childAt(element10, [1]);
            var element12 = dom.childAt(element10, [3]);
            var morphs = new Array(31);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 0]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 0]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element5, [5, 0]),0,0);
            morphs[3] = dom.createAttrMorph(element6, 'title');
            morphs[4] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
            morphs[5] = dom.createMorphAt(dom.childAt(element5, [9, 0]),0,0);
            morphs[6] = dom.createAttrMorph(element7, 'title');
            morphs[7] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
            morphs[8] = dom.createMorphAt(dom.childAt(element5, [13, 0]),0,0);
            morphs[9] = dom.createMorphAt(dom.childAt(element5, [15, 0]),0,0);
            morphs[10] = dom.createMorphAt(dom.childAt(element5, [17, 0]),0,0);
            morphs[11] = dom.createMorphAt(dom.childAt(element5, [19, 0]),0,0);
            morphs[12] = dom.createMorphAt(dom.childAt(element5, [21, 0]),0,0);
            morphs[13] = dom.createMorphAt(dom.childAt(element5, [23, 0]),0,0);
            morphs[14] = dom.createMorphAt(dom.childAt(element5, [25, 0]),0,0);
            morphs[15] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
            morphs[16] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
            morphs[17] = dom.createMorphAt(dom.childAt(element9, [3, 0]),0,0);
            morphs[18] = dom.createMorphAt(dom.childAt(element9, [5, 0]),0,0);
            morphs[19] = dom.createMorphAt(dom.childAt(element9, [7, 0]),0,0);
            morphs[20] = dom.createMorphAt(dom.childAt(element9, [9, 0]),0,0);
            morphs[21] = dom.createMorphAt(dom.childAt(element9, [11, 0]),0,0);
            morphs[22] = dom.createMorphAt(dom.childAt(element9, [13, 0]),0,0);
            morphs[23] = dom.createMorphAt(dom.childAt(element9, [15, 0]),0,0);
            morphs[24] = dom.createMorphAt(dom.childAt(element9, [17, 0]),0,0);
            morphs[25] = dom.createMorphAt(dom.childAt(element9, [19, 0]),0,0);
            morphs[26] = dom.createAttrMorph(element11, 'title');
            morphs[27] = dom.createElementMorph(element11);
            morphs[28] = dom.createAttrMorph(element12, 'title');
            morphs[29] = dom.createElementMorph(element12);
            morphs[30] = dom.createMorphAt(element8,3,3);
            return morphs;
          },
          statements: [
            ["inline","t",["report.subscription_report.name"],[],["loc",[null,[36,23],[36,62]]]],
            ["inline","t",["report.subscription_report.price"],[],["loc",[null,[37,23],[37,63]]]],
            ["inline","t",["report.subscription_report.distributor_price"],[],["loc",[null,[38,23],[38,75]]]],
            ["attribute","title",["concat",[["subexpr","t",["report.subscription_report.paid_to_protectus_title"],[],["loc",[null,[39,25],[39,83]]]]]]],
            ["inline","t",["report.subscription_report.paid_to_protectus"],[],["loc",[null,[40,21],[40,73]]]],
            ["inline","t",["report.subscription_report.count"],[],["loc",[null,[42,23],[42,63]]]],
            ["attribute","title",["concat",[["subexpr","t",["report.subscription_report.base_title"],[],["loc",[null,[43,25],[43,70]]]]]]],
            ["inline","t",["report.subscription_report.base"],[],["loc",[null,[44,21],[44,60]]]],
            ["inline","t",["report.subscription_report.discount"],[],["loc",[null,[46,23],[46,66]]]],
            ["inline","t",["report.subscription_report.tax"],[],["loc",[null,[47,23],[47,61]]]],
            ["inline","t",["report.subscription_report.transaction_fee"],[],["loc",[null,[48,23],[48,73]]]],
            ["inline","t",["report.subscription_report.distributor_share_before_transaction_fee"],[],["loc",[null,[49,23],[49,98]]]],
            ["inline","t",["report.subscription_report.distributor_transaction_fee_share"],[],["loc",[null,[50,23],[50,91]]]],
            ["inline","t",["report.subscription_report.distributor_share_after_transaction_fee"],[],["loc",[null,[51,23],[51,97]]]],
            ["inline","t",["report.subscription_report.to_protectus"],[],["loc",[null,[52,23],[52,70]]]],
            ["block","each",[["get","model.groups",["loc",[null,[57,20],[57,32]]]]],[],0,null,["loc",[null,[57,12],[77,21]]]],
            ["inline","t",["report.summary"],[],["loc",[null,[81,30],[81,52]]]],
            ["content","model.count",["loc",[null,[82,23],[82,38]]]],
            ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","model.base",["loc",[null,[83,50],[83,60]]]]],[],[]],"currency",["subexpr","@mut",[["get","model.currency",["loc",[null,[83,70],[83,84]]]]],[],[]]],["loc",[null,[83,23],[83,86]]]],
            ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","model.discount",["loc",[null,[84,50],[84,64]]]]],[],[]],"currency",["subexpr","@mut",[["get","model.currency",["loc",[null,[84,74],[84,88]]]]],[],[]]],["loc",[null,[84,23],[84,90]]]],
            ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","model.tax",["loc",[null,[85,50],[85,59]]]]],[],[]],"currency",["subexpr","@mut",[["get","model.currency",["loc",[null,[85,69],[85,83]]]]],[],[]]],["loc",[null,[85,23],[85,85]]]],
            ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","model.transactionFee",["loc",[null,[86,50],[86,70]]]]],[],[]],"currency",["subexpr","@mut",[["get","model.currency",["loc",[null,[86,80],[86,94]]]]],[],[]]],["loc",[null,[86,23],[86,96]]]],
            ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","model.distributorShareBeforeTransactionFee",["loc",[null,[87,50],[87,92]]]]],[],[]],"currency",["subexpr","@mut",[["get","model.currency",["loc",[null,[87,102],[87,116]]]]],[],[]]],["loc",[null,[87,23],[87,118]]]],
            ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","model.distributorTransactionFee",["loc",[null,[88,50],[88,81]]]]],[],[]],"currency",["subexpr","@mut",[["get","model.currency",["loc",[null,[88,91],[88,105]]]]],[],[]]],["loc",[null,[88,23],[88,107]]]],
            ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","model.distributorShareAfterTransactionFee",["loc",[null,[89,50],[89,91]]]]],[],[]],"currency",["subexpr","@mut",[["get","model.currency",["loc",[null,[89,101],[89,115]]]]],[],[]]],["loc",[null,[89,23],[89,117]]]],
            ["inline","localized-currency",[],["value",["subexpr","@mut",[["get","model.toProtectus",["loc",[null,[90,50],[90,67]]]]],[],[]],"currency",["subexpr","@mut",[["get","model.currency",["loc",[null,[90,77],[90,91]]]]],[],[]]],["loc",[null,[90,23],[90,93]]]],
            ["attribute","title",["concat",[["subexpr","t",["misc.export_csv"],[],["loc",[null,[92,47],[92,70]]]]]]],
            ["element","action",["exportCsv"],[],["loc",[null,[92,72],[92,94]]]],
            ["attribute","title",["concat",[["subexpr","t",["misc.export_xls"],[],["loc",[null,[93,47],[93,70]]]]]]],
            ["element","action",["exportXls"],[],["loc",[null,[93,72],[93,94]]]],
            ["block","if",[["subexpr","access-control",["isAdmin"],[],["loc",[null,[96,18],[96,44]]]]],[],1,null,["loc",[null,[96,12],[110,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": [
              "multiple-nodes"
            ]
          },
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 116,
              "column": 0
            }
          },
          "moduleName": "carlock-admin/templates/c/purchases/reports/distributor-report.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","toolbar");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("				");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","predefined-range");
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n				");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n				");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","date-range");
          var el3 = dom.createTextNode("\n						");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n								");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("-");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n								");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n				");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n				");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","generate form-button");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("				");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","clear");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","report");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("		");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element14 = dom.childAt(fragment, [1]);
          var element15 = dom.childAt(element14, [5]);
          var element16 = dom.childAt(element14, [7]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(element14,1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element14, [3]),1,1);
          morphs[2] = dom.createMorphAt(element15,1,1);
          morphs[3] = dom.createMorphAt(element15,5,5);
          morphs[4] = dom.createElementMorph(element16);
          morphs[5] = dom.createMorphAt(element16,0,0);
          morphs[6] = dom.createMorphAt(element14,9,9);
          morphs[7] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","access-control",["hasDistributorRoAccess"],[],["loc",[null,[3,10],[3,51]]]]],[],0,null,["loc",[null,[3,4],[7,11]]]],
          ["inline","drop-down",[],["identifier","predifned-range","placeholder","misc.date_custom_range","value",["subexpr","@mut",[["get","dateRange",["loc",[null,[9,90],[9,99]]]]],[],[]],"items",["subexpr","@mut",[["get","dateRangeList",["loc",[null,[10,14],[10,27]]]]],[],[]],"idField","id","localizedValues",true,"textField","name"],["loc",[null,[9,6],[10,80]]]],
          ["inline","date-picker",[],["class","date-from","value",["subexpr","@mut",[["get","dateFrom",["loc",[null,[13,44],[13,52]]]]],[],[]],"timeZoneId",["subexpr","@mut",[["get","profile.instance.timeZoneId",["loc",[null,[13,64],[13,91]]]]],[],[]],"placeholder",["subexpr","t",["misc.date_from"],[],["loc",[null,[14,20],[14,40]]]]],["loc",[null,[13,6],[14,42]]]],
          ["inline","date-picker",[],["class","date-to","value",["subexpr","@mut",[["get","dateTo",["loc",[null,[16,44],[16,50]]]]],[],[]],"timeZoneId",["subexpr","@mut",[["get","profile.instance.timeZoneId",["loc",[null,[16,62],[16,89]]]]],[],[]],"placeholder",["subexpr","t",["misc.date_to"],[],["loc",[null,[17,22],[17,40]]]]],["loc",[null,[16,8],[17,42]]]],
          ["element","action",["generate"],[],["loc",[null,[19,38],[19,59]]]],
          ["inline","t",["report.generate_button"],[],["loc",[null,[19,60],[19,90]]]],
          ["block","if",[["get","loading",["loc",[null,[20,10],[20,17]]]]],[],1,null,["loc",[null,[20,4],[26,11]]]],
          ["block","if",[["get","model",["loc",[null,[31,10],[31,15]]]]],[],2,null,["loc",[null,[31,4],[114,11]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": [
            "wrong-type",
            "multiple-nodes"
          ]
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 120,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/c/purchases/reports/distributor-report.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","access-control",["isDistributor"],[],["loc",[null,[1,6],[1,38]]]]],[],0,null,["loc",[null,[1,0],[116,7]]]],
        ["inline","dialogs/pick-distributor",[],["id","pick-distributor-dialog","visible",["subexpr","@mut",[["get","showPickDistributorDialog",["loc",[null,[118,64],[118,89]]]]],[],[]],"select","selectDistributor"],["loc",[null,[118,0],[119,30]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});