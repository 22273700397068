define('carlock-admin/components/lists/coupon-list', ['exports', 'ember', 'carlock-admin/mixins/base-list', 'carlock-admin/models/c/coupon/coupon'], function (exports, Ember, BaseList, CouponModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(BaseList['default'], {
        prepareLoadRequest: function prepareLoadRequest(filter, sort, descending, page, pageSize) {
            if (this.get('isAdmin')) {
                return this.carlock.getCoupons(filter, null, page, pageSize, sort, descending);
            }
        },
        loadRequestSucceeded: function loadRequestSucceeded(response) {
            var list = this.get('model');

            for (var i = 0; i < response.coupons.length; i++) {
                list.pushObject(CouponModel['default'].create().load(response.coupons[i]));
            }
        },
        actions: {
            clearFilter: function clearFilter() {
                this.set('filter', null);
                this.set('offerUuid', null);
            },
            addCoupon: function addCoupon() {
                this.toggleProperty('showAddCouponDialog');
            },
            search: function search() {
                this.reloadData();
            }
        }
    });

});