define('carlock-admin/components/if-equals', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        equals: Ember['default'].computed('a', 'b', function () {
            return this.get('a') == this.get('b');
        })
    });

});