define('carlock-admin/models/c/vehicle/subscription-offer', ['exports', 'ember', 'carlock-admin/models/country', 'carlock-admin/helpers/format-date'], function (exports, Ember, CountryModel, FormatDate) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        distributorUuid: null,
        descriptionKey: null,
        descriptionFormatted: Ember['default'].computed('descriptionKey', function () {
            return 'offer.' + this.get('descriptionKey');
        }),
        subscriptionDays: null,
        smsNotifications: null,
        price: null,
        priceFormatted: Ember['default'].computed('price', function () {
            return (this.get('price') / 100.0).toFixed(2).toLocaleString();
        }),
        currency: null,
        start: null,
        startFormatted: Ember['default'].computed('start', FormatDate['default']),
        end: null,
        endFormatted: Ember['default'].computed('end', FormatDate['default']),
        deviceType: null,
        deviceTypeFormatted: Ember['default'].computed('deviceType', function () {
            return 'vehicle.device_type_version.' + this.get('deviceType').toLowerCase();
        }),
        countryIsoCode: null,
        type: null,
        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('distributorUuid', data.distributor_uuid);
            this.set('descriptionKey', data.description_key);
            this.set('subscriptionDays', data.subscription_days);
            this.set('smsNotifications', data.sms_notifications);
            this.set('price', data.price);
            this.set('currency', data.currency);
            this.set('start', data.start);
            this.set('end', data.end);
            this.set('deviceType', data.device_type);
            this.set('countryIsoCode', data.country_iso_code);

            if (data.country_iso_code) this.set('country', CountryModel['default'].FIXTURES.findBy('isoCode', data.country_iso_code));

            this.set('type', data.type);
            return this;
        }
    });

});