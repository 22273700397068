define('carlock-admin/models/c/vehicle/create-device', ['exports', 'ember'], function (exports, Ember) {

   'use strict';

   exports['default'] = Ember['default'].Object.extend({
      distributor: null,
      deviceType: null,
      deviceSerialNumber: null,
      deviceImei: null,
      activationCode: null,
      activationDays: 31,
      distributorSim: false,
      simProvider: null,
      simIccid: null
   });

});