define('carlock-admin/routes/c/login', ['exports', 'ember', 'carlock-admin/routes/unauthenticated', 'carlock-admin/models/c/login'], function (exports, Ember, Base, LoginModel) {

    'use strict';

    exports['default'] = Base['default'].extend({
        setupController: function setupController(controller, model) {
            this.logger.trace('controller:login: Setting up controller');
            this._super(controller, model);
        },
        model: function model() {
            return LoginModel['default'].create();
        }
    });

});