define('carlock-admin/controllers/application', ['exports', 'ember', 'carlock-admin/models/language', 'carlock-admin/config/environment'], function (exports, Ember, LanguageModel, Config) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend({
        availableLanguages: LanguageModel['default'].FIXTURES,
        selectedLanguage: null,
        languageBarOpen: false,
        drawerMenuOpen: false,
        showProfile: false,
        showHelp: false,
        loginBackgroundVisible: Ember['default'].computed('currentPath', function () {
            return this.get('currentPath').indexOf('login') >= 0;
        }),
        actions: {
            logout: function logout() {
                this.get('session').invalidate();
            },
            changeLanguage: function changeLanguage(language) {
                this.logger.trace('controller:application: Changing language: ' + language.isoCode);
                this.set('selectedLanguage', language);
                this.get('i18n').set('locale', language.isoCode);

                // Remember choice
                Cookies.set('language', language.isoCode, { expires: 365 });

                this.set('languageBarOpen', false);
                this.set('drawerMenuOpen', false);
                this.logger.trace('controller:application: Changed language');
            },
            toggleProfile: function toggleProfile() {
                this.set('showProfile', !this.get('showProfile'));
            },
            toggleLanguageBar: function toggleLanguageBar() {
                this.logger.trace('controller:application: Toggling language bar');
                this.set('languageBarOpen', !this.get('languageBarOpen'));
                this.set('drawerMenuOpen', false);
            },
            toggleLanguageDrawer: function toggleLanguageDrawer() {
                this.logger.trace('controller:application: Toggling language drawer');
                this.set('drawerMenuOpen', !this.get('drawerMenuOpen'));
                this.set('languageBarOpen', false);
            },
            hideError: function hideError() {
                this.set('localizedError', null);
                this.set('errorDetails', null);
            }
        }
    });

});