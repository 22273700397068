define('carlock-admin/templates/c/purchases/promo', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": [
            "multiple-nodes",
            "wrong-type"
          ]
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/c/purchases/promo.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","order-form");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" \n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element0,0,0);
        morphs[1] = dom.createMorphAt(element0,2,2);
        morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
        return morphs;
      },
      statements: [
        ["inline","validated-input",[],["name","purchase.promo.ig_name","value",["subexpr","@mut",[["get","igName",["loc",[null,[2,61],[2,67]]]]],[],[]],"placeholder","@carlock.co"],["loc",[null,[2,7],[2,95]]]],
        ["inline","validated-input",[],["name","purchase.promo.promo_link","value",["subexpr","@mut",[["get","igComputedLink",["loc",[null,[2,153],[2,167]]]]],[],[]],"disabled","disabled"],["loc",[null,[2,96],[2,189]]]],
        ["inline","forms/shipment-form",[],["ship","ship","orderId",["subexpr","@mut",[["get","orderId",["loc",[null,[7,42],[7,49]]]]],[],[]],"items",["subexpr","@mut",[["get","items",["loc",[null,[7,56],[7,61]]]]],[],[]],"title","purchase.promo.title"],["loc",[null,[7,0],[7,92]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});