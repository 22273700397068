define('carlock-admin/instance-initializers/ember-i18n', ['exports', 'ember', 'ember-i18n/stream', 'ember-i18n/legacy-helper', 'carlock-admin/config/environment'], function (exports, Ember, Stream, legacyHelper, ENV) {

  'use strict';

  exports['default'] = {
    name: 'ember-i18n',

    initialize: function initialize(appOrAppInstance) {
      if (legacyHelper['default'] != null) {
        (function () {
          // Used for Ember < 1.13
          var i18n = appOrAppInstance.container.lookup('service:i18n');

          i18n.localeStream = new Stream['default'](function () {
            return i18n.get('locale');
          });

          Ember['default'].addObserver(i18n, 'locale', i18n, function () {
            this.localeStream.value(); // force the stream to be dirty
            this.localeStream.notify();
          });

          Ember['default'].HTMLBars._registerHelper('t', legacyHelper['default']);
        })();
      }
    }
  };

});