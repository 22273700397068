define('carlock-admin/components/dialogs/regenerate-purchase-invoice', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/config/environment'], function (exports, Ember, Base, Config) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        purchaseUuid: null,
        actions: {
            confirm: function confirm() {
                this.logger.trace('component:regenerate-purchase-invoice: Regenerating');
                var _this = this;

                var prepare = function prepare() {
                    var recalculateAmounts = _this.get('recalculateAmounts');
                    var uuid = _this.get('purchaseUuid');
                    var data = {
                        'recalculate_amounts': recalculateAmounts ? true : false
                    };
                    return _this.carlock.regeneratePurchaseInvoice(uuid, data);
                };

                var success = function success(data) {
                    _this.logger.trace('component:regenerate-purchase-invoice: Downloading');
                    window.location = Config['default'].adminURL + data.relative_path;
                    _this.set('visible', false);
                };

                this.save(prepare, success);
            }
        }
    });

});