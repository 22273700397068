define('carlock-admin/components/order-picker', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        ascending: null,
        actions: {
            toggle: function toggle() {
                this.set('ascending', !this.get('ascending'));
            }
        }
    });

});