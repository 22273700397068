define('carlock-admin/models/language', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var language = Ember['default'].Object.extend({
        name: null,
        isoCode: null,
        rtl: null,
        firstDay: null, /* 0 Sunday, 1 Monday */
        countries: null
    });

    var enl = ['au', 'ca', 'gb', 'us'];
    var en = language.create({ name: 'English', isoCode: 'en', rtl: false, countries: enl, flag: 'gb', firstDay: 0 });

    var frl = ['cd', 'fr', 'ca', 'mg', 'cm', 'ci', 'bf', 'ne', 'sn', 'ml', 'rw', 'be', 'ht', 'td', 'gn', 'bi', 'bj', 'ch', 'tg', 'cf', 'cg', 'ga', 'km', 'gq', 'dj', 'lu', 'vu', 'sc', 'mc'];
    var fr = language.create({ name: 'Français', isoCode: 'fr', rtl: false, countries: frl, flag: 'fr', firstDay: 1 });

    /*
     var itl = ['it', 'sm', 'mt', 'va'];
     var it = language.create({name: 'Italiano', isoCode: 'it', rtl: false, countries: itl});
     */

    var arl = ['dz', 'ba', 'td', 'km', 'dj', 'eg', 'gm', 'er', 'ir', 'iq', 'il', 'jo', 'kw', 'lb', 'ly', 'mr', 'ma', 'om', 'ps', 'qa', 'sa', 'so', 'sd', 'sy', 'tn', 'ae', 'eh', 'ye'];
    var ar = language.create({ name: 'المملكة العربية السعودية', isoCode: 'ar', rtl: true, countries: arl, flag: 'sa', firstDay: 1 });

    var sll = ['si'];
    var sl = language.create({ name: 'Slovenščina', isoCode: 'sl', rtl: false, countries: sll, flag: 'si', firstDay: 1 });

    var ukl = ['ua'];
    var uk = language.create({ name: 'Український', isoCode: 'uk', rtl: false, countries: ukl, flag: 'ua', firstDay: 0 });

    language.EU_FIXTURES = [en /*fr, it, sl, uk*/];
    language.AR_FIXTURES = [en /*fr, it, ar, sl, uk*/];
    language.FIXTURES = [en /*fr, it, ar, sl, uk*/];
    exports['default'] = language;

});