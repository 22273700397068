define('carlock-admin/controllers/c/purchases/reports/gb-vat-report', ['exports', 'carlock-admin/mixins/base'], function (exports, Base) {

    'use strict';

    exports['default'] = Ember.Controller.extend(Base['default'], {
        taxDateFrom: null,
        taxDateTo: null,
        generateTaxReport: function generateTaxReport() {
            var _this = this;

            var from = this.get('taxDateFrom');
            var to = this.get('taxDateTo');

            if (!from) {
                return;
            }

            var prepare = function prepare() {
                return _this.carlock.sendGbVatReport(from, to);
            };
            var success = function success() {};
            this.load(prepare, success);
        },
        actions: {
            generateTaxReport: function generateTaxReport() {
                this.generateTaxReport();
            }
        }
    });

});