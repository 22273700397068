define('carlock-admin/components/lists/cohort-list', ['exports', 'ember', 'carlock-admin/mixins/base-list', 'carlock-admin/models/c/vehicle/cohorts'], function (exports, Ember, BaseList, CohortsModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(BaseList['default'], {
        dateFrom: moment.utc().valueOf(),
        dateTo: moment.utc().valueOf(),
        prepareLoadRequest: function prepareLoadRequest() {
            if (this.dateFrom && this.dateTo) {
                var from = moment(this.dateFrom).utc().startOf('month').valueOf();
                var to = moment(this.dateTo).utc().endOf('month').valueOf();
                var distributorUuid = this.get('distributor') ? this.get('distributor.uuid') : null;
                return this.carlock.getCohorts(from.valueOf(), to.valueOf(), distributorUuid);
            }
        },
        loadRequestSucceeded: function loadRequestSucceeded(response) {
            this.set('reachedInfinity', true); //We load all data at the same time
            var list = this.get('model');
            list.pushObject(CohortsModel['default'].create().load(response));
        },
        actions: {
            togglePickDistributorDialog: function togglePickDistributorDialog() {
                this.toggleProperty('showPickDistributorDialog');
            },
            selectDistributor: function selectDistributor(distributor) {
                this.set('distributor', distributor);
            },
            generate: function generate() {
                this.logger.trace('controller:cohort-report: Loading data');
                this.reloadData();
            },
            sort: function sort() {
                //Not needed atm
            },
            dateFromChanged: function dateFromChanged(date) {
                this.set('dateFrom', date);
            },
            dateToChanged: function dateToChanged(date) {
                this.set('dateTo', date);
            },
            'export': function _export() {
                this.get('export').exportCohorts(this.get('model'), 'Cohorts ' + new Date() + '.csv');
            }
        }
    });

});