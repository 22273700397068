define('carlock-admin/models/c/vehicle/device', ['exports', 'ember', 'carlock-admin/models/c/vehicle/sim', 'carlock-admin/helpers/format-date'], function (exports, Ember, SimModel, FormatDate) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        serialNumber: null,
        serialNumberHidden: Ember['default'].computed('type', function () {
            //return this.get('type') === 'MOBILE_PHONE';
            return false;
        }),
        imei: null,
        type: null,
        typeFormatted: Ember['default'].computed('type', function () {
            return 'vehicle.device_type.' + this.get('type').toLowerCase();
        }),
        typeVersion: null,
        typeVersionFormatted: Ember['default'].computed('typeVersion', function () {
            return 'vehicle.device_type_version.' + this.get('typeVersion').toLowerCase();
        }),
        distributorSim: null,
        firmwareVersion: null,
        firmwareRevision: null,
        configuration: null,
        password: null,
        sim: null,
        batchCode: null,
        batchDate: null,
        batchDateFormatted: Ember['default'].computed('batchDate', FormatDate['default']),

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('serialNumber', data.serial_number);
            this.set('imei', data.imei);
            this.set('type', data.type);
            this.set('typeVersion', data.type_version);
            this.set('distributorSim', data.distributor_sim);
            this.set('firmwareVersion', data.firmware_version);
            this.set('firmwareRevision', data.firmware_revision);
            this.set('configuration', data.configuration);
            this.set('password', data.password);
            this.set('batchCode', data.batch_code);
            this.set('batchDate', data.batch_date);

            if (data.sim) {
                this.set('sim', SimModel['default'].create().load(data.sim));
            }

            return this;
        }
    });

});