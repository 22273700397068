define('carlock-admin/models/c/purchase/coupon', ['exports', 'ember', 'carlock-admin/models/c/purchase/purchase-offer', 'carlock-admin/helpers/format-date'], function (exports, Ember, PurchaseOfferModel, FormatDate) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		uuid: null,
		code: null,
		offer: null,
		type: null,
		value: null,
		start: null,
		startFormatted: Ember['default'].computed('start', 'profile.instance.timeZoneId', FormatDate['default']),
		end: null,
		endFormatted: Ember['default'].computed('end', 'profile.instance.timeZoneId', FormatDate['default']),
		load: function load(data) {
			this.set('uuid', data.uuid);
			this.set('code', data.code);
			this.set('offer', PurchaseOfferModel['default'].create().load(data.offer));
			this.set('type', data.type);
			this.set('value', data.value);
			this.set('start', data.start);
			this.set('end', data.end);
			return this;
		}
	});

});