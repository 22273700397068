define('carlock-admin/templates/components/dialogs/add-vehicle', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "carlock-admin/templates/components/dialogs/add-vehicle.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(element0,1,1);
          morphs[1] = dom.createMorphAt(element0,3,3);
          morphs[2] = dom.createMorphAt(element0,5,5);
          morphs[3] = dom.createMorphAt(element0,7,7);
          morphs[4] = dom.createMorphAt(element0,9,9);
          morphs[5] = dom.createMorphAt(element0,11,11);
          morphs[6] = dom.createMorphAt(element0,13,13);
          morphs[7] = dom.createMorphAt(element0,15,15);
          morphs[8] = dom.createMorphAt(element0,17,17);
          return morphs;
        },
        statements: [
          ["inline","validated-drop-down",[],["identifier","vehicle-distributor","name","vehicle.distributor","mandatory",true,"value",["subexpr","@mut",[["get","vehicle.distributor",["loc",[null,[5,18],[5,37]]]]],[],[]],"items",["subexpr","@mut",[["get","distributorList",["loc",[null,[5,44],[5,59]]]]],[],[]],"idField","uuid","textField","name","errors",["subexpr","@mut",[["get","errors.vehicle.distributor",["loc",[null,[6,19],[6,45]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[6,57],[6,67]]]]],[],[]]],["loc",[null,[4,8],[6,69]]]],
          ["inline","validated-drop-down",[],["identifier","vehicle-device-type","name","vehicle.device_type_title","mandatory",true,"value",["subexpr","@mut",[["get","vehicle.deviceType",["loc",[null,[8,18],[8,36]]]]],[],[]],"items",["subexpr","@mut",[["get","deviceTypeList",["loc",[null,[8,43],[8,57]]]]],[],[]],"idField","id","textField","displayName","errors",["subexpr","@mut",[["get","errors.vehicle.deviceType",["loc",[null,[9,19],[9,44]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[9,56],[9,66]]]]],[],[]],"localizedValues",true],["loc",[null,[7,8],[9,89]]]],
          ["inline","validated-input",[],["name","vehicle.device_serial","value",["subexpr","@mut",[["get","vehicle.deviceSerialNumber",["loc",[null,[11,61],[11,87]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.vehicle.deviceSerialNumber",["loc",[null,[12,19],[12,52]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[12,64],[12,74]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[11,8],[12,99]]]],
          ["inline","validated-input",[],["name","vehicle.device_imei","value",["subexpr","@mut",[["get","vehicle.deviceImei",["loc",[null,[13,59],[13,77]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.vehicle.deviceImei",["loc",[null,[14,19],[14,44]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[14,56],[14,66]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[13,8],[14,91]]]],
          ["inline","validated-input",[],["name","vehicle.activation_code","value",["subexpr","@mut",[["get","vehicle.activationCode",["loc",[null,[15,63],[15,85]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.vehicle.activationCode",["loc",[null,[16,19],[16,48]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[16,60],[16,70]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[15,8],[16,95]]]],
          ["inline","validated-input",[],["name","vehicle.activation_days","value",["subexpr","@mut",[["get","vehicle.activationDays",["loc",[null,[17,62],[17,84]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.vehicle.activationDays",["loc",[null,[18,19],[18,48]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[18,60],[18,70]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[17,8],[18,95]]]],
          ["inline","validated-drop-down",[],["identifier","vehicle-sim-provider","name","vehicle.sim_provider_title","mandatory",false,"value",["subexpr","@mut",[["get","vehicle.simProvider",["loc",[null,[21,18],[21,37]]]]],[],[]],"items",["subexpr","@mut",[["get","providerList",["loc",[null,[21,44],[21,56]]]]],[],[]],"idField","id","textField","displayName","errors",["subexpr","@mut",[["get","errors.vehicle.simProvider",["loc",[null,[22,19],[22,45]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[22,57],[22,67]]]]],[],[]],"localizedValues",true],["loc",[null,[20,8],[22,90]]]],
          ["inline","validated-input",[],["name","vehicle.sim_iccid","value",["subexpr","@mut",[["get","vehicle.simIccid",["loc",[null,[23,57],[23,73]]]]],[],[]],"mandatory",false,"errors",["subexpr","@mut",[["get","errors.vehicle.simIccid",["loc",[null,[24,19],[24,42]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[24,54],[24,64]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[23,8],[24,89]]]],
          ["inline","validated-checkbox",[],["name","vehicle.sim_owned_by_distributor","checked",["subexpr","@mut",[["get","vehicle.distributorSim",["loc",[null,[26,77],[26,99]]]]],[],[]],"notes","vehicle.sim_owned_by_distributor_notes"],["loc",[null,[26,8],[27,60]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": [
            "wrong-type"
          ]
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/components/dialogs/add-vehicle.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","dialogs/modal-dialog",[],["visible",["subexpr","@mut",[["get","visible",["loc",[null,[1,32],[1,39]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[1,51],[1,61]]]]],[],[]],"localizedError",["subexpr","@mut",[["get","localizedError",["loc",[null,[1,77],[1,91]]]]],[],[]],"errorDetails",["subexpr","@mut",[["get","errorDetails",["loc",[null,[1,105],[1,117]]]]],[],[]],"saving",["subexpr","@mut",[["get","saving",["loc",[null,[2,11],[2,17]]]]],[],[]],"title","vehicle.add_title","confirm","confirm","show","show"],0,null,["loc",[null,[1,0],[29,25]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});