define('carlock-admin/models/c/vehicle/distributor', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		uuid: null,
		name: null,
		notificationEmail: null,
		supportEmail: null,
		load: function load(data) {
			this.set('uuid', data.uuid);
			this.set('name', data.name);
			this.set('notificationEmail', data.notification_email);
			this.set('supportEmail', data.support_email);
			return this;
		}
	});

});