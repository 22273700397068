define('carlock-admin/models/c/basic-purchase', ['exports', 'ember', 'carlock-admin/helpers/format-date'], function (exports, Ember, FormatDate) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        distributorName: null,
        date: null,
        dateFormatted: Ember['default'].computed('date', 'profile.instance.timeZoneId', FormatDate['default']),
        status: null,
        statusFormatted: Ember['default'].computed('status', function () {
            return 'purchase.status.' + this.get('status').toLowerCase();
        }),
        platform: null,
        platformFormatted: Ember['default'].computed('platform', function () {
            return 'purchase.platform.' + this.get('platform').toLowerCase();
        }),
        email: null,
        name: null,
        quantity: null,
        type: null,
        typeFormatted: Ember['default'].computed('type', function () {
            return 'purchase.type.' + this.get('type').toLowerCase();
        }),
        item: null,
        itemFormatted: Ember['default'].computed('item', function () {
            return 'offer.' + this.get('item');
        }),
        vatId: null,

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('distributorName', data.distributor_name);
            this.set('date', data.date);
            this.set('status', data.status);
            this.set('platform', data.platform);
            this.set('email', data.email);
            this.set('name', data.name);
            this.set('quantity', data.quantity);
            this.set('type', data.type);
            this.set('item', data.item);
            this.set('vatId', data.vat_id);
            return this;
        }
    });

});