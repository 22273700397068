define('carlock-admin/mixins/base-list', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Mixin.create(Base['default'], {
        reload: null,
        filter: null,
        sort: null,
        descending: 'false',
        addedSearchTag: false,
        patternMatching: true,
        observeChanges: Ember['default'].observer('reload', 'sort', 'descending', function () {
            if (!this.get('addedSearchTag')) {
                Ember['default'].run.debounce(this, this.reloadData, 500);
            }
            this.set('addedSearchTag', false);
        }),
        reloadData: function reloadData() {
            if (this.get('skipLoading')) {
                this.set('reachedInfinity', true);
                this.logger.trace('component:base-list: Skipping load');
                return;
            }

            this.set('model', Ember['default'].A());
            this.set('page', 0);
            this.set('totalCount', null);
            this.set('reachedInfinity', false);
            this.set('noData', false);
            this.set('errorCode', null);
            this.loadChunk();
        },
        model: null,
        page: 0,
        pageSize: 50,
        totalCount: null,
        reachedInfinity: false,
        noData: false,
        selection: null,
        skipLoading: false,
        paternMatching: false,

        loadChunk: function loadChunk() {
            this.logger.trace('component:base-list: Loading chunk data');
            var _this = this;
            var page = _this.get('page');
            var pageSize = _this.get('pageSize');

            var prepare = function prepare() {
                var role = _this.get('role');
                var filter = _this.get('filter');
                var sort = _this.get('sort');
                var descending = _this.get('descending');
                return _this.prepareLoadRequest(filter, sort, descending, page, pageSize);
            };

            var success = function success(response) {
                var list = _this.get('model');

                if (!list) {
                    list = Ember['default'].A();
                    _this.set('model', list);
                }

                _this.loadRequestSucceeded(response);

                if (response.total_count == null || (page + 1) * pageSize >= response.total_count) {
                    _this.set('reachedInfinity', true);
                    _this.logger.trace('component:base-list: Reached infinity');
                }

                if (response.total_count == null && list.length == 0 || response.total_count == 0) {
                    _this.set('noData', true);
                }

                _this.set('selection', null);
                _this.set('totalCount', response.total_count);
                _this.logger.trace('component:base-list: List updated');
            };

            this.load(prepare, success);
        },
        selectItem: function selectItem(item) {
            this.logger.trace('component:base-list: Selecting item');
            var selection = this.get('selection');

            if (selection !== item) {
                this.set('selection', item);

                if (selection) {
                    selection.set('selected', false);
                }
            }

            if (item) {
                item.set('selected', !item.get('selected'));
            }

            this.sendAction('select', item);
        },
        actions: {
            loadMore: function loadMore() {
                this.logger.trace('component:base-list: Infinity loading');

                if (this.get('reachedInfinity')) {
                    this.logger.trace('component:base-list: Reached infinity');
                    return;
                }

                if (this.get('loading')) {
                    this.logger.trace('component:base-list: Already loading');
                    return;
                }

                if (this.get('error')) {
                    this.logger.trace('component:base-list: Error occurred');
                    return;
                }

                if (this.get('skipLoading')) {
                    this.set('reachedInfinity', true);
                    this.logger.trace('component:base-list: Skipping load');
                    return;
                }

                var page = this.get('page');
                var total = this.get('totalCount');

                if (!total) {
                    this.set('page', 0);
                    this.loadChunk();
                } else {
                    if (!this.get('page') && this.get('page') !== 0) {
                        this.set('page', 0);
                    } else {
                        this.set('page', page + 1);
                    }

                    this.loadChunk();
                }
            },
            clearFilter: function clearFilter() {
                this.logger.trace('component:base-list: Clearing filter');
                this.set('filter', '');
            },
            sort: function sort(index) {
                this.logger.trace('component:base-list: Sorting: index=' + index);
                var current = this.get('sort');

                if (index === current) {
                    this.set('descending', !this.get('descending'));
                } else {
                    this.set('descending', false);
                    this.set('sort', index);
                }
            },
            select: function select(item) {
                this.selectItem(item);
            },
            setTag: function setTag(tag) {
                var f = this.get('filter') || "";
                if (!f.includes(tag + ':')) {
                    this.set('addedSearchTag', true);
                    this.set('filter', f + ' ' + tag + ':');
                }
            },
            search: function search() {
                this.reloadData();
            }
        }
    });

});