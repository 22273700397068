define('carlock-admin/models/c/purchase/billing-info', ['exports', 'carlock-admin/models/c/purchase/address'], function (exports, Address) {

	'use strict';

	exports['default'] = Address['default'].extend({
		email: null,
		load: function load(email, address) {
			this.set('email', email);
			this._super(address);
			return this;
		}
	});

});