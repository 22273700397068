define('carlock-admin/models/c/vehicle/health', ['exports', 'ember', 'carlock-admin/models/c/vehicle/health-statistic', 'carlock-admin/helpers/format-time'], function (exports, Ember, HealthStatisticModel, FormatTime) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        deviceDeadTime: null,
        deviceToServerDelay: null,
        gsmStrength: null,
        numberOfSatellites: null,
        pushNotificationDelay: null,
        timestamp: null,
        timestampFormatted: Ember['default'].computed('timestamp', 'profile.instance.timeZoneId', FormatTime['default']),

        load: function load(data) {
            this.set('deviceDeadTime', HealthStatisticModel['default'].create().load(data.device_dead_time, true));
            this.set('deviceToServerDelay', HealthStatisticModel['default'].create().load(data.device_to_server_delay, true));
            this.set('gsmStrength', HealthStatisticModel['default'].create().load(data.gsm_strength, false, 'gsmStrength'));
            this.set('numberOfSatellites', HealthStatisticModel['default'].create().load(data.number_of_satellites, false));
            this.set('pushNotificationDelay', HealthStatisticModel['default'].create().load(data.push_notification_delay, true));
            this.set('timestamp', data.timestamp);

            return this;
        }
    });

});