define('carlock-admin/models/c/purchase/purchase-offer', ['exports', 'ember', 'carlock-admin/models/c/purchase/offer'], function (exports, Ember, OfferModel) {

	'use strict';

	exports['default'] = OfferModel['default'].extend({
		type: null,
		physical: Ember['default'].computed('type', function () {
			return this.get('type') === 'PHYSICAL';
		}),
		internal: null,
		internalFormatted: Ember['default'].computed('internal', function () {
			return this.get('internal') ? 'misc.yes' : 'misc.no';
		}),
		load: function load(data) {
			this._super(data);
			this.set('type', data.type);
			this.set('internal', data.internal);
			return this;
		}
	});

});