define('carlock-admin/components/dialogs/confirm-purchase-refund', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/config/environment'], function (exports, Ember, Base, Config) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        purchaseUuid: null,
        actions: {
            confirm: function confirm() {
                this.logger.trace('controller:confirm-purchase-refund: Refunding');
                var _this = this;

                var prepare = function prepare() {
                    var uuid = _this.get('purchaseUuid');
                    var data = {
                        'date': _this.get('date')
                    };
                    return _this.carlock.refundPurchase(uuid, data);
                };

                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});