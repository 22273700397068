define('carlock-admin/controllers/c/purchases/reports/fb-export', ['exports', 'carlock-admin/mixins/base'], function (exports, Base) {

    'use strict';

    exports['default'] = Ember.Controller.extend(Base['default'], {
        actions: {
            generateFbExport: function generateFbExport() {
                var _this = this;

                var prepare = function prepare() {
                    return _this.carlock.getFbExport();
                };
                this.load(prepare, function () {});
            }
        }
    });

});