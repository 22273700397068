define('carlock-admin/controllers/c/devices/bulk', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/vehicle/subscription-tier', 'carlock-admin/models/c/basic-device'], function (exports, Ember, Base, SubscriptionTier, BasicDeviceModel) {

    'use strict';

    var filterFields = ['emails', 'imeis', 'serials', 'phone_numbers', 'iccid', 'paypal_emails'];

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        devices: null,
        page: 0,
        pageSize: 100,
        emails: null,
        imeis: null,
        serials: null,
        phone_numbers: null,
        iccid: null,
        paypal_emails: null,
        reachedInfinity: true,
        showFilters: true,

        anyFilterSet: Ember['default'].computed.apply(Ember['default'], filterFields.concat([function () {
            return this.get('emails') || this.get('imeis') || this.get('serials') || this.get('phone_numbers') || this.get('iccid') || this.get('paypal_emails');
        }])),

        onFiltersChange: Ember['default'].observer.apply(Ember['default'], filterFields.concat([function () {
            this.set('page', 0);
            this.set('devices', null);
            this.set('reachedInfinity', true);

            if (this.get('paypal_emails')) {
                this.set('showFilters', false);
            } else {
                this.set('showFilters', true);
            }
        }])),

        clearFilters: function clearFilters() {
            this.set('emails', null);
            this.set('imeis', null);
            this.set('serials', null);
            this.set('phone_numbers', null);
            this.set('iccid', null);
            this.set('paypal_emails', null);
            this.set('reachedInfinity', true);
        },

        loadMore: function loadMore() {
            if (this.get('reachedInfinity')) {
                return;
            }

            this.search();
        },

        search: function search() {
            var _this = this;

            this.logger.trace('controller: Bulk searching devices');

            if (!this.get('anyFilterSet')) {
                return;
            }

            var request = {};

            filterFields.forEach(function (field) {
                if (_this.get(field)) {
                    request[field] = _this.get(field).split(',');
                }
            });

            var success = function success(data) {
                _this.set('page', (_this.get('page') || 0) + 1);
                var devices = _this.get('devices') || Ember['default'].A();

                if (data) {
                    data.devices.forEach(function (device) {
                        return devices.pushObject(BasicDeviceModel['default'].create().load(device));
                    });

                    _this.set('devices', devices);
                    _this.set('reachedInfinity', data.devices.length < data.total_count);
                }
            };

            var prepare = function prepare() {
                return _this.carlock.searchDevicesInBulk(request, _this.get('page'), _this.get('pageSize'));
            };
            return this.save(prepare, success);
        },

        exportItems: function exportItems(csv) {
            this.logger.trace('controller:device-list: Exporting items ' + csv);
            this.set('exporting', true);
            this['export'].exportDevices(this.get('devices'), csv);
            this.set('exporting', false);
        },

        actions: {
            clearFilters: function clearFilters() {
                this.clearFilters();
            },

            search: function search() {
                this.search();
            },

            loadMore: function loadMore() {
                this.loadMore();
            },

            exportCsv: function exportCsv() {
                this.exportItems(true);
            },

            exportXls: function exportXls() {
                this.exportItems(false);
            },

            select: function select(device) {
                this.logger.trace('controller: Redirecting to vehicle');
                this.transitionToRoute('c.vehicle', {
                    queryParams: {
                        uuid: device.get('vehicleUuid')
                    }
                });
            }
        }
    });

});