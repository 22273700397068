define('carlock-admin/models/c/basic-gift-code', ['exports', 'ember', 'carlock-admin/helpers/format-time'], function (exports, Ember, FormatTime) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        code: null,
        codeFormatted: Ember['default'].computed('code', function () {
            var code = this.get('code');

            if (code) {
                return code.replace(/(.{5})(.{5})(.{5})/, "$1-$2-$3");
            }

            return null;
        }),
        offer: null,
        offerFormatted: Ember['default'].computed('offer', function () {
            return 'offer.' + this.get('offer').toLowerCase();
        }),
        date: null,
        dateFormatted: Ember['default'].computed('date', 'profile.instance.timeZoneId', FormatTime['default']),
        claimDate: null,
        claimDateFormatted: Ember['default'].computed('claimDate', 'profile.instance.timeZoneId', FormatTime['default']),
        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('code', data.code);
            this.set('offer', data.offer);
            this.set('date', data.date);
            this.set('claimDate', data.claim_date);
            return this;
        }
    });

});