define('carlock-admin/components/dialogs/set-vehicle-device', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        vehicleUuid: null,
        device: null,
        validations: {
            'device': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                this.set('device', null);
            },
            confirm: function confirm() {
                this.logger.trace('component:set-vehicle-device: Setting device');
                var _this = this;

                var prepare = function prepare() {
                    var deviceUuid = _this.get('device.device.uuid');
                    var vehicleUuid = _this.get('vehicleUuid');
                    var data = {
                        'device_uuid': deviceUuid
                    };
                    return _this.carlock.setVehicleDevice(vehicleUuid, data);
                };

                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});