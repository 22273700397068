define('carlock-admin/models/c/purchase/shipped-item', ['exports', 'ember', 'carlock-admin/helpers/format-time'], function (exports, Ember, FormatTime) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
		uuid: null,
		coupon: null,
		platformItemId: null,
		shipmentDate: null,
		shipmentDateFormatted: Ember['default'].computed('shipmentDate', 'profile.instance.timeZoneId', FormatTime['default']),
		trackingNumber: null,
		shipmentCompany: null,

		load: function load(data) {
			this.set('uuid', data.uuid);
			this.set('quantity', data.quantity);
			this.set('platformItemId', data.platform_item_id);
			this.set('shipmentDate', data.shipment_date);
			this.set('trackingNumber', data.tracking_number);
			this.set('shipmentCompany', data.shipment_company);

			return this;
		}
	});

});