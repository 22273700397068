define('carlock-admin/models/c/vehicle/account', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
				uuid: null,
				sms: null,
				calls: null,

				load: function load(data) {
							this.set('uuid', data.uuid);
							this.set('sms', parseInt(data.account_subscription.sms));
							this.set('calls', parseInt(data.account_subscription.calls));

							return this;
				}
	});

});