define('carlock-admin/models/c/basic-user', ['exports', 'ember', 'carlock-admin/models/country'], function (exports, Ember, CountryModel) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        email: null,
        name: null,
        country: null,
        countryIsoCode: null,

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('email', data.email);
            this.set('name', data.name);
            this.set('country', CountryModel['default'].FIXTURES.findBy('isoCode', data.country_iso_code));
            this.set('countryIsoCode', data.country_iso_code);
            return this;
        }
    });

});