define('carlock-admin/components/lists/gift-code-list', ['exports', 'ember', 'carlock-admin/mixins/base-list', 'carlock-admin/models/c/basic-gift-code'], function (exports, Ember, BaseList, BasicGiftCodeModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(BaseList['default'], {
        prepareLoadRequest: function prepareLoadRequest(filter, sort, descending, page, pageSize) {
            return this.carlock.getGiftCodes(filter, sort, descending, page, pageSize);
        },
        loadRequestSucceeded: function loadRequestSucceeded(response) {
            var list = this.get('model');

            for (var i = 0; i < response.gift_codes.length; i++) {
                list.pushObject(BasicGiftCodeModel['default'].create().load(response.gift_codes[i]));
            }
        },
        loadAndExportItems: function loadAndExportItems(csv) {
            this.logger.trace('controller:gift-codes: Loading items ' + csv);
            var _this = this;
            var page = 0;
            var pageSize = 2000; // Max items
            _this.set('exporting', true);

            var prepare = function prepare() {
                var role = _this.get('role');
                var filter = _this.get('filter');
                var sort = _this.get('sort');
                var descending = _this.get('descending');
                return _this.carlock.getGiftCodes(filter, sort, descending, page, pageSize);
            };

            var success = function success(response) {
                var list = Ember['default'].A();

                for (var i = 0; i < response.gift_codes.length; i++) {
                    list.pushObject(BasicGiftCodeModel['default'].create().load(response.gift_codes[i]));
                }

                _this['export'].exportGiftCodes(list, csv);
                _this.set('exporting', false);
            };

            var error = function error() {
                _this.set('exporting', false);
            };

            this.load(prepare, success, error);
        },
        actions: {
            exportCsv: function exportCsv() {
                this.loadAndExportItems(true);
            },
            exportXls: function exportXls() {
                this.loadAndExportItems(false);
            },
            generate: function generate() {
                this.toggleProperty('showGenerateGiftCodesDialog');
            },
            search: function search() {
                this.reloadData();
            }
        }
    });

});