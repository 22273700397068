define('carlock-admin/models/c/basic-device', ['exports', 'ember', 'carlock-admin/helpers/format-date', 'carlock-admin/helpers/format-time', 'carlock-admin/models/c/vehicle/user', 'carlock-admin/models/c/vehicle/device'], function (exports, Ember, FormatDate, FormatTime, UserModel, DeviceModel) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        vehicleUuid: null,
        distributorName: null,
        device: null,
        user: null,
        activationDate: null,
        activationDateFormatted: Ember['default'].computed('activationDate', 'profile.instance.timeZoneId', FormatDate['default']),
        expirationDate: null,
        expirationDateFormatted: Ember['default'].computed('expirationDate', 'profile.instance.timeZoneId', FormatDate['default']),
        lastSeen: null,
        lastSeenFormatted: Ember['default'].computed('lastSeen', 'profile.instance.timeZoneId', FormatTime['default']),
        vehicleName: null,
        distributorCountry: null,

        load: function load(data) {
            this.set('vehicleUuid', data.vehicle_uuid);
            this.set('distributorName', data.distributor_name);
            this.set('device', DeviceModel['default'].create().load(data.device));
            this.set('user', UserModel['default'].create().load(data.user));
            this.set('activationDate', data.activation_date);
            this.set('expirationDate', data.expiration_date);
            this.set('lastSeen', data.last_seen);
            this.set('vehicleName', data.vehicle_name);

            var distributor = undefined;

            switch (data.distributor_name) {
                case "CarLock":
                    distributor = 'EU';
                    break;
                case "CarLock USA":
                    distributor = 'US';
                    break;
                case "CarLock Italy":
                    distributor = 'IT';
                    break;
                case "CarLock France":
                    distributor = 'FR';
                    break;
                case "CarLock Saudi Arabia":
                    distributor = 'AR';
                    break;
                case "CarLock South Africa":
                    distributor = 'SA';
                    break;

                case "CarLock Germany":
                    distributor = 'DE';
                    break;

                case "CarLock Australia":
                    distributor = 'AU';
                    break;

                case "CarLock Ukraine":
                    distributor = 'UK';
                    break;

                case "CarLock Promo":
                    distributor = '$$';
                    break;
                default:
                    distributor = 'N/A';
                    break;

            }

            this.set('distributorCountry', distributor + '/' + this.get("user.countryIsoCode"));

            return this;
        }
    });

});