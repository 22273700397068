define('carlock-admin/components/lists/promo-list', ['exports', 'ember', 'carlock-admin/mixins/base-list', 'carlock-admin/models/c/vehicle/promo'], function (exports, Ember, BaseList, PromoModel) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend(BaseList['default'], {
				selectedDate: moment().utc().valueOf(),
				prepareLoadRequest: function prepareLoadRequest() {
							return this.carlock.getPromo();
				},
				loadRequestSucceeded: function loadRequestSucceeded(response) {
							var _this = this;

							this.set('reachedInfinity', true); //We load all data at the same time
							var list = this.get('model');
							var items = response.filter(function (i) {
										var expirationDate = moment(i.subscription.expiration_date).utc();
										var dateBeforeExtension = moment(expirationDate).utc();
										var nextExtensionDate = moment(expirationDate).utc();

										if (expirationDate.isAfter(moment())) {
													dateBeforeExtension.subtract(6, 'month');
										}
										var from = moment(_this.selectedDate).utc().startOf('month');
										var to = moment(_this.selectedDate).utc().endOf('month');

										//isBetween is buggy
										return dateBeforeExtension.isSameOrAfter(from) && dateBeforeExtension.isSameOrBefore(to) || nextExtensionDate.isSameOrAfter(from) && nextExtensionDate.isSameOrBefore(to);
							});
							list.pushObject(PromoModel['default'].create().load(items));
				},
				actions: {
							generate: function generate() {
										this.logger.trace('controller:promo-report: Loading data');
										this.reloadData();
							},
							sort: function sort(col) {
										//Not needed atm
							},
							selectedDateChanged: function selectedDateChanged(date) {
										this.set('selectedDate', date);
							}
				}
	});

});