define('carlock-admin/models/c/user/login-record', ['exports', 'ember', 'carlock-admin/helpers/format-time'], function (exports, Ember, FormatTime) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        uuid: null,
        timestamp: null,
        timestampFormatted: Ember['default'].computed('timestamp', 'profile.instance.timeZoneId', FormatTime['default']),
        clientType: null,
        clientVersion: null,
        clientLocale: null,
        clientOsVersion: null,
        clientDeviceModel: null,
        notificationsTargetId: null,

        load: function load(data) {
            this.set('uuid', data.uuid);
            this.set('timestamp', data.timestamp);
            this.set('clientType', data.client_type);
            this.set('clientVersion', data.client_version);
            this.set('clientLocale', data.client_locale);
            this.set('clientOsVersion', data.client_os_version);
            this.set('clientDeviceModel', data.client_device_model);
            this.set('notificationsTargetId', data.notifications_target_id);

            return this;
        }
    });

});