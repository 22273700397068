define('carlock-admin/models/c/profile', ['exports', 'ember', 'carlock-admin/models/country', 'carlock-admin/models/time-zone'], function (exports, Ember, CountryModel, TimeZoneModel) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        email: null,
        name: null,
        firstNameFormatted: Ember['default'].computed('name', function () {
            var name = this.get('name');

            if (name) {
                var space = name.indexOf(' ');

                if (space > 1) {
                    return name.substring(0, space);
                }

                return name;
            }

            return name;
        }),
        country: null,
        timeZoneId: null,
        enabled2Fa: null,
        load: function load(data) {
            this.set('email', data.email);
            this.set('name', data.name);
            this.set('country', CountryModel['default'].FIXTURES.findBy('isoCode', data.country_iso_code));
            this.set('timeZone', TimeZoneModel['default'].FIXTURES.findBy('id', data.time_zone_id));
            this.set('timeZoneId', data.time_zone_id);
            this.set('enabled2Fa', data.enabled2_fa);

            return this;
        }
    });

});