define('carlock-admin/models/c/gift-code/gift-code-offer', ['exports', 'ember', 'carlock-admin/helpers/format-date'], function (exports, Ember, FormatDate) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
				uuid: null,
				descriptionKey: null,
				descriptionKeyFormatted: Ember['default'].computed('descriptionKey', function () {
							return 'offer.' + this.get('descriptionKey').toLowerCase();
				}),
				subscriptionDays: null,
				smsNotifications: null,
				callNotifications: null,
				start: null,
				startFormatted: Ember['default'].computed('start', 'profile.instance.timeZoneId', FormatDate['default']),
				end: null,
				endFormatted: Ember['default'].computed('end', 'profile.instance.timeZoneId', FormatDate['default']),
				isMultiUse: null,
				isMultiUseFormatted: Ember['default'].computed('isMultiUse', function () {
							return this.get('isMultiUse') ? 'misc.yes' : 'misc.no';
				}),
				load: function load(data) {
							this.set('uuid', data.uuid);
							this.set('descriptionKey', data.description_key);
							this.set('subscriptionDays', data.subscription_days);
							this.set('smsNotifications', data.sms_notifications);
							this.set('callNotifications', data.call_notifications);
							this.set('start', data.start);
							this.set('end', data.end);
							this.set('isMultiUse', data.is_multi_use);
							return this;
				}
	});

});