define('carlock-admin/components/dialogs/set-backup-notifications', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        account: null,
        deltaSms: 0,
        deltaCalls: 0,
        newSms: Ember['default'].computed('account.sms', 'deltaSms', function () {
            return this.get('account.sms') + parseInt(this.get('deltaSms'));
        }),
        newCalls: Ember['default'].computed('account.calls', 'deltaCalls', function () {
            return this.get('account.calls') + parseInt(this.get('deltaCalls'));
        }),

        validations: {
            'deltaSms': {
                numericality: {
                    onlyInteger: true
                }
            },
            'deltaCalls': {
                numericality: {
                    onlyInteger: true
                }
            }
        },

        actions: {
            show: function show() {
                this.set('deltaSms', 0);
                this.set('deltaCalls', 0);
            },
            confirm: function confirm() {
                var _this = this;

                this.logger.trace('component:set-notifications: Saving to server');

                var prepare = function prepare() {
                    var data = {};
                    if (_this.get('deltaSms')) {
                        data.sms = parseInt(_this.get('deltaSms'));
                    }

                    if (_this.get('deltaCalls')) {
                        data.calls = parseInt(_this.get('deltaCalls'));
                    }
                    console.log(data);

                    if (Object.keys(data).length !== 0) {
                        return _this.carlock.addBackupNotifications(_this.get('account.uuid'), data);
                    }
                };

                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});