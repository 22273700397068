define('carlock-admin/templates/components/dialogs/edit-vehicle-sim', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "carlock-admin/templates/components/dialogs/edit-vehicle-sim.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(element0,1,1);
          morphs[1] = dom.createMorphAt(element0,3,3);
          morphs[2] = dom.createMorphAt(element0,5,5);
          morphs[3] = dom.createMorphAt(element0,7,7);
          morphs[4] = dom.createMorphAt(element0,9,9);
          morphs[5] = dom.createMorphAt(element0,11,11);
          morphs[6] = dom.createMorphAt(element0,13,13);
          return morphs;
        },
        statements: [
          ["inline","validated-drop-down",[],["identifier","sim-provider","name","vehicle.sim_provider_title","value",["subexpr","@mut",[["get","sim.provider",["loc",[null,[5,96],[5,108]]]]],[],[]],"items",["subexpr","@mut",[["get","providerList",["loc",[null,[6,18],[6,30]]]]],[],[]],"idField","id","textField","displayName","mandatory",true,"errors",["subexpr","@mut",[["get","errors.sim.provider",["loc",[null,[6,90],[6,109]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[7,23],[7,33]]]]],[],[]],"localizedValues",true],["loc",[null,[5,8],[7,56]]]],
          ["inline","validated-input",[],["name","vehicle.sim_iccid","value",["subexpr","@mut",[["get","sim.iccid",["loc",[null,[9,57],[9,66]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.sim.iccid",["loc",[null,[10,19],[10,35]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[10,47],[10,57]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[9,8],[10,82]]]],
          ["inline","validated-input",[],["name","vehicle.sim_imsi","value",["subexpr","@mut",[["get","sim.imsi",["loc",[null,[11,56],[11,64]]]]],[],[]],"errors",["subexpr","@mut",[["get","errors.sim.imsi",["loc",[null,[12,19],[12,34]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[12,46],[12,56]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[11,8],[12,81]]]],
          ["inline","validated-input",[],["name","vehicle.sim_msisdn","value",["subexpr","@mut",[["get","sim.msisdn",["loc",[null,[13,58],[13,68]]]]],[],[]],"mandatory",true,"errors",["subexpr","@mut",[["get","errors.sim.msisdn",["loc",[null,[14,19],[14,36]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[14,48],[14,58]]]]],[],[]],"enterPressed","confirm"],["loc",[null,[13,8],[14,83]]]],
          ["inline","validated-drop-down",[],["identifier","sim-service-profile","name","vehicle.sim_service_profile_title","value",["subexpr","@mut",[["get","sim.serviceProfile",["loc",[null,[16,110],[16,128]]]]],[],[]],"items",["subexpr","@mut",[["get","serviceProfileList",["loc",[null,[17,18],[17,36]]]]],[],[]],"idField","id","textField","displayName","errors",["subexpr","@mut",[["get","errors.sim.serviceProfile",["loc",[null,[17,81],[17,106]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[18,23],[18,33]]]]],[],[]],"localizedValues",true],["loc",[null,[16,8],[18,56]]]],
          ["inline","validated-drop-down",[],["identifier","sim-status","name","vehicle.sim_status_title","value",["subexpr","@mut",[["get","sim.status",["loc",[null,[20,92],[20,102]]]]],[],[]],"items",["subexpr","@mut",[["get","statusList",["loc",[null,[21,18],[21,28]]]]],[],[]],"idField","id","textField","displayName","mandatory",true,"errors",["subexpr","@mut",[["get","errors.sim.status",["loc",[null,[21,88],[21,105]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[22,23],[22,33]]]]],[],[]],"localizedValues",true],["loc",[null,[20,8],[22,56]]]],
          ["inline","validated-checkbox",[],["name","vehicle.activate_new_sim","checked",["subexpr","@mut",[["get","newSim",["loc",[null,[24,69],[24,75]]]]],[],[]]],["loc",[null,[24,8],[24,77]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": [
            "wrong-type"
          ]
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/components/dialogs/edit-vehicle-sim.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","dialogs/modal-dialog",[],["visible",["subexpr","@mut",[["get","visible",["loc",[null,[1,32],[1,39]]]]],[],[]],"showErrors",["subexpr","@mut",[["get","showErrors",["loc",[null,[1,51],[1,61]]]]],[],[]],"localizedError",["subexpr","@mut",[["get","localizedError",["loc",[null,[1,77],[1,91]]]]],[],[]],"errorDetails",["subexpr","@mut",[["get","errorDetails",["loc",[null,[1,105],[1,117]]]]],[],[]],"saving",["subexpr","@mut",[["get","saving",["loc",[null,[2,11],[2,17]]]]],[],[]],"title",["subexpr","if",[["get","adding",["loc",[null,[2,28],[2,34]]]],"vehicle.add_sim_title","vehicle.edit_sim_title"],[],["loc",[null,[2,24],[2,84]]]],"confirm","confirm","show","show","close","close"],0,null,["loc",[null,[1,0],[26,25]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});