define('carlock-admin/routes/c/vehicle/debug', ['exports', 'carlock-admin/routes/authenticated', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Base, AuthenticatedRouteMixin) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        model: function model(params) {
            return {
                uuid: params.vehicle_uuid
            };
        }
    });

});