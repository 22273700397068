define('carlock-admin/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'carlock-admin/config/environment'], function (exports, Ember, Resolver, LoadInitializers, Config) {

    'use strict';

    Ember['default'].MODEL_FACTORY_INJECTIONS = true;

    var App = Ember['default'].Application.extend({
        modulePrefix: Config['default'].modulePrefix,
        podModulePrefix: Config['default'].podModulePrefix,
        Resolver: Resolver['default']
    });

    new LoadInitializers['default'](App, Config['default'].modulePrefix);

    exports['default'] = App;

});