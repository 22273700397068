define('carlock-admin/routes/base', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Route.extend({
        sessionData: Ember['default'].computed.alias('session.session.content.authenticated'),
        setupController: function setupController(controller, model) {
            this.logger.trace('route:base: Setting up controller');

            // We need to check for errors
            var error = null;

            if (model) {
                if (model.error) {
                    this.logger.trace('route:base: Found error');
                    error = model.error;
                    model = null;
                } else if (this.transformModel) {
                    this.logger.trace('route:base: Transforming model');
                    model = this.transformModel(model);
                    this.logger.trace('route:base: Transformed model');
                }
            }

            // Setup base controller
            this._super(controller, model);

            // Clean previous state
            controller.set('saving', false);
            controller.set('loading', false);
            controller.set('showErrors', false);
            controller.set('frozen', false);

            // Show error
            if (error) {
                this.logger.trace('route:base: Displaying error');
                var translated = this.i18n.t('error.' + error);
                controller.set('applicationController.error', translated);
            }

            // Load interface
            if (controller.loadInterface) {
                Ember['default'].run.scheduleOnce('afterRender', this, function () {
                    controller.loadInterface();
                });
            }

            // Load data
            if (controller.loadData) {
                controller.loadData();
            }

            this.logger.trace('route:base: Setup complete');
        },
        onModelError: function onModelError(errorCode) {
            return { error: errorCode };
        }
    });

});