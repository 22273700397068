define('carlock-admin/models/c/purchase/purchase-item', ['exports', 'ember', 'carlock-admin/models/c/purchase/purchase-offer', 'carlock-admin/models/c/purchase/coupon', 'carlock-admin/models/c/purchase/subscription-plan'], function (exports, Ember, PurchaseOfferModel, CouponModel, SubscriptionPlanModel) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
					uuid: null,
					offer: null,
					plan: null,
					coupon: null,
					quantity: null,
					price: null,
					tax: null,
					taxPercentage: null,
					distributorShare: null,
					accountUuid: null,
					vehicleUuid: null,
					load: function load(data) {
									this.set('uuid', data.uuid);

									if (data.offer) {
													this.set('offer', PurchaseOfferModel['default'].create().load(data.offer));
									}

									if (data.plan) {
													this.set('plan', SubscriptionPlanModel['default'].create().load(data.plan));
									}

									if (data.coupon) {
													this.set('coupon', CouponModel['default'].create().load(data.coupon));
									}

									this.set('quantity', data.quantity);
									this.set('price', data.price);
									this.set('tax', data.tax);
									this.set('taxPercentage', data.tax_percentage);
									this.set('distributorShare', data.distributor_share);
									this.set('accountUuid', data.account_uuid);
									this.set('vehicleUuid', data.vehicle_uuid);
									return this;
					}
	});

});