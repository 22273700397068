define('carlock-admin/components/month-picker', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        selectedDate: null,
        months: Ember['default'].computed(function () {
            var months = [];
            for (var i = 0; i < 12; i++) {
                months.push({ id: i, name: this.i18n.t('misc.month_' + i).toString() });
            }
            return months;
        }),
        years: Ember['default'].computed('selectedDate', function () {
            var year = moment().utc().year();
            var years = [];

            var j = 0;
            for (var i = year - 10; i <= year + 10; i++) {
                years.push({ id: j, name: i });
                j++;
            }

            return years;
        }),
        month: null,
        year: null,
        monthInstanceObserver: Ember['default'].observer('monthInstance', function () {
            this.set('month', this.get('monthInstance.id'));
        }),
        yearInstanceObserver: Ember['default'].observer('yearInstance', function () {
            this.set('year', this.get('yearInstance.name'));
        }),
        monthObserver: Ember['default'].observer('month', function () {
            var selectedDate = moment(this.get('selectedDate')).utc();
            selectedDate.month(this.get('month'));
            selectedDate.year(this.get('yearInstance').name);
            this.set('selectedDate', selectedDate.valueOf());
        }),
        yearObserver: Ember['default'].observer('year', function () {
            var selectedDate = moment(this.get('selectedDate')).utc();
            selectedDate.year(this.get('yearInstance').name);
            this.set('selectedDate', selectedDate.valueOf());
        }),
        selectedDateObserver: Ember['default'].observer('selectedDate', function () {
            this.sendAction('selectedDateChanged', this.get('selectedDate'));
        }),
        init: function init() {
            this._super.apply(this, arguments);
            this.set('selectedDate', moment().utc().valueOf());
            this.set('yearInstance', this.get('years')[10]);
            this.set('year', this.get('yearInstance.id'));
            this.set('monthInstance', this.get('months')[moment().utc().month()]);
            this.set('month', this.get('monthInstance.id'));
        },

        actions: {
            previousMonth: function previousMonth() {
                var selectedMonth = this.get('monthInstance');
                if (selectedMonth.id === 0) {
                    this.set('monthInstance', this.get('months')[11]);
                    if (this.get('yearInstance').id > 0) {
                        this.set('yearInstance', this.get('years')[this.get('yearInstance').id - 1]);
                    }
                } else {
                    this.set('monthInstance', this.get('months')[selectedMonth.id - 1]);
                }
            },
            nextMonth: function nextMonth() {
                var selectedMonth = this.get('monthInstance');
                if (selectedMonth.id === 11) {
                    this.set('monthInstance', this.get('months')[0]);
                    if (this.get('yearInstance').id < this.get('years').length) {
                        this.set('yearInstance', this.get('years')[this.get('yearInstance').id + 1]);
                    }
                } else {
                    this.set('monthInstance', this.get('months')[selectedMonth.id + 1]);
                }
            }
        }
    });

});