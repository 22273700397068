define('carlock-admin/models/ip-info', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        ipAddress: null,
        countryIsoCode: null,
        state: null,
        latitude: null,
        longitude: null,
        timeZoneOffset: null,
        timeZoneId: null,
        load: function load(data) {
            this.set('ipAddress', data.ip_address);
            this.set('countryIsoCode', data.country_iso_code);
            this.set('state', data.state);
            this.set('latitude', data.latitude);
            this.set('longitude', data.longitude);
            this.set('timeZoneOffset', data.time_zone_offset);
            this.set('timeZoneId', data.time_zone_id);
            return this;
        }
    });

});