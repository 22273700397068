define('carlock-admin/components/dialogs/generate-coupon', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/coupon/coupon'], function (exports, Ember, Base, CouponModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        command: null,
        offer: null,
        giftCodes: null,
        generated: null,
        footerVisible: true,
        validations: {
            'count': {
                presence: true,
                numericality: {
                    onlyInteger: true,
                    greaterThan: 0,
                    lessThan: 101
                }
            },
            'offer': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                this.set('count', 10);
            },
            close: function close() {
                this.set('visible', false);
            },
            confirm: function confirm() {
                this.logger.trace('component:generate-gift-codes: Generating');
                var _this = this;

                var prepare = function prepare() {
                    var data = {
                        'count': _this.get('count'),
                        'offer_uuid': _this.get('offer.uuid')
                    };
                    return _this.carlock.generateGiftCodes(data);
                };

                var success = function success(response) {
                    var list = Ember['default'].A();

                    for (var i = 0; i < response.length; i++) {
                        list.pushObject(GiftCodeModel.create().load(response[i]));
                    }

                    _this.set('giftCodes', list);
                    _this.set('footerVisible', false);
                    _this.set('generated', true);
                };

                this.save(prepare, success);
            },
            exportCsv: function exportCsv() {
                this['export'].exportGiftCodes(this.get('giftCodes'), true);
            },
            exportXls: function exportXls() {
                this['export'].exportGiftCodes(this.get('giftCodes'), false);
            }
        }
    });

});