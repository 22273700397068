define('carlock-admin/controllers/c/devices/devices', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/helpers/computed-parser'], function (exports, Ember, Base, ComputedParser) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        queryParams: ['filter', 'sort', 'descending'],
        filter: null,
        filterString: Ember['default'].computed('filter', ComputedParser['default']),
        sort: '2',
        sortInt: Ember['default'].computed('sort', ComputedParser['default']),
        descending: 'true',
        descendingBool: Ember['default'].computed('descending', ComputedParser['default']),
        actions: {
            viewVehicle: function viewVehicle(device) {
                this.logger.trace('controller: Redirecting to vehicle');
                this.transitionToRoute('c.vehicle', {
                    queryParams: {
                        uuid: device.get('vehicleUuid')
                    }
                });
            }
        }
    });

});