define('carlock-admin/routes/c/purchases/shipment', ['exports', 'ember', 'carlock-admin/routes/authenticated', 'carlock-admin/models/c/purchase/shipment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, Base, ShipmentModel, AuthenticatedRouteMixin) {

    'use strict';

    exports['default'] = Base['default'].extend(AuthenticatedRouteMixin['default'], {
        model: function model(params) {
            return this.carlock.getShipment(params.uuid).then(null, this.onModelError);
        },
        transformModel: function transformModel(model) {
            return ShipmentModel['default'].create().load(model);
        }
    });

});