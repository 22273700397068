define('carlock-admin/components/dialogs/pick-distributor', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        distributor: null,
        validations: {
            'distributor': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                this.set('distributor', null);
            },
            confirm: function confirm() {
                this.sendAction('select', this.get('distributor'));
                this.toggleProperty('visible');
            }
        }
    });

});