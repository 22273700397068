define('carlock-admin/components/localized-coupon', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        tagName: 'span',
        coupon: null,
        currency: null,
        fixed: Ember['default'].computed('coupon.type', function () {
            console.log(this.get('coupon.type'));
            return this.get('coupon.type') === 'FIXED';
        })
    });

});