define('carlock-admin/controllers/c/purchases/business', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        distributor: null,

        actions: {
            load: function load() {
                var _this = this;

                this.logger.trace('controller:business: Getting VAT IDs');

                var prepare = function prepare() {
                    return _this.carlock.getActiveSubscriptionsVatIds(_this.get('distributor.uuid'));
                };

                var success = function success(result) {
                    _this.set('vatIds', result.vat_ids);
                };

                this.load(prepare, success);
            },
            clearFilter: function clearFilter() {
                this.set('distributor', null);
            },
            togglePickDistributorDialog: function togglePickDistributorDialog() {
                this.toggleProperty('showPickDistributorDialog');
            },
            selectDistributor: function selectDistributor(distributor) {
                this.set('distributor', distributor);
            }
        }
    });

});