define('carlock-admin/components/dialogs/regenerate-purchase-credit-note', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/config/environment'], function (exports, Ember, Base, Config) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        purchaseUuid: null,
        actions: {
            confirm: function confirm() {
                this.logger.trace('component:regenerate-purchase-credit-note: Regenerating');
                var _this = this;

                var prepare = function prepare() {
                    var uuid = _this.get('purchaseUuid');
                    return _this.carlock.regeneratePurchaseCreditNote(uuid, {});
                };

                var success = function success(data) {
                    _this.logger.trace('component:regenerate-purchase-credit-note: Downloading');
                    window.location = Config['default'].adminURL + data.relative_path;
                    _this.set('visible', false);
                };

                this.save(prepare, success);
            }
        }
    });

});