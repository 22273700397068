define('carlock-admin/components/dialogs/confirm-purchase-payment', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(Base['default'], {
        purchaseUuid: null,
        date: null,
        validations: {
            'date': {
                presence: true
            }
        },
        actions: {
            show: function show() {
                this.set('date', new Date().getTime());
            },
            confirm: function confirm() {
                this.logger.trace('component:confirm-purchase-payment: Confirming payment');
                var _this = this;

                var prepare = function prepare() {
                    var uuid = _this.get('purchaseUuid');
                    var data = {
                        'date': _this.get('date')
                    };
                    return _this.carlock.confirmPurchasePayment(uuid, data);
                };

                var success = function success() {
                    _this.set('visible', false);
                    _this.sendAction('success');
                };

                this.save(prepare, success);
            }
        }
    });

});