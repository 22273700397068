define('carlock-admin/components/lists/gift-code-offer-list', ['exports', 'ember', 'carlock-admin/mixins/base-list', 'carlock-admin/models/c/gift-code/gift-code-offer'], function (exports, Ember, BaseList, GiftCodeOfferModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(BaseList['default'], {
        prepareLoadRequest: function prepareLoadRequest() {
            return this.carlock.getGiftCodeOffers();
        },
        loadRequestSucceeded: function loadRequestSucceeded(response) {
            var list = this.get('model');

            for (var i = 0; i < response.length; i++) {
                list.pushObject(GiftCodeOfferModel['default'].create().load(response[i]));
            }
        }
    });

});