define('carlock-admin/models/c/purchase/report/distributor-subscription-report', ['exports', 'ember', 'carlock-admin/models/c/purchase/report/distributor-subscription-group'], function (exports, Ember, DistributorSubscriptionGroupModel) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
							currency: null,
							base: null,
							discount: null,
							tax: null,
							transactionFee: null,
							distributorShareBeforeTransactionFee: null,
							distributorTransactionFee: null,
							distributorShareAfterTransactionFee: null,
							toProtectus: null,
							groups: null,
							count: Ember['default'].computed('groups', function () {
													return this.get('groups').length();
							}),
							load: function load(data) {
													this.set('currency', data.currency);
													this.set('base', data.base);
													this.set('discount', data.discount);
													this.set('tax', data.tax);
													this.set('transactionFee', data.transaction_fee);
													this.set('distributorShareBeforeTransactionFee', data.distributor_share_before_transaction_fee);
													this.set('distributorTransactionFee', data.distributor_transaction_fee);
													this.set('distributorShareAfterTransactionFee', data.distributor_share_after_transaction_fee);
													this.set('toProtectus', data.to_protectus);

													var groupsList = Ember['default'].A();
													var count = 0;

													for (var i = 0; i < data.groups.length; i++) {
																			var group = DistributorSubscriptionGroupModel['default'].create().load(data.groups[i]);
																			groupsList.pushObject(group);
																			count += group.get('count');
													}

													this.set('groups', groupsList);
													this.set('count', count);

													return this;
							}
	});

});