define('carlock-admin/services/export', ['exports', 'ember', 'carlock-admin/misc/obzorje-sifranti'], function (exports, Ember, sifranti) {

    'use strict';

    exports['default'] = Ember['default'].Service.extend({
        csv: Ember['default'].inject.service('csv'),
        excel: Ember['default'].inject.service('excel'),
        session: Ember['default'].inject.service('session'),
        access: Ember['default'].inject.service('access'),
        logger: Ember['default'].inject.service('logger'),
        i18n: Ember['default'].inject.service('i18n'),
        exportToCsv: function exportToCsv(data, filename) {
            this.get('csv')['export'](data, filename);
        },
        getListHeaders: function getListHeaders(list) {
            var itemsHeader = new Set();
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = list[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var _item = _step.value;

                    Object.keys(_item).forEach(function (k) {
                        return itemsHeader.add(k);
                    });
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator['return']) {
                        _iterator['return']();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return Array.from(itemsHeader).sort();
        },

        exportPurchaseReportItems: function exportPurchaseReportItems(items, csv) {
            this.get('logger').trace('service:export: Exporting purchase report items: csv=' + csv + ' count=' + items.length);
            var i18n = this.get('i18n');

            var data = [[i18n.t('report.subscription_report.purchase_uuid').toString(), i18n.t('report.subscription_report.date').toString(), i18n.t('report.subscription_report.vehicle_uuid').toString(), i18n.t('report.subscription_report.account_uuid').toString(), i18n.t('report.subscription_report.name').toString(), i18n.t('report.subscription_report.currency').toString(), i18n.t('report.subscription_report.price').toString(), i18n.t('report.subscription_report.distributor_price').toString(), i18n.t('report.subscription_report.platform').toString(), i18n.t('report.subscription_report.paid_to_protectus').toString(), i18n.t('report.subscription_report.base').toString(), i18n.t('report.subscription_report.discount').toString(), i18n.t('report.subscription_report.tax').toString(), i18n.t('report.subscription_report.tax_percentage').toString(), i18n.t('report.subscription_report.transaction_fee').toString(), i18n.t('report.subscription_report.distributor_share_before_transaction_fee').toString(), i18n.t('report.subscription_report.distributor_transaction_fee_percentage').toString(), i18n.t('report.subscription_report.distributor_transaction_fee_share').toString(), i18n.t('report.subscription_report.distributor_share_after_transaction_fee').toString(), i18n.t('report.subscription_report.to_protectus').toString()]];

            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                var row = [item.get('purchaseUuid').toString(), item.get('dateFormatted').toString(), item.get('vehicleUuid').toString(), item.get('accountUuid').toString(), i18n.t(item.get('descriptionKeyFormatted')).toString(), item.get('currency').toString(), (item.get('price') / 100).toString(), (item.get('distributorPrice') / 100).toString(), i18n.t(item.get('platformFormatted')).toString(), i18n.t(item.get('paidToProtectusFormatted')).toString(), (item.get('base') / 100).toString(), (item.get('discount') / 100).toString(), (item.get('tax') / 100).toString(), item.get('taxPercentage').toString(), (item.get('transactionFee') / 100).toString(), (item.get('distributorShareBeforeTransactionFee') / 100).toString(), item.get('transactionFeePercentage').toString(), (item.get('distributorTransactionFee') / 100).toString(), (item.get('distributorShareAfterTransactionFee') / 100).toString(), (item.get('toProtectus') / 100).toString()];
                data.push(row);
            }

            var from = this.get('dateFrom');
            var fromFormatted = from ? moment(from).format('l') : null;
            var to = this.get('dateTo');
            var toFormatted = to ? moment(to).format('l') : null;
            var name = i18n.t('report.subscription_report.title');

            if (from || to) {
                name += ' (' + fromFormatted + ' - ' + toFormatted + ')';
            }

            if (csv) {
                name += '.csv';
                this.get('csv')['export'](data, name);
            } else {
                name += '.xls';
                this.get('excel')['export'](data, name);
            }

            this.get('logger').trace('service:export: Exported purchase report items');
        },
        exportDevices: function exportDevices(items, csv) {
            this.get('logger').trace('service:export: Exporting devices: csv=' + csv + ' count=' + items.length);
            var isAdmin = this.get('access').isAdmin();
            var i18n = this.get('i18n');

            var data = [[i18n.t('devices.distributor').toString(), i18n.t('vehicle.device_type_title').toString(), i18n.t('vehicle.device_type_version_title').toString(), i18n.t('vehicle.device_serial').toString(), i18n.t('vehicle.device_imei').toString(), i18n.t('vehicle.sim_provider_title').toString(), i18n.t('vehicle.sim_iccid').toString(), i18n.t('vehicle.sim_msisdn').toString(), i18n.t('vehicle.user_email').toString(), i18n.t('vehicle.user_name').toString(), i18n.t('vehicle.user_phone_number').toString(), i18n.t('vehicle.user_country').toString(), i18n.t('vehicle.activation_date').toString(), i18n.t('vehicle.subscription_expiration_date').toString(), i18n.t('vehicle.state_last_seen').toString()]];

            // Items
            for (var j = 0; j < items.length; j++) {
                var item = items[j];
                var row = [item.get('distributorName').toString(), i18n.t(item.get('device.typeFormatted')).toString(), i18n.t(item.get('device.typeVersionFormatted')).toString(), item.get('device.serialNumber').toString(), item.get('device.imei').toString(), (isAdmin || item.get('device.distributorSim')) && item.get('device.sim') ? i18n.t(item.get('device.sim.providerFormatted')).toString() : '', (isAdmin || item.get('device.distributorSim')) && item.get('device.sim') ? item.get('device.sim.iccid').toString() : '', (isAdmin || item.get('device.distributorSim')) && item.get('device.sim') ? item.get('device.sim.msisdn').toString() : '', item.get('user.email').toString(), item.get('user.name').toString(), item.get('user.country.niceName').toString(), item.get('user.phoneNumber') ? item.get('user.phoneNumber').toString() : '', item.get('activationDate') ? item.get('activationDateFormatted').toString() : '', item.get('expirationDate') ? item.get('expirationDateFormatted').toString() : '', item.get('lastSeen') ? item.get('lastSeenFormatted').toString() : ''];

                data.push(row);
            }

            // Download
            var name = i18n.t('devices.export_file');

            if (csv) {
                name += '.csv';
                this.get('csv')['export'](data, name);
            } else {
                name += '.xls';
                this.get('excel')['export'](data, name);
            }

            this.get('logger').trace('service:export: Exported devices');
        },
        exportGiftCodes: function exportGiftCodes(items, csv) {
            this.get('logger').trace('service:export: Exporting gift codes: csv=' + csv + ' count=' + items.length);
            var i18n = this.get('i18n');

            // Header
            var data = [[i18n.t('gift_code.code').toString(), i18n.t('gift_code.offer').toString(), i18n.t('gift_code.date_created').toString(), i18n.t('gift_code.date_claimed').toString()]];

            // Items
            for (var j = 0; j < items.length; j++) {
                var item = items[j];
                var row = [item.get('codeFormatted').toString(), item.get('offer.descriptionKey') ? i18n.t(item.get('offer.descriptionKeyFormatted')).toString() : i18n.t(item.get('offerFormatted')).toString(), item.get('dateFormatted').toString(), item.get('claimDate') ? item.get('claimDateFormatted').toString() : ''];

                data.push(row);
            }

            // Download
            var name = i18n.t('gift_code.export_file');

            if (csv) {
                name += '.csv';
                this.get('csv')['export'](data, name);
            } else {
                name += '.xls';
                this.get('excel')['export'](data, name);
            }

            this.get('logger').trace('service:export: Exported gift codes');
        },
        exportPaypalAccountingData: function exportPaypalAccountingData(items, csv) {
            this.get('logger').trace('service:export: Exporting paypal data: count=' + items.length);

            var i18n = this.get('i18n');

            var numFormat = function numFormat(num) {
                return !num || num === '/' ? '/' : num.toLocaleString('sl-SI', { maximumFractionDigits: 2 });
            };

            var isEu = function isEu(c) {
                return ["Germany", "France", "UK", "Italy", "Spain", "Poland", "Romania", "Netherlands", "Belgium", "Greece", "Portugal", "Czech Republic", "Hungary", "Sweden", "Austria", "Bulgaria", "Denmark", "Finland", "Slovakia", "Ireland", "Croatia", "Lithuania", "Slovenia", "Latvia", "Estonia", "Cyprus", "Luxembourg", "Malta"].includes(c);
            };

            //Header
            var data = [[i18n.t('paypal_export.contact_name'), i18n.t('paypal_export.country'), i18n.t('paypal_export.invoice_number'), i18n.t('paypal_export.invoice_number_obzorje'), 'Besedilo', i18n.t('paypal_export.reference'), i18n.t('paypal_export.date'), 'Partner', 'Filter', 'Konto', 'Debet', 'Kredit', 'Osnova za DDV',
            //i18n.t('paypal_export.total'),
            i18n.t('paypal_export.total_in_euro'),
            //i18n.t('paypal_export.tax_total'),
            i18n.t('paypal_export.tax_total_in_euro'), i18n.t('paypal_export.invoice_amount_paid'), i18n.t('paypal_export.invoice_amount_due'),
            //i18n.t('paypal_export.invoice_amount_paid_in_euro'),
            //i18n.t('paypal_export.invoice_amount_due_in_euro'),
            i18n.t('paypal_export.description'), i18n.t('paypal_export.quantity'), i18n.t('paypal_export.discount'), i18n.t('paypal_export.currency'), i18n.t('paypal_export.eu_company_vat_number'), i18n.t('paypal_export.tax_label')]];

            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
                for (var _iterator2 = items[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var _item2 = _step2.value;

                    var partner = '';
                    var vatId = _item2.get('euCompanyVatNumber');
                    var isIsl = _item2.get('invoiceNumberObzorje').startsWith('ISL');

                    if (vatId) {
                        var p = sifranti['default'].sifrant.FIXTURES.findBy('vatId', vatId);
                        if (!p) {
                            p = sifranti['default'].sifrant.FIXTURES.findBy('name', _item2.get('name'));
                        }

                        if (p) {
                            partner = p.id;
                        } else {
                            partner = 'NOT_FOUND';
                        }
                    } else {
                        if (_item2.get('isStripe')) {
                            partner = '423';
                        } else if (_item2.get('currency') === 'EUR') {
                            partner = '4';
                        } else if (_item2.get('currency') === 'GBP') {
                            partner = '244';
                        } else {
                            partner = 'No VAT ID and paid in currency other than GBP or EUR';
                        }
                    }

                    for (var i = 0; i < 3; i++) {
                        var filter = 'terjatev';
                        var c = _item2.get('country');
                        var inEu = isEu(c);
                        var kredit = undefined,
                            type = undefined;
                        var total = _item2.get('totalInEuro');
                        var tax = _item2.get('taxTotalInEuro');
                        var debet = total;

                        var ddvBase = undefined;

                        if (_item2.get('currency') === 'EUR') {
                            ddvBase = _item2.get('invoiceAmountPaid') - tax;
                        } else {
                            ddvBase = _item2.get('invoiceAmountPaidInEuro') - tax;
                        }

                        if (isNaN(ddvBase)) {
                            ddvBase = '/';
                        } else {
                            ddvBase = ddvBase;
                        }

                        var konto = undefined;
                        if (vatId && inEu && c !== 'Slovenia') {
                            type = 'EU_COM';
                            konto = 1210;
                        } else if (inEu) {
                            type = 'EU';
                            konto = 1200;
                        } else {
                            type = 'WORLD';
                            konto = 1211;
                        }

                        if (i === 1) {
                            filter = 'prihodek';
                            debet = '';

                            if (type === 'EU_COM') {
                                konto = 7610;
                                kredit = total;
                            } else if (type === 'EU') {
                                var k = sifranti['default'].konto.FIXTURES.findBy('country', c);
                                konto = k ? k.prihodek : '/';
                                kredit = ddvBase;
                            } else {
                                konto = 7631;
                                kredit = (c === 'Slovenia' ? _item2.get('invoiceAmountPaid') : total) - tax;
                            }
                        } else if (i === 2) {
                            filter = 'ddv';
                            debet = '';

                            if (type === 'EU_COM') {
                                konto = 5020;
                                kredit = total;
                                debet = total;
                            } else if (type === 'EU') {
                                var k = sifranti['default'].konto.FIXTURES.findBy('country', c);
                                konto = k ? k.ddv : '/';
                                kredit = tax;
                            } else {
                                konto = 26000;
                                kredit = tax;
                            }
                        }

                        var month = new Date().getMonth();

                        data.push([_item2.get('name'), _item2.get('country'), _item2.get('invoiceNumber'), _item2.get('invoiceNumberObzorje').replace(/-0-0([0-9]{4})/g, '-' + month + '$1'), _item2.get('name') + ' ' + _item2.get('country'), _item2.get('reference'), _item2.get('dateFormatted'), partner, filter, konto, numFormat(debet), numFormat(kredit), numFormat(ddvBase),
                        //item.get('total'),
                        numFormat(_item2.get('totalInEuro')),
                        //item.get('taxTotal'),
                        numFormat(_item2.get('taxTotalInEuro')), numFormat(_item2.get('invoiceAmountPaid')), numFormat(_item2.get('invoiceAmountDue')),
                        //item.get('invoiceAmountPaidInEuro'),
                        //item.get('invoiceAmountDueInEuro'),
                        _item2.get('description'), _item2.get('quantity'), _item2.get('discount'), _item2.get('currency'), _item2.get('euCompanyVatNumber'), _item2.get('taxLabel')]);
                    }
                }
            } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion2 && _iterator2['return']) {
                        _iterator2['return']();
                    }
                } finally {
                    if (_didIteratorError2) {
                        throw _iteratorError2;
                    }
                }
            }

            var name = 'paypal-export';

            if (csv) {
                name += '.csv';
                this.get('csv')['export'](data, name);
            } else {
                name += '.xls';
                this.get('excel')['export'](data, name);
            }
        },
        exportStripeAccountingData: function exportStripeAccountingData(text, csv) {
            this.get('logger').trace('service:export: Exporting stripe data: count=' + text.length);
            saveAs(new Blob([text], { type: "data:text/csv;charset=utf-8" }), 'stripe-export.csv');
        },
        exportUsTaxData: function exportUsTaxData(data, csv) {
            this.get('logger').trace('service:export: Exporting us tax data: count=' + data.length);
            var name = 'tax-export';

            if (csv) {
                name += '.csv';
                this.get('csv')['export'](data, name);
            } else {
                name += '.xls';
                this.get('excel')['export'](data, name);
            }
        },
        exportShippicaData: function exportShippicaData(purchase) {
            var address = purchase.shipment_address;
            var header = ['customerordernumber', 'customername', 'userid'];
            var row = [purchase.payment_id, 'PROTECTUS TECHNOLOGIES, INC', ''];
            var date = moment();

            header.push('shiptofirstname', 'shiptolastname');
            var name = address.name.split(' ');
            row.push(name[0], name.slice(1).join(' '));

            header.push('shiptoaddress1', 'shiptoaddress2', 'shiptoaddress3', 'shiptocity', 'shiptostate', 'shiptozip', 'shiptocountry');
            row.push(address.address, address.address2, '', address.city, address.state_iso_code, address.zip_code, address.country_iso_code);

            header.push('shiptophonenumber', 'shiptoemail');
            row.push('', purchase.email);

            header.push('servicerequested', 'releasedate', 'returnaddressname', 'returnaddress1', 'returnaddress2', 'returnaddresscity', 'returnaddressstate', 'returnaddresszip', 'returnaddresscountry', 'returnaddressphone');
            row.push('Ground', date.format('MM/DD/YYYY'), 'Protectus Technologies', '753 West 1700 South', '', 'Salt Lake City', 'UT', '84104', 'US', '');

            header.push('must_ship_together', 'customeruniquelineid', 'itemvariationname', 'salestitle', 'quantity', 'currency', 'recipientprice', 'recipientshipprice', 'recipientdiscount', 'recipientshipdiscount', 'recipientdiscountid');
            var data = [header];

            var getItemId = function getItemId(item) {
                switch (item.offer.description_key) {
                    case 'device_promo':
                        return 'CL200';
                    case 'sim':
                        return '0L-VIF0-ISM1';
                    case 'micro_sim':
                        return 'CLSim';
                    case 'tpsim':
                        return 'Tpsim';
                    default:
                        return;
                }
            };

            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
                for (var _iterator3 = purchase.items[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                    var _item3 = _step3.value;

                    if (!_item3.offer) {
                        continue;
                    }

                    var itemId = getItemId(_item3);
                    if (!itemId) continue;

                    var itemrow = ['1', '', itemId, '', _item3.quantity, '', '', '', '', ''];
                    data.push(row.concat(itemrow));
                }
            } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion3 && _iterator3['return']) {
                        _iterator3['return']();
                    }
                } finally {
                    if (_didIteratorError3) {
                        throw _iteratorError3;
                    }
                }
            }

            this.get('logger').trace('service:export: Exporting shippica data: count=' + data.length);
            var filename = 'order-' + new Date().toString() + '.xls';
            this.get('excel')['export'](data, filename);
        },
        exportCohorts: function exportCohorts(cohortsList, filename) {
            if (!cohortsList) {
                return;
            }
            var cohorts = cohortsList[0];
            var header = Object.keys(cohorts).filter(function (key) {
                return key !== 'cohortItems';
            });
            var rows = [];
            rows.push(header);
            var aggregationsRow = [];

            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
                for (var _iterator4 = header[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                    var key = _step4.value;

                    var cell = cohorts[key];
                    if (key === 'utmCountMap') {
                        cell = cohorts.get('utmCountMapFormatted');
                    }
                    aggregationsRow.push(cell);
                }
            } catch (err) {
                _didIteratorError4 = true;
                _iteratorError4 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion4 && _iterator4['return']) {
                        _iterator4['return']();
                    }
                } finally {
                    if (_didIteratorError4) {
                        throw _iteratorError4;
                    }
                }
            }

            rows.push(aggregationsRow);
            rows.push([]);

            if (cohorts.cohortItems) {
                var itemsHeader = this.getListHeaders(cohorts.cohortItems);
                rows.push(itemsHeader);

                var _iteratorNormalCompletion5 = true;
                var _didIteratorError5 = false;
                var _iteratorError5 = undefined;

                try {
                    for (var _iterator5 = cohorts.cohortItems[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                        var _item4 = _step5.value;

                        var row = [];
                        var _iteratorNormalCompletion6 = true;
                        var _didIteratorError6 = false;
                        var _iteratorError6 = undefined;

                        try {
                            for (var _iterator6 = itemsHeader[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                                var itemField = _step6.value;

                                var cell = _item4[itemField];
                                if (itemField.includes('Date')) {
                                    cell = _item4.get(itemField + 'Formatted');
                                }
                                row.push(cell);
                            }
                        } catch (err) {
                            _didIteratorError6 = true;
                            _iteratorError6 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion6 && _iterator6['return']) {
                                    _iterator6['return']();
                                }
                            } finally {
                                if (_didIteratorError6) {
                                    throw _iteratorError6;
                                }
                            }
                        }

                        rows.push(row);
                    }
                } catch (err) {
                    _didIteratorError5 = true;
                    _iteratorError5 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion5 && _iterator5['return']) {
                            _iterator5['return']();
                        }
                    } finally {
                        if (_didIteratorError5) {
                            throw _iteratorError5;
                        }
                    }
                }
            }

            this.get('csv')['export'](rows, filename);
        },
        exportPurchases: function exportPurchases(purchaseList, filename) {
            if (!purchaseList) {
                return;
            }
            var rows = [];
            var purchaseFields = this.getListHeaders(purchaseList);
            rows.push(purchaseFields);

            var _iteratorNormalCompletion7 = true;
            var _didIteratorError7 = false;
            var _iteratorError7 = undefined;

            try {
                for (var _iterator7 = purchaseList[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
                    var purchase = _step7.value;

                    var row = [];
                    var _iteratorNormalCompletion8 = true;
                    var _didIteratorError8 = false;
                    var _iteratorError8 = undefined;

                    try {
                        for (var _iterator8 = purchaseFields[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
                            var field = _step8.value;

                            var cell = purchase[field];
                            if (field.includes('Date')) {
                                cell = item.get(field + 'Formatted');
                            }

                            row.push(cell);
                        }
                    } catch (err) {
                        _didIteratorError8 = true;
                        _iteratorError8 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion8 && _iterator8['return']) {
                                _iterator8['return']();
                            }
                        } finally {
                            if (_didIteratorError8) {
                                throw _iteratorError8;
                            }
                        }
                    }

                    rows.push(row);
                }
            } catch (err) {
                _didIteratorError7 = true;
                _iteratorError7 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion7 && _iterator7['return']) {
                        _iterator7['return']();
                    }
                } finally {
                    if (_didIteratorError7) {
                        throw _iteratorError7;
                    }
                }
            }

            this.get('csv')['export'](rows, filename);
        }
    });

});