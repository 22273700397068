define('carlock-admin/routes/index', ['exports', 'ember', 'carlock-admin/routes/authenticated'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Base['default'].extend({
        redirect: function redirect() {
            if (this.get('session.isAuthenticated')) {
                this.logger.trace('route:index: Redirecting to profile');
                this.transitionTo('c.profile');
            } else {
                this.logger.trace('route:index: Redirecting to login');
                this.transitionTo('c.login');
            }
        }
    });

});