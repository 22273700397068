define('carlock-admin/helpers/format-date', ['exports'], function (exports) {

    'use strict';

    var formatDate = {
        get: function get(name) {
            if (name.endsWith('Formatted')) {
                var value = this.get(name.substring(0, name.length - 9));
                var timeZoneId = this.get('profile.instance.timeZoneId');

                if (value) {
                    var timeZone = null;

                    if (timeZoneId) timeZone = moment.tz.zone(timeZoneId);

                    if (!timeZone) timeZoneId = 'UTC';

                    return new moment.tz(value, timeZoneId).format('YYYY-MM-DD');
                }

                return null;
            } else {
                throw 'Must end with \"Formatted\"';
            }
        }
    };

    exports['default'] = formatDate;

});