define('carlock-admin/components/lists/device-list', ['exports', 'ember', 'carlock-admin/mixins/base-list', 'carlock-admin/models/c/basic-device'], function (exports, Ember, BaseList, BasicDeviceModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(BaseList['default'], {
        skipLoading: Ember['default'].computed('filter', function () {
            return this.get('filter') === null;
        }),
        searchTags: ["email", "name", "phone_number", "imei", "serial_number", "iccid", "msisdn"],
        patternMatching: false,

        prepareLoadRequest: function prepareLoadRequest(filter, sort, descending, page, pageSize) {
            var patternMatching = this.get('patternMatching');
            if (this.get('access').hasVehicleRoAccess()) {
                return this.carlock.getDevices(filter, sort, descending, page, pageSize, patternMatching);
            }

            return this.carlock.getDistributorDevices(filter, sort, descending, page, pageSize, patternMatching);
        },
        loadRequestSucceeded: function loadRequestSucceeded(response) {
            var list = this.get('model');
            this.set('totalCount', response.total_count);

            for (var i = 0; i < response.devices.length; i++) {
                list.pushObject(BasicDeviceModel['default'].create().load(response.devices[i]));
            }
        },
        exportItems: function exportItems(csv) {
            this.logger.trace('controller:device-list: Exporting items ' + csv);
            var _this = this;
            var page = 0;
            var pageSize = 2000; // Max items
            _this.set('exporting', true);

            var prepare = function prepare() {
                var role = _this.get('role');
                var filter = _this.get('filter');
                var sort = _this.get('sort');
                var descending = _this.get('descending');
                var patternMatching = _this.get('patternMatching');

                if (_this.get('access').hasVehicleRoAccess()) {
                    return _this.carlock.getDevices(filter, sort, descending, page, pageSize, patternMatching);
                }

                return _this.carlock.getDistributorDevices(filter, sort, descending, page, pageSize, patternMatching);
            };

            var success = function success(response) {
                var list = Ember['default'].A();

                for (var i = 0; i < response.devices.length; i++) {
                    list.pushObject(BasicDeviceModel['default'].create().load(response.devices[i]));
                }

                // Header
                _this['export'].exportDevices(list, csv);
                _this.set('exporting', false);
            };

            var error = function error() {
                _this.set('exporting', false);
            };

            this.load(prepare, success, error);
        },
        actions: {
            exportCsv: function exportCsv() {
                this.exportItems(true);
            },
            exportXls: function exportXls() {
                this.exportItems(false);
            },
            addVehicle: function addVehicle() {
                this.toggleProperty('showAddVehicleDialog');
            }
        }
    });

});