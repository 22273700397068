define('carlock-admin/components/lists/user-list', ['exports', 'ember', 'carlock-admin/mixins/base-list', 'carlock-admin/models/c/basic-user'], function (exports, Ember, BaseList, BasicUserModel) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend(BaseList['default'], {
        filterObserver: Ember['default'].observer('filter', function () {
            Ember['default'].run.once(this, this.reloadData);
        }),
        prepareLoadRequest: function prepareLoadRequest(filter, sort, descending, page, pageSize) {
            if (this.get('access').hasVehicleRoAccess()) {
                return this.carlock.getUsers(filter, sort, descending, page, pageSize);
            }

            return this.carlock.getDistributorUsers(filter, sort, descending, page, pageSize);
        },
        loadRequestSucceeded: function loadRequestSucceeded(response) {
            var list = this.get('model');

            for (var i = 0; i < response.users.length; i++) {
                list.pushObject(BasicUserModel['default'].create().load(response.users[i]));
            }
        }
    });

});