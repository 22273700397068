define('carlock-admin/models/c/purchase/offer', ['exports', 'ember', 'carlock-admin/models/c/purchase/distributor', 'carlock-admin/helpers/format-date'], function (exports, Ember, DistributorModel, FormatDate) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
				uuid: null,
				distributor: null,
				distributorPrice: null,
				descriptionKey: null,
				descriptionKeyFormatted: Ember['default'].computed('descriptionKey', function () {
							return 'offer.' + this.get('descriptionKey');
				}),
				subscriptionDays: null,
				smsNotifications: null,
				callNotifications: null,
				price: null,
				priceFormatted: Ember['default'].computed('price', 'currency', function () {
							var price = this.get('price');
							var currency = this.get('currency');
							if (!price || !currency) {
										return;
							}

							return price / 100.0 + ' ' + currency;
				}),
				currency: null,
				start: null,
				startFormatted: Ember['default'].computed('start', 'profile.instance.timeZoneId', FormatDate['default']),
				end: null,
				endFormatted: Ember['default'].computed('end', 'profile.instance.timeZoneId', FormatDate['default']),
				deviceType: null,
				countryIsoCode: null,
				special: null,
				specialFormatted: Ember['default'].computed('special', function () {
							return this.get('special') ? 'misc.yes' : 'misc.no';
				}),
				load: function load(data) {
							this.set('uuid', data.uuid);
							this.set('distributor', DistributorModel['default'].create().load(data.distributor));
							this.set('distributorPrice', data.distributor_price);
							this.set('descriptionKey', data.description_key);
							this.set('subscriptionDays', data.subscription_days);
							this.set('smsNotifications', data.sms_notifications);
							this.set('callNotifications', data.call_notifications);
							this.set('price', data.price);
							this.set('currency', data.currency);
							this.set('start', data.start);
							this.set('end', data.end);
							this.set('deviceType', data.device_type);
							this.set('countryIsoCode', data.country_iso_code);
							this.set('special', data.special);
							return this;
				}
	});

});