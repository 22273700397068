define('carlock-admin/models/c/vehicle/lock', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Object.extend({
        locked: null,
        lockedFormatted: Ember['default'].computed('locked', function () {
            return this.get('locked') ? 'misc.yes' : 'misc.no';
        }),
        lockedSince: null,
        lockedSinceFormatted: Ember['default'].computed('lockedSince', function () {
            var date = this.get('lockedSince');

            if (date) {
                return moment.tz(date, 'UTC').format('YYYY-MM-DD HH:mm:ss');
            }

            return null;
        }),
        unlockedSince: null,
        unlockedSinceFormatted: Ember['default'].computed('unlockedSince', function () {
            var date = this.get('unlockedSince');

            if (date) {
                return moment.tz(date, 'UTC').format('YYYY-MM-DD HH:mm:ss');
            }

            return null;
        }),

        inFollowMode: null,
        inFollowModeFormatted: Ember['default'].computed('inFollowMode', function () {
            return this.get('inFollowMode') ? 'misc.yes' : 'misc.no';
        }),
        source: null,
        sourceFormatted: Ember['default'].computed('source', function () {
            return 'vehicle.lock_source.' + this.get('source').toLowerCase();
        }),
        unlockedUnderScheduledLock: null,
        unlockedUnderScheduledLockFormatted: Ember['default'].computed('unlockedUnderScheduledLock', function () {
            return this.get('unlockedUnderScheduledLock') ? 'misc.yes' : 'misc.no';
        }),
        latitude: null,
        longitude: null,
        load: function load(data) {
            this.set('locked', data.locked);
            this.set('lockedSince', data.locked_since);
            this.set('unlockedSince', data.unlocked_since);
            this.set('inFollowMode', data.in_follow_mode);
            this.set('source', data.source);
            this.set('unlockedUnderScheduledLock', data.unlocked_under_scheduled_lock);
            this.set('latitude', data.latitude);
            this.set('longitude', data.longitude);
            return this;
        }
    });

});