define('carlock-admin/components/dialogs/modal-dialog', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].Component.extend({
        title: 'misc.confirm',
        closeButtonVisible: true,
        confirmButtonVisible: true,
        footerVisible: true,
        visibleObserver: Ember['default'].observer('visible', function () {
            var visible = this.get('visible');

            if (!visible) {
                this.sendAction('close');
                this.set('localizedError', null);
                this.set('showErrors', false);
            } else {
                this.sendAction('show');
            }
        }),
        actions: {
            hideError: function hideError() {
                this.logger.trace('component:modal-dialog: Hiding error');
                this.set('localizedError', null);
            },
            close: function close() {
                this.logger.trace('component:modal-dialog: Closing modal dialog');
                this.set('visible', false);
            },
            confirm: function confirm() {
                this.logger.trace('component:modal-dialog: Confirming action');
                this.sendAction('confirm');
            },
            preventBubbling: function preventBubbling() {
                // To prevent mouse click inside the modal form closing it
            }
        }
    });

});