define('carlock-admin/controllers/c/user', ['exports', 'ember', 'carlock-admin/mixins/base'], function (exports, Ember, Base) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        queryParams: ['uuid'],
        actions: {
            toggleUserDebugModeDialog: function toggleUserDebugModeDialog() {
                this.toggleProperty('showUserDebugModeDialog');
            },
            showDevice: function showDevice(device) {
                this.logger.trace('controller: Redirecting to vehicle');
                this.transitionToRoute('c.vehicle', {
                    queryParams: {
                        uuid: device.get('vehicleUuid')
                    }
                });
            },
            toggleSendPushNotificationDialog: function toggleSendPushNotificationDialog() {
                this.toggleProperty('showSendPushNotificationDialog');
            },
            refresh: function refresh() {
                this.get('target.router').refresh();
            },
            clearLogin: function clearLogin(uuid) {
                var _this = this;

                this.logger.trace('controller:user: Clearing login ' + uuid);
                var logins = this.get('model.logins').filter(function (l) {
                    return l.uuid === uuid;
                });

                if (!logins) {
                    return; //No login found
                }

                var prepare = function prepare() {
                    var token = _this.hash;

                    if (!token) {
                        token = _this.get('sessionData.token');
                    }

                    return _this.carlock.logout(logins[0].get('uuid'));
                };

                var success = function success() {
                    _this.logger.trace('controller:user Expired login');
                    _this.set('model.logins', _this.get('model.logins').filter(function (l) {
                        return l.uuid !== uuid;
                    }));
                };

                this.save(prepare, success);
            },

            toggleHelped: function toggleHelped() {
                var _this2 = this;

                this.logger.trace('controller:user: Setting helped');
                var uuid = this.get('model.user.uuid');
                var helped = this.get('model.user.helped');

                var prepare = function prepare() {
                    return _this2.carlock.setUserHelped(uuid, helped);
                };

                var success = function success() {};

                this.save(prepare, success);
            }
        }
    });

});