define('carlock-admin/controllers/c/purchases/reports/us-vat-report', ['exports', 'carlock-admin/mixins/base', 'carlock-admin/models/state'], function (exports, Base, StateModel) {

    'use strict';

    exports['default'] = Ember.Controller.extend(Base['default'], {
        taxDateFrom: null,
        taxDateTo: null,
        generateTaxReport: function generateTaxReport() {
            var _this = this;

            var from = this.get('taxDateFrom');
            var to = this.get('taxDateTo');

            if (!from) {
                return;
            }

            var prepare = function prepare() {
                return _this.carlock.getTaxDataByUsStates(from, to);
            };

            var success = function success(response) {
                if (response) {
                    var data = [];
                    var sums = {};

                    var _iteratorNormalCompletion = true;
                    var _didIteratorError = false;
                    var _iteratorError = undefined;

                    try {
                        for (var _iterator = response[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                            var state = _step.value;

                            sums[state.state_iso_code] = {
                                count: state.count,
                                sum: state.sum
                            };
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion && _iterator['return']) {
                                _iterator['return']();
                            }
                        } finally {
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }

                    var includeCountries = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachussetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Nebraska', 'Nevada', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = StateModel['default'].FIXTURES[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var state = _step2.value;

                            if (!includeCountries.includes(state.name)) {
                                continue;
                            }

                            var r = sums[state.isoCode];
                            var sum = r ? r.sum : 0;
                            var count = r ? r.count : 0;
                            data.push([state.name, count, sum]);
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2['return']) {
                                _iterator2['return']();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }

                    data.sort(function (a, b) {
                        return a[0].localeCompare(b[0]);
                    });
                    data.unshift(['State', '# transactions', 'Sum in cents']);
                    _this['export'].exportUsTaxData(data, true);
                }
            };

            this.load(prepare, success);
        },
        actions: {
            generateTaxReport: function generateTaxReport() {
                this.generateTaxReport();
            }
        }
    });

});