define('carlock-admin/models/c/vehicle/vehicle-other-settings', ['exports', 'ember', 'carlock-admin/models/time-zone', 'carlock-admin/models/car-manufacturer'], function (exports, Ember, TimeZoneModel, CarManufacturerModel) {

	'use strict';

	exports['default'] = Ember['default'].Object.extend({
				timeZoneId: null,
				timeZone: null,
				smartCarlock: null,
				smartCarlockFormatted: Ember['default'].computed('smartCarlock', function () {
							return this.get('smartCarlock') ? 'misc.yes' : 'misc.no';
				}),
				manufacturerId: null,
				manufacturer: null,
				model: null,
				speedLimit: null,
				load: function load(data) {
							this.set('timeZoneId', data.time_zone_id);
							this.set('timeZone', TimeZoneModel['default'].FIXTURES.findBy('id', data.time_zone_id));
							this.set('smartCarlock', data.smart_carlock);
							this.set('manufacturerId', data.manufacturer_id);
							this.set('manufacturer', CarManufacturerModel['default'].FIXTURES.findBy('id', data.manufacturer_id));
							this.set('model', data.model);
							this.set('speedLimit', data.speed_limit);
							return this;
				}
	});

});