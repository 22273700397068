define('carlock-admin/session-stores/application', ['exports', 'ember-simple-auth/session-stores/cookie'], function (exports, Cookie) {

    'use strict';

    exports['default'] = Cookie['default'].extend({
        sameSite: 'None',
        _write: function _write(value, expiration) {
            var path = '; path=/';
            var domain = Ember.isEmpty(this.cookieDomain) ? '' : '; domain=' + this.cookieDomain;
            var expires = Ember.isEmpty(expiration) ? '' : '; expires=' + new Date(expiration).toUTCString();
            var secure = !!this._secureCookies ? ';secure' : '';
            var sameSite = secure ? '; SameSite=' + this.sameSite : '';
            document.cookie = this.cookieName + '=' + encodeURIComponent(value) + domain + path + expires + secure + sameSite;
            if (expiration !== null) {
                var cachedExpirationTime = this._read(this.cookieName + ':expiration_time');
                document.cookie = this.cookieName + ':expiration_time=' + encodeURIComponent(this.cookieExpirationTime || cachedExpirationTime) + domain + path + expires + secure;
            }
        }
    });

});