define('carlock-admin/routes/c/forgot-password', ['exports', 'ember', 'carlock-admin/routes/unauthenticated', 'carlock-admin/models/c/forgot-password'], function (exports, Ember, Base, ForgotPasswordModel) {

    'use strict';

    exports['default'] = Base['default'].extend({
        setupController: function setupController(controller, model) {
            this.logger.trace('controller:forgot-password: Setting up controller');
            this._super(controller, model);
        },
        model: function model() {
            return ForgotPasswordModel['default'].create();
        }
    });

});