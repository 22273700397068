define('carlock-admin/controllers/c/purchases/reports/distributor-report', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/date-range-option', 'carlock-admin/models/c/purchase/report/distributor-subscription-report', 'carlock-admin/models/c/vehicle/distributor'], function (exports, Ember, Base, DateRangeOptionModel, DistributorSubscriptionReportModel, DistributorModel) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        distributorUuid: Ember['default'].computed.alias('session.session.content.authenticated.distributor_uuid'),
        distributors: null,
        dateRangeList: DateRangeOptionModel['default'].FIXTURES,
        dateRange: null,
        preventReset: false,
        dateRangeObserver: Ember['default'].observer('dateRange', 'profile.instance.timeZoneId', function () {
            this.logger.trace('controller:subscription-report: Option or timezone changed');
            var option = this.get('dateRange');

            if (!option) {
                this.logger.trace('controller:subscription-report: Invalid option selected');
                return;
            }

            if (option.id === 3) {
                this.logger.trace('controller:subscription-report: Selected custom date range');
                return;
            }

            this.logger.trace('controller:subscription-report: Selected option: ' + option.id);
            var timeZoneId = this.get('profile.instance.timeZoneId');
            var to, from;

            if (!timeZoneId) {
                timeZoneId = 'UTC';
            }

            if (option.id == 1) {
                to = moment().tz(timeZoneId).millisecond(0).second(0).minute(0).hour(0);
                from = moment(to).date(1);
                to.add(1, 'days');
            } else {
                to = moment().tz(timeZoneId).millisecond(0).second(0).minute(0).hour(0).date(1);
                from = moment(to).subtract(1, 'months');
            }

            this.set('preventReset', true);
            this.set('dateFrom', from.utc().valueOf());
            this.set('dateTo', to.utc().valueOf());
            this.set('preventReset', false);
            this.logger.trace('controller:subscription-report: Set dateFrom: ' + this.get('dateFrom'));
            this.logger.trace('controller:subscription-report: Set dateTo: ' + this.get('dateTo'));
        }),
        customDateRangeObserver: Ember['default'].observer('dateFrom', 'dateTo', function () {
            if (!this.get('preventReset')) {
                this.set('dateRange', DateRangeOptionModel['default'].FIXTURES[2]);
            }
        }),
        prepareItems: function prepareItems() {
            this.logger.trace('controller:subscription-report: Preparing items');
            var groups = this.get('model.groups');
            var items = [];
            var indexes = [];

            for (var i = 0; i < groups.length; i++) {
                indexes[i] = 0;
            }

            var getNextItem = function getNextItem() {
                var firstIndex = null;
                var first = null;

                for (var n = 0; n < groups.length; n++) {
                    var groupItems = groups[n].get('items');

                    if (groupItems.length <= indexes[n]) {
                        continue;
                    }

                    var candidate = groups[n].get('items')[indexes[n]].get('date');

                    if (first == null || candidate < first) {
                        firstIndex = n;
                        first = candidate;
                    }
                }

                if (first == null) {
                    return null;
                }

                return groups[firstIndex].get('items')[indexes[firstIndex]++];
            };

            var item;

            while ((item = getNextItem()) != null) {
                items.push(item);
            }

            return items;
        },
        runLoadDistributors: function runLoadDistributors() {
            var _this2 = this;

            Ember['default'].run.scheduleOnce('afterRender', function () {
                _this2.loadDistributors();
            });
        },
        loadDistributors: function loadDistributors() {
            var _this = this;

            if (!_this.get('access').isAdmin()) {
                return;
            }

            var prepare = function prepare() {
                return _this.carlock.getDistributors();
            };

            var success = function success(response) {
                var list = [];

                for (var i = 0; i < response.length; i++) {
                    list.pushObject(DistributorModel['default'].create().load(response[i]));
                }

                _this.distributors = list;
            };

            this.load(prepare, success);
        },
        actions: {
            togglePickDistributorDialog: function togglePickDistributorDialog() {
                this.toggleProperty('showPickDistributorDialog');
            },
            selectDistributor: function selectDistributor(distributor) {
                this.set('distributor', distributor);
            },
            generate: function generate() {
                this.logger.trace('controller:subscription-report: Getting report');
                var _this = this;

                var prepare = function prepare() {
                    var dateFrom = _this.get('dateFrom');
                    var dateTo = _this.get('dateTo');
                    var uuid = _this.get('distributor.uuid');

                    if (!uuid) {

                        //If no distributor is selected, then calculate total from all distributors
                        if (_this.get('access').isAdmin()) {
                            var distributors = _this.get('distributors');
                            var distributorIterator = _this.get('distributorIterator');

                            if (distributors == null) {
                                _this.prepareLoadRequest();
                                return;
                            } else {
                                if (!distributorIterator) {
                                    distributorIterator = 0;
                                    _this.set('distributorIterator', 0);
                                    _this.set('totalPayToProtectus', 0);
                                }

                                uuid = distributors[distributorIterator].get('uuid');
                            }
                        } else {
                            uuid = _this.get('distributorUuid');
                        }
                    }

                    return _this.carlock.getDistributorSubscriptionReport(uuid, dateFrom, dateTo);
                };

                var success = function success(response) {
                    var subscriptionModel = DistributorSubscriptionReportModel['default'].create().load(response);
                    var distributorIterator = _this.get('distributorIterator');
                    var distributors = _this.get('distributors');
                    var totalPayToProtectus = _this.get('totalPayToProtectus');

                    if (distributorIterator || distributorIterator === 0) {
                        if (_this.get('distributorUuid') === distributors[distributorIterator].get('uuid')) {
                            _this.set('model', subscriptionModel);
                        }

                        _this.set('totalPayToProtectus', (totalPayToProtectus ? totalPayToProtectus : 0) + subscriptionModel.get('toProtectus'));
                        distributorIterator++;
                        _this.set('distributorIterator', distributorIterator);

                        //Load next distributor
                        if (distributorIterator < distributors.length) {
                            _this.set('loading', false);
                            _this.load(prepare, success);
                        } else {
                            _this.set('distributorIterator', null);
                        }
                    } else {
                        _this.set('model', subscriptionModel);
                        _this.set('totalPayToProtectus', 0);
                    }
                };

                this.load(prepare, success);
            },
            exportGroupCsv: function exportGroupCsv(group) {
                this['export'].exportPurchaseReportItems(group.get('items'), true);
            },
            exportGroupXls: function exportGroupXls(group) {
                this['export'].exportPurchaseReportItems(group.get('items'), false);
            },
            exportCsv: function exportCsv() {
                this['export'].exportPurchaseReportItems(this.prepareItems(), true);
            },
            exportXls: function exportXls() {
                this['export'].exportPurchaseReportItems(this.prepareItems(), false);
            }
        }
    });

});