define('carlock-admin/models/state', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var state = Ember['default'].Object.extend({
        isoCode: null,
        name: null,
        niceName: Ember['default'].computed('isoCode', 'name', function () {
            return this.get('name') + ' - ' + this.get('isoCode');
        })
    });

    state.FIXTURES = [{ isoCode: 'AL', name: 'Alabama' }, { isoCode: 'AK', name: 'Alaska' }, { isoCode: 'AZ', name: 'Arizona' }, { isoCode: 'AR', name: 'Arkansas' }, { isoCode: 'CA', name: 'California' }, { isoCode: 'CO', name: 'Colorado' }, { isoCode: 'CT', name: 'Connecticut' }, { isoCode: 'DC', name: 'District of Columbia' }, { isoCode: 'DE', name: 'Delaware' }, { isoCode: 'FL', name: 'Florida' }, { isoCode: 'GA', name: 'Georgia' }, { isoCode: 'HI', name: 'Hawaii' }, { isoCode: 'ID', name: 'Idaho' }, { isoCode: 'IL', name: 'Illinois' }, { isoCode: 'IN', name: 'Indiana' }, { isoCode: 'IA', name: 'Iowa' }, { isoCode: 'KS', name: 'Kansas' }, { isoCode: 'KY', name: 'Kentucky' }, { isoCode: 'LA', name: 'Louisiana' }, { isoCode: 'ME', name: 'Maine' }, { isoCode: 'MD', name: 'Maryland' }, { isoCode: 'MA', name: 'Massachussetts' }, { isoCode: 'MI', name: 'Michigan' }, { isoCode: 'MN', name: 'Minnesota' }, { isoCode: 'MS', name: 'Mississippi' }, { isoCode: 'MO', name: 'Missouri' }, { isoCode: 'MT', name: 'Montana' }, { isoCode: 'NE', name: 'Nebraska' }, { isoCode: 'NV', name: 'Nevada' }, { isoCode: 'NH', name: 'New Hampshire' }, { isoCode: 'NJ', name: 'New Jersey' }, { isoCode: 'NM', name: 'New Mexico' }, { isoCode: 'NY', name: 'New York' }, { isoCode: 'NC', name: 'North Carolina' }, { isoCode: 'ND', name: 'North Dakota' }, { isoCode: 'OH', name: 'Ohio' }, { isoCode: 'OK', name: 'Oklahoma' }, { isoCode: 'OR', name: 'Oregon' }, { isoCode: 'PA', name: 'Pennsylvania' }, { isoCode: 'RI', name: 'Rhode Island' }, { isoCode: 'SC', name: 'South Carolina' }, { isoCode: 'SD', name: 'South Dakota' }, { isoCode: 'TN', name: 'Tennessee' }, { isoCode: 'TX', name: 'Texas' }, { isoCode: 'UT', name: 'Utah' }, { isoCode: 'VT', name: 'Vermont' }, { isoCode: 'VA', name: 'Virginia' }, { isoCode: 'WA', name: 'Washington' }, { isoCode: 'WV', name: 'West Virginia' }, { isoCode: 'WI', name: 'Wisconsin' }, { isoCode: 'WY', name: 'Wyoming' }];

    state.getNiceNames = function () {
        var list = Ember['default'].A();

        for (var i = 0; i < state.FIXTURES.length; i++) {
            var fs = state.FIXTURES[i];
            var s = state.create();
            s.set('isoCode', fs.isoCode);
            s.set('name', fs.name);
            list.push(s);
        }

        return list;
    };

    exports['default'] = state;

});