define('carlock-admin/initializers/profile', ['exports'], function (exports) {

    'use strict';

    exports.initialize = initialize;

    function initialize(application) {
        application.inject('model', 'profile', 'service:profile');
        application.inject('controller', 'profile', 'service:profile');
        application.inject('route', 'profile', 'service:profile');
        application.inject('component', 'profile', 'service:profile');
    }

    exports['default'] = {
        name: 'profile',
        initialize: initialize
    };

});